
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Container, Row, Col, FormGroup, Label, InputGroup, InputGroupAddon, InputGroupText, Input, Card, CardBody } from 'reactstrap';
//import homepage from './homepage.css'
//import ContactUsForm from './ContactUsForm'
//import actions from './actions'



class PrivacyPolicy extends Component {

    constructor(props) {
        super(props);

    }



    componentDidMount() {
        window.scrollTo(0, 0);
    }



    render() {

        return (

            <div className="my-5 pt-sm-5">
            <Container className="formBackground">
              
                        <div className="animated fadeIn">
                            <Row>

                                <Col xs="12" sm="12" lg="12">
                                    <div className="text-center">
                                        <h1><b>Privacy Policy</b></h1>
                                        <hr style={{width:"25%", height:"100%", backgroundColor:"#FFDF00", border:"none", height:"5px"}} />
                                    </div>
                                </Col>
                            </Row>


                            <br />
                            <br />

                         

                                    <Row>
                                        <Col xs="12" sm="12" lg="12">
                                            
                                                <h2><b>Who We Are</b></h2>
                                      
                                        </Col>
                                    </Row>

                                    <br />

                                    <Row>

                                        <Col xs="12" sm="12" lg="12">

                                        <p>

                                            This Privacy Notice outlines how GD Valueguard Sdn Bhd ("Insubee") collects, uses, 
                                            maintains and discloses your personal data in respect of commercial transactions and 
                                            how Insubee safeguards the personal data in pursuant to the Personal Data Protection Act 2010. 
                                            For more information, please visit 
                                            <a href="https://www.gdexpress.com/system/assets/183/Privacy%20Notice.pdf" target="_blank"> HERE</a>.

                                            

                                        </p>
                                          

                                        </Col>

                                    </Row>

                        <br />

                        </div>
                   

                <br />
                </Container>

            </div>
        );
    }
}



//const mapDispatchToProps = dispatch => {
//return {


// contactUS: (content) => {
// dispatch(actions.contactUs(content));
//}

//}
//}

//const mapStateToProps = state => {

//const { home } = state || {}; // entire Redux store state (the same value returned by a call to store.getState()).  
//return { home };
//};



//export default connect(
//mapStateToProps,
//mapDispatchToProps
//)(ContactUs)

export default PrivacyPolicy;
