import React, { Component, useState } from 'react';
import { connect } from 'react-redux';
import { Container, Row, Col, FormGroup, Label, InputGroup, InputGroupAddon, InputGroupText, Input, Card, CardBody, Button } from 'reactstrap';
import { Field, reduxForm, FormSection, FieldArray } from 'redux-form'
import ValidateField from '../GlobalComponents/FormElements/ValidateField';
import ValidateFieldTextArea from '../GlobalComponents/FormElements/ValidateFieldTextArea';
import CheckboxField from "../GlobalComponents/FormElements/CheckboxField";
import { ButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { required, email, onlyNumbers, onlyNumbersWithMaxLength, maxLength } from '../GlobalComponents/ValidationRules';
import ReCAPTCHA from "react-google-recaptcha";
import DropdownSelectField from "../GlobalComponents/FormElements/DropdownSelectField";
import { hot } from "react-hot-loader";

const PhoneNumberNormalize = onlyNumbersWithMaxLength(11);


class ContactUsForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {

        };
    }

    render() {
        const { handleSubmit, change, isLoading, DisableButton, onChangeReCAPTCHA } = this.props;

        const DropDownSalutation = (props) => {
            const [dropdownOpen, setDropdownOpen] = useState(false);

            const toggle = () => setDropdownOpen(prevState => !prevState);

            return (
                <div>
                    <ButtonDropdown isOpen={dropdownOpen} toggle={toggle}  >
                        <DropdownToggle style={{ backgroundColor: "#ffffff", width: "462px" }} caret>

                        </DropdownToggle>
                        <DropdownMenu style={{ width: "462px" }}>
                            <DropdownItem value="Mr">Mr,</DropdownItem>
                            <DropdownItem value="Ms">Ms,</DropdownItem>
                            <DropdownItem value="Dr">Dr,</DropdownItem>
                            <DropdownItem value="Dato">Dato,</DropdownItem>
                            <DropdownItem value="Datin">Datin,</DropdownItem>
                            <DropdownItem value="Tan Sri">Tan Sri,</DropdownItem>
                            <DropdownItem value="Puan Sri">Puan Sri,</DropdownItem>

                        </DropdownMenu>
                    </ButtonDropdown>
                </div>
            );
        }

        return (

            <form onSubmit={handleSubmit}>
                <Container className="formBackground">
                    <br />
                    <Card>
                        <CardBody>

                            <Row>
                                <Col xs="12" sm="12" lg="6">
                                    <label htmlFor="Salutation">Salutation<span className="required">*</span></label>

                                    <Field
                                        name="Salutation"
                                        component={DropdownSelectField}
                                        label="Salutation"
                                        filter={false}
                                        validate={[required]}
                                        type="text"
                                        data={["Mr,", "Ms,", "Dr,", "Dato,", "Datin,", "Tan Sri,", "Puan Sri,"]}
                                        placeholder="Please select"
                                    />
                                </Col>
                                <Col xs="12" sm="12" lg="6">
                                    <label htmlFor="name">Name<span className="required">*</span></label>
                                    <Field className="form-control" name="name" label="Name" required="true" component={ValidateField} type="text" />
                                </Col>
                            </Row>

                            <br />

                            <Row>
                                <Col xs="12" sm="12" lg="6">
                                    <label htmlFor="ContactNumber">Contact Number<span className="required">*</span></label>
                                    <Field className="form-control" name="ContactNumber" label="ContactNumber" maxLength={11} component={ValidateField} validate={[required]} type="phone" normalize={PhoneNumberNormalize, onlyNumbers} required="true" />
                                </Col>
                                <Col xs="12" sm="12" lg="6">
                                    <label htmlFor="Email">Email<span className="required">*</span></label>
                                    <Field className="form-control" name="email" label="Email" component={ValidateField} validate={[required, email]} type="text" />
                                </Col>
                            </Row>


                            <br />

                            <Row>
                                <Col xs="12" sm="12" lg="12">
                                    <label htmlFor="Enquiry">Your Enquiry<span className="required">*</span></label>
                                    <Field className="form-control" name="Enquiry" label="Enquiry" required="true" component={ValidateFieldTextArea} type="text" />
                                </Col>

                            </Row>

                            <br />

                            <div style={{ float: "left" }}>
                                <Field
                                    className="form-control"
                                    name="PrivacyNotice"
                                    //label="I agree to Insubee and/or its External Parties processing my personal data according to the Privacy Notice."
                                    component={CheckboxField}
                                />
                            </div>
                            <span style={{ fontSize: "14px" }}>I agree to Insubee and/or its External Parties processing my personal data according to the <a href="https://www.gdexpress.com/system/assets/183/Privacy%20Notice.pdf" target="_blank">Privacy Notice</a>.<span className="required">*</span></span>
                            <br />
                            <br />

                            <div className="c-recaptcha">
                                <ReCAPTCHA
                                    //Production sitekey
                                    sitekey="6LdzF20fAAAAAJL9sdcXKjiS7uzadgNzKWvoohmQ"
                                    //LocalHost sitekey
                                    //sitekey="6LfxG20fAAAAADIh9AhmoN_8Md58bmDQ3HLgwvXp"
                                    //Dev sitekey
                                    //sitekey="6LfCm20fAAAAAPLlB_H-kyiV_MgxOic68JLOhm6p"
                                    onChange={onChangeReCAPTCHA}
                                />
                            </div>

                        </CardBody>
                    </Card>

                    <br />
                    <Row>
                        <Col xs="4" sm="4" lg="4">
                        </Col>
                        <Col xs="4" sm="4" lg="4">
                            <Button type="submit" className="btnInsubeeR" disabled={DisableButton}>
                                Submit
                            </Button>
                        </Col>
                        <Col xs="4" sm="4" lg="4">
                        </Col>
                    </Row>
                </Container>
            </form>

        )
    }
}


ContactUsForm = reduxForm({
    // a unique name for the form
    form: 'ContactUsForm',
    destroyOnUnmount: true,

})(ContactUsForm)

export default hot(module)(ContactUsForm);
