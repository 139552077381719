import React from 'react';
import SubmitClaimsPresentation from './SubmitClaimsPresentation';
import { connect } from 'react-redux';
import actions from './actions';
import { change } from 'redux-form';
import { formValueSelector } from 'redux-form';

const selector = formValueSelector('SubmitClaimsForm')

// Data return from reducer (Redux store).  ( /store/configureStore)
function mapStateToProps(state) {

    const { ClaimStore } = state;

    return {
        ClaimStore,
        date: selector(state, "date"),
        PolicyNo: selector(state, "PolicyNo"),
        ClaimAmount: selector(state, "ClaimAmount"),
    }
}

// Calling Api via dispatching action
// Triggered from child Presentation
function mapDispatchToProps(dispatch) {
    return {
        submitClaim: (formdata) => { dispatch(actions.submitClaim(formdata)) },
        getUserPolicy: () => { dispatch(actions.getUserPolicy()) },
        ChangeValue: (form, fieldName, value) => {dispatch(change(form, fieldName, value))},
    }
}


const SubmitClaims = connect(mapStateToProps, mapDispatchToProps)(SubmitClaimsPresentation);

export default (SubmitClaims);