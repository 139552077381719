import React, { Component } from 'react';
import { Button, FormGroup, Label, Input, Row, Col, Card, CardBody, InputGroup, InputGroupText } from 'reactstrap';
import "./style.css";
import { Field, reduxForm, change } from 'redux-form';
import ValidateField from "../GlobalComponents/FormElements/ValidateField";
import { required, email, alphabets, alphabetsWithSpecialCharacters, passwordsMatch, onlyNumbersWithMaxLength, alphaNumeric, phoneNumberNormalize, identityNumberNormalize } from '../GlobalComponents/ValidationRules';
import DropdownSelectField from "../GlobalComponents/FormElements/DropdownSelectField";
import Swal from 'sweetalert2';
import PasswordStrengthBar from 'react-password-strength-bar';
import { connect } from 'react-redux'
import actions from './actions'
const PostcodeNormalize = onlyNumbersWithMaxLength(5);

class SignUpForm extends Component {

    constructor(props) {
        super(props);
        this.state = {
            idType:'',
            states: '',
            
            cityGroup: [
                {
                    label: "",
                    value: 0
                }
            ],
            nationalityGroup: [
                {
                    label: "",
                    value: 0
                }
            ],
            salutationList: [
                {
                    label: "MR",
                    value: "MR"
                },
                {
                    label: "MISS",
                    value: "MISS"
                },
                {
                    label: "MS",
                    value: "MS"
                },
                {
                    label: "MRS",
                    value: "MRS"
                },
                {
                    label: "MASTER",
                    value: "MASTER"
                }
            ],
            maritalStatuslist: [
                {
                    label: "SINGLE",
                    value: "SINGLE"
                },
                {
                    label: "MARRIED",
                    value: "MARRIED"
                },
                {
                    label: "BUSINESS",
                    value: "BUSINESS"
                },
                {
                    label: "WIDOWED",
                    value: "WIDOWED"
                },
                {
                    label: "UNKNOWN",
                    value: "UNKNOWN"
                },
                {
                    label: "NA",
                    value: "NA"
                },
                {
                    label: "DIVORCED",
                    value: "DIVORCED"
                }
            ],
        };
       
        this.handleChange = this.handleChange.bind(this);
        this.onchangePasswordMeter = this.onchangePasswordMeter.bind(this);
        this.handleBlurFromPostcode = this.handleBlurFromPostcode.bind(this);
        this.handleBlurFromIdentityNo = this.handleBlurFromIdentityNo.bind(this);
        this.resetForm = this.resetForm.bind(this);
    }

    resetForm(event) {
        event.preventDefault();
        this.props.reset();
    }

    handleBlurFromPostcode(event) {
        this.props.getLocationByFromPostcode(this.state.postcode);
    }

    onchangePasswordMeter(event) {
        
    }

    handleBlurFromIdentityNo(event) {
        if (this.state.idType == 'NRIC') {
            let getIdentityNo = event.target.value;
            if (getIdentityNo.length == 12) {
                //get last digit
                let lastDigit = getIdentityNo % 10;
                //check even
                if (lastDigit % 2 == 0) {
                    this.props.change('gender', 'Female');

                } else {
                    this.props.change('gender', 'Male');
                }
            }
        }
    }

    handleChange(event) {

        const target = event.target;
        const value = target.value;

        const name = target.name;

        this.setState({
            [name]: value
        });
        
    }

    

    identityNoChange(e) {
        //console.log(e);
    }

    componentDidMount() {
        window.scrollTo(0, 0);
    }


    componentWillReceiveProps(nextProps) {
        if (nextProps.account.locationList !== 'undefined') {
            if (this.props.account.locationList !== nextProps.account.locationList) {
                if (typeof nextProps.account.locationList !== 'undefined') {
                    this.props.change('state', nextProps.account.locationList.state);
                    this.setState({ cityGroup: nextProps.account.locationList.districtList });
                    this.props.change('city', '');
                }
            }
        }
        if (nextProps.nationalityOption !== this.props.nationalityOption) {
            this.setState({ nationalityGroup: nextProps.nationalityOption });
        }

    }

    componentWillMount() {
        
    }

    //static getDerivedStateFromProps(props, state) {
        
    //    return {
    //        nationalityGroup: props.nationalityOption,
    //    }
           
    //    return state;
    //}

    render() {
        const { Password } = this.state;
        const { handleSubmit, change, account } = this.props;

        const nationalityResult = this.state.nationalityGroup.filter(option => option.value === parseInt(this.state.nationalityid))
        let nationalityLabel = '';

        if (nationalityResult.length > 0) {
            nationalityLabel = nationalityResult[0].label;
        }

        let uniqueCity = [];
        this.state.cityGroup.map(item => {
            var findItem = uniqueCity.find(x => x.label === item.district);
            if (!findItem) {
                uniqueCity.push({ value: item.districtId, label: item.district });
            }
            /*return { value: item.districtId, label: item.district }*/
        });
       
        return (
            <form onSubmit={handleSubmit} >
                <div className="my-2">
                    <div className="container">
                        <Row>
                            <Col xs="12" sm="12" lg="12">
                                <div className="col-sm-12">
                                    <h4 className="font-weight-bold">Register</h4>
                                </div>

                                <div className="col-sm-12" style={{ display: 'none' }}>
                                    <p className="pl-1 pt-4 pb-4 pr-4" style={{ background: '#FFDF00', color: 'blue' }}>Please complete all the required fields* </p>
                                </div>

                                <div className="row">
                                    <Card className="col-sm-12 border-0">
                                        <CardBody>
                                            <FormGroup row>
                                                <Label
                                                    className="customLabel customRequired"
                                                    sm={3}
                                                >
                                                    Email
                                                </Label>
                                                <Col sm={{ offset: 1, size: 7 }}>
                                                    <Field className="form-control" placeholder="" name="email" label="Email" component={ValidateField} validate={[required, email]} type="text" />
                                                </Col>
                                            </FormGroup>
                                            <FormGroup row>
                                                <Label
                                                    className="customLabel customRequired"
                                                    sm={3}
                                                >
                                                    Password
                                                </Label>
                                                <Col sm={{ offset: 1, size: 7 }}>
                                                    <Field className="form-control" name="Password" id="password" label="Password" autoComplete="nope" component={ValidateField} validate={[required]} onChange={this.handleChange} type="password" />
                                                    <PasswordStrengthBar
                                                        onChangeScore={this.onchangePasswordMeter}
                                                        minLength={8}
                                                        password={Password} />
                                                </Col>
                                            </FormGroup>
                                            <FormGroup row>
                                                <Label
                                                    className="customLabel customRequired"
                                                    sm={3}
                                                >
                                                    Confirm Password
                                                </Label>
                                                <Col sm={{ offset: 1, size: 7 }}>
                                                    <Field className="form-control" name="confirmPassword" label="Confirm Password" id="confirmPassword" autoComplete="nope" component={ValidateField} validate={[required, passwordsMatch]} type="password" />
                                                </Col>
                                            </FormGroup>
                                        </CardBody>
                                    </Card>
                                </div>

                                <div className="col-sm-12">
                                    <h4 className="font-weight-bold">Personal Information</h4>
                                </div>

                                <div className="row">
                                    <Card className="col-sm-12 border-0">
                                        <CardBody>
                                            <FormGroup row>
                                                {/*<Label*/}
                                                {/*    className="customLabel customRequired"*/}
                                                {/*    sm={3}*/}
                                                {/*>*/}
                                                {/*    Given Name*/}
                                                {/*</Label>*/}
                                                {/*<Col sm={{ offset: 1, size: 7 }}>*/}
                                                {/*    <Field className="form-control" name="givenName" label="Given Name" component={ValidateField} type="text" validate={[required, alphabets]} />*/}
                                                {/*</Col>*/}
                                                <Label className="customLabel customRequired" sm={3}>Given Name</Label>
                                                <Col sm={{ offset: 1, size: 7 }}>
                                                    <InputGroup className="input-group">
                                                    <Field
                                                        name="salutation"
                                                        component={DropdownSelectField}
                                                        label="Salutation"
                                                        validate={[required]}
                                                        filter={false}
                                                        type="text"
                                                        data={this.state.salutationList}
                                                        valueField="value"
                                                        textField="label"
                                                        autoComplete={false}
                                                        className="nric-passport-dropdown"
                                                    />
                                                        <Field className="form-control" name="givenName" label="Given Name" component={ValidateField} type="text" validate={[required, alphabets]} />
                                                    </InputGroup>
                                                </Col>
                                            </FormGroup>
                                            <FormGroup row>
                                                <Label
                                                    className="customLabel customRequired"
                                                    sm={3}
                                                >
                                                    Family Name
                                                </Label>
                                                <Col sm={{ offset: 1, size: 7 }}>
                                                    <Field className="form-control" name="familyName" label="Family Name" component={ValidateField} type="text" validate={[required, alphabets]} />
                                                </Col>
                                            </FormGroup>
                                            <FormGroup row>
                                                <Label
                                                    className="customLabel customRequired"
                                                    sm={3}
                                                >
                                                    Citizenship
                                                </Label>
                                                <Col sm={{ offset: 1, size: 7 }}>
                                                    <Field
                                                        name="citizenShip"
                                                        component={DropdownSelectField}
                                                        label="Citizenship"
                                                        validate={[required]}
                                                        filter={false}
                                                        type="text"
                                                        data={this.state.nationalityGroup}
                                                        valueField="value"
                                                        textField="label"
                                                        className="Width640 custDropDown"
                                                        onChange={(e) => {
                                                            if (e.value == 130) {
                                                                this.props.change('idType', 'NRIC');
                                                                this.setState({ idType: 'NRIC' });
                                                            } else {
                                                                this.props.change('idType', 'Passport');
                                                                this.setState({ idType: 'Passport' });
                                                            }
                                                        }}
                                                    />
                                                </Col>
                                            </FormGroup>
                                            <FormGroup row>
                                                <Label
                                                    className="customLabel customRequired"
                                                    sm={3}
                                                >
                                                    NRIC / Passport
                                                </Label>
                                                <Col sm={{ offset: 1, size: 2 }} xs={6}>
                                                    <Field
                                                        name="idType"
                                                        component={DropdownSelectField}
                                                        label="NRIC / Passport"
                                                        filter={false}
                                                        validate={[required]}
                                                        type="text"
                                                        data={["NRIC", "Passport"]}
                                                        placeholder=""
                                                        disabled={true}
                                                    />
                                                </Col>
                                                <Col sm={5} xs={6}>
                                                    <Field className="form-control" name="identityNo" label="NRIC / Passport" component={ValidateField}
                                                        type="text" validate={[required]} onBlur={this.handleBlurFromIdentityNo}
                                                        normalize={this.state.idType === "NRIC" ? identityNumberNormalize : null} />

                                                    {/*<div class="input-group mb-3">*/}
                                                    {/*    <span class="input-group-text" style={{ borderRadius: "0" }}>*/}

                                                    {/*    </span>*/}

                                                    {/*</div>*/}

                                                </Col>
                                            </FormGroup>
                                            <FormGroup row>
                                                <Label
                                                    className="customLabel customRequired"
                                                    sm={3}
                                                >
                                                    Gender
                                                </Label>
                                                <Col sm={{ offset: 1, size: 7 }}>
                                                    <Field
                                                        name="gender"
                                                        component={DropdownSelectField}
                                                        label="Gender"
                                                        filter={false}
                                                        validate={[required]}
                                                        type="text"
                                                        data={["Male", "Female"]}
                                                        className="Width640 custDropDown"
                                                        disabled={this.state.idType=='NRIC' ? true:false}
                                                    />
                                                </Col>
                                            </FormGroup>
                                            <FormGroup row>
                                                <Label className="customLabel customRequired" sm={3}>Marital Status</Label>
                                                <Col sm={{ offset: 1, size: 7 }}>
                                                    <Field
                                                        name="maritalStatus"
                                                        component={DropdownSelectField}
                                                        label="Marital Status"
                                                        placeholder="Please Select"
                                                        filter={false}
                                                        validate={[required]}
                                                        type="text"
                                                        valueField="value"
                                                        textField="label"
                                                        data={this.state.maritalStatuslist}
                                                        className="Width640 custDropDown"
                                                    />
                                                </Col>
                                            </FormGroup>
                                            <FormGroup row>
                                                <Label
                                                    className="customLabel customRequired"
                                                    sm={3}
                                                >
                                                    Mobile No
                                                </Label>
                                                <Col sm={{ offset: 1, size: 7 }}>
                                                    <InputGroup>
                                                        <InputGroupText style={{ borderRadius: "0" }}>
                                                            +60
                                                        </InputGroupText>
                                                        <Field className="form-control" name="mobileNo" label="Mobile No" component={ValidateField} type="text" validate={[required]} normalize={phoneNumberNormalize} />
                                                    </InputGroup>

                                                </Col>
                                            </FormGroup>
                                            <FormGroup row>
                                                <Label
                                                    className="customLabel customRequired"
                                                    sm={3}
                                                >
                                                    Billing Address
                                                </Label>
                                                <Col sm={{ offset: 1, size: 7 }}>
                                                    <Field className="form-control" name="billingAddress" label="Billing Address" component={ValidateField} type="text" validate={[required]} />
                                                </Col>
                                            </FormGroup>
                                            <FormGroup row>
                                                <Label
                                                    className="customLabel customRequired"
                                                    sm={3}
                                                >
                                                    Postcode
                                                </Label>
                                                <Col sm={{ offset: 1, size: 7 }}>
                                                    <Field className="form-control" name="postcode" label="Postcode" component={ValidateField} type="text" validate={[required, PostcodeNormalize]} onChange={this.handleChange} onBlur={this.handleBlurFromPostcode} maxLength={5} />
                                                </Col>
                                            </FormGroup>
                                            <FormGroup row>
                                                <Label
                                                    className="customLabel customRequired"
                                                    sm={3}
                                                >
                                                    City
                                                </Label>
                                                <Col sm={{ offset: 1, size: 7 }}>
                                                    {/*<Field className="form-control" name="city" label="City" component={ValidateField} type="text" disabled={true} validate={[required]} />*/}
                                                    <Field
                                                        name="city"
                                                        component={DropdownSelectField}
                                                        label="City"
                                                        validate={[required]}
                                                        filter={false}
                                                        type="text"
                                                        data={uniqueCity}
                                                        valueField="value"
                                                        textField="label"
                                                        autoComplete={false}
                                                        className="Width640 custDropDown"
                                                    />
                                                </Col>
                                            </FormGroup>
                                            <FormGroup row>
                                                <Label
                                                    className="customLabel customRequired"
                                                    sm={3}
                                                >
                                                    State
                                                </Label>
                                                <Col sm={{ offset: 1, size: 7 }}>
                                                    <Field className="form-control" name="state" label="State" component={ValidateField} type="text" disabled={true} validate={[required]} />
                                                </Col>
                                            </FormGroup>
                                        </CardBody>
                                    </Card>
                                </div>

                                <div className="col-sm-12">
                                    <h4 className="font-weight-bold">Extra Information</h4>
                                </div>

                                <div className="row">
                                    <Card className="col-sm-12 border-0">
                                        <CardBody>
                                            <FormGroup row>
                                                <Label
                                                    className="customLabel"
                                                    sm={3}>
                                                    Company Name
                                                </Label>
                                                <Col sm={{ offset: 1, size: 7 }}>
                                                    <Field className="form-control" name="companyName" label="Company Name" component={ValidateField} type="text" validate={[alphaNumeric]} />
                                                </Col>
                                            </FormGroup>
                                            <FormGroup row>
                                                <Label
                                                    className="customLabel"
                                                    sm={3}>
                                                    Business Registration No
                                                </Label>
                                                <Col sm={{ offset: 1, size: 7 }}>
                                                    <Field className="form-control" name="businessRegistrationNo" label="Business Registration No" component={ValidateField} type="text"  />
                                                </Col>
                                            </FormGroup>
                                            <br></br>
                                            <p>Please click here for the <a href="https://www.gdexpress.com/system/assets/183/Privacy%20Notice.pdf" target="_blank">Personal Data Protection Act (PDPA)</a></p>
                                            <FormGroup
                                                check
                                            >
                                                <Input id="checkDisclaimer" type="checkbox" name="checkTNC" onChange={this.props.handleCheckBox} />
                                                <Label for="checkDisclaimer" check>
                                                    I have fully read the terms and conditions in the disclaimer above
                                                </Label>
                                            </FormGroup>
                                            <br></br>
                                            <FormGroup
                                                check
                                            >
                                                <Input id="checkPDPA" type="checkbox" name="checkPDPA" onChange={this.props.handleCheckBox} />
                                                <Label for="checkPDPA" check>
                                                    I have fully read the terms and conditions in the Personal Data Protection Act (PDPA)
                                                </Label>
                                            </FormGroup>
                                        </CardBody>
                                    </Card>
                                </div>
                                <br></br>
                                <div className="col-sm-12">
                                    <Button color="warning" className="col-sm-2 btn font-weight-bold btn-sm btn-insubee-orange" size="sm" onClick={this.resetForm}>Reset</Button>

                                    <Button color="warning" className="col-sm-2 mt-sm-0 mt-4 ml-sm-2 font-weight-bold btn-sm btn btn-insubee-orange" size="sm">Register</Button>
                                </div>
                            </Col>
                        </Row>
                        <Row>

                        </Row>
                    </div>
                </div>
            </form>
        )
    }
}


SignUpForm = reduxForm({
    // a unique name for the form
    form: 'SignUpForm',
    destroyOnUnmount: true,
    initialValues: {

    }
})(SignUpForm)

const mapDispatchToProps = dispatch => {
    return {
        getLocationByFromPostcode: (content) => {
            if (content != '' && content!=null) {
                if (content.length >= 5) {
                    dispatch(actions.getLocationByFromPostcode(content));
                }
            }
        },
        getNationalityList: (content) => {
            dispatch(actions.getNationalityList(content));
        },
    }
}

const mapStateToProps = state => {

    const { router, account } = state || {}; // entire Redux store state (the same value returned by a call to store.getState()).

    return { router, account };
};


export default connect(
    mapStateToProps,
    mapDispatchToProps
)(SignUpForm)