import React from 'react';
import { connect } from 'react-redux';
import actions from './actions';
import SweetAlert from 'sweetalert2-react';
import { Button, FormGroup, Label, Input, Row, Col, Card, CardBody, InputGroup, InputGroupText } from 'reactstrap';
import "./style.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';



import ProductBurglary from '../../image/Product/2_burglary.jpeg';
import ProductEmployer from '../../image/Product/2_employer.jpeg';
import ProductFire from '../../image/Product/2_fire.jpeg';
import ProductGlass from '../../image/Product/2_glass.jpeg';
import ProductLiability from '../../image/Product/2_liability.jpeg';
import ProductMoney from '../../image/Product/2_money.jpeg';

import ProductBeauty from '../../image/Product/Beauty.svg';
import ProductFNB from '../../image/Product/FNB.svg';
import ProductHotel from '../../image/Product/Hotel.svg';
import ProductOffice from '../../image/Product/Office.svg';
import ProductRetail from '../../image/Product/Retail.svg';
import ProductServices from '../../image/Product/Service.svg';

import PdfIcon from '../../image/Product/PdfIcon.svg';


import PdfWording from "../../file/Tokio-Marine-SME-BizOne-Policy Wording-Eng.pdf";
import PdfDisclosure from "../../file/TM-SME-BIZONE-Disclosure-Sheet-V3.pdf";
import PdfLogo from "../../image/Policy/icons8_pdf.png";


const BusinessOccupationList = [
    {
        name: "Office",
        description: "Administrative service, accounting service, advertising office and photographic or art studio.",
        src: ProductOffice

    },
    {
        name: "Services",
        description: "Schools, kindergartens, colleges, universities, doctors and dentist clinics.",
        src: ProductServices

    },
    {
        name: "Retail",
        description: "Retail store for general merchandise except for furniture and paint retail shop.",
        src: ProductRetail

    },
    {
        name: "Hotel",
        description: "Hotels, boarding houses, rest houses and chalets except for wood based chalets.",
        src: ProductHotel

    },
    {
        name: "Food And Beverage",
        description: "Coffee shop and restaurants.",
        src: ProductFNB

    },
    {
        name: "Beauty & Wellness",
        description: "Hair salons, beauty salons, fitness centres, gyms, saunas and massage parlours.",
        src: ProductBeauty
    },

];


const CoverageList = [
    {
        name: "If Your Property And Stock Is Damaged",
        description: "We’ll cover damage from fires, storm, flood, and more.",
        src: ProductFire

    },
    {
        name: "If You Are Robbed",
        description: "We’ll cover for damage or loss of stock and repair cost of damage to property.",
        src: ProductBurglary

    },
    {
        name: "If Your Money Is Lost",
        description: "We’ll cover for lost from the premise or during in transit to bank",
        src: ProductMoney

    },
    {
        name: "If Your Glass Is Damaged",
        description: "We’ll cover for cost of repairs for glass doors and windows.",
        src: ProductGlass

    },
    {
        name: "If You Are Sued For Liability",
        description: "We’ll cover for costs of litigation and compensation.",
        src: ProductEmployer

    },
    {
        name: "If You Are Liable For Your Employee’s Injury",
        description: "We’ll cover for costs in respect of such injury.",
        src: ProductLiability

    },
];


class ProductListPresentation extends React.Component {
    constructor(props) {
        super(props);
        this.state = {

        };

        this.GetQuote = this.GetQuote.bind(this);
    }

    componentDidMount() {
        window.scrollTo(0, 0);
    }

    GetQuote() {
        this.props.history.push('/ProductGetQuote');
    }

    render() {

        const ListBusinessOccupation = BusinessOccupationList.map((item) => {
            return (

                /*<Col className="pt-2 pb-2 mx-3" xs="12" sm="3" lg="3">*/
                <Col className="BusinessOcc-items" xs="12" lg="3">

                    <div className="BusinessOcc-img">
                        <img alt="" className="img-fluid img_product" aria-hidden="true" src={item.src}></img>
                    </div>

                    <div className="pt-2">
                        <h5 className="text-center p-1"><b>{item.name}</b></h5>
                        <p className="text-center">{item.description}</p>
                    </div>

                </Col>

            );
        });

        const ListofCoverage = CoverageList.map((item) => {
            return (

                <Col className="BusinessCoverage-items" xs="12" sm="3" lg="3">
                    <Row>
                        <Col>
                            <div><img alt="" className="img-fluid img_product" aria-hidden="true" height="40" src={item.src}></img></div>
                        </Col>
                        <Col>
                            <div className="BusinessCoverage-txt">
                                <h5 className="p-1"><b>{item.name}</b></h5>
                                <p className="">{item.description}</p>
                            </div>
                        </Col>
                    </Row>
                </Col>

            );
        });

        return (
            <div className="mt-5">
                <div className="gqProductListContainer">

                    <div className="BusinessOccupation-container">
                        <div className="backgroundIMG">
                            <div className="ProductList-ContentContainer">

                                <h1><b>Business Occupation That We Cover</b></h1>
                                <div className="ProductTxt-Container mt-3">
                                    <span>Don’t take the blow yourself, insure your business from losses.<br />
                                        Check out what’s covered!</span>
                                </div>


                                <Row className="justify-content-center mt-3 mb-5 px-3">
                                    {ListBusinessOccupation}
                                </Row>


                                <div className="BusinessList-btnContainer">
                                    <Button className="btnInsubee" onClick={this.GetQuote}><b>Get Quote</b></Button>
                                </div>

                            </div>
                        </div>
                    </div>

                    <div className="OurCoverage-container mb-5">
                        <div className="ContentContainer pb-5">
                            <h1><b>Our Coverage</b></h1>
                            <div className="ProductTxt-Container mt-3">
                                <span>Don’t take the blow yourself, insure your business from losses.<br />
                                    Check out what’s covered!</span>
                            </div>
                        </div>

                        <Row className="justify-content-center pb-5 mx-1 px-3">
                            {ListofCoverage}
                        </Row>

                        <div className="BusinessList-btnContainer">
                            <Button className="btnInsubee" onClick={this.GetQuote}><b>Get Quote</b></Button>
                        </div>
                    </div>

                    <div className="BusinessListPdf-bgColor pb-5">
                        <div className="ContentContainer pb-5">
                            <h1><b>Please refer to the policy contract for more infromation on the benefits provided.</b></h1>
                            <div className="ProductTxt-Container mt-3">
                                <span>This policy is underwritten by Tokio Marine Insurans (Malaysia)</span>
                            </div>
                        </div>

                        <Row className="justify-content-center mt-3 mx-1">
                            <Col className="mb-5" xs="12" sm="4" lg="4">
                                <a href={PdfWording} target="_blank">
                                    <div className="BusinessList-PDF">
                                        <div className="BusinessOcc-img">
                                            <img alt="" className="img-fluid img_product" aria-hidden="true" src={PdfIcon} style={{ float: "left" }}></img>
                                        </div>

                                        <div className="BusinessList-Pdftxt pt-2">
                                            <span>Tokio Marine SME BizOne Policy Wording</span>
                                        </div>
                                    </div>
                                </a>
                            </Col>

                            <Col className="" xs="12" sm="4" lg="4">
                                <a href={PdfDisclosure} target="_blank">
                                    <div className="BusinessList-PDF">
                                        <div className="BusinessOcc-img">
                                            <img alt="" className="img-fluid img_product" aria-hidden="true" src={PdfIcon} style={{ float: "left" }}></img>
                                        </div>

                                        <div className="BusinessList-Pdftxt pt-2">
                                            <span>Tokio Marine SME BizOne Product Disclosure Sheet</span>
                                        </div>
                                    </div>
                                </a>
                            </Col>
                        </Row>

                    </div>


                </div>
            </div>
        )
    }

}

export default (ProductListPresentation);