import React, { Component } from 'react';
import { connect } from 'react-redux';
import actions from './actions';
import CheckboxField from "../GlobalComponents/FormElements/CheckboxField";
import { Modal, ModalHeader, ModalBody, ModalFooter, Button, Container, Form, Row, Col, FormGroup, Label, Table, InputGroup, InputGroupAddon, InputGroupText, Input, Card, CardBody, Collapse, CardHeader } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Field, reduxForm, FormSection, change } from 'redux-form';
import ValidateField from "../GlobalComponents/FormElements/ValidateField";
import { required, email, alphabets, passwordsMatch, onlyNumbersWithMaxLength, alphaNumeric, phoneNumberNormalize, identityNumberNormalize } from '../GlobalComponents/ValidationRules';
import DropdownSelectField from "../GlobalComponents/FormElements/DropdownSelectField";
import Cookies from 'universal-cookie';
import constants from "../_constants/constants";
import { AccessLevel } from './constants';

const cookies = new Cookies();

class RolePresentation extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isEdit: props.isEdit,
            isNew: props.isNew,
            showModal: props.showModal,
            backdrop: 'static',
            selectedRole: [],
            roleName: '',
            
        };
        this.resetForm = this.resetForm.bind(this);
        this.triggerOnHide = this.triggerOnHide.bind(this);
    }

    triggerOnHide() {
        this.setState({
            selectedRole: [],
            isEdit: false,
            isNew: false,
            showModal: false,
        });
        this.props.onHide();
    }

    resetForm(event) {
        event.preventDefault();
        if (this.state.isEdit) {
                this.props.change('roleName', '');
                this.props.change('AccessLevel', []);
                this.props.change('id', '');
        } else {
            this.props.reset();
        }

    }


    componentDidMount() {
    }

    componentDidUpdate(prevProps) {

        if (this.props.isEdit !== prevProps.isEdit) {
            if (this.props.isEdit == true) {
               
            }
        }
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.showModal !== 'undefined') {
            if (this.props.showModal !== nextProps.showModal) {
                this.setState({
                    showModal: nextProps.showModal,
                    isEdit: nextProps.isEdit,
                    isNew: nextProps.isNew,
                });
            }
            if (nextProps.showModal == false) {
                this.props.reset();
            }

        }
        if (typeof nextProps.selectedRole !== 'undefined') {
            if (nextProps.selectedRole && Object.keys(nextProps.selectedRole).length !== 0) {
                if (this.state.selectedRole !== nextProps.selectedRole) {
                    this.setState({
                        selectedRole: nextProps.selectedRole,
                    });
                    if (nextProps.showModal == true) {
                        if (nextProps.isEdit == true) {
                            this.props.change('roleName', nextProps.selectedRole.roleName);
                            this.props.change('id', nextProps.selectedRole.id);
                            nextProps.selectedRole.access.map(data => {
                                if (data.claimValue == true) {
                                    this.props.change("AccessLevel"+"."+data.claimName, true);
                                }
                            });
                        }
                    }
                }
            }
        }

    }



    render() {

        const { handleSubmit, change } = this.props;

        let title = "";
        if (this.state.isNew) {
            title = "Create Role";
        }else if (this.state.isEdit ) {
            title = "Update Role";
        }

        return (
            <div>
                <Modal isOpen={this.state.showModal} size="lg" toggle={this.triggerOnHide} backdrop={this.state.backdrop}>
                    <form onSubmit={handleSubmit} >
                        <ModalHeader toggle={this.triggerOnHide}> {title} </ModalHeader>
                        <ModalBody>
                            <div className="my-2">
                                <div className="container">
                                    <Row>
                                        <Col xs="12" sm="12" lg="12">

                                            <div className="col-sm-12" style={{ display: 'none' }}>
                                                <p className="pl-1 pt-4 pb-4 pr-4" style={{ background: '#FFDF00', color: 'blue' }}>Please complete all the required fields* </p>
                                            </div>

                                            <div className="row">
                                                <Card className="col-sm-12 border-0">
                                                    <CardBody>
                                                        <FormGroup row>
                                                            <Label
                                                                className="customLabel customRequired"
                                                                sm={3}
                                                            >
                                                                Role Name
                                                            </Label>
                                                            <Col sm={{ offset: 1, size: 7 }}>
                                                                <Field className="form-control" placeholder="" name="roleName" label="Role Name" component={ValidateField} validate={[required]} type="text"/>
                                                            </Col>
                                                        </FormGroup>
                                                        <FormGroup row>
                                                            <Label
                                                                className="customLabel customRequired"
                                                                sm={3}
                                                            >
                                                                Role Access
                                                            </Label>
                                                            <Col sm={{ offset: 1, size: 7 }}>
                                                                <FormSection name="AccessLevel">
                                                                    <div className="row">
                                                                        {
                                                                            AccessLevel.map((access,index) => {
                                                                                return (
                                                                                    <div className="col-sm-6" key={index}>
                                                                                        <Field name={access.name} label={access.label} component={CheckboxField} />
                                                                                    </div>
                                                                                    )
                                                                            })
                                                                        }
                                                                        
                                                                    </div>
                                                                </FormSection>
                                                            </Col>
                                                        </FormGroup>
                                                       
                                                    </CardBody>
                                                </Card>
                                            </div>

                                            <br></br>
                                            <div className="col-sm-12">
                                                <Button color="dark" className="col-sm-2" size="sm" onClick={this.resetForm}>Reset</Button>

                                                <Button color="dark" type="submit" className="col-sm-2 mt-sm-0 mt-4 ml-sm-2" size="sm">{this.state.isEdit ? 'Confirm' : 'Add'}</Button>
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row>

                                    </Row>
                                </div>
                            </div>


                        </ModalBody>
                    </form>
                </Modal>
            </div>
        );

    }
}

RolePresentation = reduxForm({
    // a unique name for the form
    form: 'RolePresentation',
    destroyOnUnmount: true,
    initialValues: {

    }
})(RolePresentation)

const mapDispatchToProps = dispatch => {
    return {
    }
}

const mapStateToProps = state => {

    const { userSetting, router } = state || {};

    return { userSetting, router };
};


export default connect(
    mapStateToProps,
    mapDispatchToProps
)(RolePresentation)