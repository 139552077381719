import React, { Component } from 'react';
import { Button, FormGroup, Row, Col, Card, CardBody } from 'reactstrap';
import "./style.css";
import { Field, reduxForm } from 'redux-form';
import ValidateField from "../GlobalComponents/FormElements/ValidateField";
import { required, email } from '../GlobalComponents/ValidationRules';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserCircle, faLock, faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import FacebookLogo from "../../image/Account/facebook.png";
import GoogleLogo from "../../image/Account/google.png";


class LoginForm extends Component {
    
    constructor(props) {
        super(props);
        this.state = {
            showPassword: false,
        };
        this.triggerShowPassword = this.triggerShowPassword.bind(this);
    }

    triggerShowPassword() {
        this.setState({ showPassword: !this.state.showPassword });
    }

    componentDidMount() {
        window.scrollTo(0, 0);
    }

    render() {
        const { handleSubmit, change, message, goSignUp, goForgotPassword } = this.props;

        return (
            <form onSubmit={handleSubmit} >
                <div className="my-5 pt-sm-2">
                    <div className="container">
                        <Row className="text-center">
                            <Col xs="12" sm="12" lg="12">
                                <h2 style={{ fontWeight: 'bold' }}>Welcome to My Account</h2>
                                <div className="row pt-3">
                                    <Card className="col-sm-12 p-3 border-0">
                                        <CardBody>
                                            <FormGroup row>
                                                <Col className="text-right" style={{ alignSelf: 'center' }} xs={2} sm={{ offset: 2, size: 2 }}>
                                                    <FontAwesomeIcon size="2x" icon={faUserCircle} />
                                                </Col>
                                                <Col sm={4} xs={8}>
                                                    <Field className="form-control" placeholder="Email Address" name="email" label="Email" component={ValidateField} validate={[required, email]} type="text" />
                                                </Col>
                                            </FormGroup>
                                            <FormGroup row>
                                                <Col className="text-right" style={{ alignSelf: 'center' }} xs={2} sm={{ offset: 2, size: 2 }}>
                                                    <FontAwesomeIcon size="2x" icon={faLock} />
                                                </Col>
                                                <Col xs={8} sm={4}>
                                                    <Field className="form-control" placeholder="Password" name="Password" id="Password" autoComplete="nope" component={ValidateField} validate={[required]} type={this.state.showPassword ? "text" : "password"} />
                                                </Col>
                                                <Col className="text-left" style={{ alignSelf: 'center' }} xs={2} sm={2}>
                                                    <FontAwesomeIcon size="2x" style={{cursor:'pointer'}} icon={this.state.showPassword ? faEye : faEyeSlash} onClick={this.triggerShowPassword} />
                                                </Col>
                                            </FormGroup>
                                            <FormGroup row>
                                                <Col className="text-right" style={{ alignSelf: 'center' }} xs={2} sm={{ offset: 2, size: 2 }}>

                                                </Col>
                                                <Col xs={8} sm={4}>
                                                    <Button color="warning" className="btn btn-insubee-orange" block size="sm">Login</Button>
                                                </Col>
                                                <Col className="text-left" style={{ alignSelf: 'center' }} xs={2} sm={2}>

                                                </Col>
                                            </FormGroup>

                                            {/*<br></br>*/}
                                            {/*<p className="text-center font-weight-bold">OR</p>*/}
                                            {/*<br></br>*/}

                                            {/*<FormGroup row style={{ alignItems: 'center' }}>*/}
                                            {/*    <Col sm={{ offset: 4, size: 2 }} xs={6}>*/}
                                            {/*        <img src={FacebookLogo} height="60" />*/}
                                            {/*    </Col>*/}
                                            {/*    <Col sm={2} xs={6}>*/}
                                            {/*        <img src={GoogleLogo} height="45" />*/}
                                            {/*    </Col>*/}
                                            {/*</FormGroup>*/}

                                            <br></br>
                                            <a type="link" style={{ fontFamily: "Open Sans", fontSize: "14px" }} onClick={goForgotPassword} >Forgot your Password?</a>

                                            <hr></hr>

                                            <p className="text-center">Dont have an account?</p>

                                            <br></br>

                                            <FormGroup row>
                                                <Col className="text-right" style={{ alignSelf: 'center' }} xs={2} sm={{ offset: 2, size: 2 }}>

                                                </Col>
                                                <Col xs={8} sm={4}>
                                                    <Button color="warning" className="btn btn-insubee-orange" onClick={goSignUp} block size="sm">Sign Up</Button>
                                                </Col>
                                                <Col className="text-left" style={{ alignSelf: 'center' }} xs={2} sm={2}>

                                                </Col>
                                            </FormGroup>

                                            {/*<br></br>*/}
                                            {/*<p className="text-center font-weight-bold">OR</p>*/}
                                            {/*<br></br>*/}

                                            {/*<FormGroup row style={{ alignItems: 'center' }}>*/}
                                            {/*    <Col sm={{ offset: 4, size: 2 }} xs={6}>*/}
                                            {/*        <img src={FacebookLogo} height="60" />*/}
                                            {/*    </Col>*/}
                                            {/*    <Col sm={2} xs={6}>*/}
                                            {/*        <img src={GoogleLogo} height="45" />*/}
                                            {/*    </Col>*/}
                                            {/*</FormGroup>*/}

                                            {/*<p className="text-center font-weight-bold">Please sign up for new user</p>*/}

                                        </CardBody>
                                    </Card>
                                </div>
                            </Col>
                        </Row>
                        <Row>

                        </Row>
                    </div>
                </div>
            </form>
        )
    }
}


LoginForm = reduxForm({
    // a unique name for the form
    form: 'LoginForm',
    destroyOnUnmount: true,
    initialValues: {

    }
})(LoginForm)

export default LoginForm;