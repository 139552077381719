import constants from '../_constants/constants';
import Cookie from 'universal-cookie'
import { handleResponse } from "../_helpers/handleResponse";
import { authHeader, authHeaderFile, authHeaderWithoutUser } from '../_helpers/authHeader';

const cookies = new Cookie();

const accountServices = {
    signup,
    login,
    logout,
    resetpassword,
    forgotpassword,
    getNationalityList,
    getLocationByFromPostcode,
    getCommonPasswordList
}

function forgotpassword(forgotModel) {
    const requestOptions = {
        method: "POST",
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(forgotModel)
    };


    return fetch(constants.apiForgotPassword, requestOptions).then(
        (response) => handleResponse(response, requestOptions, constants.apiForgotPassword)
    ).then(data => {
        return data;
    })
}

function resetpassword(resetModel) {
    const requestOptions = {
        method: "POST",
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(resetModel)
    };

    return fetch(constants.apiResetPassword, requestOptions).then(
        (response) => handleResponse(response, requestOptions, constants.apiResetPassword)
    ).then(data => {
        return data;
    });
}

function getLocationByFromPostcode(postcode) {
    const requestOptions = {
        method: "GET",
        headers: authHeader()
    };

    const url = "/api/Home/LocationByPostcode/" + postcode;

    return fetch(url, requestOptions).then(
        (response) => handleResponse(response, requestOptions, url)
    ).then(data => {
        return data;
    });
}

function getNationalityList() {
    const requestOptions = {
        method: "GET",
        headers: authHeader()
    };
    return fetch(constants.apiGetNationalityList, requestOptions).then(
        (response) => handleResponse(response, requestOptions, constants.apiGetNationalityList)
    ).then(data => {
        return data;
    })
}

function getCommonPasswordList() {
    const requestOptions = {
        method: "GET",
        headers: authHeader()
    };
    return fetch(constants.getCommonPasswordTxt, requestOptions).then(data => {
        return data.text();
    })
}

function signup(registerModel) {
    const requestOptions = {

        method: "POST",
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(registerModel)
    };


    return fetch(constants.apiSignup, requestOptions).then(
        (response) => handleResponse(response, requestOptions, constants.apiSignup)
    ).then(data => {
        return data;
    });
}

function login(loginDetails) {

    const requestOptions = {
        method: "POST",
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(loginDetails)
    };

    return fetch(constants.apiLogin, requestOptions).then(
        (response) => handleResponse(response, requestOptions, constants.apiLogin)
    ).then(user => {
        return user;
    });
}

function logout() {
    cookies.remove(constants.tokenName, { path: '/' });
    cookies.remove(constants.userId, { path: '/' });
    cookies.remove(constants.userName, { path: '/' });
    cookies.remove(constants.roleName, { path: '/' });
    cookies.remove(constants.isVerified, { path: '/' });
    cookies.remove(constants.refreshToken, { path: '/' });
    cookies.remove(constants.adminAccess, { path: '/' });
}

export default accountServices;
