import React, { Component } from 'react';
import { Button, FormGroup, Label, Input, Row, Col, Card, CardBody, InputGroup, InputGroupText } from 'reactstrap';
import "./style.css";
import { Field, reduxForm,change } from 'redux-form';
import ValidateField from "../GlobalComponents/FormElements/ValidateField";
import { required, email, alphabets, alphabetsWithSpecialCharacters, passwordsMatch, onlyNumbersWithMaxLength, alphaNumeric, phoneNumberNormalize, identityNumberNormalize, alphabetsWithSpecialCharacter } from '../GlobalComponents/ValidationRules';
import DropdownSelectField from "../GlobalComponents/FormElements/DropdownSelectField";
import RadioValidateField from "../GlobalComponents/FormElements/RadioValidateField";
import Swal from 'sweetalert2';

const PostcodeNormalize = onlyNumbersWithMaxLength(5);
class EditProfileForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            postcode:"",
            profile: [],
            nationality: [],
            nationalityOption: [],
            countryOption: [],
            cityGroup: [],
            idType: "",
            selectedCity: {}
        };
        this.handleBlurFromPostcode = this.handleBlurFromPostcode.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.resetForm = this.resetForm.bind(this);
        this.handleBlurFromIdentityNo = this.handleBlurFromIdentityNo.bind(this);
    }

    componentDidMount() {
        window.scrollTo(0, 0);
    }

    handleChange(event) {

        const target = event.target;
        const value = target.value;

        const name = target.name;

        this.setState({
            [name]: value
        });

    }

    handleBlurFromIdentityNo(event) {
        if (this.state.idType == 'NRIC') {
            let getIdentityNo = event.target.value;
            if (getIdentityNo.length == 12) {
                //get last digit
                let lastDigit = getIdentityNo % 10;
                //check even
                if (lastDigit % 2 == 0) {
                    this.props.change('gender', 'Female');

                } else {
                    this.props.change('gender', 'Male');
                }
            }
        }
    }

    resetForm(event) {
        event.preventDefault();
        this.props.resetForm();
    }

    handleBlurFromPostcode(postcode) {
        this.props.handlePostcode(this.state.postcode);
        this.props.change('city', '');
    }

    componentWillReceiveProps(nextProps) {

        if (typeof nextProps.cityData !== 'undefined') {
            if (this.state.cityGroup !== nextProps.cityData) {
                this.setState({
                    cityData: nextProps.cityData,
                    cityGroup: nextProps.cityData.districtList,
                    
                });
            }
        }

        if (typeof nextProps.profile !== 'undefined') {
            if (this.state.profile !== nextProps.profile) {
                
                this.setState({
                    profile: nextProps.profile,
                    postcode: nextProps.profile.postcode
                });

                this.props.handlePostcode(nextProps.profile.postcode);
                
                this.props.change("givenName", nextProps.profile.firstName);
                this.props.change("familyName", nextProps.profile.lastName);
                this.props.change("identityNo", nextProps.profile.identityNumber);
                this.props.change("mobileNo", nextProps.profile.phoneNumber);
                this.props.change("billingAddress", nextProps.profile.address1);
                this.props.change("city", nextProps.profile.city);
                this.props.change("state", nextProps.profile.state);
                this.props.change("postcode", nextProps.profile.postcode);
                this.props.change("gender", nextProps.profile.gender);
                this.props.change("companyName", nextProps.profile.companyName);
                this.props.change("businessRegistrationNo", nextProps.profile.businessRegistrationNo);
                this.props.change("sameMaillingAddress", "1");

            }
        }

        if (typeof nextProps.nationality !== 'undefined') {
            if (this.state.nationality !== nextProps.nationality) {
               
                let nationalList = nextProps.nationality;
                let nationalityOption = [];
                let countryOption = [];

                nationalityOption = nationalList.map(function (obj) {
                    return { value: obj.countryId, label: obj.nationality }
                });


                countryOption = nationalList.map(function (obj) {
                    return { value: obj.countryId, label: obj.countryName }
                });

                this.setState({
                    nationality: nextProps.nationality,
                    nationalityOption: nationalityOption,
                    countryOption: countryOption
                });

                let selectedCitizen = nationalityOption.find(a => a.value == nextProps.profile.citizenshipId);
                if (selectedCitizen.value == 130) {
                    this.setState({
                        idType: "NRIC"
                    })
                } else {
                    this.setState({
                        idType: "Passport"
                    })
                }
                this.props.change("citizenShip", selectedCitizen);
            }
        }
    }


    render() {
        const { handleSubmit } = this.props;

        let uniqueCity = [];
     
        this.state.cityGroup && this.state.cityGroup.map(item => {
            var findItem = uniqueCity.find(x => x.label === item.district);
            if (!findItem) {
                uniqueCity.push({ value: item.districtId, label: item.district });
            }
            /*return { value: item.districtId, label: item.district }*/
        });
        if (typeof this.state.cityData !== 'undefined') {
            if (typeof this.state.cityData.districtList !== 'undefined') {
                let selectedCity = this.state.cityData.districtList.filter(x => x.district == this.state.profile.city);
                let defaultValueCity = typeof selectedCity !== 'undefined' && selectedCity.map(item => {
                    return { value: item.districtId, label: item.district };
                })
                //console.log(selectedCity);
                //console.log(defaultValueCity);
               
                
            }
            
            if (typeof this.state.cityData.state !== 'undefined') {
                this.props.change('state', this.state.cityData.state);
            }
        }
        

        return (
            <form onSubmit={handleSubmit} >
                <div className="my-3">
                    <div className="container">
                        <Row>
                            <Col xs="12" sm="12" lg="12">

                                <div className="row col-sm-12">
                                    <div className="col-sm-9 col-xs-12"><h4 className="font-weight-bold ">Personal Information</h4></div>
                                    {/*<div className="col-sm-3 col-xs-12 text-right"><Button color="warning" className="btn font-weight-bold w-100 btn-sm btn-insubee-orange" size="sm">Edit Profile</Button></div>*/}
                                </div>
                                <div className="col-sm-12"><hr className="mt-0"></hr></div>
                                <div className="row">
                                    <Card className="col-sm-12 border-0">
                                        <CardBody>
                                            <FormGroup row>
                                                <Label
                                                    className="customLabel customRequired"
                                                    sm={3}
                                                >
                                                    Given Name
                                                </Label>
                                                <Col sm={{ offset: 1, size: 7 }}>
                                                    <Field className="form-control" name="givenName" label="Given Name" component={ValidateField} type="text" validate={[required, alphabets]} />
                                                </Col>
                                            </FormGroup>
                                            <FormGroup row className="lessMarginBottom">
                                                <Label
                                                    className="customLabel customRequired"
                                                    sm={3}
                                                >
                                                    Family Name
                                                </Label>
                                                <Col sm={{ offset: 1, size: 7 }}>
                                                    <Field className="form-control" name="familyName" label="Family Name" component={ValidateField} type="text" validate={[required, alphabets]} />
                                                </Col>
                                            </FormGroup>
                                            <FormGroup row>
                                                <Label
                                                    className="customLabel customRequired"
                                                    sm={3}
                                                >
                                                    Citizenship
                                                </Label>
                                                <Col sm={{ offset: 1, size: 7 }}>
                                                    <Field
                                                        name="citizenShip"
                                                        component={DropdownSelectField}
                                                        label="Citizenship"
                                                        validate={[required]}
                                                        filter={false}
                                                        type="text"
                                                        data={this.state.nationalityOption}
                                                        valueField="value"
                                                        textField="label"
                                                        onChange={(e) => {
                                                            if (e.value == 130) {
                                                                this.setState({ idType: 'NRIC' });
                                                            } else {
                                                                this.setState({ idType: 'Passport' });
                                                            }
                                                        }}
                                                    />
                                                </Col>
                                            </FormGroup>
                                            <FormGroup row>
                                                <Label
                                                    className="customLabel customRequired"
                                                    sm={3}
                                                >
                                                    NRIC / Passport
                                                </Label>
                                                <Col sm={{ offset: 1, size: 7 }}>
                                                    <Field className="form-control" name="identityNo" label="NRIC / Passport" component={ValidateField}
                                                        type="text" validate={[required]} onBlur={this.handleBlurFromIdentityNo}
                                                        normalize={this.state.idType === "NRIC" ? identityNumberNormalize : null}/>
                                                </Col>
                                            </FormGroup>
                                            <FormGroup row>
                                                <Label
                                                    className="customLabel customRequired"
                                                    sm={3}
                                                >
                                                    Gender
                                                </Label>
                                                <Col sm={{ offset: 1, size: 7 }}>
                                                    <Field
                                                        name="gender"
                                                        component={DropdownSelectField}
                                                        label="Gender"
                                                        filter={false}
                                                        validate={[required]}
                                                        type="text"
                                                        data={["Male", "Female"]}
                                                    />
                                                </Col>
                                            </FormGroup>
                                            <FormGroup row>
                                                <Label
                                                    className="customLabel customRequired"
                                                    sm={3}
                                                >
                                                    Mobile No
                                                </Label>
                                                <Col sm={{ offset: 1, size: 7 }}>
                                                    <Field className="form-control" name="mobileNo" label="Mobile No" component={ValidateField} type="text" validate={[required, phoneNumberNormalize]} />
                                                </Col>
                                            </FormGroup>
                                            <FormGroup row>
                                                <Label
                                                    className="customLabel customRequired"
                                                    sm={3}
                                                >
                                                    Billing Address
                                                </Label>
                                                <Col sm={{ offset: 1, size: 7 }}>
                                                    <Field className="form-control" name="billingAddress" label="Billing Address" component={ValidateField} type="text" validate={[required]} />
                                                </Col>
                                            </FormGroup>
                                            <FormGroup row>
                                                <Label
                                                    className="customLabel customRequired"
                                                    sm={3}
                                                >
                                                    Postcode
                                                </Label>
                                                <Col sm={{ offset: 1, size: 7 }}>
                                                    <Field className="form-control" name="postcode" label="Postcode" autoComplete={false}
                                                        component={ValidateField} type="text" onChange={this.handleChange}
                                                        validate={[required, PostcodeNormalize]} onBlur={this.handleBlurFromPostcode} maxLength={5} />
                                                </Col>
                                            </FormGroup>
                                            <FormGroup row>
                                                <Label
                                                    className="customLabel customRequired"
                                                    sm={3}
                                                >
                                                    City
                                                </Label>
                                                <Col sm={{ offset: 1, size: 7 }}>
                                                    {/*<Field className="form-control" name="city" label="City" component={ValidateField} type="text" validate={[required]} />*/}
                                                    <Field
                                                        name="city"
                                                        component={DropdownSelectField}
                                                        label="City"
                                                        validate={[required]}
                                                        filter={false}
                                                        type="text"
                                                        data={uniqueCity}
                                                        valueField="value"
                                                        textField="label"
                                                        autoComplete={false}
                                                    />
                                                </Col>
                                            </FormGroup>
                                            <FormGroup row>
                                                <Label
                                                    className="customLabel customRequired"
                                                    sm={3}
                                                >
                                                    State
                                                </Label>
                                                <Col sm={{ offset: 1, size: 7 }}>
                                                    <Field className="form-control" name="state" label="State" component={ValidateField} type="text" disabled={true} validate={[required]} />
                                                </Col>
                                            </FormGroup>
                                            <FormGroup row>
                                                <Label
                                                    className="customLabel customRequired"
                                                    sm={3}
                                                >
                                                    Mailing Address same with Billing Address?
                                                </Label>
                                                <Col sm={{ offset: 1, size: 7 }}>
                                                    <Field component={RadioValidateField} name="sameMaillingAddress" validate={[required]} required={true} options={[
                                                        { title: 'Yes', value: "1" },
                                                        { title: 'No', value: "0" }
                                                    ]} />
                                                </Col>
                                            </FormGroup>
                                        </CardBody>
                                    </Card>
                                </div>

                                <div className="col-sm-12">
                                    <h4 className="font-weight-bold">Extra Information</h4>
                                    <hr className="mt-0"></hr>
                                </div>

                                <div className="row">
                                    <Card className="col-sm-12 border-0">
                                        <CardBody>
                                            <FormGroup row>
                                                <Label
                                                    className="customLabel"
                                                    sm={3}>
                                                    Company Name
                                                </Label>
                                                <Col sm={{ offset: 1, size: 7 }}>
                                                    <Field className="form-control" name="companyName" label="Company Name" component={ValidateField} type="text" validate={[alphaNumeric]} />
                                                </Col>
                                            </FormGroup>
                                            <FormGroup row>
                                                <Label
                                                    className="customLabel"
                                                    sm={3}>
                                                    Business Registration No
                                                </Label>
                                                <Col sm={{ offset: 1, size: 7 }}>
                                                    <Field className="form-control" name="businessRegistrationNo" label="Business Registration No" component={ValidateField} type="text" />
                                                </Col>
                                            </FormGroup>
                                        </CardBody>
                                    </Card>
                                </div>
                                <br></br>
                                <div className="col-sm-12">
                                    <Button color="warning" type="button" className="col-sm-2 btn font-weight-bold btn-sm btn-insubee-orange" size="sm" onClick={this.resetForm}>Reset</Button>

                                    <Button color="warning" className="col-sm-2 mt-sm-0 mt-4 ml-sm-2 font-weight-bold btn-sm btn btn-insubee-orange" size="sm">Save</Button>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </div>
            </form>
        )
    }

}


EditProfileForm = reduxForm({
    // a unique name for the form
    form: 'EditProfileForm',
    destroyOnUnmount: true,
    initialValues: {

    }
})(EditProfileForm)

export default EditProfileForm;