import React, { Component, useMemo, useCallback } from 'react';
import { connect } from 'react-redux';
import { Button, Container, Row, Col, FormGroup, Label, InputGroup, InputGroupAddon, InputGroupText, Input, Card, CardBody, Collapse, CardHeader } from 'reactstrap';

import { Link } from "react-router-dom";
import SubmitClaimsForm from './SubmitClaimsForm';

import { Field, reduxForm } from 'redux-form';
import ValidateField from "../GlobalComponents/FormElements/ValidateField";
import { required, email, alphabets, passwordsMatch, onlyNumbersWithMaxLength, alphaNumeric, phoneNumberNormalize, number } from '../GlobalComponents/ValidationRules';
import DropdownSelectField from "../GlobalComponents/FormElements/DropdownSelectField";
import { useDropzone } from 'react-dropzone'
import { toast } from 'react-toastify';
import moment from 'moment';


const baseStyle = {
    height: 200,
    borderWidth: 2,
    borderColor: '#666',
    borderStyle: 'dashed',
    borderRadius: 5,
    marginBottom: 25,
    textAlign: 'center',
    paddingTop: 70
}

const activeStyle = {
    borderStyle: 'solid',
    borderColor: '#899889',
    backgroundColor: '#eee'
}


function StyledDropzone(props) {

    var file = [];

    function getBase64(file) {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = error => reject(error);
        });
    }

    const onDrop = useCallback(acceptedFiles => {
        file = acceptedFiles;
    }, [])

    const onClick = useCallback(() => {
        if (file.length >= 1) {
            getBase64(file[0]).then(data => {
                var obj = {
                    fileString: data.split('base64,')[1],
                }
                props.bulkUploadShipmentsSummary(obj);
            });
        }
        else {
            toast.error("Please select a file to upload.");
        }
    }, [])

    const {
        getRootProps,
        getInputProps,
        isDragActive,
        acceptedFiles
    } = useDropzone({ onDrop, multiple: false, accept: ".xlsx, .xls" })

    const style = useMemo(() => ({
        ...baseStyle,
        ...(isDragActive ? activeStyle : {}),
    }), [
        isDragActive,
    ])

    const files = acceptedFiles.map(file => (
        <li key={file.path}>
            {file.path}
        </li>
    ))



    return (


        <div className="my-3">
            <div className="container">


                <div className="row">
                    <Card className="col-sm-12 border-0">
                        <CardBody>




                            <div {...getRootProps({ style })}>
                                <input id="file" name="file" {...getInputProps()} />
                                <p>Drag your Excel file here, or click to select file</p>
                                <br />

                                <p><span className="font-weight-bold">Selected File:</span> {files.length === 0 ? "No file selected yet. " : files}</p>
                            </div>





                        </CardBody>
                    </Card>

                </div>
            </div>
        </div>


    )
}

function Basic(props) {

    var file = [];

    function getBase64(file) {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = error => reject(error);
        });
    }

    const onDrop = useCallback(acceptedFiles => {
        file = acceptedFiles;

        if (file.length >= 1) {
            getBase64(file[0]).then(data => {
                var obj = {
                    fileString: data.split('base64,')[1],
                }
                //props.bulkUploadShipmentsSummary(obj);
                console.log(obj);
                console.log(obj);
            });
        }

    }, [])

    const {
        getRootProps,
        getInputProps,
        isDragActive,
        acceptedFiles
    } = useDropzone({ onDrop })

    const files = acceptedFiles.map(file => (
        <li key={file.path}>
            {file.path}
        </li>
    ));

    return (
        <section className="container">
            <div {...getRootProps({ className: 'dropzone' })}>
                <input {...getInputProps()} />
                <p>Drag your Excel file here, or click to select file</p>
            </div>
            <br />

            <p><span className="font-weight-bold">Selected File:</span> {files.length === 0 ? "No file selected yet. " : files}</p>
        </section>
    );
}

class SubmitClaimsPresentation extends Component {

    constructor(props) {
        super(props);
        this.state = {
        };

        this.handleSubmit = this.handleSubmit.bind(this);
        this.changeHandler = this.changeHandler.bind(this);
        this.clear = this.clear.bind(this);
    }

    clear() {

        this.props.ChangeValue("SubmitClaimsForm", "PolicyNo", "");
        this.props.ChangeValue("SubmitClaimsForm", "ClaimAmount","");
        this.setState({
            ClaimFormList: null
        });
        //this.state.claimFile = null;
    }


    changeHandler(e) {
        if (e.target.files.length != 0) {
            
            var ListofClaimForm = [];
            ListofClaimForm = e.target.files;
            this.setState({
                ClaimFormList: ListofClaimForm,
                //[e.target.name]: e.target.files[0],
                //policyFileName: e.target.files[0].name
            });
        } else {
            this.setState({
                [e.target.name]: e.target.value
            });
        }

    }

    componentDidMount() {
        window.scrollTo(0, 0);
        this.props.getUserPolicy();

        let date = moment();
        this.props.ChangeValue("SubmitClaimsForm", "date", moment(date).format("DD/MM/YYYY"));
    }

    handleSubmit() {

        const { date, PolicyNo, ClaimAmount } = this.props;

        let formdata = new FormData();
        for (let i = 0; i < this.state.ClaimFormList.length; i++) {
            formdata.append("ClaimForm", this.state.ClaimFormList[i]);
        }
        //formdata.append("ClaimForm", this.state.claimFile);
        formdata.append("CustomerPolicyId", PolicyNo.value);
        formdata.append("ClaimAmount", ClaimAmount);

        this.props.submitClaim(formdata);
    }


    render() {

        const { ClaimStore } = this.props;
        let DisableButton = true;

        if (this.state.ClaimFormList) {
            DisableButton = false;
        }

        else {

            DisableButton = true;
        }

        var ListofPolicy = [
            {
                label: "",
                value: 0
            }
        ];
        console.log(ClaimStore);

        if (ClaimStore.UserPolicyList) {
            ListofPolicy = ClaimStore.UserPolicyList.map(item => {
                return ({ value: item.customerPolicyId, label: item.policyNo });
            });
        }


        return (

            <div className="my-5 pt-sm-5">
                <Container className="formBackground">

                    <Row>

                        <Col xs="12" sm="12" lg="12">
                            <div className="text-center">
                                <h1><b>Submit Claim</b></h1>
                            </div>
                        </Col>
                    </Row>


                    <React.Fragment>
                        <SubmitClaimsForm
                            onSubmit={this.handleSubmit}
                            policyList={ListofPolicy}
                            changeHandler={this.changeHandler}
                            buttonDisabled={DisableButton}
                            clear={this.clear}
                        />

                    </React.Fragment>

                </Container>

            </div>
        );
    }
}


export default SubmitClaimsPresentation;
