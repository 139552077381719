const homeConstants = {

    HOME_SENDEMAIL_SUCCESS: 'HOME_SENDEMAIL_SUCCESS',
    HOME_SENDEMAIL_FAIL: 'HOME_SENDEMAIL_FAIL',

    HOME_USERINTEREST_SUCCESS: 'HOME_USERINTEREST_SUCCESS',
    HOME_USERINTEREST_FAIL: 'HOME_USERINTEREST_FAIL',

    GET_MASTERENTRY_REQUEST: "GET_MASTERENTRY_REQUEST",
    GET_MASTERENTRY_SUCCESS: "GET_MASTERENTRY_SUCCESS",
    GET_MASTERENTRY_FAILURE: "GET_MASTERENTRY_FAILURE",

}

export default homeConstants;