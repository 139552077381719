import React, { useEffect } from 'react';
import { Redirect } from 'react-router';
import { Route } from 'react-router-dom';
import ReactGA from 'react-ga';

const TRACKING_ID = "UA-228664458-1";
ReactGA.initialize(TRACKING_ID);
const AnonymousRoute = ({ component: Component, ...rest }) => {

    useEffect(() => {
        ReactGA.pageview(window.location.pathname + window.location.search);
    }, [window.location.pathname]);

    return (
        <Route
            {...rest}
            render={
                props =>
                    <Component {...props} />
            }
        />
    )
}

export default AnonymousRoute;