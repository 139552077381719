import { connect } from 'react-redux';
import ContactUsPresentation from './ContactUsPresentation';
import actions from './actions';
import { change } from "redux-form";
import { formValueSelector } from 'redux-form';

const selector = formValueSelector('ContactUsForm')

function mapStateToProps(state) {
    const { home } = state;
    return {
        home,
        isPrivacyNotice: selector(state, "PrivacyNotice")
    }
}

function mapDispatchToProps(dispatch) {
    return {
        SendEmail: (emailDetails) => { dispatch(actions.SendEmail(emailDetails)) },
    }
}

const ContactUs = connect(mapStateToProps, mapDispatchToProps)(ContactUsPresentation);

export default ContactUs;