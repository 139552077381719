import userSettingConstants from './constants';

const initialState = {
    isLoading: false,
    roleList:[],
};


export const reducer = (state, action) => {
    state = state || initialState;

    switch (action.type) {
        //GET ROLE
        case userSettingConstants.GET_ROLE_REQUEST:
            return {
                ...state,
                roleList: undefined,
                isLoading: true,
            }
        case userSettingConstants.GET_ROLE_SUCCESS:
            return {
                ...state,
                roleList: action.data,
                isLoading: false,
            }
        case userSettingConstants.GET_ROLE_FAILURE:
            return {
                ...state,
                roleList: undefined,
                isLoading: true,
            }
        //ADD ROLE
        case userSettingConstants.ADD_ROLE_REQUEST:
            return {
                ...state,
                isLoading: true,
                showFail: false,
                showSuccess: false
            }
        case userSettingConstants.ADD_ROLE_SUCCESS:
            return {
                ...state,
                isLoading: false,
                showFail: false,
                showSuccess: true
            }
        case userSettingConstants.ADD_ROLE_FAIL:
            return {
                isLoading: false,
                showFail: true,
                showSuccess: false
            }
        case userSettingConstants.HIDE_ADD_ROLE_FAIL:
            return {
                isLoading: false,
                showFail: false,
                showSuccess: false
            }
        //UPDATE ROLE
        case userSettingConstants.UPDATE_ROLE_REQUEST:
            return {
                ...state,
                isLoading: true,
                showFail: false,
                showSuccess: false
            }
        case userSettingConstants.UPDATE_ROLE_SUCCESS:
            return {
                ...state,
                isLoading: false,
                showFail: false,
                showSuccess: true
            }
        case userSettingConstants.UPDATE_ROLE_FAIL:
            return {
                isLoading: false,
                showFail: true,
                showSuccess: false
            }
        case userSettingConstants.HIDE_UPDATE_ROLE_FAIL:
            return {
                isLoading: false,
                showFail: false,
                showSuccess: false
            }
        //DELETE ROLE
        case userSettingConstants.ROLE_DELETE_REQUEST:
            return {
                ...state,
                isLoading: true,
                showFail: false,
                showSuccess: false
            }
        case userSettingConstants.ROLE_DELETE_SUCCESS:
            return {
                ...state,
                isLoading: false,
                showFail: false,
                showSuccess: true
            }
        case userSettingConstants.ROLE_DELETE_FAIL:
            return {
                isLoading: false,
                showFail: true,
                showSuccess: false
            }
        case userSettingConstants.ROLE_HIDE_DELETE_FAIL:
            return {
                isLoading: false,
                showFail: false,
                showSuccess: false
            }
        default:
            return state;
    }
}