import React from 'react';
import { connect } from 'react-redux'
import actions from './actions'
import SweetAlert from 'sweetalert2-react';
import ResetPasswordForm from './ResetPasswordForm';
import { Field, reduxForm, FormSection } from 'redux-form'
import Swal from 'sweetalert2';
import { encrypt } from "../_helpers/crypto";

class ResetPasswordPresentation extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            code: '',
            email:'',
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleChange(event) {
        this.setState({ email: event.target.value });
    }

    handleSubmit(resetModel) {
        let commonPassword = this.props.account.commonPasswordList.split("\r\n");
        if (commonPassword.includes(resetModel.Password)) {
            Swal.fire({
                title: 'Reset Password',
                text: 'The password is too common.',
                type: 'error',
            });
            return false;
        }
        var resetObj = {
            Code: this.state.code,
            Email: resetModel.email,
            Password: encrypt(resetModel.Password),
            ConfirmPassword: resetModel.confirmPassword,
        }
        this.props.resetpassword(resetObj)


    }

    componentDidMount() {
        Promise.all([
            this.props.getCommonPasswordList()
        ])
    }

    render() {
        const { message, router, hideResetFail } = this.props;
        const code = router.location.query.code;
        const email = router.location.query.email;
        if (code !== undefined) {
            this.state.code = code;
        }


        return (

            <div>
                <ResetPasswordForm
                    onSubmit={this.handleSubmit}
                    email={email}
                />
            </div>
        );
    }
}


const mapDispatchToProps = dispatch => {
    return {
        resetpassword: (content) => {
            dispatch(actions.resetpassword(content))
        },
        getCommonPasswordList: (content) => {
            dispatch(actions.getCommonPasswordList(content));
        },
    }
}

const mapStateToProps = state => {

    const { account, router } = state || {}; // entire Redux store state (the same value returned by a call to store.getState()).

    return { account, router };
};


export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ResetPasswordPresentation)
