import constants from '../_constants/constants';
import Cookie from 'universal-cookie'
import { handleResponse } from "../_helpers/handleResponse";
import { authHeader, authHeaderFile, authHeaderWithoutUser } from '../_helpers/authHeader';

const cookies = new Cookie();

const paymentServices = {
    getPaymentSuccess,
}


function getPaymentSuccess(transId) {
    const requestOptions = {
        method: "GET",
        headers: authHeader()
    };
    return fetch(constants.apiGetPaymentSuccess + "?transId=" + transId, requestOptions).then(
        (response) => handleResponse(response, requestOptions, constants.apiGetPaymentSuccess + "?transId=" + transId)
    ).then(data => {
        return data;
    })
}



export default paymentServices;
