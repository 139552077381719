import React, { Component, useState } from 'react';
import { Button, FormGroup, Label, Input, Row, Col, Card, CardBody, InputGroup, InputGroupText, InputGroupAddon, } from 'reactstrap';
import "./style.css";
import { Field, reduxForm } from 'redux-form';
import ValidateField from "../GlobalComponents/FormElements/ValidateField";
import { required, email, alphabets, onlyNumbersWithMaxLength, numberRequired, minValue, maxValue, phoneNumberNormalize, onlyNumbers, numberWithRangeBuildingPropertyQuantity, identityNumberNormalize, trimString } from '../GlobalComponents/ValidationRules';
import DropdownSelectField from "../GlobalComponents/FormElements/DropdownSelectField";
import DatePicker from "react-datepicker";
import { addMonths } from 'date-fns';
import moment from 'moment';
import actions from './actions'
import { connect } from 'react-redux'
import { scrollToFirstError } from '../_helpers/helperFunctions';
import "react-datepicker/dist/react-datepicker.css";
import Cookies from 'universal-cookie';
import CalendarIcon from '../../image/Product/calendar.svg'
import step from '../../image/Product/step.svg'
import stepActive from '../../image/Product/stepActive.svg'

const cookies = new Cookies();


const PostcodeNormalize = onlyNumbersWithMaxLength(6);
const numberWithRangeBPQ = numberWithRangeBuildingPropertyQuantity(1, 1000);
const minValue1 = minValue(1);
const maxValue1 = maxValue(99);


class ProductGetQuoteForm extends Component {

    constructor(props) {
        super(props);
        this.state = {
            IdentificationType: "",
            active: false,
            BuildingPropertyQuantity: 0,
            StockInTradeQuantity: 0,
            FFFQuantity: 0,
            OfficeEquipmentQuantity: 0,
            MiscellaneousItemQuantity: 0,
            cityGroup: [
                {
                    label: "",
                    value: 0
                }
            ],
            correspondanceCityGroup: [
                {
                    label: "",
                    value: 0
                }
            ],
            BusinessTradeOccDropDown: [
                {
                    label: "",
                    value: 0
                }
            ],
            maritalStatuslist: [
                {
                    label: "SINGLE",
                    value: 1
                },
                {
                    label: "MARRIED",
                    value: 2
                },
                {
                    label: "BUSINESS",
                    value: 3
                },
                {
                    label: "WIDOWED",
                    value: 4
                },
                {
                    label: "UNKNOWN",
                    value: 5
                },
                {
                    label: "NA",
                    value: 6
                },
                {
                    label: "DIVORCED",
                    value: 7
                }
            ],
            salutationList: [
                {
                    label: "MR",
                    value: 1
                },
                {
                    label: "MISS",
                    value: 2
                },
                {
                    label: "MS",
                    value: 3
                },
                {
                    label: "MRS",
                    value: 4
                },
                {
                    label: "MASTER",
                    value: 5
                }
            ],
            ShowExceed: false,
            ShowMinReq: false,
            DisableCorrespondancAddress: true,
            startDate: "",
            endDate: "",
            ExceedMessage: "",
            MinReqMessage: "",
            AllowedDelete: false,
            DisplayCheckMark: false,
            DisplayCheckMark2: false,
            RenovationOnChange: false,
        };
        this.onIncrement = this.onIncrement.bind(this);
        this.onDecrement = this.onDecrement.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.GetPostCode = this.GetPostCode.bind(this);
        this.GetCorrespondancePostCode = this.GetCorrespondancePostCode.bind(this);
        this.handleChecked = this.handleChecked.bind(this);
        //this.onStartDateChange = this.onStartDateChange.bind(this);
        this.onChangeSelect = this.onChangeSelect.bind(this);
        this.callEcoverAuthorize = this.callEcoverAuthorize.bind(this);
        this.getToken = this.getToken.bind(this);
        //this.callEcoverRequestToken = this.callEcoverRequestToken.bind(this);
    }

    onChangeSelect(event, name) {

        this.setState({
            [name]: true
        });

    }

    callEcoverAuthorize() {

        var requestOptions = {
            method: 'POST',
            redirect: 'follow'
        };

        //fetch("/Ecoveroauth/ProductGetQuote", requestOptions)
        fetch("/Ecoveroauth/OAuthAuthorize", requestOptions)
            .then(response => response.text())
            .then(result => console.log(result))
            .catch(error => console.log('error', error));
    }

    getToken() {
        var requestOptions = {
            method: 'GET',
            redirect: 'follow'
        };

        //fetch("/Ecoveroauth/ProductGetQuote", requestOptions)
        fetch("/Ecoveroauth/GetToken", requestOptions)
            .then(response => response.text())
            .then(result => console.log(result))
            .catch(error => console.log('error', error));
    }

    //callEcoverRequestToken() {
    //    var requestOptions = {
    //        method: 'POST',
    //        redirect: 'follow'
    //    };

    //    fetch("https://test.e-cover.com.my/easc/oauth/token?client_id=INSUBEEAPI&client_secret=IBapi@2023&grant_type=authorization_code&code=76681ca667ed2277d090446b8bee2b39c625288290ca71251a0e45edee2cfdd1de26a231df88130dd11f72cc7373c6c94853acf5232953eee8f1ab026dcfafaa&redirect_uri=https://insubee-dev.azurewebsites.net/Ecoveroauth/ProductGetQuote", requestOptions)
    //        .then(response => response.text())
    //        .then(result => console.log(result))
    //        .catch(error => console.log('error', error));

    //}

    //onStartDateChange(date) {

    //    this.setState({ startDate: date });

    //    let FutureDate = moment(date).add(1, "y");

    //        this.setState({
    //            endDate: moment(FutureDate).format("DD/MM/YYYY")
    //        });

    //}

    handleChecked(event) {

        let check = event.target.checked;

        if (check == true) {

            this.setState({
                DisableCorrespondancAddress: true
            });

            this.props.change('CorrespondanceAddress1', "");
            this.props.change('CorrespondanceAddress2', "");
            this.props.change('CorrespondanceAddress3', "");
            this.props.change('CorrespondancePostcode', "");
            this.props.change('CorrespondanceCity', "");
            this.props.change('CorrespondanceState', "");

        }

        else {

            this.setState({
                DisableCorrespondancAddress: false
            });

        }

        let isChecked = JSON.stringify(check);
        localStorage.setItem('CorrespondanceAddress', isChecked);
    }

    GetCorrespondancePostCode(event) {
        this.props.getCorrespondanceLocationByFromPostcode(this.state.CorrespondancePostcode);
    }

    GetPostCode(event) {
        this.props.getLocationByFromPostcode(this.state.postcode);
    }

    componentDidUpdate(previousProps, previousState) {

        const { IsOwn } = this.props;

        let StockInTradeQuantity = parseInt(this.state.StockInTradeQuantity == "" ? 0 : this.state.StockInTradeQuantity);
        let FFFQuantity = parseInt(this.state.FFFQuantity == "" ? 0 : this.state.FFFQuantity);
        let OfficeEquipmentQuantity = parseInt(this.state.OfficeEquipmentQuantity == "" ? 0 : this.state.OfficeEquipmentQuantity);
        let MiscellaneousItemQuantity = parseInt(this.state.MiscellaneousItemQuantity == "" ? 0 : this.state.MiscellaneousItemQuantity);
        let BuildingPropertyQuantity = parseInt(this.state.BuildingPropertyQuantity == "" ? 0 : this.state.BuildingPropertyQuantity);

        if (IsOwn == false) {
            //if user didnt clear the BuildingPropertyQuantity value and straight choose Property Owner = Rental
            //Then set BuildingPropertyQuantity = ""
            if (previousState.BuildingPropertyQuantity !== "") {
                this.setState({
                    BuildingPropertyQuantity: "",
                });
            }
            else {
                //if user clear the BuildingPropertyQuantity value and choose Property Owner = Rental
                //then will enable the next button
                if (previousState.ShowMinReq !== false) {
                    this.setState({
                        ShowMinReq: false
                    });
                }
            }
        }

        else {
            if (BuildingPropertyQuantity <= 0) {
                if (previousState.ShowMinReq !== true) {
                    this.setState({
                        ShowMinReq: true,
                        MinReqMessage: " * Building/ Property minimum value must at least RM 1,000.00."
                    });
                }
            }

            else {
                if (previousState.ShowMinReq !== false) {
                    this.setState({
                        ShowMinReq: false
                    });
                }
            }
        }

        let total = StockInTradeQuantity + FFFQuantity + OfficeEquipmentQuantity + MiscellaneousItemQuantity;

        let totalIncludeBPQ = StockInTradeQuantity + FFFQuantity + OfficeEquipmentQuantity + MiscellaneousItemQuantity + BuildingPropertyQuantity;

        if (total > 500 || totalIncludeBPQ > 1000) {
            if (previousState.ShowExceed !== true) {
                if (totalIncludeBPQ > 1000) {
                    this.setState({
                        ShowExceed: true,
                        ExceedMessage: " * Total Building/ Property and Renovation/ Features maximum value cannot exceed RM 1,000,000.00. If total value exceed RM 1,000,000.00, please contact Insubee admin."
                    });
                }

                if (total > 500) {
                    this.setState({
                        ShowExceed: true,
                        ExceedMessage: " * Renovation/ Features maximum value cannot exceed RM 500,000.00. If total value exceed RM 500,000.00, please contact Insubee admin."
                    });
                }

            }

        }

        else if (total !== 0) {
            if (total < 25) {
                if (previousState.ShowExceed !== true) {
                    this.setState({
                        ShowExceed: true,
                        ExceedMessage: " * Total Renovation/ Features minimum value must at least RM 25,000.00."
                    });
                }
            }
            else {
                if (previousState.ShowExceed !== false) {
                    this.setState({
                        ShowExceed: false
                    });
                }

            }
        }

        //When Renovation/ Features Value all 0, to prevent user copy and paste 0
        else if (total == 0 && previousState.ShowExceed !== true && this.state.RenovationOnChange == true) {
            this.setState({
                ShowExceed: true,
                ExceedMessage: " * Total Renovation/ Features minimum value must at least RM 25,000.00.",
                RenovationOnChange: false
            });
        }

        //Changes from "exceed RM 500,000.00" to "at least RM 25,000.00"
        else if (total == 0 && previousState.ShowExceed == true && this.state.RenovationOnChange == true) {
            this.setState({
                ShowExceed: true,
                ExceedMessage: " * Total Renovation/ Features minimum value must at least RM 25,000.00.",
                RenovationOnChange: false
            });
        }


        //else {
        //    if (previousState.ShowExceed !== false) {
        //        this.setState({
        //            ShowExceed: false
        //        });
        //    }
        //}

    }

    handleChange(event) {
        //const { change } = this.props;

        const target = event.target;
        const value = target.value.replace(/\D/g, '');

        const name = target.name;

        if (name == "BuildingPropertyQuantity") {

            if (value > 1000) {

                this.setState({
                    [name]: 1000
                });
                //change([name], 1000);
            }

            else {
                this.setState({
                    [name]: value
                });
            }
        }

        else {

            this.setState({
                [name]: value
            });
        }

        this.setState({
            RenovationOnChange: true
        });
    }

    onDecrement(eventNameID, e) {

        e.preventDefault();

        const { change } = this.props;

        if (eventNameID == eventNameID) {

            if (this.state[eventNameID] > 0) {

                var Decrement = parseInt(this.state[eventNameID]) - 1;
                this.setState({
                    [eventNameID]: Decrement
                });

                change([eventNameID], Decrement);
            }
        }

    }

    onIncrement(eventNameID, e) {

        e.preventDefault();

        const { change } = this.props;

        if (eventNameID == eventNameID) {

            if (this.state[eventNameID] < 500) {

                var Increment = parseInt(this.state[eventNameID]) + 1;
                this.setState({
                    [eventNameID]: Increment
                });

                change([eventNameID], Increment);
            }
        }

    }


    componentDidMount() {

        window.scrollTo(0, 0);

        if (sessionStorage.getItem("insubeeToken") == undefined) {
            this.setState({
                IsGuest: true
            });
        }

        else {
            this.setState({
                IsGuest: false
            });

        }

        const { GetFromLocalStorage } = this.props
        if (GetFromLocalStorage == true) {

            const getQuoteDetails = localStorage.getItem('productFormDetails');
            const CorrespondanceAddress = localStorage.getItem('CorrespondanceAddress');
            const getQuoteObj = JSON.parse(getQuoteDetails);
            const CorrespondanceAddressObj = JSON.parse(CorrespondanceAddress);
            let financialInstitutionId = ""

            if (CorrespondanceAddressObj == false) {
                this.props.change('CorrespondanceAddress1', getQuoteObj.LocationRisk.CorrespondanceAddress1);
                this.props.change('CorrespondanceAddress2', getQuoteObj.LocationRisk.CorrespondanceAddress2);
                this.props.change('CorrespondanceAddress3', getQuoteObj.LocationRisk.CorrespondanceAddress3);
                this.props.change('CorrespondancePostcode', getQuoteObj.LocationRisk.CorrespondancePostcode);
                this.props.change('CorrespondanceCity', getQuoteObj.LocationRisk.CorrespondanceCity);
                this.props.change('CorrespondanceState', getQuoteObj.LocationRisk.CorrespondanceState);
                this.props.getCorrespondanceLocationByFromPostcode(getQuoteObj.LocationRisk.CorrespondancePostcode);
            }

            if (getQuoteObj.FinancialInstitutionId !== 0) {
                financialInstitutionId = getQuoteObj.FinancialInstitutionId;
            }

            this.props.change('CompanyName', getQuoteObj.CompanyName);
            this.props.change('BusinessRegistrationNo', getQuoteObj.BusinessRegistrationNo);
            this.props.change('Address1', getQuoteObj.LocationRisk.Address1);
            this.props.change('Address2', getQuoteObj.LocationRisk.Address2);
            this.props.change('Address3', getQuoteObj.LocationRisk.Address3);
            this.props.change('postcode', getQuoteObj.LocationRisk.Postcode);
            this.props.change('city', getQuoteObj.LocationRisk.City);
            this.props.change('state', getQuoteObj.LocationRisk.State);
            this.props.getLocationByFromPostcode(getQuoteObj.LocationRisk.Postcode);
            this.props.change('BuildingPropertyQuantity', getQuoteObj.BuildingPropertyValue.slice(0, -3));
            this.props.change('StockInTradeQuantity', getQuoteObj.StockInTradeValue.slice(0, -3));
            this.props.change('FFFQuantity', getQuoteObj.FurnitureValue.slice(0, -3));
            this.props.change('OfficeEquipmentQuantity', getQuoteObj.OfficeEquipmentValue.slice(0, -3));
            this.props.change('MiscellaneousItemQuantity', getQuoteObj.MiscellaneousItemValue.slice(0, -3));
            this.props.change('BuildingAge', getQuoteObj.BuildingAge);
            this.props.change('BuildingStorey', getQuoteObj.BuildingStorey);
            this.props.change('endDate', getQuoteObj.EndDate);
            this.props.change('guestIC', getQuoteObj.GuestIdentityNumber);
            this.props.change('guestEmail', getQuoteObj.GuestEmailAddress);
            this.props.change('guestName', getQuoteObj.GuestName);
            this.props.change('guestMobileNo', getQuoteObj.GuestMobileNo.slice(3));
            this.props.change('BusinessTradeOccupation', getQuoteObj.BusinessTradeOccupationLabel);
            this.props.change('BusinessTradeOccupationID', getQuoteObj.BusinessTradeOccupationId);
            this.props.change('BusinessOccupation.label', getQuoteObj.BusinessOccupation);
            this.props.change('BusinessOccupation.value', getQuoteObj.BusinessOccupationId);
            this.props.change('propertyOwner.label', getQuoteObj.PropertyOwner);
            this.props.change('propertyOwner.value', getQuoteObj.PropertyOwnerTypeId);
            this.props.change('FinancialInstitution.financialInstitutionName', getQuoteObj.FinancialInstitutionName);
            this.props.change('FinancialInstitution.financialInstitutionId', financialInstitutionId);
            this.props.change('FinancialInstitutionType.financialInstitutionTypeDisplayName', getQuoteObj.FinancialInstitutionTypeDisplayName);
            this.props.change('FinancialInstitutionType.financialInstitutionTypeValue', getQuoteObj.FinancialInstitutionType);
            this.props.change('IdentificationType.label', getQuoteObj.GuestIdentificationTypeName);
            this.props.change('IdentificationType.value', getQuoteObj.GuestIdentificationType);
            //this.props.change('salutation.label', getQuoteObj.salutation);
            //this.props.change('salutation.value', getQuoteObj.salutationID);
            //this.props.change('maritalStatus.label', getQuoteObj.maritalStatus);
            //this.props.change('maritalStatus.value', getQuoteObj.maritalStatusID);
            this.props.change('salutation.label', getQuoteObj.salutation);
            this.props.change('salutation.value', getQuoteObj.salutationID);
            this.props.change('maritalStatus.label', getQuoteObj.maritalStatus);
            this.props.change('maritalStatus.value', getQuoteObj.maritalStatusID);
            this.setState({
                DisableCorrespondancAddress: CorrespondanceAddressObj,
                BuildingPropertyQuantity: getQuoteObj.BuildingPropertyValue.slice(0, -3),
                StockInTradeQuantity: getQuoteObj.StockInTradeValue.slice(0, -3),
                FFFQuantity: getQuoteObj.FurnitureValue.slice(0, -3),
                OfficeEquipmentQuantity: getQuoteObj.OfficeEquipmentValue.slice(0, -3),
                MiscellaneousItemQuantity: getQuoteObj.MiscellaneousItemValue.slice(0, -3),
            });
        }

        else {

            let check = true;
            let isChecked = JSON.stringify(check);
            localStorage.setItem('CorrespondanceAddress', isChecked);
            localStorage.removeItem('selectedCoveragePlan');
        }
        //this.callEcoverAuthorize();
    }


    componentWillReceiveProps(nextProps) {

        if (typeof nextProps.product !== 'undefined') {
            if (nextProps.product.locationList !== 'undefined') {
                if (this.props.product.locationList !== nextProps.product.locationList) {
                    if (typeof nextProps.product.locationList !== 'undefined') {

                        if (nextProps.product.locationList.districtList.length == 1) {

                            this.props.change('state', nextProps.product.locationList.state);
                            this.setState({ cityGroup: nextProps.product.locationList.districtList });
                            let City = nextProps.product.locationList.districtList[0];
                            this.props.change('city', City.district);
                        }
                        else {
                            if (nextProps.GetFromLocalStorage == true) {
                                this.setState({ cityGroup: nextProps.product.locationList.districtList });
                            }
                            else {
                                this.props.change('state', nextProps.product.locationList.state);
                                this.setState({ cityGroup: nextProps.product.locationList.districtList });
                                this.props.change('city', '');
                            }
                        }
                    }
                }
            }
        }

        // For Correspondance Postcode
        if (typeof nextProps.product !== 'undefined') {
            if (nextProps.product.CorrespondancelocationList !== 'undefined') {
                if (this.props.product.CorrespondancelocationList !== nextProps.product.CorrespondancelocationList) {
                    if (typeof nextProps.product.CorrespondancelocationList !== 'undefined') {

                        if (nextProps.product.CorrespondancelocationList.districtList.length == 1) {

                            this.props.change('CorrespondanceState', nextProps.product.CorrespondancelocationList.state);
                            this.setState({ correspondanceCityGroup: nextProps.product.CorrespondancelocationList.districtList });
                            let City = nextProps.product.CorrespondancelocationList.districtList[0];
                            this.props.change('CorrespondanceCity', City.district);
                        }
                        else {
                            if (nextProps.GetFromLocalStorage == true) {
                                this.setState({ correspondanceCityGroup: nextProps.product.CorrespondancelocationList.districtList });
                                this.props.change('CorrespondanceState', nextProps.product.CorrespondancelocationList.state);
                            }
                            else {
                                this.props.change('CorrespondanceState', nextProps.product.CorrespondancelocationList.state);
                                this.setState({ correspondanceCityGroup: nextProps.product.CorrespondancelocationList.districtList });
                                this.props.change('CorrespondanceCity', '');
                            }
                        }
                    }
                }
            }
        }

        if (nextProps.nationalityOption !== this.props.nationalityOption) {
            this.setState({ nationalityGroup: nextProps.nationalityOption });
        }

        if (nextProps.SelectedBusinessTradeOcc !== undefined) {


            if (nextProps.SelectedBusinessTradeOcc.length == 1) {

                let businessTradeOccDropDown = nextProps.SelectedBusinessTradeOcc.map(item => {
                    return ({ value: item.businessTradeOccupationId, label: item.tradeOccupationName });
                });

                this.setState({
                    BusinessTradeOccDropDown: businessTradeOccDropDown,
                    AllowedDelete: true,
                    DisplayCheckMark2: true
                });

                let BusinessTradeName = businessTradeOccDropDown[0];

                this.props.change('BusinessTradeOccupation', BusinessTradeName.label);
                this.props.change('BusinessTradeOccupationID', BusinessTradeName.value);
            }

            else {

                if (this.state.AllowedDelete == true) {
                    this.props.change('BusinessTradeOccupation', '');
                }

                let businessTradeOccDropDown = nextProps.SelectedBusinessTradeOcc.map(item => {
                    return ({ value: item.businessTradeOccupationId, label: item.tradeOccupationName });
                });

                this.setState({
                    BusinessTradeOccDropDown: businessTradeOccDropDown,
                    AllowedDelete: false,
                    DisplayCheckMark2: false
                });
            }

        }

    }

    render() {

        const { handleSubmit, ListOfBusinessOccupation, ListOfPropertyOwner, onClickPropertyOwner, onClickFinancialInst, IsOwn,
            DisableFinancialInstitutionType,
            RemoveBtnFinancialInstitution,
            RemoveBtnFinancialInstitutionType,
            ListOfStateHoliday, onStartDateChange, startDate } = this.props;
        const { ListOfFinancialInstitution, ListOfFinancialInstitutionType, onYearChange, BuildingConstructedYear, identificationTypes, removeValue } = this.props;

        var BusinessOccDropDown = [
            {
                label: "",
                value: 0
            }
        ];

        var propertyOwner = [
            {
                label: "",
                value: 0
            }
        ];

        var identificationList = [
            {
                label: "",
                value: 0
            }
        ];

        if (identificationTypes) {
            let ListIdentification = identificationTypes.map(item => {
                return ({ value: item.identificationTypeId, label: item.identificationTypeName });
            });

            identificationList = ListIdentification;
        }

        if (ListOfPropertyOwner) {
            let propertyOwnerList = ListOfPropertyOwner.map(item => {
                return ({ value: item.propertyOwnerTypeId, label: item.propertyOwnerTypeName });
            });

            propertyOwner = propertyOwnerList;
        }

        if (ListOfBusinessOccupation) {
            let BusinessOccList = ListOfBusinessOccupation.map(item => {
                return ({ value: item.businessOccupationId, label: item.occupationName });
            });

            BusinessOccDropDown = BusinessOccList;
        }

        let uniqueCity = [];
        let uniqueCorrespondanceCity = [];

        this.state.cityGroup.map(item => {
            var findItem = uniqueCity.find(x => x.label === item.district);
            if (!findItem) {
                uniqueCity.push({ value: item.districtId, label: item.district });
            }
            /*return { value: item.districtId, label: item.district }*/
        });

        this.state.correspondanceCityGroup.map(item => {
            var findItem = uniqueCorrespondanceCity.find(x => x.label === item.district);
            if (!findItem) {
                uniqueCorrespondanceCity.push({ value: item.districtId, label: item.district });
            }
            /*return { value: item.districtId, label: item.district }*/
        });



        let StateHolidayList = ListOfStateHoliday?.map(item => {
            return new Date(item)
        }
        );

        return (

            <div className="gqProductContainer">
                <div className="backgroundIMG">

                    <div className="ContentContainer">

                        <h1><b>We Got You Covered in Every Possible Way</b></h1>

                        <div className="gqTxtContainer">
                            <span style={{ fontSize: "18px" }}>Insubee is an online insurance platform offering bite-size insurance. We aim to simplify
                            insurance by working with insurers to curate affordable and simple policies to cover risks that you truly need to worry about.
                            </span>
                        </div>

                        <div className="gqbtnContainer" style={{ width: "300px" }}>
                            <div className="roundedButton" id="IBroundedButton" style={{ width: "250px" }}>
                                <a className="" href="/Business" id="IBbuttonlink" style={{ width: "250px" }}><b>Know more details</b></a>
                            </div>
                        </div>

                        {/*<div className="Progressbar">*/}

                        {/*<div className="circle attrName" data-title="Get Quote"><span class="dotActive">1</span></div>*/}
                        {/*<div className="attrName" data-title="Details"><span class="dot">2</span></div>*/}
                        {/*<div className="attrName" data-title="Payment"><span class="dot">3</span></div>*/}

                        {/*</div>*/}

                        {/*<div className="progress-container gqForm">*/}
                        {/*    <ul className="progress-steps">*/}
                        {/*        <li className="active"><div className="circle"></div><span style={{ fontWeight: "bold" }}>Get Quote</span></li>*/}
                        {/*        <li className=""><span>Details</span></li>*/}
                        {/*        <li className=""><span>Payment</span></li>*/}
                        {/*    </ul>*/}
                        {/*</div>*/}

                        <div className="progress-container gqForm">
                            <div className="steps-progress">
                                <div className="steps"><img src={stepActive} /></div><hr />
                                <div className="steps"><img src={step} /></div><hr />
                                <div className="steps"><img src={step} /></div>
                            </div>
                            <div className="steps-progress hideProgress">
                                <div style={{ marginLeft: "-10px" }}><span>Get Quote</span></div><hr />
                                <div style={{ marginLeft: "-8px" }}><span>Details</span></div><hr />
                                <div style={{ marginRight: "-8px" }}><span>Payment</span></div>
                            </div>
                        </div>


                        <div className="gqFormContainer">

                            <form onSubmit={handleSubmit} >

                                {(() => {
                                    if (this.state.IsGuest == true) {

                                        return (
                                            <>
                                                <FormGroup>
                                                    <Label className="customLabel customRequired">Email Address</Label>
                                                    <Field className="form-control" name="guestEmail" label="Email" component={ValidateField} validate={[required, email]} type="text" />
                                                </FormGroup>

                                                <FormGroup>
                                                    <Label className="customLabel customRequired">Guest Name</Label>
                                                    <InputGroup className="input-group">
                                                        {/*<Field*/}
                                                        {/*    name="salutation"*/}
                                                        {/*    placeholder="Salutation"*/}
                                                        {/*    component={DropdownSelectField}*/}
                                                        {/*    label="salutation"*/}
                                                        {/*    validate={[required]}*/}
                                                        {/*    filter={false}*/}
                                                        {/*    type="text"*/}
                                                        {/*    data={this.state.salutationList}*/}
                                                        {/*    valueField="value"*/}
                                                        {/*    textField="label"*/}
                                                        {/*    autoComplete={false}*/}
                                                        {/*    className="nric-passport-dropdown"*/}
                                                        {/*/>*/}
                                                        {/*<Field className="form-control input-group-textbox guestIC" name="guestName" label="Guest Name" component={ValidateField} type="text" validate={[required, alphabets]} />*/}
                                                        <Field className="form-control" name="guestName" label="Guest Name" component={ValidateField} type="text" validate={[required, alphabets]} />
                                                    </InputGroup>
                                                </FormGroup>

                                                <FormGroup style={{ display: "none" }} >
                                                    <Label className="customLabel">Marital Status</Label>
                                                    <div className="wrapCheckmark">
                                                        {/*<Field*/}
                                                        {/*    name="maritalStatus"*/}
                                                        {/*    component={DropdownSelectField}*/}
                                                        {/*    label="maritalStatus"*/}
                                                        {/*    placeholder="Please Select"*/}
                                                        {/*    filter={false}*/}
                                                        {/*    validate={[required]}*/}
                                                        {/*    type="text"*/}
                                                        {/*    valueField="value"*/}
                                                        {/*    textField="label"*/}
                                                        {/*    data={this.state.maritalStatuslist}*/}
                                                        {/*    className="Width640 custDropDown"*/}
                                                        {/*/>*/}
                                                    </div>
                                                </FormGroup>

                                                <FormGroup>
                                                    <Label className="customLabel customRequired">Guest Nationality</Label>
                                                    <Field className="form-control" name="guestNationality" label="Guest Nationality" component={ValidateField} type="text" placeholder="Malaysia" disabled={true} />
                                                </FormGroup>

                                                <FormGroup>
                                                    <Label className="customLabel customRequired">NRIC / Passport</Label>
                                                    <InputGroup className="input-group">
                                                        {/*<Field*/}
                                                        {/*    name="IdentificationType"*/}
                                                        {/*    component={DropdownSelectField}*/}
                                                        {/*    label="IdentificationType"*/}
                                                        {/*    validate={[required]}*/}
                                                        {/*    filter={false}*/}
                                                        {/*    type="text"*/}
                                                        {/*    data={identificationList}*/}
                                                        {/*    valueField="value"*/}
                                                        {/*    textField="label"*/}
                                                        {/*    autoComplete={false}*/}
                                                        {/*    className="nric-passport-dropdown"*/}
                                                        {/*    onChange={(e) => {*/}
                                                        {/*        if (e.value == 1) {*/}
                                                        {/*            //this.props.change('IdentificationType', 'NRIC');*/}
                                                        {/*            this.setState({ IdentificationType: 'NRIC' });*/}
                                                        {/*        } else {*/}
                                                        {/*            //this.props.change('IdentificationType', 'Passport');*/}
                                                        {/*            this.setState({ IdentificationType: 'Passport' });*/}
                                                        {/*        }*/}
                                                        {/*    }}*/}
                                                        {/*/>*/}
                                                        {/* <Field className="form-control input-group-textbox guestIC" name="guestIC" label="Guest Identity Card" component={ValidateField} type="text" validate={[required]} normalize={this.state.IdentificationType === "NRIC" ? identityNumberNormalize : null} minLength={this.state.IdentificationType === "NRIC" ? 12 : null} />*/}
                                                        <Field className="form-control" name="guestIC" label="Guest Identity Card" normalize={trimString} component={ValidateField} type="text" validate={[required]} minLength={12} maxLength={12} />
                                                    </InputGroup>
                                                </FormGroup>

                                                <FormGroup>
                                                    <Label className="customLabel customRequired">Guest Mobile Number</Label>
                                                    <InputGroup className="input-group">
                                                        <InputGroupText className="input-group-text">+60</InputGroupText>
                                                        <Field className="form-control input-group-textbox" name="guestMobileNo" label="Guest Mobile No" component={ValidateField} type="text" validate={[required]} normalize={phoneNumberNormalize} maxLength={10} />
                                                    </InputGroup>
                                                </FormGroup>
                                            </>
                                        )
                                    }
                                })()}
                                <FormGroup>
                                    <Label className="customLabel customRequired">Company Name</Label>
                                    <Field className="form-control" name="CompanyName" label="Company Name" component={ValidateField} type="text" validate={[required]} />
                                </FormGroup>


                                <FormGroup>
                                    <Label className="customLabel customRequired">Business Registration Number</Label>
                                    <Field className="form-control" name="BusinessRegistrationNo" label="Business Registration Number" normalize={trimString} component={ValidateField} type="text" validate={[required]} />
                                </FormGroup>


                                {/*Location of Risk*/}

                                <Label className="customLabel customRequired mt-4"><b>Location of Risk</b></Label>
                                <FormGroup>
                                    <Label className="customLabel customRequired">Address 1</Label>
                                    <Field className="form-control" name="Address1" label="Address 1" component={ValidateField} type="text" validate={[required]} maxLength={30} />
                                </FormGroup>

                                <FormGroup>
                                    <Label className="customLabel">Address 2</Label>
                                    <Field className="form-control" name="Address2" label="Address 2" component={ValidateField} type="text" maxLength={30} />
                                </FormGroup>

                                <FormGroup>
                                    <Label className="customLabel">Address 3</Label>
                                    <Field className="form-control" name="Address3" label="Address 3" component={ValidateField} type="text" maxLength={30} />
                                </FormGroup>

                                <FormGroup className="flex-div">
                                    <div className="form-Addressinline">
                                        <Label className="customLabel customRequired">Postcode</Label>
                                        <Field className="form-control" name="postcode" label="Postcode" component={ValidateField} onChange={this.handleChange} onBlur={this.GetPostCode} type="text" validate={[required]} normalize={PostcodeNormalize} />
                                    </div>
                                    <div className="">
                                        <Label className="customLabel customRequired">City</Label>
                                        <Field
                                            name="city"
                                            component={DropdownSelectField}
                                            label="City"
                                            validate={[required]}
                                            filter={false}
                                            type="text"
                                            data={uniqueCity}
                                            valueField="value"
                                            textField="label"
                                            autoComplete={false}
                                            className="custDropDown"
                                        />
                                    </div>
                                </FormGroup>

                                <FormGroup className="flex-div">
                                    <div className="form-Addressinline">
                                        <Label className="customLabel customRequired">State</Label>
                                        <Field className="form-control" name="state" label="State" component={ValidateField} type="text" validate={[required]} disabled={true} />
                                    </div>
                                    <div className="country-txt">
                                        <Label className="customLabel customRequired">Country</Label>
                                        <Field className="form-control" name="Country" label="Country" component={ValidateField} type="text" placeholder="Malaysia" disabled={true} />
                                    </div>
                                </FormGroup>


                                <div className="mt-4 mb-5">
                                    <Label className="customLabel customRequired"><b>Correspondence Address (If different from location of risk)</b></Label>

                                    <FormGroup>
                                        <label className="containerCheckBox">
                                            <Input type="checkbox" onClick={this.handleChecked} checked={this.state.DisableCorrespondancAddress} />
                                            <span className="checkmark"></span>
                                        </label>
                                        <Label for="checkDisclaimer" check>
                                            Please tick(&#10003;) if correspondence address is same as location of risk
                                        </Label>
                                    </FormGroup>

                                    {this.state.DisableCorrespondancAddress == false ?

                                        <>
                                            <FormGroup>
                                                <Label className="customLabel customRequired">Address 1</Label>
                                                <Field className="form-control" name="CorrespondanceAddress1" label="Correspondance Address 1" maxLength={30} validate={this.state.DisableCorrespondancAddress == false ? [required] : []} component={ValidateField} type="text" disabled={true ? this.state.DisableCorrespondancAddress == true : false} />
                                            </FormGroup>

                                            <FormGroup>
                                                <Label className="customLabel">Address 2</Label>
                                                <Field className="form-control" name="CorrespondanceAddress2" label="Correspondance Address 2" component={ValidateField} type="text" maxLength={30} />
                                            </FormGroup>

                                            <FormGroup>
                                                <Label className="customLabel">Address 3</Label>
                                                <Field className="form-control" name="CorrespondanceAddress3" label="Correspondance Address 3" component={ValidateField} type="text" maxLength={30} />
                                            </FormGroup>

                                            <FormGroup className="flex-div">
                                                <div className="form-Addressinline">
                                                    <Label className="customLabel customRequired">Postcode</Label>
                                                    <Field className="form-control" name="CorrespondancePostcode" label="Correspondance Postcode" component={ValidateField} onChange={this.handleChange} onBlur={this.GetCorrespondancePostCode} type="text" disabled={true ? this.state.DisableCorrespondancAddress == true : false} normalize={PostcodeNormalize} />
                                                </div>
                                                <div className="" style={{ width: "314.15px" }}>
                                                    <Label className="customLabel customRequired">City</Label>
                                                    <Field
                                                        name="CorrespondanceCity"
                                                        component={DropdownSelectField}
                                                        label="CorrespondanceCity"
                                                        validate={this.state.DisableCorrespondancAddress == false ? [required] : []}
                                                        filter={false}
                                                        type="text"
                                                        data={uniqueCorrespondanceCity}
                                                        valueField="value"
                                                        textField="label"
                                                        autoComplete={false}
                                                        className="custDropDown"
                                                    />
                                                    {/* <Field className="form-control" name="CorrespondanceCity" label="Correspondance City" component={ValidateField} type="text" disabled={true ? this.state.DisableCorrespondancAddress == true : false} />*/}
                                                </div>
                                            </FormGroup>

                                            <FormGroup className="flex-div">
                                                <div className="form-Addressinline">
                                                    <Label className="customLabel customRequired">State</Label>
                                                    <Field className="form-control" name="CorrespondanceState" label="Correspondance State" component={ValidateField} type="text" disabled={true} />
                                                </div>
                                                <div className="" style={{ width: "314.15px" }}>
                                                    <Label className="customLabel customRequired">Country</Label>
                                                    <Field className="form-control" name="CorrespondanceCountry" label="Correspondance Country" component={ValidateField} type="text" placeholder="Malaysia" disabled={true} />
                                                </div>
                                            </FormGroup>
                                        </>

                                        :

                                        <></>
                                    }


                                </div>

                                {/*Business Occupation*/}


                                <FormGroup>
                                    <Label className="customLabel">Business Occupation</Label>
                                    <div className="wrapCheckmark">
                                        <Field
                                            name="BusinessOccupation"
                                            component={DropdownSelectField}
                                            label="BusinessOccupation"
                                            placeholder="Please Select"
                                            filter={false}
                                            validate={[required]}
                                            type="text"
                                            valueField="value"
                                            textField="label"
                                            data={BusinessOccDropDown}
                                            className="Width640 custDropDown"
                                            onChange={(e) => this.onChangeSelect(e, "DisplayCheckMark")}
                                        />
                                        {(() => {
                                            if (this.state.DisplayCheckMark == true) {

                                                return (

                                                    <div className="checkMarkContainer">
                                                        <svg className="checkmark1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
                                                            <circle className="checkmark__circle" cx="26" cy="26" r="25" fill="none" />
                                                            <path className="checkmark__check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8" />
                                                        </svg>
                                                    </div>
                                                )
                                            }
                                        })()}
                                    </div>
                                </FormGroup>

                                <FormGroup>
                                    <Label className="customLabel">Business Trade Occupation</Label>
                                    <div className="wrapCheckmark">
                                        <Field
                                            name="BusinessTradeOccupation"
                                            component={DropdownSelectField}
                                            label="BusinessTradeOccupation"
                                            placeholder="Please Select"
                                            filter={false}
                                            validate={[required]}
                                            type="text"
                                            valueField="value"
                                            textField="label"
                                            data={this.state.BusinessTradeOccDropDown}
                                            className="Width640 custDropDown"
                                            onChange={(e) => this.onChangeSelect(e, "DisplayCheckMark2")}
                                        />
                                        {(() => {
                                            if (this.state.DisplayCheckMark2 == true) {

                                                return (

                                                    <div className="checkMarkContainer">
                                                        <svg className="checkmark1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
                                                            <circle className="checkmark__circle" cx="26" cy="26" r="25" fill="none" />
                                                            <path className="checkmark__check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8" />
                                                        </svg>
                                                    </div>
                                                )
                                            }
                                        })()}
                                    </div>
                                </FormGroup>

                                <FormGroup>
                                    <Label className="customLabel">Property Owner</Label>
                                    <Field
                                        name="propertyOwner"
                                        component={DropdownSelectField}
                                        label="propertyOwner"
                                        placeholder="Please Select"
                                        filter={false}
                                        validate={[required]}
                                        type="text"
                                        valueField="value"
                                        textField="label"
                                        data={propertyOwner}
                                        className="Width640 custDropDown"
                                        onChange={onClickPropertyOwner}
                                    />
                                </FormGroup>

                                {IsOwn == true ?

                                    <FormGroup className="flex-div mt-5" style={{ marginBottom: "0px" }}>
                                        <div className="form-Renovationinline">
                                            <Label className="customLabel customRequired">Building/ Property Value</Label>
                                        </div>
                                        <div className="RenovationTxtBox">
                                            <span>RM </span>
                                            <Field className="form-control" name="BuildingPropertyQuantity" label="BuildingPropertyQuantity" component={ValidateField} onChange={this.handleChange} maxLength={4} type="text" normalize={numberWithRangeBPQ} />
                                            <span>,000.00</span>
                                        </div>
                                    </FormGroup>

                                    :

                                    <div className="">
                                    </div>

                                }

                                {this.state.ShowMinReq == true && IsOwn == true ?
                                    <Label style={{ color: "red" }}>
                                        {this.state.MinReqMessage}
                                    </Label>
                                    :
                                    <div>
                                        <br />
                                    </div>
                                }


                                {/*Renovation/ Features Value*/}

                                <div className="mt-5 mb-4 rnvt-ft">
                                    <Label className="customLabel"><b>Renovation/ Features Value</b></Label>

                                    <FormGroup className="flex-div mt-3">
                                        <div className="form-Renovationinline">
                                            <Label className="customLabel customRequired">Stock in trade</Label>
                                        </div>
                                        <div className="RenovationTxtBox">
                                            <span>RM </span><Field className="form-control" name="StockInTradeQuantity" label="StockInTradeQuantity" onChange={this.handleChange} maxLength={4} component={ValidateField} type="text" normalize={onlyNumbers} /><span>,000.00</span>
                                        </div>
                                    </FormGroup>

                                    <FormGroup className="flex-div">
                                        <div className="form-Renovationinline">
                                            <Label className="customLabel customRequired">Furnitures, fixtures and fitting</Label>
                                        </div>
                                        <div className="RenovationTxtBox">
                                            <span>RM </span><Field className="form-control" name="FFFQuantity" label="FFFQuantity" onChange={this.handleChange} maxLength={4} component={ValidateField} type="text" normalize={onlyNumbers} /><span>,000.00</span>
                                        </div>
                                    </FormGroup>

                                    <FormGroup className="flex-div">
                                        <div className="form-Renovationinline">
                                            <Label className="customLabel customRequired">Office Equipment (including lights & cash registered)</Label>
                                        </div>
                                        <div className="RenovationTxtBox">
                                            <span>RM </span><Field className="form-control" name="OfficeEquipmentQuantity" label="OfficeEquipmentQuantity" onChange={this.handleChange} maxLength={4} component={ValidateField} type="text" normalize={onlyNumbers} /><span>,000.00</span>
                                        </div>
                                    </FormGroup>

                                    <FormGroup className="flex-div">
                                        <div className="form-Renovationinline">
                                            <Label className="customLabel customRequired">Miscellaneous item</Label>
                                        </div>
                                        <div className="RenovationTxtBox">
                                            <span>RM </span><Field className="form-control" name="MiscellaneousItemQuantity" label="MiscellaneousItemQuantity" onChange={this.handleChange} maxLength={4} component={ValidateField} type="text" normalize={onlyNumbers} /><span>,000.00</span>
                                        </div>
                                    </FormGroup>


                                    {this.state.ShowExceed == true ?
                                        <Label style={{ color: "red" }}>
                                            {this.state.ExceedMessage}
                                        </Label>
                                        :
                                        <div>
                                            <br />
                                        </div>
                                    }

                                </div>

                                <FormGroup className="flex-div">
                                    <div className="form-Addressinline">
                                        <Label className="customLabel customRequired">Building Age</Label>
                                        <Field className="form-control" name="BuildingAge" label="BuildingAge" component={ValidateField} type="text" normalize={onlyNumbers} />
                                    </div>
                                    <div className="country-txt">
                                        <Label className="customLabel customRequired">Building Storey</Label>
                                        <Field className="form-control" name="BuildingStorey" label="BuildingStorey" component={ValidateField} type="text" normalize={onlyNumbers} validate={[minValue1, maxValue1]} />
                                    </div>
                                </FormGroup>

                                <FormGroup className="flex-div">
                                    <div className="form-Addressinline">
                                        <Label className="customLabel customRequired">Building Constructed Year</Label>
                                        <div>
                                            <img src={CalendarIcon} className="CalendarImg" />
                                            <DatePicker
                                                name="BuildingConstructedYear"
                                                id="BuildingConstructedYear"
                                                className="form-control Start-DatePadding"
                                                //minDate={new Date()}
                                                showYearPicker
                                                dateFormat="yyyy"
                                                selected={BuildingConstructedYear}
                                                onChange={onYearChange}
                                                maxDate={new Date()}
                                                //excludeDates={StateHolidayList}
                                                autoComplete="off"
                                            />
                                        </div>
                                    </div>
                                </FormGroup>

                                <div className="mt-5 mb-5">
                                    <Label className="customLabel"><b>Period of Insurance</b></Label>

                                    <FormGroup className="flex-div">
                                        <div className="form-Addressinline">
                                            <Label className="customLabel customRequired">Start Date</Label>
                                            <div>
                                                <img src={CalendarIcon} className="CalendarImg" />
                                                <DatePicker
                                                    name="CoverageStartDate"
                                                    id="CoverageStartDate"
                                                    dateFormat="dd/MM/yyyy"
                                                    className="form-control Start-DatePadding"
                                                    minDate={new Date()}
                                                    maxDate={addMonths(new Date(), 2)}
                                                    selected={startDate}
                                                    onChange={onStartDateChange}
                                                    excludeDates={StateHolidayList}
                                                    autoComplete="off"
                                                />
                                            </div>
                                        </div>
                                        <div className="country-txt">
                                            <Label className="customLabel customRequired">End Date</Label>
                                            <Field className="form-control" name="endDate" label="End Date" component={ValidateField} type="text" disabled />
                                        </div>
                                    </FormGroup>

                                </div>


                                <FormGroup>
                                    <Label className="customLabel">Financial Institution</Label>
                                    <Field
                                        name="FinancialInstitution"
                                        component={DropdownSelectField}
                                        label="FinancialInstitution"
                                        placeholder="Please Select"
                                        filter="contains"
                                        onBlur={(e) => { e.preventDefault(); }}
                                        validate={RemoveBtnFinancialInstitution == true ? [required] : ""}
                                        type="text"
                                        valueField="financialInstitutionId"
                                        textField="financialInstitutionName"
                                        data={ListOfFinancialInstitution ? ListOfFinancialInstitution : []}
                                        className="custDropDown Width640"
                                        onChange={(e) => onClickFinancialInst(e, "FinancialInstitution")}
                                    //onChange={onClickFinancialInst}
                                    />
                                    {RemoveBtnFinancialInstitution == true ?
                                        <div className="removeBtn" onClick={(e) => removeValue(e, "FinancialInstitution")}>
                                            <b>Remove</b>
                                        </div>
                                        /* <a href="#" class="closeRR"></a>*/
                                        :
                                        <></>
                                    }
                                </FormGroup>


                                <FormGroup>
                                    <Label className="customLabel">Financial Institution Type</Label>
                                    <Field
                                        name="FinancialInstitutionType"
                                        component={DropdownSelectField}
                                        label="FinancialInstitutionType"
                                        placeholder="Please Select"
                                        filter="contains"
                                        onBlur={(e) => { e.preventDefault(); }}
                                        //validate={DisableFinancialInstitutionType == false ? [required] : ""}
                                        type="text"
                                        valueField="financialInstitutionTypeValue"
                                        textField="financialInstitutionTypeDisplayName"
                                        data={ListOfFinancialInstitutionType ? ListOfFinancialInstitutionType : []}
                                        className="custDropDown Width640"
                                        disabled={DisableFinancialInstitutionType}
                                        onChange={(e) => onClickFinancialInst(e, "FinancialInstitutionType")}
                                    />
                                    {RemoveBtnFinancialInstitutionType == true ?
                                        <div className="removeBtn" onClick={(e) => removeValue(e, "FinancialInstitutionType")}>
                                            <b>Remove</b>
                                        </div>
                                        :
                                        <></>
                                    }
                                </FormGroup>


                                <Button className="btnInsubee mt-4" disabled={true ? this.state.ShowExceed == true || this.state.ShowMinReq == true : false}>Next</Button>
                                {/*<Button className="btnInsubee mt-4" onClick={this.callEcoverAuthorize}>eCoverAuthor</Button>*/}
                                {/*<Button className="btnInsubee mt-4" onClick={this.getToken}>Get Token</Button>*/}



                            </form>

                        </div>


                    </div>


                </div>
            </div>
        )
    }
}


ProductGetQuoteForm = reduxForm({
    // a unique name for the form
    form: 'ProductGetQuoteForm',
    destroyOnUnmount: true,
    onSubmitFail: (errors) => scrollToFirstError(errors),
    initialValues: {}
})(ProductGetQuoteForm)


const mapDispatchToProps = dispatch => {
    return {
        getLocationByFromPostcode: (content) => {
            if (content != '' && content != null) {
                if (content.length >= 5) {
                    dispatch(actions.getLocationByFromPostcode(content));
                }
            }
        },
        getCorrespondanceLocationByFromPostcode: (content) => {
            if (content != '' && content != null) {
                if (content.length >= 5) {
                    dispatch(actions.getCorrespondanceLocationByFromPostcode(content));
                }
            }
        },
        getNationalityList: (content) => {
            dispatch(actions.getNationalityList(content));
        },
    }
}


const mapStateToProps = state => {

    const { router, product } = state || {}; // entire Redux store state (the same value returned by a call to store.getState()).

    return {
        router,
        product,
    };
};


export default connect(mapStateToProps, mapDispatchToProps)(ProductGetQuoteForm)
