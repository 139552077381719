import React from 'react';

import ForgotPasswordPresentation from './ForgotPasswordPresentation';



const ForgotPassword = props => (
    <ForgotPasswordPresentation {...props} />
);

export default ForgotPassword;