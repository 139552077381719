import policyConstants from './constants';
import userConstants from '../User/constants';

const initialState = {
    isLoading: false,
    policyList:[],
};


export const reducer = (state, action) => {
    state = state || initialState;

    switch (action.type) {
        //get my profile
        case userConstants.GET_PROFILE_REQUEST:
            return {
                ...state,
                userProfileSuccess: false,
                userData: undefined
            }
        case userConstants.GET_PROFILE_SUCCESS:
            return {
                ...state,
                userProfileSuccess: true,
                userData: action.data,
            }
        case userConstants.GET_PROFILE_FAILURE:
            return {
                ...state,
                userProfileSuccess: false,
                userData: undefined
            }
        //POLICY LIST
        case policyConstants.GET_POLICY_REQUEST:
            return {
                ...state,
                policySuccess: false,
                policyList: undefined,
                isLoading: true,
            }
        case policyConstants.GET_POLICY_SUCCESS:
            return {
                ...state,
                policySuccess: true,
                policyList: action.data,
                isLoading: false,
            }
        case policyConstants.GET_POLICY_FAILURE:
            return {
                ...state,
                policySuccess: false,
                policyList: undefined,
                isLoading: true,
            }
        //POLICY DETAILS
        case policyConstants.GET_POLICY_DETAILS_REQUEST:
            return {
                ...state,
                policyDetailsSuccess: false,
                policyDetails: undefined,
                isLoading: true,
            }
        case policyConstants.GET_POLICY_DETAILS_SUCCESS:
            return {
                ...state,
                policyDetailsSuccess: true,
                policyDetails: action.data,
                isLoading: false,
            }
        case policyConstants.GET_POLICY_DETAILS_FAILURE:
            return {
                ...state,
                policyDetailsSuccess: false,
                policyDetails: undefined,
                isLoading: true,
            }
        //DOWNLOAD POLICY
        case policyConstants.DOWNLOAD_POLICY_REQUEST:
            return {
                ...state,
                downloadPolicySuccess: false,
                isLoading: true,
            }
        case policyConstants.DOWNLOAD_POLICY_SUCCESS:
            return {
                ...state,
                downloadPolicySuccess: true,
                isLoading: false,
            }
        case policyConstants.DOWNLOAD_POLICY_FAILURE:
            return {
                ...state,
                downloadPolicySuccess: false,
                isLoading: true,
            }
        default:
            return state;
    }
}