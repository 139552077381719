const marketingConstants = {

    GET_DISCOUNT_VOUCHER_REQUEST: "GET_DISCOUNT_VOUCHER_REQUEST",
    GET_DISCOUNT_VOUCHER_SUCCESS: "GET_DISCOUNT_VOUCHER_SUCCESS",
    GET_DISCOUNT_VOUCHER_FAILURE: "GET_DISCOUNT_VOUCHER_FAILURE",

    GET_DISCOUNT_VOUCHER_DETAILS_REQUEST: "GET_DISCOUNT_VOUCHER_DETAILS_REQUEST",
    GET_DISCOUNT_VOUCHER_DETAILS_SUCCESS: "GET_DISCOUNT_VOUCHER_DETAILS_SUCCESS",
    GET_DISCOUNT_VOUCHER_DETAILS__FAILURE: "GET_DISCOUNT_VOUCHER_DETAILS__FAILURE",

    DISABLE_DISCOUNT_VOUCHER_REQUEST: "DISABLE_DISCOUNT_VOUCHER_REQUEST",
    DISABLE_DISCOUNT_VOUCHER_SUCCESS: "DISABLE_DISCOUNT_VOUCHER_SUCCESS",
    DISABLE_DISCOUNT_VOUCHER_FAILURE: "DISABLE_DISCOUNT_VOUCHER_FAILURE",
    SHOW_DISABLE_DISCOUNT_VOUCHER_FAIL: "SHOW_DISABLE_DISCOUNT_VOUCHER_FAIL",
    HIDE_DISABLE_DISCOUNT_VOUCHER_FAIL: "HIDE_DISABLE_DISCOUNT_VOUCHER_FAIL",

    GET_MASTER_ENTRY_REQUEST: "GET_MASTER_ENTRY_REQUEST",
    GET_MASTER_ENTRY_SUCCESS: "GET_MASTER_ENTRY_SUCCESS",
    GET_MASTER_ENTRY_FAILURE: "GET_MASTER_ENTRY_FAILURE",

    ADD_DISCOUNT_VOUCHER_REQUEST: 'ADD_DISCOUNT_VOUCHER_REQUEST',
    ADD_DISCOUNT_VOUCHER_SUCCESS: 'ADD_DISCOUNT_VOUCHER_SUCCESS',
    ADD_DISCOUNT_VOUCHER_FAIL: 'ADD_DISCOUNT_VOUCHER_FAIL',

    ADD_DISCOUNT_VOUCHER_HIDE_ADD_FAIL: 'ADD_DISCOUNT_VOUCHER_HIDE_ADD_FAIL',
    ADD_DISCOUNT_VOUCHER_SHOW_ADD_FAIL: 'ADD_DISCOUNT_VOUCHER_SHOW_ADD_FAIL',

    UPDATE_DISCOUNT_VOUCHER_REQUEST: 'UPDATE_DISCOUNT_VOUCHER_REQUEST',
    UPDATE_DISCOUNT_VOUCHER_SUCCESS: 'UPDATE_DISCOUNT_VOUCHER_SUCCESS',
    UPDATE_DISCOUNT_VOUCHER_FAIL: 'UPDATE_DISCOUNT_VOUCHER_FAIL',

    UPDATE_DISCOUNT_VOUCHER_HIDE_ADD_FAIL: 'UPDATE_DISCOUNT_VOUCHER_HIDE_ADD_FAIL',
    UPDATE_DISCOUNT_VOUCHER_SHOW_ADD_FAIL: 'UPDATE_DISCOUNT_VOUCHER_SHOW_ADD_FAIL',
}

export default marketingConstants;