import ClaimConstants from './constants';
import constants from '../_constants/constants';
import ClaimServices from "./services";
import Swal from 'sweetalert2';
import { push } from 'connected-react-router';
import { hot } from "react-hot-loader";
import { change, reset } from "redux-form";
import Browser from '../_helpers/browser';
import fileDownload from 'js-file-download';

const actions = {
    getAllClaim,
    submitClaim,
    getClaimbyID,
    getUserPolicy,
    downloadCustomerClaim,
    downloadFileFromBlob,
    CancelClaim,
}


function CancelClaim(customerClaimId) {

    return dispatch => {
        dispatch(request(customerClaimId));
        ClaimServices.CancelClaim(customerClaimId).then(
            data => {
                dispatch(success(data.result));
                Swal.fire({
                    title: 'Succesfully cancel!',
                    message: '',
                    type: 'success',
                });
                dispatch(push("/ClaimsList"));
            },
            error => {
                dispatch(failure(error.errorMessage));
                Swal.fire({
                    title: 'Invalid status',
                    message: error.errorMessage,
                    type: 'error',
                });
            }
        )
    }
    function request(data) { return { type: ClaimConstants.CANCEL_CLAIM_REQUEST, data } }
    function success(data) { return { type: ClaimConstants.CANCEL_CLAIM_SUCCESS, data } }
    function failure(error) { return { type: ClaimConstants.CANCEL_CLAIM_FAILURE, error } }

}

function downloadFileFromBlob(blob, fileName) {
    const dataURL = window.URL.createObjectURL(blob);


    if (Browser.isIE() || Browser.isEdge() || Browser.isMobile()) {
        var fileName = "Claim"

        const link = document.createElement('a');
        link.href = dataURL;
        link.setAttribute(
            fileName,
            fileName + '.zip',
        );
        document.body.appendChild(link);
        link.click();

        // Clean up and remove the link
        link.parentNode.removeChild(link);
        //print(link);
    }
    else {
        //window.open(dataURL);
        fileDownload(blob, fileName + '.zip');
    }
}

function downloadCustomerClaim(customerClaimId) {

    return dispatch => {
        dispatch(request(customerClaimId));
        ClaimServices.downloadCustomerClaim(customerClaimId).then(
            data => {
                dispatch(success(data.result));
                downloadFileFromBlob(data, "Claim");
            },
            error => {
                dispatch(failure(error));
                Swal.fire({
                    title: 'Invalid status',
                    message: error,
                    type: 'error',
                });
            }
        )
    }

    function request(data) { return { type: ClaimConstants.DOWNLOAD_CLAIM_REQUEST, data } }
    function success(data) { return { type: ClaimConstants.DOWNLOAD_CLAIM_SUCCESS, data } }
    function failure(error) { return { type: ClaimConstants.DOWNLOAD_CLAIM_FAILURE, error } }

}

function getAllClaim(page, rowPerPage) {

    return dispatch => {
        dispatch(request(page, rowPerPage));
        ClaimServices.getAllClaim(page, rowPerPage).then(
            data => {
                dispatch(success(data.result));
            },
            error => {
                dispatch(failure(error.errorMessage));
            }
        )
    }
    function request(data) { return { type: ClaimConstants.GET_ALLCLAIM_REQUEST, data } }
    function success(data) { return { type: ClaimConstants.GET_ALLCLAIM_SUCCESS, data } }
    function failure(error) { return { type: ClaimConstants.GET_ALLCLAIM_FAILURE, error } }

}

function submitClaim(formdata) {

    return dispatch => {
        dispatch(request(formdata));
        ClaimServices.submitClaim(formdata).then(
            data => {
                dispatch(success(data.result));
                Swal.fire({
                    title: 'Succesfully submitted!',
                    message: '',
                    type: 'success',
                });
                dispatch(push("/ClaimsList"));
            },
            error => {
                dispatch(failure(error));
                Swal.fire({
                    title: 'Error',
                    message: '',
                    text: error.errorMessage,
                    type: 'error'
                });
            }

        )
    }
    function request(data) { return { type: ClaimConstants.SUBMIT_CLAIM_REQUEST, data } }
    function success(data) { return { type: ClaimConstants.SUBMIT_CLAIM_SUCCESS, data } }
    function failure(error) { return { type: ClaimConstants.SUBMIT_CLAIM_FAILURE, error } }

}

function getClaimbyID(ClaimID) {

    return dispatch => {
        dispatch(request(ClaimID));
        ClaimServices.getClaimbyID(ClaimID).then(
            data => {
                dispatch(success(data.result));
                /*dispatch(push("/ClaimsDetail"));*/
            },
            error => {
                dispatch(failure(error.errorMessage));
            }
        )
    }
    function request(data) { return { type: ClaimConstants.GET_CLAIM_REQUEST, data } }
    function success(data) { return { type: ClaimConstants.GET_CLAIM_SUCCESS, data } }
    function failure(error) { return { type: ClaimConstants.GET_CLAIM_FAILURE, error } }

}

function getUserPolicy() {

    return dispatch => {
        dispatch(request());
        ClaimServices.getUserPolicy().then(
            data => {
                dispatch(success(data.result));
            },
            error => {
                dispatch(failure(error.errorMessage));
            }
        )
    }
    function request(data) { return { type: ClaimConstants.GET_USERPOLICY_REQUEST, data } }
    function success(data) { return { type: ClaimConstants.GET_USERPOLICY_SUCCESS, data } }
    function failure(error) { return { type: ClaimConstants.GET_USERPOLICY_FAILURE, error } }

}


export default hot(module)(actions);