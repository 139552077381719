import constants from '../_constants/constants';
import Cookie from 'universal-cookie'
import { handleResponse } from "../_helpers/handleResponse";
import { authHeader, authHeaderFile, authHeaderWithoutUser } from '../_helpers/authHeader';

const cookies = new Cookie();

const userServices = {
    getProfile,
    changePassword,
    updateProfile
}

function getProfile() {
    const requestOptions = {
        method: "GET",
        headers: authHeader()
    };

    return fetch(constants.apiGetProfile, requestOptions).then(
        (response) => handleResponse(response, requestOptions, constants.apiGetProfile)
    ).then(data => {
        return data;
    });

}

function changePassword(changePasswordModel) {
    const requestOptions = {
        method: "POST",
        headers: authHeader(),
        body: JSON.stringify(changePasswordModel)
    };

    return fetch(constants.apiChangePassword, requestOptions).then(
        (response) => handleResponse(response, requestOptions, constants.apiChangePassword)
    ).then(data => {
        return data;
    });
}

function updateProfile(updateModel) {
    const requestOptions = {
        method: "PUT",
        headers: authHeader(),
        body: JSON.stringify(updateModel)
    };


    return fetch(constants.apiUpdateProfile, requestOptions).then(
        (response) => handleResponse(response, requestOptions, constants.apiUpdateProfile)
    ).then(data => {
        return data;
    });
}


export default userServices;