const accountConstants = {


    USER_SIGNUP_REQUEST: 'USER_SIGNUP_REQUEST',
    USER_SIGNUP_SUCCESS: 'USER_SIGNUP_SUCCESS',
    USER_SIGNUP_FAIL: 'USER_SIGNUP_FAIL',

    HIDE_SIGNUP_FAIL: 'HIDE_SIGNUP_FAIL',
    SHOW_SIGNUP_FAIL: 'SHOW_SIGNUP_FAIL',


    USER_LOGIN_REQUEST: 'USER_LOGIN_REQUEST',
    USER_LOGIN_SUCCESS: 'USER_LOGIN_SUCCESS',
    USER_LOGIN_FAILURE: 'USER_LOGIN_FAILURE',

    USER_RESET_REQUEST: 'USER_RESET_REQUEST',
    USER_RESET_SUCCESS: 'USER_RESET_SUCCESS',
    USER_RESET_FAIL: 'USER_RESET_FAIL',
    HIDE_RESET_FAIL: 'HIDE_RESET_FAIL',
    SHOW_RESET_FAIL: 'SHOW_RESET_FAIL',

    GET_FROMLOCATION_REQUEST: "GET_FROMLOCATION_REQUEST",
    GET_FROMLOCATION_SUCCESS: "GET_FROMLOCATION_SUCCESS",
    GET_FROMLOCATION_FAILURE: "GET_FROMLOCATION_FAILURE",

    GET_NATIONALITY_SUCCESS: "GET_NATIONALITY_SUCCESS",
    GET_NATIONALITY_FAILURE: "GET_NATIONALITY_FAILURE",

    GET_COMMON_PASSWORD_SUCCESS: "GET_COMMON_PASSWORD_SUCCESS",
    GET_COMMON_PASSWORD_FAILURE: "GET_COMMON_PASSWORD_FAILURE",
}

export default accountConstants;