
import React from 'react';
import MyProfilePresentation from './MyProfilePresentation';



const MyProfile = props => (
    <MyProfilePresentation {...props} />
);

export default MyProfile;
