import React from 'react';
import ClaimsDetailPresentation from './ClaimsDetailPresentation';
import { connect } from 'react-redux';
import actions from './actions';

// Data return from reducer (Redux store).  ( /store/configureStore)
function mapStateToProps(state) {

    const { ClaimStore } = state;

    return {
        ClaimStore,
    }
}

// Calling Api via dispatching action
// Triggered from child Presentation
function mapDispatchToProps(dispatch) {
    return {
        downloadCustomerClaim: (id) => { dispatch(actions.downloadCustomerClaim(id)) },
        CancelClaim: (customerClaimId) => { dispatch(actions.CancelClaim(customerClaimId)) },
        getClaimbyID: (ClaimID) => { dispatch(actions.getClaimbyID(ClaimID)) },
    }
}


const ClaimsDetail = connect(mapStateToProps, mapDispatchToProps)(ClaimsDetailPresentation);

export default (ClaimsDetail);