
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Container, Row, Col, FormGroup, Label, InputGroup, InputGroupAddon, InputGroupText, Input, Card, CardBody } from 'reactstrap';
import Character1 from '../../image/AboutUs/Character1.png'
import Character2 from '../../image/AboutUs/Character2.png'


class AboutUs extends Component {

    constructor(props) {
        super(props);

    }



    componentDidMount() {
        window.scrollTo(0, 0);
    }



    render() {

        return (

            <div className="mt-5">
                <div className="" style={{ backgroundColor: "#F9F9F9" }}>
                    <div>
                        <div className="mt-5">
                            <div className="WhoWeAre-Content">
                                <div>
                                    <h1><b>Who We Are</b></h1>
                                </div>
                                <div>
                                    <span>
                                        Insubee is an online insurance platform offering bite-size insurance.
                                        We aim to simplify insurance by working with insurers to curate affordable and simple policies to cover risks that you truly need to worry about.
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div className="">
                            <div>
                                <div className="AboutUs-bgImg">
                                    <img src={Character1} className="AboutUs-Img1" />
                                    <div className="OurVision-Content">
                                        <div>
                                            <h1><b>Our Vision</b></h1>
                                        </div>
                                        <div>
                                            <span>
                                                To provide protection against unanticipated problems by simplifying insurance.
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="mt-5">
                            <div className="WhatWeDo-box">
                                <img src={Character2} className="AboutUs-Img2" />
                                <div className="WhatWeDo-Content">
                                    <div>
                                        <h1><b>What We Do</b></h1>
                                    </div>
                                    <div>
                                        <span>
                                            We are an insurance agency that collaborate with the top insurance companies to design insurance products just for you.
                                            Getting covered is simple, affordable and instant. The process starts with selecting the product you need and declaring information
                                            needed by the insurer. We shall arrange for the insurance policy certificate to be sent to you where an insurer has approved
                                            issuing a policy to you.
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        );
    }
}

export default AboutUs;
