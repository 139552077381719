import constants from '../_constants/constants';
import Cookie from 'universal-cookie'
import { handleResponse } from "../_helpers/handleResponse";
import { authHeader, authHeaderFile, authHeaderWithoutUser } from '../_helpers/authHeader';

const cookies = new Cookie();

const userSettingServices = {
    getRoleList,
    addRole,
    updateRole,
    deleteRole
}
function getRoleList(page, size) {
    const requestOptions = {
        method: "GET",
        headers: authHeader()
    };
    const url = "/api/Role/GetRole?pageNumber=" + page + "&pageSize=" + size;

    return fetch(url, requestOptions).then(
        (response) => handleResponse(response, requestOptions, url)
    ).then(data => {
        return data;
    });
}

function addRole(roleModel) {
    const requestOptions = {

        method: "POST",
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(roleModel)
    };


    return fetch(constants.apiAddRole, requestOptions).then(
        (response) => handleResponse(response, requestOptions, constants.apiAddRole)
    ).then(data => {
        return data;
    });
}

function updateRole(roleModel) {
    const requestOptions = {

        method: "POST",
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(roleModel)
    };


    return fetch(constants.apiUpdateRole, requestOptions).then(
        (response) => handleResponse(response, requestOptions, constants.apiUpdateRole)
    ).then(data => {
        return data;
    });
}

function deleteRole(id) {
    const requestOptions = {

        method: "DELETE",
    };


    return fetch(constants.apiDeleteRole + "/" + id, requestOptions).then(
        (response) => handleResponse(response, requestOptions, constants.apiDeleteRole + "/" + id)
    ).then(data => {
        return data;
    });
}

export default userSettingServices;
