import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import constants from '../_constants/constants';

import Cookies from 'universal-cookie';

const cookies = new Cookies();

const Login = React.lazy(() => import('../Layouts/CoreUi/views/Pages/Login'));

const AuthorizeRoute = ({ component: Component, ...rest }) => (
    //<Route {...rest} render={props => (
    //    cookies.get("insubeeToken")
    //        ? <Component {...props} />
    //        : <Redirect to={{ pathname: '/admin/login' }} />
    //)} />
    <Route {...rest} render={props => (
         <Component {...props} />
            
    )} />
)

export default AuthorizeRoute;