import React, { Component } from 'react';
import { Button,Carousel, CarouselCaption, NavLink, CarouselIndicators, CarouselItem,CarouselControl, Container, Row, Col, Card, CardBody } from 'reactstrap';
import InsuBeeLogo from "../../image/InsubeeLogo.png";
import { Link } from 'react-router-dom';
import { Field, reduxForm } from 'redux-form'
import ValidateField from '../GlobalComponents/FormElements/ValidateField';
import { connect } from 'react-redux';
import InsuranceCoverFloods from "../../image/Blog/DoesBusinessInsuranceCoverFloods346x200.png";
import RM1MillionPublicLiability from "../../image/Blog/RM1MillionPublicLiability.png";
import InsuranceAsABackbone346x200 from "../../image/Blog/InsuranceAsABackbone346x200.png";
import KeysToAccelerateBusinessTransformation346x200 from "../../image/Blog/KeysToAccelerateBusinessTransformation346x200.png";

class Blog extends Component {
    state = {

    }
    constructor(props) {
        super(props)
   
    }


    componentDidMount() {
        window.scrollTo(0, 0);
    }

    render() {
       
        

        return (
            <form>
            <div>

                    <div className="container pt-3">
                        <Row className="pt-3 pb-3">
                            <Col className="text-center pt-2" xs="12" sm="12" lg="12">
                            <h1><b>Blog</b></h1>
                            <hr style={{ width: "6%", height: "100%", backgroundColor: "#FFDF00", border: "none", height: "5px" }} />
                            </Col>
                    </Row>
                        <Row className="pt-3 pb-3">
                            <Col className="pt-2" xs="4" sm="4" lg="8">
                               
                            </Col>
                        <Col className="pt-2" xs="4" sm="4" lg="4">
                            <Field className="form-control" name="Search" label="Search" component={ValidateField} type="text" placeholder="Search"/>
                        </Col>
                    </Row>
                        <Row className="pt-2">
                            <Col className="pt-2 pb-2" xs="12" sm="4" lg="4">
                                <div className="blog_img">
                                    <div className="blog_in">
                                        <div className="insurance_product_in_2 hover01">
                                            <figure className="figure"><NavLink className="nav-linkBlog" tag={Link} to="/BlogDetailsDoesBusinessInsuranceCoverFloods"><img alt="" className="img-fluid img_insurance_product" style={{height: "200px"}} aria-hidden="true" src={InsuranceCoverFloods}>
                                        </img>
                                        </NavLink>
                                            </figure>
                                        </div>
                                    </div>
                            </div>
                            <br />
                            <NavLink className="nav-linkBlog" tag={Link} to="/BlogDetailsDoesBusinessInsuranceCoverFloods"><h4>Does Business Insurance Cover Flood Damage?</h4></NavLink>
                            <br />
                            <p> <img src={'../../assets/img/Insubee_Logo2.png'} className="fluid" width="20" height="20" /> &nbsp; Team Insubee</p>
                            <p>Due to the large number of rivers running through the country, roughly 9 percent of the total land area is under flood risk. Dealing with the aftermath of flood is a stressful situation, here is why it is important to ensure your policy covers flood. </p>
                            </Col>
                            <Col className="pt-2 pb-2" xs="12" sm="4" lg="4">
                                <div className="blog_img">
                                    <div className="blog_in">
                                        <div className="insurance_product_in_2 hover01">
                                        <figure className="figure"><NavLink className="nav-linkBlog" tag={Link} to="/BlogDetailsRM1MillionPublicLiability"><img alt="" className="img-fluid img_insurance_product" style={{height: "200px"}} aria-hidden="true" src={RM1MillionPublicLiability}>
                                        </img>
                                        </NavLink>
                                            </figure>
                                        </div>
                                    </div>
                            </div>
                            <br />
                            <NavLink className="nav-linkBlog" tag={Link} to="/BlogDetailsRM1MillionPublicLiability"><h4>RM 1 Million Public Liability: To Be or Not to Be</h4></NavLink>
                            <br />
                            <p> <img src={'../../assets/img/Insubee_Logo2.png'} className="fluid" width="20" height="20" /> &nbsp; Team Insubee</p>
                            <p>An unintended slip in your store can lead to major litigation costs. Here's why having public liability matters.</p>
                            </Col>
                            <Col className="pt-2 pb-2" xs="12" sm="4" lg="4">
                                <div className="blog_img">
                                    <div className="blog_in">
                                        <div className="insurance_product_in_2 hover01">
                                            <figure className="figure"><NavLink className="nav-linkBlog" tag={Link} to="/BlogDetailsInsuranceAsABackbone"><img alt="" className="img-fluid img_insurance_product" style={{ height: "200px" }} aria-hidden="true" src={InsuranceAsABackbone346x200}>
                                        </img>
                                        </NavLink>
                                            </figure>
                                        </div>
                                    </div>
                            </div>
                            <br />
                            <NavLink  className="nav-linkBlog"tag={Link} to="/BlogDetailsInsuranceAsABackbone"><h4>Insurance as a Backbone for Business Resilience</h4></NavLink>
                            <br />
                            <p> <img src={'../../assets/img/Insubee_Logo2.png'} className="fluid" width="20" height="20" /> &nbsp; Team Insubee</p>
                            <p>Here are key points to accelerate business transformation. </p>
                            </Col>
                    </Row>
                    <Row className="pt-2">
                        <Col className="pt-2 pb-2" xs="12" sm="4" lg="4">
                            <div className="blog_img">
                                <div className="blog_in">
                                    <div className="insurance_product_in_2 hover01">
                                            <figure className="figure"><NavLink className="nav-linkBlog" tag={Link} to="/BlogDetailsKeysToAccelerateBusiness"><img alt="" className="img-fluid img_insurance_product" style={{ height: "200px" }} aria-hidden="true" src={KeysToAccelerateBusinessTransformation346x200}>
                                        </img>
                                        </NavLink>
                                        </figure>
                                    </div>
                                </div>
                            </div>
                            <br />
                            <NavLink className="nav-linkBlog" tag={Link} to="/BlogDetailsKeysToAccelerateBusiness"><h4>Keys to Accelerate Business Transformation</h4></NavLink>
                            <br />
                            <p> <img src={'../../assets/img/Insubee_Logo2.png'} className="fluid" width="20" height="20" /> &nbsp; Team Insubee</p>
                            <p>Here are key points to accelerate business transformation. </p>
                        </Col>
                    </Row>
                    </div>

                   
                
                </div>

                </form>
        )
        
    }
}


const mapDispatchToProps = dispatch => {
    return {


    }
}







const mapStateToProps = state => {

    //const { home } = state || {}; // entire Redux store state (the same value returned by a call to store.getState()).  
    //return { home };
};


Blog = reduxForm({
    // a unique name for the form
    form: 'Blog',
    destroyOnUnmount: true,
    nitialValues: {


    }
})(Blog)

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Blog)


//export default Blog;