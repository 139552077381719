import { connect } from 'react-redux';
import actions from './actions'
import { formValueSelector, change, untouch, reset } from 'redux-form'
import ProductGetQuotePresentation from './ProductGetQuotePresentation';


const selector = formValueSelector('EditAddOnForm');
const selectorGetQuoteForm = formValueSelector('ProductGetQuoteForm');

function mapStateToProps(state) {

    const { router, product } = state || {}; // entire Redux store state (the same value returned by a call to store.getState()).   

    return {
        router,
        product,
        coverageValues: selector(state, "coverage2", "coverage4", "coverage5", "coverage6", "coverageInTransit", "coverageInPremise"),
        //coverage4FromModel: selector(state, "coverage4"),
        //coverage5FromModel: selector(state, "coverage5"),
        //coverage6FromModel: selector(state, "coverage6"),
        //coverageInTransitFromModel: selector(state, "coverageInTransit"),
        //coverageInPremiseFromModel: selector(state, "coverageInPremise"),
        businessOccupation: selectorGetQuoteForm(state, "BusinessOccupation"),
    }
}

function mapDispatchToProps(dispatch) {
    return {
        selectPlan: (content) => {dispatch(actions.selectPlan(content))},
        getMasterEntry: (content) => {dispatch(actions.getMasterEntry(content))},
        getStateHoliday: (content) => {dispatch(actions.getStateHoliday(content))},
        qtyChange: (form, fieldName, value) => {dispatch(change(form, fieldName, value))},
        untouchChange: (form, fieldName) => { dispatch(untouch(form, fieldName)) },
        reset: (form) => { dispatch(reset(form)) },
        createPolicy: (content) => {dispatch(actions.createPolicy(content))},
        UserCreatePolicy: (content) => {dispatch(actions.UserCreatePolicy(content))},
        GuestCreatePolicy: (content) => {dispatch(actions.GuestCreatePolicy(content))},
        UserCalculateDiscountVoucher: (content) => {dispatch(actions.UserCalculateDiscountVoucher(content))},
        GuestCalculateDiscountVoucher: (content) => {dispatch(actions.GuestCalculateDiscountVoucher(content))},
    }
}

const ProductGetQuote = connect(mapStateToProps, mapDispatchToProps)(ProductGetQuotePresentation);

export default ProductGetQuote;