import { scroller } from 'react-scroll';
import isObject from 'lodash/isObject';

export function scrollToFirstError(errors) {
    const errorFields = getErrorFieldNames(errors);
    scroller.scrollTo(errorFields[0], { offset: -200, smooth: true })
}

function getErrorFieldNames(errors, name = '', resultArray = []) {
    Object.keys(errors).forEach((k) => {
        const value = errors[k]
        if (typeof value === 'string') {
            resultArray.push(name + k)
        }
        if (isObject(value)) {
            Object.keys((value)).forEach((idx) => {
                const innerValue = value[idx]
                if (!innerValue) return
                const nextName = `${name + k}[${idx}].`
                getErrorFieldNames(innerValue, nextName, resultArray)
            })
        }
    })

    return resultArray
}