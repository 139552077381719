import React from 'react';
import ClaimsListPresentation from './ClaimsListPresentation';
import { connect } from 'react-redux';
import actions from './actions';

// Data return from reducer (Redux store).  ( /store/configureStore)
function mapStateToProps(state) {

    const { ClaimStore } = state;

    return {
        ClaimStore,
    }
}

// Calling Api via dispatching action
// Triggered from child Presentation
function mapDispatchToProps(dispatch) {
    return {
        getAllClaim: (page, rowPerPage) => { dispatch(actions.getAllClaim(page, rowPerPage)) },
        getClaimbyID: (ClaimID) => { dispatch(actions.getClaimbyID(ClaimID)) },
    }
}


const ClaimsList = connect(mapStateToProps, mapDispatchToProps)(ClaimsListPresentation);

export default (ClaimsList);

