import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Container, Row, Col, FormGroup, Label, InputGroup, InputGroupAddon, InputGroupText, Input, Card, CardBody, Button, Table } from 'reactstrap';
import { Link } from "react-router-dom";
import InsuBeeLogo from "../../image/InsubeeLogo.png";
import zipFormatLogo from "../../image/Claim/zipFormat.png";
import { hot } from 'react-hot-loader';
import { numberWithCommas } from "../GlobalComponents/_function";
import SweetAlert from 'sweetalert2-react';

class ClaimsDetailPresentation extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            showAlert: false,
        };
        this.goClaimList = this.goClaimList.bind(this);
        this.CancelClaim = this.CancelClaim.bind(this);
        this.handleAlert = this.handleAlert.bind(this);
    }

    handleAlert(customerClaimId) {
        if (customerClaimId) {
            this.setState({
                showAlert: true,
                claimID: customerClaimId,
            });
        }
        else {
            this.setState({
                showAlert: false,
            });
        }
    }

    CancelClaim(ClaimId) {
        this.props.CancelClaim(ClaimId);
        this.setState({
            showAlert: false,
        });
    }

    componentDidMount() {
        const claimId = window.localStorage.getItem('claimID');
        
        window.scrollTo(0, 0);
        this.props.getClaimbyID(claimId);
    }

    goClaimList() {
        this.props.history.push('/ClaimsList');
    }

    render() {

        const { ClaimStore } = this.props;

        console.log(ClaimStore);
        let Claim = [];

        if (ClaimStore.ClaimDetail) {
            Claim = ClaimStore.ClaimDetail;
        }

        return (

            <>
                <React.Fragment>
                    {/*<SweetAlert*/}
                    {/*    type="warning"*/}
                    {/*    show={this.state.showAlert}*/}
                    {/*    title="Cancel Claim"*/}
                    {/*    text="Would you like to proceed?"*/}
                    {/*    showCancelButton={true}*/}
                    {/*    onConfirm={() =>this.CancelClaim()}*/}
                    {/*    onCancel={() =>this.handleAlert()}*/}
                    {/*    confirmButtonText={"Yes"}*/}
                    {/*    cancelButtonText={"No"}*/}
                    {/*/>*/}
                </React.Fragment>

                <div className="my-3">
                    <div className="container">
                        <Row>
                            <Col xs="12" sm="12" lg="12">
                                <div className="col-sm-12">
                                    <h2 className="font-weight-bold text-center">Claim Details</h2>
                                </div>
                                <br />
                                <div className="row justify-content-center">
                                    <div className="col-sm-10">
                                        <Card style={{ border: '7px solid rgba(169,176,180,0.1)', paddingLeft: '1rem', paddingRight: '1rem' }}>
                                            <CardBody>

                                            <div className="row mt-3">
                                                <div className="col-sm-12">
                                                    <div className="d-flex">
                                                        <div className="policy-info text-left" style={{ alignSelf: 'center' }}></div>
                                                        <div className="ml-auto">

                                                            <Button color="warning" className="insubeeYellowButton" style={{ width: "100px" }} onClick={this.goClaimList}>
                                                                <b>Back</b>
                                                                </Button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                                <div className="row">
                                                    <div className="col-sm-4">
                                                        <p className="mb-0">Product</p>
                                                        <p style={{ fontSize: '20px' }}><strong>{Claim.productName}</strong></p>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-sm-4">
                                                        <p className="mb-0">Submission Date</p>
                                                        <p style={{ fontSize: '20px' }}><strong>{Claim.createdDate}</strong></p>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-sm-4">
                                                        <p className="mb-0">Claim Reference No.</p>
                                                        <p style={{ fontSize: '20px' }}><strong>{Claim.claimNo ? Claim.claimNo : "-"}</strong></p>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-sm-4">
                                                        <p className="mb-0">Policy No.</p>
                                                        <p style={{ fontSize: '20px' }}><strong>{Claim.policyNo}</strong></p>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-sm-4">
                                                        <p className="mb-0">Claim Amount</p>
                                                        <p style={{ fontSize: '20px' }}><strong>RM {Claim.claimAmount ? numberWithCommas(Claim.claimAmount.toFixed(2)) : "0.00"}</strong></p>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-sm-4">
                                                        <p className="mb-0">Policy Holder</p>
                                                        <p style={{ fontSize: '20px' }}><strong>{Claim.policyHolderName}</strong></p>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-sm-4">
                                                        <p className="mb-0">Payment Reference No.</p>
                                                        <p style={{ fontSize: '20px' }}><strong>{Claim.paymentReferenceNo ? Claim.paymentReferenceNo : "-"}</strong></p>
                                                    </div>
                                            </div>

                                                <br></br>

                                            {Claim.claimFormUrl ?
                                                <div className="row mt-3">
                                                    <div className="col-sm-12">
                                                        <p><img style={{ cursor: 'pointer' }} src={zipFormatLogo} height="40" onClick={e => this.props.downloadCustomerClaim(Claim.customerClaimId)} /> &nbsp;&nbsp; {Claim.policyHolderName}'s Claim Form</p>
                                                    </div>
                                                </div>
                                                :
                                                <> </>
                                            }
                                                <Table striped bordered hover>
                                                    <thead>
                                                        <tr>
                                                            <th>Date</th>
                                                            <th>Status</th>
                                                            <th>Updated by</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {Claim.claimTransactions && 
                                                            Claim.claimTransactions.map((ClaimValue) => (

                                                            <tr>
                                                                <td>{ClaimValue.updatedDate}</td>
                                                                <td>{ClaimValue.claimStatus}</td>
                                                                <td>{ClaimValue.updatedUserName}</td>
                                                            </tr>
                                                        ))}

                                                    </tbody>
                                                </Table>

                                                {Claim.claimStatusId != 1 ?
                                                    <>
                                                    </>
                                                    :
                                                    <div className="row mt-3">
                                                        <div className="col-sm-12">
                                                            <div className="d-flex">
                                                                <div className="policy-info text-left" style={{ alignSelf: 'center' }}></div>
                                                                <div className="ml-auto">

                                                                    <Button color="warning" className="insubeeYellowButton" style={{ width: "100%" }} onClick={() => this.CancelClaim(Claim.customerClaimId)}>
                                                                        Cancel Claim
                                                                </Button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                }

                                                <div className=" my-5 pt-sm-5 text-left">
                                                    <span>Should you have any problem with our services, please call us at 03-7787 2222 or drop us an e-mail at help.support@insubee.com</span>
                                                </div>


                                            </CardBody>
                                        </Card>
                                    </div>
                                </div>

                            </Col>
                        </Row>
                    </div>
                </div>

            </>

            //<div className="my-5 pt-sm-5">
            //    <Container className="formBackground">

            //        <div>

            //            <Row>
            //                <Col xs="12" sm="12" lg="12">
            //                    <div className="text-center">
            //                        <h1><b>Claim Details</b></h1>
            //                    </div>
            //                </Col>
            //            </Row>

            //            <Card style={{ border: "4px solid" }}>
            //                <CardBody>

            //                    <Row>
            //                        <Col xs="10" sm="10" lg="10">
            //                            <div className="text-left" style={{ padding: "30px" }}>
            //                                <p>Product: {Claim.productName}</p>
            //                                <p>Submission Date: {Claim.policyHolderName}</p>
            //                                <p>Claim Reference No.: {Claim.claimNo}</p>
            //                                <p>Policy No.: {Claim.policyNo}</p>
            //                                <p>Claim Amount: {Claim.claimAmount}</p>
            //                                <p>Policy Holder: {Claim.policyHolderName}</p>
            //                                <p>Payment Reference No.: {Claim.paymentReferenceNo}</p>
            //                            </div>
            //                        </Col>

            //                        <Col xs="2" sm="2" lg="2">
            //                            <div>
            //                                <Button color="warning" className="insubeeYellowButton" style={{ width: "100%" }} onClick={this.goClaimList}>
            //                                    Cancel</Button>
            //                                {/*<button type="submit" className="insubeeYellowButton" style={{ width: "100%" }} onClick={this.goClaimList}>*/}
            //                                {/*            Cancel*/}
            //                                {/*            </button>*/}
            //                            </div>
            //                        </Col>
            //                    </Row>


            //                    <Row>
            //                        <Col xs="12" sm="12" lg="12">
            //                            <div className="text-left" style={{ padding: "30px" }}>
            //                                <p><img src={PdfLogo} height="40" /> &nbsp;&nbsp; [Policy Holder]'s Claim Form</p>
            //                            </div>
            //                        </Col>
            //                    </Row>

            //                    <Table striped bordered hover>
            //                        <thead>
            //                            <tr>
            //                                <th>Date</th>
            //                                <th>Status</th>
            //                                <th>Updated by</th>
            //                            </tr>
            //                        </thead>
            //                        <tbody>
            //                            {Claim.claimTransactions.map((ClaimValue) => (

            //                                <tr>
            //                                    <td>{ClaimValue.updatedDate}</td>
            //                                    <td>{ClaimValue.claimStatus}</td>
            //                                    <td>{ClaimValue.updatedUserName}</td>
            //                                </tr>
            //                            ))}  

            //                        </tbody>
            //                    </Table>

            //                    <div className=" my-5 pt-sm-5 text-left">
            //                        <span>Should you have any problem with our services, please call us at 03-XXXX XXXX or drop us an e-mail at admin@insubee.com.my</span>
            //                    </div>



            //                </CardBody>
            //            </Card>

            //        </div>


            //    </Container>

            //    </div>

              
        );
    }
}


export default hot(module)(ClaimsDetailPresentation);