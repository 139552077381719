
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Button, Container, Row, Col, FormGroup, Label, InputGroup, InputGroupAddon, InputGroupText, Input, Card, CardBody, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import ValidateField from "../GlobalComponents/FormElements/ValidateField";
import { onlyNumbers, required, numberWithRange, numberWithRangeBurglary, numberWithRangeMoney, numberWithRangeGlass, numberWithRangePublicLiability, numberWithRangeEmployerLiability } from '../GlobalComponents/ValidationRules';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { faMinus } from '@fortawesome/free-solid-svg-icons';
import { Field, reduxForm, FormSection } from 'redux-form';
import "./style.css";
import { hot } from "react-hot-loader";


const numberWithRange25to500 = numberWithRangeBurglary(25, 500);
const numberWithRange10to100 = numberWithRangeMoney(10, 100);
const numberWithRange5to50 = numberWithRangeGlass(5, 50);
const numberWithRange250to2000 = numberWithRangePublicLiability(250, 2000);
const numberWithRange1000to2000 = numberWithRangeEmployerLiability(1000, 2000);


class EditAddOnForm extends Component {

    constructor(props) {
        super(props);
        this.state = {
            ShowErrorMsg: false,
            MViewButton: true,
        }

        //this.onIncrement = this.onIncrement.bind(this);
        //this.onDecrement = this.onDecrement.bind(this);
        //this.handleChange = this.handleChange.bind(this);
        //this.PremiumCheck = this.PremiumCheck.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.modelEditToggle = this.modelEditToggle.bind(this);
    }

    handleChange(event, id) {

        const target = event.target;
        const value = target.value;

        const name = target.name;

        let ErrorMsg;
        let ModelViewButton;

        if (id == 2) {
            if (value < 25) {
                ErrorMsg = true;
                ModelViewButton = true;
            }
            else {
                ErrorMsg = false;
                ModelViewButton = false;
            }
        }

        if (id == 3) {
            if (name == "coverageInTransit" || name == "coverageInPremise") {
                if (value < 10) {
                    ErrorMsg = true;
                    ModelViewButton = true;
                }
                else {
                    ErrorMsg = false;
                    ModelViewButton = false;
                }
            }
        }

        if (id == 4) {
            if (value < 5) {
                ErrorMsg = true;
                ModelViewButton = true;
            }
            else {
                ErrorMsg = false;
                ModelViewButton = false;
            }
        }

        if (id == 5) {
            if (value < 250) {
                ErrorMsg = true;
                ModelViewButton = true;
            }
            else {
                ErrorMsg = false;
                ModelViewButton = false;
            }
        }

        if (id == 6) {
            if (value < 1000) {
                ErrorMsg = true;
                ModelViewButton = true;
            }
            else {
                ErrorMsg = false;
                ModelViewButton = false;
            }
        }

        this.setState({
            ShowErrorMsg: ErrorMsg,
            MViewButton: ModelViewButton,
        });


    }

    componentDidMount() {

    }

    modelEditToggle(e) {
        this.setState({
            ShowErrorMsg: false,
            MViewButton: true,
        });

        this.props.editToggle(e);
    }

    componentWillReceiveProps(nextProps) {



    }

    render() {

        const { editToggle, isOpen, SelectedComponentList, PremiumCheck, handleChange, onIncrement, onDecrement, Amount, addOnCoveragePlanId, CancelAddOnCoverage, ShowErrorMsg, DisableModelViewButton } = this.props;
        let Subtotal = 0;
        let errorMessage = "";

        if (Amount) {
            if (Amount.addOnCoveragePlanId !== addOnCoveragePlanId) {

                if (SelectedComponentList.addOnCoveragePlanId == addOnCoveragePlanId) {

                    if (SelectedComponentList.premium !== null) {

                        Subtotal = SelectedComponentList.premium;

                    }

                    if (SelectedComponentList.totalPremium !== null) {

                        Subtotal = SelectedComponentList.totalPremium;

                    }

                }

                else {
                    Subtotal = 0;
                    //this.props.change('coverage', 0);
                }
            }
            else {
                if (Amount.addOnCoveragePlanId !== 3) {
                    Subtotal = Amount.premium;
                }
                else {
                    Subtotal = Amount.totalPremium;
                }
            }
        }

        if (SelectedComponentList) {

            if (SelectedComponentList.addOnCoveragePlanId == 2) {

                errorMessage = "Amount must in between RM25,000.00 - RM500,000.00"
                //if (SelectedComponentList.coverage !== null) {
                //    var coverage2 = SelectedComponentList.coverage.toString();
                //    this.props.change('coverage2', coverage2.slice(0, -3));
                //}
            }

            if (SelectedComponentList.addOnCoveragePlanId == 3) {

                errorMessage = "Amount must in between RM10,000.00 - RM100,000.00"
            }

            if (SelectedComponentList.addOnCoveragePlanId == 4) {

                errorMessage = "Amount must in between RM5,000.00 - RM50,000.00"
            }

            if (SelectedComponentList.addOnCoveragePlanId == 5) {

                errorMessage = "Amount must in between RM250,000.00 - RM2,000,000.00"
            }

            if (SelectedComponentList.addOnCoveragePlanId == 6) {

                errorMessage = "Amount must in between RM1,000,000.00 - RM2,000,000.00"
            }

        }


        return (
            <>

                <Modal isOpen={isOpen} toggle={(e) => this.modelEditToggle(e)}>
                    <div className="modal-header addOnModal">
                        <div className="modal-title">
                            <h2> {SelectedComponentList.addOnCoveragePlanName}</h2></div>
                        {/*<button type="button" className="close" aria-label="Close" style={{ color: "white" }} onClick={editToggle}>*/}
                        {/*    <span aria-hidden="true">x</span>*/}
                        {/*</button>*/}
                    </div>

                    <div className="modalContainer">

                        <ModalBody>

                            <Label className="customLabel">Please key in the coverage amount.</Label>

                            {SelectedComponentList.addOnCoveragePlanId !== 3 ?
                                <div className="row pt-1 pl-3 pb-2 pr-3">

                                    <FormGroup className="flex-div mt-3 mb-0" style={{ width: "450px" }}>
                                        <div className="form-AddOn">
                                            {SelectedComponentList.addOnCoveragePlanId == 5 ?
                                                <Label className="customLabel">Any one accident</Label>
                                                :
                                                SelectedComponentList.addOnCoveragePlanId == 6 ?
                                                    <Label className="customLabel">Any one accident / period</Label>
                                                    :
                                                    <Label className="customLabel">{SelectedComponentList.addOnCoveragePlanName}</Label>
                                            }
                                        </div>
                                        <div className="form-AddOn2">
                                            <span>RM </span>
                                            {/*<Field className="form-control" name={"coverage" + SelectedComponentList.addOnCoveragePlanId} label="coverage" onChange={(event) => handleChange(event, SelectedComponentList.addOnCoveragePlanId)} component={ValidateField} type="text" validate={[required]} />*/}
                                            <Field className="form-control" name={"coverage" + SelectedComponentList.addOnCoveragePlanId} label="coverage" onChange={(event) => this.handleChange(event, SelectedComponentList.addOnCoveragePlanId)} component={ValidateField} type="text"
                                                normalize={
                                                    SelectedComponentList.addOnCoveragePlanId == 2 ?
                                                        numberWithRange25to500
                                                        :
                                                        SelectedComponentList.addOnCoveragePlanId == 4 ?
                                                            numberWithRange5to50
                                                            :
                                                            SelectedComponentList.addOnCoveragePlanId == 5 ?
                                                                numberWithRange250to2000
                                                                :
                                                                numberWithRange1000to2000
                                                }
                                            />
                                            <span>,000.00</span>
                                        </div>
                                    </FormGroup>

                                </div>

                                :

                                <>
                                    <div className="row">
                                        <Col xs="6" sm="6" lg="6">
                                            <Label className="customLabel" sm={7}> In transit</Label>
                                        </Col>
                                        <Col xs="6" sm="6" lg="6">
                                            <InputGroupAddon addonType="prepend" >
                                                <Label className="customLabel" style={{ paddingRight: "10px", paddingTop: "0.5rem" }}>
                                                    <b> RM </b>
                                                </Label>
                                                {/*<button className="gdexBlueButtonSquared" onClick={(e) => onDecrement("coverageInTransit", e)} id="coverageInTransitDecrement"> <FontAwesomeIcon icon={faMinus} /> </button>*/}
                                                <Field className="form-control" name="coverageInTransit" label="coverageInTransit" onChange={(event) => this.handleChange(event, SelectedComponentList.addOnCoveragePlanId)} component={ValidateField} type="text" normalize={numberWithRange10to100} />
                                                {/*<button className="gdexBlueButtonSquared" onClick={(e) => onIncrement("coverageInTransit", e)} id="coverageInTransitIncrement"> <FontAwesomeIcon icon={faPlus} /> </button>*/}
                                                <Label className="customLabel" style={{ paddingTop: "0.5rem", paddingLeft: "2px" }}>
                                                    <b>,000.00</b>
                                                </Label>
                                            </InputGroupAddon>
                                        </Col>
                                    </div>

                                    <br />

                                    <div className="row">
                                        <Col xs="6" sm="6" lg="6">
                                            <Label className="customLabel" sm={7} >In premise</Label>
                                        </Col>
                                        <Col xs="6" sm="6" lg="6">
                                            <InputGroupAddon addonType="prepend" >
                                                <Label className="customLabel" style={{ paddingRight: "10px", paddingTop: "0.5rem" }}>
                                                    <b> RM </b>
                                                </Label>
                                                {/*<button className="gdexBlueButtonSquared" onClick={(e) => onDecrement("coverageInPremise", e)} id="coverageInPremiseDecrement"> <FontAwesomeIcon icon={faMinus} /> </button>*/}
                                                <Field className="form-control" name="coverageInPremise" label="coverageInPremise" onChange={(event) => this.handleChange(event, SelectedComponentList.addOnCoveragePlanId)} component={ValidateField} type="text" normalize={numberWithRange10to100} />
                                                {/*<button className="gdexBlueButtonSquared" onClick={(e) => onIncrement("coverageInPremise", e)} id="coverageInPremiseIncrement"> <FontAwesomeIcon icon={faPlus} /> </button>*/}
                                                <Label className="customLabel" style={{ paddingTop: "0.5rem", paddingLeft: "2px" }}>
                                                    <b>,000.00</b>
                                                </Label>
                                            </InputGroupAddon>
                                        </Col>
                                    </div>
                                </>

                            }


                            {this.state.ShowErrorMsg == true ?
                                <div className="AddOnErrMsg">
                                    <Label className="customLabel">
                                        {errorMessage}
                                    </Label>
                                </div>
                                :
                                <></>
                            }

                            <div className="text-right">
                                <Button color="warning" onClick={() => PremiumCheck(SelectedComponentList.addOnCoveragePlanId, "Premium")} className="AddOnModelBtn" disabled={this.state.MViewButton == true ? true : false}>Check Premium</Button>
                            </div>



                        </ModalBody>
                        <ModalFooter className="custom-ModalFooter">

                            <Col xs="4" sm="4" lg="4">
                                <div className="text-left">
                                    <Label className="customLabel" style={{ marginBottom: "0px" }}>Subtotal</Label>
                                    <br />
                                    <Label className="customLabel"> {Subtotal ? Subtotal.toFixed(2) : "0.00"} </Label>
                                </div>
                            </Col>


                            <div className="col-8 col-sm-8 col-lg-8 text-right pr-0">
                                <div className="row">
                                    <Col xs="6" sm="6" lg="6">
                                        {SelectedComponentList.IsQuote == true ?
                                            <div className="text-right">
                                                <Button style={{ background: "red", width: "150px" }} onClick={() => CancelAddOnCoverage(SelectedComponentList.addOnCoveragePlanId)} className="AddOnModelBtn">Remove</Button>
                                            </div>
                                            :
                                            <div className="text-right"> </div>
                                        }
                                    </Col>
                                    <Col xs="6" sm="6" lg="6">
                                        <div className="text-right">
                                            <Button color="warning" style={{ width: "150px" }} onClick={() => PremiumCheck(SelectedComponentList.addOnCoveragePlanId, "continue")} className="AddOnModelBtn" disabled={this.state.MViewButton == true ? true : false}>Continue</Button>
                                        </div>
                                    </Col>
                                </div>
                            </div>


                        </ModalFooter>

                    </div>

                </Modal>

            </>

        );
    }
}

EditAddOnForm = reduxForm({
    // a unique name for the form
    form: 'EditAddOnForm',
    destroyOnUnmount: true,
    initialValues: {

    }
})(EditAddOnForm)

export default hot(module)(EditAddOnForm)

