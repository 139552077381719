const adminConstants = {


    ADMIN_LOGIN_REQUEST: 'ADMIN_LOGIN_REQUEST',
    ADMIN_LOGIN_SUCCESS: 'ADMIN_LOGIN_SUCCESS',
    ADMIN_LOGIN_FAILURE: 'ADMIN_LOGIN_FAILURE',

    GET_ADMIN_USER_LIST_REQUEST: "GET_ADMIN_USER_LIST_REQUEST",
    GET_ADMIN_USER_LIST_SUCCESS: "GET_ADMIN_USER_LIST_SUCCESS",
    GET_ADMIN_USER_LIST_FAILURE: "GET_ADMIN_USER_LIST_FAILURE",

    ADMIN_SIGNUP_REQUEST: 'ADMIN_SIGNUP_REQUEST',
    ADMIN_SIGNUP_SUCCESS: 'ADMIN_SIGNUP_SUCCESS',
    ADMIN_SIGNUP_FAIL: 'ADMIN_SIGNUP_FAIL',

    ADMIN_HIDE_SIGNUP_FAIL: 'ADMIN_HIDE_SIGNUP_FAIL',
    ADMIN_SHOW_SIGNUP_FAIL: 'ADMIN_SHOW_SIGNUP_FAIL',

    ADMIN_DELETE_REQUEST: 'ADMIN_DELETE_REQUEST',
    ADMIN_DELETE_SUCCESS: 'ADMIN_DELETE_SUCCESS',
    ADMIN_DELETE_FAIL: 'ADMIN_DELETE_FAIL',

    ADMIN_HIDE_DELETE_FAIL: 'ADMIN_HIDE_DELETE_FAIL',
    ADMIN_SHOW_DELETE_FAIL: 'ADMIN_SHOW_DELETE_FAIL',

    GET_ADMIN_USER_DETAILS_REQUEST: "GET_ADMIN_USER_DETAILS_REQUEST",
    GET_ADMIN_USER_DETAILS_SUCCESS: "GET_ADMIN_USER_DETAILS_SUCCESS",
    GET_ADMIN_USER_DETAILS_FAILURE: "GET_ADMIN_USER_DETAILS_FAILURE",

    //Set Password
    ADMIN_SET_PASSWORD_REQUEST: 'ADMIN_SET_PASSWORD_REQUEST',
    ADMIN_SET_PASSWORD_SUCCESS: 'ADMIN_SET_PASSWORD_SUCCESS',
    ADMIN_SET_PASSWORD_FAIL: 'ADMIN_SET_PASSWORD_FAIL',

    ADMIN_HIDE_SET_PASSWORD_FAIL: 'ADMIN_HIDE_SET_PASSWORD_FAIL',
    ADMIN_SHOW_SET_PASSWORD_FAIL: 'ADMIN_SHOW_SET_PASSWORD_FAIL',

    //Update User
    ADMIN_UPDATE_USER_REQUEST: 'ADMIN_UPDATE_USER_REQUEST',
    ADMIN_UPDATE_USER_SUCCESS: 'ADMIN_UPDATE_USER_SUCCESS',
    ADMIN_UPDATE_USER_FAIL: 'ADMIN_UPDATE_USER_FAIL',

    ADMIN_HIDE_UPDATE_USER_FAIL: 'ADMIN_HIDE_UPDATE_USER_FAIL',
    ADMIN_SHOW_UPDATE_USER_FAIL: 'ADMIN_SHOW_UPDATE_USER_FAIL',

    //Change Password
    ADMIN_CHANGE_PASSWORD_REQUEST: "ADMIN_CHANGE_PASSWORD_REQUEST",
    ADMIN_CHANGE_PASSWORD_SUCCESS: "ADMIN_CHANGE_PASSWORD_SUCCESS",
    ADMIN_CHANGE_PASSWORD_FAIL: "ADMIN_CHANGE_PASSWORD_FAIL",
    ADMIN_SHOW_CHANGE_PASSWORD_FAIL: "ADMIN_SHOW_CHANGE_PASSWORD_FAIL",

    //Forgot Password
    ADMIN_FORGOT_PWD_REQUEST: 'ADMIN_FORGOT_PWD_REQUEST',
    ADMIN_FORGOT_PWD_SUCCESS: 'ADMIN_FORGOT_PWD_SUCCESS',
    ADMIN_FORGOT_PWD_FAIL: 'ADMIN_FORGOT_PWD_FAIL',

    HIDE_ADMIN_FORGOT_PWD_FAIL: 'HIDE_ADMIN_FORGOT_PWD_FAIL',
    SHOW_ADMIN_FORGOT_PWD_FAIL: 'SHOW_ADMIN_FORGOT_PWD_FAIL',

    GET_ALL_ROLE_REQUEST: "GET_ALL_ROLE_REQUEST",
    GET_ALL_ROLE_SUCCESS: "GET_ALL_ROLE_SUCCESS",
    GET_ALL_ROLE_FAILURE: "GET_ALL_ROLE_FAILURE",

    //COMMON PASSWORD
    GET_COMMON_PASSWORD_SUCCESS: "GET_COMMON_PASSWORD_SUCCESS",
    GET_COMMON_PASSWORD_FAILURE: "GET_COMMON_PASSWORD_FAILURE",

    //ADMIN RESET PASSWORD
    ADMIN_RESET_REQUEST: 'ADMIN_RESET_REQUEST',
    ADMIN_RESET_SUCCESS: 'ADMIN_RESET_SUCCESS',
    ADMIN_RESET_FAIL: 'ADMIN_RESET_FAIL',
    ADMIN_HIDE_RESET_FAIL: 'ADMIN_HIDE_RESET_FAIL',
    ADMIN_SHOW_RESET_FAIL: 'ADMIN_SHOW_RESET_FAIL',

}

export default adminConstants;