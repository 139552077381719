import paymentConstants from './constants';

const initialState = {
    isLoading: false,
    showSuccess: false
};


export const reducer = (state, action) => {
    state = state || initialState;

    switch (action.type) {
        //PAYMENT
        case paymentConstants.GET_PAYMENT_PAGE_SUCCESS:
            return {
                ...state,
                paymentSuccessData: action.data,
                isLoading: true,
                showSuccess: true
            }
        case paymentConstants.GET_PAYMENT_PAGE_FAIL:
            return {
                ...state,
                isLoading: false,
                showSuccess: false
            }
        default:
            return state;
    }
}