const crmConstants = {

    GET_CUSTOMER_LIST_REQUEST: "GET_CUSTOMER_LIST_REQUEST",
    GET_CUSTOMER_LIST_SUCCESS: "GET_CUSTOMER_LIST_SUCCESS",
    GET_CUSTOMER_LIST_FAILURE: "GET_CUSTOMER_LIST_FAILURE",

    GET_CUSTOMER_DETAILS_REQUEST: "GET_CUSTOMER_DETAILS_REQUEST",
    GET_CUSTOMER_DETAILS_SUCCESS: "GET_CUSTOMER_DETAILS_SUCCESS",
    GET_CUSTOMER_DETAILS_FAILURE: "GET_CUSTOMER_DETAILS_FAILURE",

    //Reset Password
    ADMIN_RESET_CUSTOMER_PASSWORD_REQUEST: "ADMIN_RESET_CUSTOMER_PASSWORD_REQUEST",
    ADMIN_RESET_CUSTOMER_PASSWORD_SUCCESS: "ADMIN_RESET_CUSTOMER_PASSWORD_SUCCESS",
    ADMIN_RESET_CUSTOMER_PASSWORD_FAIL: "ADMIN_RESET_CUSTOMER_PASSWORD_FAIL",
    ADMIN_SHOW_RESET_CUSTOMER_PASSWORD_FAIL: "ADMIN_SHOW_RESET_CUSTOMER_PASSWORD_FAIL",
    ADMIN_HIDE_RESET_CUSTOMER_PASSWORD_FAIL: "ADMIN_HIDE_RESET_CUSTOMER_PASSWORD_FAIL",
}

export default crmConstants;