import { connect } from 'react-redux';
import ProductDetailsPresentation from './ProductDetailsPresentation';
import actions from './actions';

// Data return from reducer (Redux store).  ( /store/configureStore)
function mapStateToProps(state) {
    const { reducer} = state;
    return {
        reducer
    }
}


// Calling Api via dispatching action
// Triggered from child Presentation
function mapDispatchToProps(dispatch) {
    return {


    }
}

const ProductDetails = connect(mapStateToProps, mapDispatchToProps)(ProductDetailsPresentation);

export default ProductDetails;

