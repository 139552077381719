import React from 'react';
import { connect } from 'react-redux'
import actions from './actions'
import SweetAlert from 'sweetalert2-react';
import { Form, Button, Label, FormGroup } from 'reactstrap'
import { Field, reduxForm, FormSection } from 'redux-form'
import Swal from 'sweetalert2';
import ChangePasswordForm from './ChangePasswordForm';
import { encrypt } from "../_helpers/crypto";

class ChangePasswordPresentation extends React.Component {

    constructor(props) {
        super(props);
        this.state = {

        };

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleChange(event) {
        const target = event.target;
        const value = target.value;

        const name = target.name;

        this.setState({
            [name]: value
        });
    }

    handleSubmit(changePasswordModel) {
        let commonPassword = this.props.account.commonPasswordList.split("\r\n");
        if (commonPassword.includes(changePasswordModel.currentPassword)) {
            Swal.fire({
                title: 'Change Password',
                text: 'The password is too common.',
                type: 'error',
            });
            return false;
        }
        var changePasswordObj = {
            CurrentPassword: encrypt(changePasswordModel.currentPassword),
            NewPassword: encrypt(changePasswordModel.Password)
        }
        this.props.changePassword(changePasswordObj)

    }

    componentDidMount() {
        
        Promise.all([
            this.props.getCommonPasswordList(),
            window.scrollTo(0, 0)
        ])
    }

    render() {

        const { account, message } = this.props;

        return (
            <ChangePasswordForm
                onSubmit={this.handleSubmit}
            />
        );
    }
}

const mapDispatchToProps = dispatch => {
    return {
        changePassword: (content) => {

            dispatch(actions.changePassword(content))
        },
        getCommonPasswordList: (content) => {
            dispatch(actions.getCommonPasswordList(content));
        },
    }
}

const mapStateToProps = state => {

    const { account,message } = state || {}; // entire Redux store state (the same value returned by a call to store.getState()).



    return { account,message };
};


export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ChangePasswordPresentation)
