import React from 'react';
import queryString from 'query-string';
import { Button, FormGroup, Label, Input, Row, Col, Card, CardBody, InputGroup, InputGroupText } from 'reactstrap';
import { numberWithCommas } from "../GlobalComponents/_function";

class PaymentSuccessPresentation extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            paymentData: [],
            redirectToHomepage: false
        }
    }

    componentDidMount() {

        window.sessionStorage.removeItem("customerPolicyID");
        /*window.sessionStorage.removeItem("quotingReferenceId");*/

        const values = queryString.parse(this.props.location.search);

        if (values) {
            //call api get ipay88log by transID
            this.props.getPaymentSuccess(values.transId);
        }

        //this.id = setTimeout(
        //    () => this.props.history.push('/policy')
        //    , 10000)
    }

    componentWillUnmount() {
        clearTimeout(this.id)
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.payment !== 'undefined') {
            if (this.props.payment.paymentSuccessData !== nextProps.payment.paymentSuccessData) {
                if (typeof nextProps.payment.paymentSuccessData !== 'undefined') {
                    this.setState({
                        paymentData: nextProps.payment.paymentSuccessData.result
                    });
                }
            }
        }
    }

    //componentDidUpdate(prevProps) {
    //    if (typeof this.props.payment.paymentSuccessData !== 'undefined' && this.props.payment.showSuccess !== prevProps.payment.showSuccess) {
    //        this.setState({
    //            paymentData: this.props.payment.paymentSuccessData.result
    //        })
    //    }
    //}

    render() {


        return (
            <div className="my-3 pt-3">
                <div className="container">
                    <div className="row">
                        <div className="col-sm-12">
                            <Card style={{ background: '#FFDF00' }} className="card-payment-success">

                                <CardBody>
                                    <img className="card-img" style={{ opacity: .25,width:'300px',height:'auto',float:'right' }} src="/assets/img/Insubee_Logo.png" alt="Card image" />
                                    <div className="card-img-overlay align-items-start">
                                        <div className="row">
                                            <div className="col-sm-12">
                                                <h5 className="font-weight-bold">Dear {this.state.paymentData.name} ,</h5>
                                                <hr />
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-sm-12">
                                               <p>We have received your payment and thank you for trusting and choosing Insubee!</p>
                                            </div>
                                        </div>
                                        <br></br>
                                        <div className="row mt-1">
                                            <div className="col-sm-4">
                                                <p className="mb-0">Transaction ID :</p>

                                            </div>
                                            <div className="col-sm-4">
                                                <p style={{ fontSize: '16px' }}>{this.state.paymentData.transId ? this.state.paymentData.transId : ""}</p>

                                            </div>
                                        </div>

                                        <div className="row mt-1">
                                            <div className="col-sm-4">
                                                <p className="mb-0">Amount :</p>
                                            </div>
                                            <div className="col-sm-4">
                                                <p style={{ fontSize: '16px' }}>RM {this.state.paymentData.amount ? numberWithCommas(this.state.paymentData.amount.toFixed(2)) : "-"}</p>
                                            </div>
                                        </div>
                                        <br></br>
                                        <div className="row">
                                            <div className="col-sm-12">
                                                <p>Please take note that your policy is currently being processed and will be sent to you once it is ready together with invoice.</p>
                                            </div>
                                        </div>
                                        <br></br>
                                        <br></br>
                                        <div className="row pt-sm-5 pt-0">
                                            <div className="col-sm-8">
                                                <p>Best Regards,<br></br>
                                                Team Insubee</p>
                                            </div>
                                            <div className="col-sm-4 text-right">
                                                <Button className="dark" onClick={() => this.props.history.push('/policy')}>Close</Button>
                                            </div>
                                        </div>
                                    </div>
                                    
                                </CardBody>
                            </Card>
                        </div>
                        <br />
                    </div>
                            
                </div>
            </div>
        )
    }
}

export default PaymentSuccessPresentation;


