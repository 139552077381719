import React from 'react';
import { Collapse, Container, Navbar, Col, Nav, NavItem, NavLink, NavbarBrand, NavbarToggler, } from 'reactstrap';
import { connect } from 'react-redux'
import { Link } from 'react-router-dom';
import './DefaultLayoutNavMenu.css';
import { Badge, UncontrolledDropdown, DropdownItem, DropdownMenu, DropdownToggle, Button} from 'reactstrap';
import InsuBeeLogo from "../../../image/InsubeeLogo.svg";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { faCaretDown } from '@fortawesome/free-solid-svg-icons';
import { faUser } from '@fortawesome/free-solid-svg-icons';

import LoginColored from "../../../image/Home/login_colored.png";
import NoLogin from "../../../image/Home/without_login.png";
import Cookies from 'universal-cookie';
import EmailDotIcon from '../../../image/NavBar/email-outline-badged.svg'
import EmailIcon from '../../../image/NavBar/email.svg'
import EmailIconHover from '../../../image/NavBar/emailHover.svg'


const cookies = new Cookies();
class DefaultLayoutSideNavMenu extends React.Component {
    constructor(props) {
        super(props);

        
        this.state = {
            DisplayBtn: "none"
        };

        this.ShowSideNav = this.ShowSideNav.bind(this);
    }


    ShowSideNav(event) {

        this.setState({ DisplayBtn: event });
    };

    signOut(e) {
        cookies.remove("insubeeToken");
        cookies.remove("refreshToken");
        cookies.remove("isVerified");
        cookies.remove("userId");
        cookies.remove("userName");
        cookies.remove("roleName");
        cookies.remove("adminAccess");

        sessionStorage.removeItem("insubeeToken");
        sessionStorage.removeItem("refreshToken");
        sessionStorage.removeItem("isVerified");
        sessionStorage.removeItem("userId");
        sessionStorage.removeItem("userTypeId");
        sessionStorage.removeItem("userName");
        sessionStorage.removeItem("roleName");
        sessionStorage.removeItem("adminAccess");
        this.setState({
            logout: true
        });
        window.location.href = "/";
    }


    render() {


        return (

            <>
                <Button className="sideNavBtn" onClick={() => this.ShowSideNav("block")} ><FontAwesomeIcon icon={faUser}/> ☰</Button>

                <div class="w3-sidebar w3-bar-block w3-border-right" style={{ display: this.state.DisplayBtn }} id="mySidebar">
                    <Button class="sideNavBtn" onClick={() => this.ShowSideNav("none")}>Close</Button>
                    <a class="w3-bar-item w3-button" href="" onClick={e => this.signOut(e)}><b>Sign Out</b></a>
                <a class="w3-bar-item w3-button" href="/Policy"> <b>My Policies</b></a>
                <a class="w3-bar-item w3-button" href="/ClaimsList"> <b>My Claims</b></a>
                <a class="w3-bar-item w3-button" href="/MyProfile"> <b>My Profile</b></a>
            </div>

            </>
        );
    }
}



const mapDispatchToProps = dispatch => {
    return {

        //getDashboardData: () => {

        //    dispatch(actions.getDashboardData())

        //},

    }
}







const mapStateToProps = state => {

    //const { dashboard } = state || {}; // entire Redux store state (the same value returned by a call to store.getState()).  
    //return { dashboard };
};



export default DefaultLayoutSideNavMenu;