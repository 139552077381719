import moment from 'moment';

const numberWithCommas = (value) => {
    return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

const getDayByDate = (date) => {
    return moment(date).format('dddd');
}

//A global function to format date t0o DD-MM-YYYY
const formatDate = (date) => {
    return moment(date, "YYYY-MM-DDTHH:mm:ss").format("DD-MM-YYYY");
}

const formatDatetoISO = (date) => {
    return moment(date).toISOString();
}

const formatDateTime = (data) => {
    return moment(data).format("DD-MM-YYYY hh:mma");
}

const format12HourTime = (time, secondsNeed) => {
    if (!secondsNeed) {
        return moment(time, "HH:mm:ss").format("hh:mma");
    }

    return moment(time, "HH:mm:ss").format("hh:mm:ssa");

}

const format24HourTime = (time) => {
    return moment(time, "HH:mm:ss").format("HH:mm");
}

const getDifferentDayFromToday = (date) => {
    return moment(date).diff(moment(), "days")
}

const isSameToday = (date) => {
    return moment().isSame(moment(date), "day")
}

const getDayOfWeekByDate = (date) => {
    var day = moment(date).day();

    switch (day) {
        case 0:
            return "Sunday";
        case 1:
            return "Monday";
        case 2:
            return "Tuesday";
        case 3:
            return "Wednesday";
        case 4:
            return "Thursday";
        case 5:
            return "Friday";
        case 6:
            return "Saturday";
        default:
            return "Invalid Day";
    }
}

const convertTo24HoursFormat = (time) => {
    if (time.includes("am")) {
        var convertedTime = time.replace("am", "");
        var h = convertedTime.split(":");

        convertedTime = h[0] + ":" + h[1];

        return convertedTime;
    }

    if (time.includes("pm")) {
        var convertedTime = time.replace("pm", "");
        var h = convertedTime.split(":");
        if (parseInt(h[0]) == 12) {
            convertedTime = h[0] + ":" + h[1];
        } else {
            convertedTime = (parseInt(h[0]) + 12) + ":" + h[1];
        }

        return convertedTime;
    }

    return time;
}

const parcelReadyTime = [
    "09:00am", "09:30am", "10:00am",
    "10:30am", "11:00am", "11:30am",
    "12:00pm", "12:30pm", "01:00pm",
    "01:30pm", "02:00pm", "02:30pm",
    "03:00pm", "03:30pm", "04:00pm",
    "04:30pm"
];

const parcelReadyTimeForSat = ["09:00am", "09:30am", "10:00am", "10:30am", "11:00am"]

const getParcelReadyTime = (day) => {
    if (day && day == "Saturday") {
        var parcelReadyTimeObj = parcelReadyTimeForSat.map((time) => {
            return {
                displayTime: time,
                timeValues: convertTo24HoursFormat(time)
            }
        })
    } else {
        var parcelReadyTimeObj = parcelReadyTime.map((time) => {
            return {
                displayTime: time,
                timeValues: convertTo24HoursFormat(time)
            }
        })
    }

    return parcelReadyTimeObj
}

const getOfficeCloseTime = () => {
    return ["12:00am", "12:30am", "01:00am", "01:30am", "02:00am", "02:30am", "03:00am", "03:30am", "04:00am", "04:30am", "05:00am", "05:30am", "06:00am", "06:30am", "07:00am", "07:30am", "08:00am", "08:30am", "09:00am", "09:30am", "10:00am", "10:30am", "11:00am", "11:30am", "12:00pm", "12:30pm", "01:00pm", "01:30pm", "02:00pm", "02:30pm", "03:00pm", "03:30pm", "04:00pm", "04:30pm", "05:00pm", "05:30pm", "06:00pm", "06:30pm", "07:00pm", "07:30pm", "08:00pm", "08:30pm", "09:00pm", "09:30pm", "10:00pm", "10:30pm", "11:00pm", "11:30pm"]
}

export { numberWithCommas, getDayByDate, formatDate, formatDatetoISO, isSameToday, formatDateTime, format12HourTime, format24HourTime, getDayOfWeekByDate, convertTo24HoursFormat, getParcelReadyTime, getOfficeCloseTime, getDifferentDayFromToday }