import React, { Component } from 'react';
import { Button,Carousel, CarouselCaption, NavLink, CarouselIndicators, CarouselItem,CarouselControl, Container, Row, Col, Card, CardBody } from 'reactstrap';

import facebook from '../../image/Footer/facebook.png';
import instagram from '../../image/Footer/instagram.png';
import whatapps from '../../image/Footer/WhatsApp.png';
import Slider1 from "../../image/Blog/KeysToAccelerateBusinessTransformation1903x500.png";

class BlogDetailsKeysToAccelerateBusiness extends Component {
    state = {

    }
    constructor(props) {
        super(props)
   
    }


    componentDidMount() {
        window.scrollTo(0, 0);
    }

    render() {
       
        

        return (
            <div>
                <Row>
                    <Col xs="12" sm="12" lg="12">
                        <div className="blog-image">
                            <img alt="" style={{ height: "500px", width: "100%" }} src={Slider1}>
                            </img>
                        </div>
                        <div className="blog-text">
                            <h1 style={{ fontSize: "50px", color: "white" }}>Does Homeowners Insurance Cover Wildfires? </h1>
                            <br />
                            <br />
                            <p> <img src={'../../assets/img/Insubee_Logo.png'} className="fluid" width="20" height="20" /> &nbsp; Team Insubee</p>
                        </div>
                    </Col>
                </Row>

                <br />
                <Row>
                    <Col xs="12" sm="12" lg="11">
                        <div className="container pt-3" style={{paddingLeft:"8em", paddingRight:"0em"}}>

                            <h5><b>Keys to Accelerate Business Transformation</b></h5>

                            <p>In an age defined by rapidly evolving market shifts, capacity for change has never been more critical. This is why today, 
                            businesses - both small and large alike - are prioritising transformation to adapt and remain relevant. 
                            </p>
                            <br />

                            <p>Whether it be digital transformation, cultural transformation, business process transformation, management transformation or any of the other types, the journey to successful transformation is paved with false steps and painful reinvention. 
                            In fact, according to a study conducted by Ernst and Young (EY) in 2021, only 19% of transformations are ever 
                            successful. 
                            </p>
                            <br />

                            <p>To increase the chances of success, some things remain true across the spectrum for all types of transformation. Chief among them are effective leadership, and employee engagement. 
                            Successfully addressing these two components is key to accelerating business transformation.
                            </p>
                            <br />

                            <h5><b>Lead by Example</b></h5>
                            <p>The importance of leadership has been echoed so many times it becomes easy to gloss over and ignore. However, leadership has 
                            proven to be an indelible factor in the success of any business transformation, and therefore should not be underestimated. 
                            </p>
                            <br />

                            <p>The potency of leadership will influence your business’ ability to adapt to change. Before embarking on transformational change, 
                            it is crucial to have a clearly articulated vision and strategy, as well as the alignment of senior leaders in the business. Furthermore, enforcing a culture of cooperation 
                            and collaboration across departments goes a long way in increasing trust in leadership and trust in the transformation. 
                            </p>
                            <br />

                            <p>McKinsey, a management consulting firm to many of the world’s most influential businesses and institutions, in a survey, 
                            revealed that 84 percent of respondents at companies where transformation failed, say invested leadership is critical. 
                            </p>
                            <br />

                            <p>Leaders are responsible for the culture of an organisation. To integrate change into the work culture, leaders need to walk the talk. When this happens, especially if they are 
                            asking employees to transform how they get their work done, they demonstrate trust and belief in the process.
                            </p>
                            <br />

                            <h5><b>Employee Engagement</b></h5>
                            <p>Employee engagement can spell the difference between the success and failure of a transformation. It just so happens that sometimes not all employees are on board with the changes. 
                            Sometimes, despite communicating what is required of each individual to make the transformation a success, 
                                as well as providing the necessary resources such as training, some people won’t change. 
                                In events like this, it makes sense, painful, but necessary sense to cut them out of the equation. 
                                This can have a positive impact on the rest of your business as high performers become more motivated, 
                                low performers opt-out, and those in the middle realise they need to step up to the plate. 
                            </p>
                            <br />

                            <p>It is interesting to note that businesses with successful transformations have supportive employees 63% of the time, 
                            while failed transformations have supportive employees 30% of the time according to a study by EY. 
                            </p>
                            <br />

                            <p>Businesses can increase employee engagement by frequently communicating with employees about transformation, 
                            securing adequate resources and involving team leaders in the transformation process. 
                            </p>
                            <br />

                            <h5><b>Bonus: Culture</b></h5>
                            <p>A subset of employee engagement and an often ignored crucial ingredient for business transformation is culture. Peter Drucker, one of 
                            the most influential thinkers on management put it aptly when he said “culture eats strategy for breakfast. 
                            </p>
                            <br />

                            <p>The essence of transformation is change. Change for businesses 
                            sometimes means adapting to new ways of operating before transformations start bearing fruit.
                            </p>
                            <br />

                            <p>Good cultures, therefore, tend to focus on trust than control which provides 
                            a safe space for learning and experimenting to uncover what works and what doesn’t. 
                            </p>
                            <br />

                            <p>At the end of the day, the success of a transformation can only be measured by the achievement of objectives outlined during its launch. 
                            This doesn’t mean that the transformation will be viable and sustainable in the long term. 
                            </p>
                            <br />

                            <p>Long-term sustainability can only be realised when strong engagement is achieved at all levels of a business from owner to staff. What this brings is agility to 
                            respond to changing conditions when and if necessary, which is crucial in this day and age.
                            </p>
                            <br />

                            <p>Sources:
                            </p>
                            <p>https://www.linkedin.com/pulse/sme-business-transformation-concept-need-makrand-dekhane/
                            </p>
                            <p>https://www.oecd-forum.org/posts/big-ideas-for-small-and-medium-enterprises-helping-smes-achieve-digital-transformation
                            </p>
                            <p>https://hbr.org/2011/09/four-ways-successful-transformation-do
                            </p>
                            <p>https://www.ey.com/en_jp/corporate-culture/what-are-the-key-factors-for-a-successful-transformation
                            </p>
                            <p>https://www.netguru.com/blog/digital-transformation-strategy
                            </p>
                            <p>https://blog.smarp.com/business-transformation-2020-guide
                            </p>

                        </div>
                    </Col>
                        <Col xs="12" sm="12" lg="1">
                        <div class="icon-bar">
                            <p> Share 
                            <a href="https://www.facebook.com/GDEXOfficialPage/"><img src={facebook} width="25px" height="25px" /></a>
                            <a href="https://twitter.com/GDEX_Official/" class="twitter"><img src={instagram} width="25px" height="25px" /></a>
                                <a href="https://www.youtube.com/channel/UCei9LfDrXmLVGdEIfQKHflQ" class="whatapps"><img src={whatapps} width="25px" height="25px" /></a>
                            </p>
                                </div>

                    </Col>
                </Row>

                <br />  
                
            </div>
        )
        
    }
}


export default BlogDetailsKeysToAccelerateBusiness;