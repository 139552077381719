import marketingConstants from './constants';

const initialState = {
    isLoading: false,
    discountVoucherList: [],
};


export const reducer = (state, action) => {
    state = state || initialState;

    switch (action.type) {
        //DISCOUNT VOUCHER LIST
        case marketingConstants.GET_DISCOUNT_VOUCHER_REQUEST:
            return {
                ...state,
                discountVoucherSuccess: false,
                discountVoucherList: undefined,
                isLoading: true,
            }
        case marketingConstants.GET_DISCOUNT_VOUCHER_SUCCESS:
            return {
                ...state,
                discountVoucherSuccess: true,
                discountVoucherList: action.data,
                isLoading: false,
            }
        case marketingConstants.GET_DISCOUNT_VOUCHER_FAILURE:
            return {
                ...state,
                discountVoucherSuccess: false,
                discountVoucherList: undefined,
                isLoading: true,
            }
        //MASTER ENTRY
        case marketingConstants.GET_MASTER_ENTRY_REQUEST:
            return {
                ...state,
                masterEntrySuccess: false,
                masterEntryList: undefined,
                isLoading: true,
            }
        case marketingConstants.GET_MASTER_ENTRY_SUCCESS:
            return {
                ...state,
                masterEntrySuccess: true,
                masterEntryList: action.data,
                isLoading: false,
            }
        case marketingConstants.GET_MASTER_ENTRY_FAILURE:
            return {
                ...state,
                masterEntrySuccess: false,
                masterEntryList: undefined,
                isLoading: true,
            }
        //DISABLE DISCOUNT VOUCHER
        case marketingConstants.DISABLE_DISCOUNT_VOUCHER_REQUEST:
            return {
                ...state,
                isLoading: true,
                showFail: false,
                showSuccess: false
            }
        case marketingConstants.DISABLE_DISCOUNT_VOUCHER_SUCCESS:
            return {
                ...state,
                isLoading: false,
                showFail: false,
                showSuccess: true,
            }
        case marketingConstants.SHOW_DISABLE_DISCOUNT_VOUCHER_FAILURE:
            return {
                isLoading: false,
                showFail: true,
                showSuccess: false
            }
        case marketingConstants.HIDE_DISABLE_DISCOUNT_VOUCHER_FAIL:
            return {
                isLoading: false,
                showFail: false,
                showSuccess: false
            }
        //ADD DISCOUNT VOUCHER
        case marketingConstants.ADD_DISCOUNT_VOUCHER_REQUEST:
            return {
                ...state,
                isLoading: true,
                showFail: false,
                showSuccess: false
            }
        case marketingConstants.ADD_DISCOUNT_VOUCHER_SUCCESS:
            return {
                ...state,
                isLoading: false,
                showFail: false,
                showSuccess: true
            }
        case marketingConstants.ADD_DISCOUNT_VOUCHER_FAIL:
            return {
                isLoading: false,
                showFail: true,
                showSuccess: false
            }
        case marketingConstants.ADD_DISCOUNT_VOUCHER_HIDE_ADD_FAIL:
            return {
                isLoading: false,
                showFail: false,
                showSuccess: false
            }
        //UPDATE DISCOUNT VOUCHER
        case marketingConstants.UPDATE_DISCOUNT_VOUCHER_REQUEST:
            return {
                ...state,
                isLoading: true,
                showFail: false,
                showSuccess: false
            }
        case marketingConstants.UPDATE_DISCOUNT_VOUCHER_SUCCESS:
            return {
                ...state,
                isLoading: false,
                showFail: false,
                showSuccess: true
            }
        case marketingConstants.UPDATE_DISCOUNT_VOUCHER_FAIL:
            return {
                isLoading: false,
                showFail: true,
                showSuccess: false
            }
        case marketingConstants.UPDATE_DISCOUNT_VOUCHER_HIDE_ADD_FAIL:
            return {
                isLoading: false,
                showFail: false,
                showSuccess: false
            }
        default:
            return state;
    }
}