import React, { Component } from 'react';
import { Button, Carousel, CarouselCaption, NavLink, CarouselIndicators, CarouselItem, CarouselControl, Container, Row, Col, Card, CardBody, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { Collapse } from 'reactstrap';
import "./homepage.css";
import '../../../node_modules/react-modal-video/scss/modal-video.scss';
//import ModalVideo from 'react-modal-video';
import Browser from '../_helpers/browser';

import "animate.css/animate.min.css";
import { AnimationOnScroll } from 'react-animation-on-scroll';
import ReactPlayer from 'react-player'
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserCircle } from '@fortawesome/free-solid-svg-icons';


import PlayImage from "../../image/Home/icon8_play.png";
import Slider1 from "../../image/Home/homepage_slider.jpg";
import InsuranceProduct from '../../image/Product/InsuranceProduct.jpeg';
import EasyPayment from '../../image/Product/EasyPayment.jpeg';
import BackedbyChampions from '../../image/Product/BackedbyChampions.jpeg';
import DesignJustForYou from '../../image/Product/DesignJustForYou.jpeg';

import thumbnail from '../../image/Home/thumbnail.svg';
import InsuranceProductHome from '../../image/Home/InsuranceProductHome.svg'
import insuranceList from '../../image/Home/insuranceList.svg'
import testimonialsStarRate from '../../image/Home/testimonialsStarRate.svg'

import BusinessInsuranceCoverFloods from '../../image/Blog/BusinessInsuranceCoverFloods.svg'
import InsuranceAsABackbone from '../../image/Blog/InsuranceAsABackbone.svg'
import RM1MillionPublicLiability from '../../image/Blog/RM1MillionPublicLiability.svg'
import KeysToAccelerateBusinessTransformation from '../../image/Blog/KeysToAccelerateBusinessTransformation346x200.png'


import ProductBurglary from '../../image/Product/2_burglary.jpeg';
import ProductEmployer from '../../image/Product/2_employer.jpeg';
import ProductFire from '../../image/Product/2_fire.jpeg';
import ProductGlass from '../../image/Product/2_glass.jpeg';
import ProductLiability from '../../image/Product/2_liability.jpeg';
import ProductMoney from '../../image/Product/2_money.jpeg';


import Insubeebanner from '../../image/Home/InsubeeBanner.png'
import InsubeebannerMobile from '../../image/Home/insubee-banner-2-2-mobile.png'
import TMLogo from '../../image/Home/TokioMarine-Logo-S.png'



const mobileImage = [
    {
        src: InsubeebannerMobile,
    },


];

const desktopImage = [
    {
        src: Insubeebanner,
    },

];

const componentList = [
    {
        name: "Property & Stock Is Damaged",
        description: "We’ll cover damage from fires, storm, flood, and more.",
        src: ProductFire

    },
    {
        name: "If You Are Robbed",
        description: "We’ll cover for damage or loss of stock and repair cost of damage to property.",
        src: ProductBurglary

    },
    {
        name: "Money Is Lost",
        description: "We’ll cover for lost from the premise or during in transit to bank",
        src: ProductMoney

    },
    {
        name: "Glass Is Damaged",
        description: "We’ll cover for cost of repairs for glass doors and windows.",
        src: ProductGlass

    },
    {
        name: "Are Sued For Liability",
        description: "We’ll cover for costs of litigation and compensation.",
        src: ProductEmployer

    },
    {
        name: "Liable For Your Employee’s Injury",
        description: "We’ll cover for costs in respect of such injury.",
        src: ProductLiability

    },

];

function FadeInSection(props) {
    const [isVisible, setVisible] = React.useState(false);
    const domRef = React.useRef();
    React.useEffect(() => {
        const observer = new IntersectionObserver(entries => {
            entries.forEach(entry => setVisible(entry.isIntersecting));
        });
        observer.observe(domRef.current);
    }, []);
    return (
        <div
            className={`fade-in-section ${isVisible ? 'is-visible' : ''}`}
            ref={domRef}
        >
            {props.children}
        </div>
    );
}



class Home extends Component {

    constructor(props) {
        super(props);
        this.state = {
            activeIndex: 0,
            animating: false,
            isOpenVideoModal: false,
            FAQ: [
                {
                    id: 1,
                    question: "What policies do Insubee offer?",
                    answer: "We offer Insubee Business Insurance. We are continuously exploring new products together with insurance providers to customise simple and affordable policies to meet your needs. "
                },
                {
                    id: 2,
                    question: "What does Business policy cover?",
                    answer: "Business policy covers your property damage caused by fire, lightning, storm, flood, vandalism, earthquakes, aircraft damage and explosion. This policy also covers you for losses due to theft and other optional covers based on your needs."
                },
                {
                    id: 3,
                    question: "What kind of things and I covered for with a Business policy?",
                    answer: "Your business policy covers your assets against perils which includes fire, storm, tempest, flood, aircraft damage, impact damage, bursting or overflowing of water tanks, riot, strike, malicious damage, earthquake or an explosion.",
                },
                {
                    id: 4,
                    question: "Can I increase or decrease coverages?",
                    answer: "Absolutely. If you do not wish to have add on basic plan or premier plan which offers fixed coverage, you'll able to select and adjust the coverage and coverage amount that is suitable for you with flexi plan."
                },
                {
                    id: 5,
                    question: "Who is eligible to purchase Business Insurance?",
                    answer: "You are eligible to purchase Business Insurance if you fall under business occupation which includes offices, services, retail, hotel, food & beverage and beauty & wellness."
                },
                {
                    id: 6,
                    question: "How much does Business Insurance cost?",
                    answer: "Premium will depend on your business nature and coverage amount. The lowest premium is RM10/month! Get a free quote now."
                },
                {
                    id: 7,
                    question: "How do I pay for my insurance?",
                    answer: "You can pay us via debit card, credit card, online banking or through various e-wallet options."
                },
                {
                    id: 8,
                    question: "Why is Business Insurance important?",
                    answer: "A business insurance policy protects your business assets and pays for legal obligations. Without business insurance, an unfortunate incident could be financially devastating for business owners."
                },
                {
                    id: 9,
                    question: "Who underwrites the insurance policy?",
                    answer: "We work with the top insurance providers such as Allianz General Insurance Company (Malaysia) Berhad, Tokio Marine Insurance (Malaysia) Berhad and MSIG Insurance (Malaysia) Bhd to tailor make policies just for you."
                },
                {
                    id: 10,
                    question: "What to do in the event of claim?",
                    answer: "You must notify us via email to help.support@insubee.com with full details of the incident or accident as soon as possible. If burglary or break-on is involved, you are required to lodge a police report immediately."
                },
            ],
        }

        this.openVideoModal = this.openVideoModal.bind(this)
        this.DirectTo = this.DirectTo.bind(this)
        this.next = this.next.bind(this);
        this.previous = this.previous.bind(this);
        this.goToIndex = this.goToIndex.bind(this);
        this.onExiting = this.onExiting.bind(this);
        this.onExited = this.onExited.bind(this);
    }


    openVideoModal() {
        this.setState({ isOpenVideoModal: true });
    }

    DirectTo(value) {

        if (value == 1) {
            this.props.history.push('/ProductGetQuote');
        }
        else if (value == 2) {
            this.props.history.push('/Blog');
        }
        else if (value == "Flood") {
            this.props.history.push('/BlogDetailsDoesBusinessInsuranceCoverFloods');
        }
        else if (value == "Backbone") {
            this.props.history.push('/BlogDetailsInsuranceAsABackbone');
        }
        else if (value == "Million") {
            this.props.history.push('/BlogDetailsRM1MillionPublicLiability');
        }
        else if (value == "Accelerate") {
            this.props.history.push('/BlogDetailsKeysToAccelerateBusiness');
        }

    }

    componentDidMount() {
        window.scrollTo(0, 0);
    }

    onExiting() {
        this.animating = true;
    }

    onExited() {
        this.animating = false;
    }

    next() {
        if (this.animating) return;
        const nextIndex = this.state.activeIndex === desktopImage.length - 1 ? 0 : this.state.activeIndex + 1;
        this.setState({ activeIndex: nextIndex });
    }

    previous() {
        if (this.animating) return;
        const nextIndex = this.state.activeIndex === 0 ? desktopImage.length - 1 : this.state.activeIndex - 1;
        this.setState({ activeIndex: nextIndex });
    }

    goToIndex(newIndex) {
        if (this.animating) return;
        this.setState({ activeIndex: newIndex });
    }


    render() {
        const { activeIndex } = this.state;

        const mobileUI = mobileImage.map((item) => {
            return (
                <CarouselItem
                    onExiting={this.onExiting}
                    onExited={this.onExited}

                    key={item.src}
                >
                    <a href={item.url} target="_blank" >
                        <img className={item.url !== null ? "pointerCursor img-fluid" : "normalCursor img-fluid"} src={item.src} alt={item.altText} />
                    </a>

                    <div className="carousel-caption">
                        <h1><b>Get Protected <br /> In 3 Easy Steps</b></h1>
                        <p>Flexible coverage based on your risks. Affordable rates.</p>
                        <div>
                            <Button className="btnInsubeeR" onClick={() => this.DirectTo(1)}>
                                Let's Protect It
                            </Button>
                        </div>
                    </div>

                </CarouselItem>
            );
        });

        const desktopUI = desktopImage.map((item) => {
            return (
                <CarouselItem
                    onExiting={this.onExiting}
                    onExited={this.onExited}

                    key={item.src}
                >

                    <a href={item.url} target="_blank" >
                        <img className={item.url !== null ? "carouselPicture pointerCursor img-fluid ObjFit" : " carouselPicture normalCursor img-fluid ObjFit"} src={item.src} alt={item.altText} />
                    </a>

                    <div className="carousel-caption d-none d-md-block ">
                        <h1 style={{ fontSize: "50px" }}><b>Get Protected <br /> In 3 Easy Steps</b></h1>
                        <p>Flexible coverage based on your risks. Affordable rates.</p>
                        <div style={{ width: "250px" }}>
                            <Button className="btnInsubeeR" onClick={() => this.DirectTo(1)}>
                                Let's Protect It
                            </Button>
                        </div>
                    </div>

                </CarouselItem>
            );
        });

        const InsuranceComponent = componentList.map((item) => {
            return (
                <div className="InsCmptBox mb-5">
                    <div key={item.name} className="InsCmptItem">
                        <img src={item.src} style={{ width: "160px", height: "160px" }} />
                        <br />
                        <span><b>{item.name}</b></span><br />
                        <span>{item.description}</span>

                    </div>
                </div>
            );
        });

        const MostAskedQuestion = this.state.FAQ.map((x) => {
            return (
                <FAQList
                    key={"MostAskedQuestion-" + x.id}
                    question={x.question}
                    answer={x.answer}
                    id={x.id}
                />
            )
        });

        return (
            <div className="mt-5">

                {Browser.isMobile() ?

                    <Carousel activeIndex={activeIndex} next={this.next} previous={this.previous} className="carousel-fade" >
                        <CarouselIndicators items={mobileUI} activeIndex={activeIndex} onClickHandler={this.goToIndex} />

                        {mobileUI}

                    </Carousel>
                    :
                    <Carousel activeIndex={activeIndex} next={this.next} previous={this.previous} className="carousel-fade" >
                        <CarouselIndicators items={desktopUI} activeIndex={activeIndex} onClickHandler={this.goToIndex} />

                        {desktopUI}

                    </Carousel>
                }

                {/*<FadeInSection>*/}

                <div className="HomeGCIS">
                    <div className="HomeFlex">
                        <AnimationOnScroll animateIn="animate__slideInLeft" animatePreScroll={false}>
                            <div className="Homecolumn">
                                <div className="ChatText">
                                    <div>
                                        <h1><b>Getting covered <br /> is simple</b></h1>
                                        <p>It's affordable and instant. The process starts with selecting the product you need and declaring information needed by the insurer.</p>
                                    </div>
                                    <div className="IProductContainer">
                                        <img className="IProductContainer-img" src={InsuranceProductHome} />
                                        <div style={{ marginTop: "-15px" }} >
                                            <h3><b>SME Biz One</b></h3>
                                            <span>Insurance In an Instant</span>
                                        </div>
                                        <div style={{ width: "250px", marginTop: "1rem" }}>
                                            <Button className="btnInsubee" onClick={() => this.DirectTo(1)}><b>Get Quote</b></Button>
                                        </div>
                                    </div>
                                </div>
                                <img id="ChatBoxHome" className="" />
                            </div>
                        </AnimationOnScroll>
                        <AnimationOnScroll animateIn="animate__slideInRight" animatePreScroll={false}>
                            <div className="Homecolumn2">
                                <img id="InsubeeCharacter" className="HomeChracterImg" />
                            </div>
                        </AnimationOnScroll>
                    </div>
                </div>
                <div className="bckgColor mb-5">
                    <div className="bckgColor-content">
                        <img src={TMLogo} className="" height="100"/>
                    </div>
                </div>


                <div className="HomeHIIW">

                    <AnimationOnScroll animateIn="animate__zoomIn" animatePreScroll={false}>
                        <div className="HIIWTxt">
                            <div>
                                <h1><b>How Insubee Insurance Works</b></h1>
                            </div>
                            <span>Insubee treat the premiums you pay as if it’s your money, not ours.  With Insubee, everything becomes simple and transparent. We pay claims super fast, and give back what’s left to causes you care about.</span>
                        </div>
                    </AnimationOnScroll>

                    <AnimationOnScroll animateIn="animate__backInUp" animatePreScroll={false} duration={0.8} >
                        <div className="HIIWComponent mt-5">
                            {InsuranceComponent}
                        </div>
                    </AnimationOnScroll>
                </div>

                <div className="HomeVBnr mb-4">

                    <ReactPlayer
                        url="/assets/InsubeeVideo.mp4"
                        playing
                        light={thumbnail}
                        controls
                        width="100%"
                        height="594px"
                    //style={{ zIndex: "-1", position: "relative" }}
                    />
                    <div className="HomeVLinearGradient"></div>
                </div>

                <div className="HomeTestimonials">
                    <div className="HomeTestimonialsBGImg">
                        <AnimationOnScroll animateIn="animate__zoomIn" animatePreScroll={false}>
                            <img src={testimonialsStarRate} className="" />
                        </AnimationOnScroll>
                        <AnimationOnScroll animateIn="animate__zoomIn" animatePreScroll={false}>
                            <div className="mt-5">
                                <span style={{ color: "#BDBDBD" }}><b>TESTIMONIALS</b></span>
                                <br /> <br />
                                <h1><b>Don’t Take Our Word For It</b></h1>
                                <div className="HomeTestimonials-txt">
                                    <span>Still on the fence about getting insurance from us? Let results speak for themselves</span>
                                </div>
                            </div>
                        </AnimationOnScroll>

                        <AnimationOnScroll animateIn="animate__flipInX" animatePreScroll={false}>
                            <TestimonialSlider />
                        </AnimationOnScroll>

                    </div>

                </div>


                <div className="FiveMinBG">
                    <div style={{ height: "450px" }} >
                        <div className="FiveMinContainer row">
                            <div className="FiveMinTxt">
                                <h1><b>Buy Instantly In 5 Minutes.</b></h1>
                                <span>Treat yourself to amazing promotions with our sweet deals!</span>
                            </div>

                            <div className="FiveMinBtn-box">
                                <Button className="btnInsubeeR" onClick={() => this.DirectTo(1)} style={{ height: "63px" }} >Get Quote</Button>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="FaqContainer">
                    <AnimationOnScroll animateIn="animate__heartBeat" animatePreScroll={false}>
                        <div className="FaqTitle mb-5">
                            <span><b>Asked question</b></span><br /><br />
                            <h1><b>Top 10 Most Popular Questions Asked</b></h1>
                        </div>
                    </AnimationOnScroll>

                    <div className="faqBox">
                        <div className="row">

                            {MostAskedQuestion}

                        </div>
                    </div>
                </div>

                <div className="HomeBlog" >
                    <div className="HomeBlogHeader">
                        <span style={{ color: "#FFFFFF" }}><b>OUR BLOG</b></span>
                        <br /><br />
                        <h1><b>Your Daily Dose</b></h1>
                        <div className="row">
                            <div className="col-md-9">
                                <div className="HomeBlogTxt">
                                    <span>Nobody’s got time for boring articles. Consume our bite-sized insurance deets, and be an insurance know-it-all in less than 5 minutes!</span>
                                </div>
                            </div>
                            <div className="col-md-3">
                                <Button className="btnInsubeeR" style={{ height: "63px" }} onClick={() => this.DirectTo(2)}>Read More</Button>
                            </div>
                        </div>
                    </div>
                    <div className="HomeBlogContain">
                        <HomeBlogSlider DirectTo={this.DirectTo} />
                    </div>
                </div>


                {/*</FadeInSection>*/}


                <Modal backdrop={false} centered isOpen={this.state.isOpenVideoModal} size="lg" toggle={() => this.setState({ isOpenVideoModal: false })}>
                    <ModalHeader toggle={() => this.setState({ isOpenVideoModal: false })}>
                    </ModalHeader>
                    <ModalBody>
                        <video width="100%" height="100%" controls preload="true" autoPlay>
                            <source src="/assets/InsubeeVideo.mp4" type="video/mp4">
                            </source>
                        </video>
                    </ModalBody>
                </Modal>
            </div>

        )

    }
}


class TestimonialSlider extends Component {

    render() {

        const settings = {
            dots: false,
            arrows: false,
            infinite: true,
            slidesToShow: 3,
            slidesToScroll: 1,
            autoplay: true,
            speed: 7000,
            autoplaySpeed: 1000,
            cssEase: "linear"
        };

        return (

            <div className="SliderContainer testimonialSC mt-5">
                <Slider {...settings}>
                    <div className="SliderItems">
                        <div>
                            <div className="SliderAvatarName">
                                <FontAwesomeIcon icon={faUserCircle} style={{ width: "58px", height: "57px" }} />
                                <span><b>Lively Grocer</b></span>
                            </div>
                            <p>It blows our mind how convenient it is to get business insurance with Insubee.
                                The process is pretty straightforward especially with the help from their customer care representative.
                                Definitely recommend this to business owners. Knowing that we are insured keeps us at peace</p>
                        </div>
                    </div>
                    <div className="SliderItems">
                        <div>
                            <div className="SliderAvatarName">
                                <FontAwesomeIcon icon={faUserCircle} style={{ width: "58px", height: "57px" }} />
                                <span><b>Luqman Hakim</b></span>
                            </div>
                            <p>Cepat & senang! Sebutharga dapat dengan segera & pembayaran dengan mudah melalui atas talian. Suka dengan promo yang diberikan</p>
                        </div>
                    </div>
                    <div className="SliderItems">
                        <div>
                            <div className="SliderAvatarName">
                                <FontAwesomeIcon icon={faUserCircle} style={{ width: "58px", height: "57px" }} />
                                <span><b>Exprezz Food Trading</b></span>
                            </div>
                            <p>Insubee, has been a game-changer for my business. Their personalized service and commitment to understanding my goals have made all the difference. Highly recommended!"</p>
                        </div>
                    </div>
                    <div className="SliderItems">
                        <div>
                            <div className="SliderAvatarName">
                                <FontAwesomeIcon icon={faUserCircle} style={{ width: "58px", height: "57px" }} />
                                <span><b>Lively Grocer</b></span>
                            </div>
                            <p>It blows our mind how convenient it is to get business insurance with Insubee.
                                The process is pretty straightforward especially with the help from their customer care representative.
                                Definitely recommend this to business owners. Knowing that we are insured keeps us at peace</p>
                        </div>
                    </div>
                    <div className="SliderItems">
                        <div>
                            <div className="SliderAvatarName">
                                <FontAwesomeIcon icon={faUserCircle} style={{ width: "58px", height: "57px" }} />
                                <span><b>Luqman Hakim</b></span>
                            </div>
                            <p>Cepat & senang! Sebutharga dapat dengan segera & pembayaran dengan mudah melalui atas talian. Suka dengan promo yang diberikan</p>
                        </div>
                    </div>
                    <div className="SliderItems">
                        <div>
                            <div className="SliderAvatarName">
                                <FontAwesomeIcon icon={faUserCircle} style={{ width: "58px", height: "57px" }} />
                                <span><b>Exprezz Food Trading</b></span>
                            </div>
                            <p>Insubee, has been a game-changer for my business. Their personalized service and commitment to understanding my goals have made all the difference. Highly recommended!"</p>
                        </div>
                    </div>
                </Slider>
            </div>
        )

    }
}

class FAQList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            collapse: false
        };

        this.toggle = this.toggle.bind(this);
    }

    toggle() {
        this.setState({ collapse: !this.state.collapse });
    }

    render() {
        return (

            <div className="col-md-12">
                <div className="cardFaq">
                    <div className={this.state.collapse == true ? "FaqQ active" : "FaqQ"} style={{ cursor: "pointer" }} onClick={this.toggle}>
                        <div className="row">
                            <div className="col-md-12">
                                <span><b>{this.props.question}</b></span>
                            </div>
                        </div>
                    </div>
                    <Collapse isOpen={this.state.collapse}>
                        <div className="FaqAPanel">
                            <span>{this.props.answer}</span>
                            {this.props.id == 3 ?
                                <>
                                    <br /><br />
                                    <span>You can even add extra coverage to include:</span>
                                    <br /><br />
                                    <ul>
                                        <li>Burglary - Provides protection against incidents of theft to your property</li>
                                        <li>Money - Cover loss of money whilst in transit and whilst in the premises</li>
                                        <li>Glass - Indemnify for any glass broken by fracture extending through the entire thickness of the glass as a result of an accident</li>
                                        <li>Public Liability - Covers legal liability to third party as a result of an accident happening in connection with your business operations</li>
                                        <li>Employer's Liability - Indemnifies against liability at law to pay compensation and cost and expenses to the employees in respect of accidental bodily injury or disease arising out of or in the course of employment</li>
                                    </ul>
                                </>
                                :
                                <>
                                </>

                            }

                        </div>
                    </Collapse>
                </div>
                <hr />
            </div>

        )
    }
}

class HomeBlogSlider extends Component {

    render() {

        const settings = {
            dots: false,
            arrows: true,
            infinite: true,
            slidesToShow: Browser.isMobile() ? 1 : 3,
            slidesToScroll: 1,
            //autoplay: true,
            //speed: 9000,
            //autoplaySpeed: 1000,
            cssEase: "linear"
        };

        return (

            <div className="SliderContainer BlogSL mt-5">
                <Slider {...settings}>
                    <div className="BSliderItems">
                        <div>
                            <img src={BusinessInsuranceCoverFloods} className="" />
                        </div>
                        <div className="BSliderTxt">
                            <span id="TeamInsubeeTxt"><b>Team Insubee</b></span>
                            <br /><br />
                            <h5><b>Does Business Insurance Cover Flood Damage?</b></h5>
                            <span>Due to the large number of rivers running through the country, roughly 9 percent…</span>
                            <br /><br />
                            <Button className="btnInsubeeReadMore" onClick={() => this.props.DirectTo("Flood")}>Read More</Button>
                        </div>
                    </div>
                    <div className="BSliderItems">
                        <div>
                            <img src={InsuranceAsABackbone} className="" />
                        </div>
                        <div className="BSliderTxt">
                            <span id="TeamInsubeeTxt"><b>Team Insubee</b></span>
                            <br /><br />
                            <h5><b>Insurance As A Backbone For Business Resilience</b></h5>
                            <span>Here are key points to accelerate business transformation.</span>
                            <br /><br />
                            <Button className="btnInsubeeReadMore" onClick={() => this.props.DirectTo("Backbone")}>Read More</Button>
                        </div>
                    </div>
                    <div className="BSliderItems">
                        <div>
                            <img src={RM1MillionPublicLiability} className="" />
                        </div>
                        <div className="BSliderTxt">
                            <span id="TeamInsubeeTxt"><b>Team Insubee</b></span>
                            <br /><br />
                            <h5><b>RM 1 Million Public Liability: To Be Or Not To Be</b></h5>
                            <span>An unintended slip in your store can lead to major litigation costs. Here’s why…</span>
                            <br /><br />
                            <Button className="btnInsubeeReadMore" onClick={() => this.props.DirectTo("Million")}>Read More</Button>
                        </div>
                    </div>
                    <div className="BSliderItems">
                        <div>
                            <img src={KeysToAccelerateBusinessTransformation} className="" style={{ width: "380px", height: "186px" }} />
                        </div>
                        <div className="BSliderTxt">
                            <span id="TeamInsubeeTxt"><b>Team Insubee</b></span>
                            <br /><br />
                            <h5><b>Keys to Accelerate Business Transformation</b></h5>
                            <span>Here are key points to accelerate business transformation.</span>
                            <br /><br />
                            <Button className="btnInsubeeReadMore" onClick={() => this.props.DirectTo("Accelerate")}>Read More</Button>
                        </div>
                    </div>
                    <div className="BSliderItems">
                        <div>
                            <img src={InsuranceAsABackbone} className="" />
                        </div>
                        <div className="BSliderTxt">
                            <span id="TeamInsubeeTxt"><b>Team Insubee</b></span>
                            <br /><br />
                            <h5><b>Insurance As A Backbone For Business Resilience</b></h5>
                            <span>Here are key points to accelerate business transformation.</span>
                            <br /><br />
                            <Button className="btnInsubeeReadMore" onClick={() => this.props.DirectTo("Backbone")}>Read More</Button>
                        </div>
                    </div>
                </Slider>
            </div>
        )

    }
}

export default Home;