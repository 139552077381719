import React from 'react'

class CheckboxField extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        const {
            input,
            className,
            label,
            disabled,
            meta: { touched, error, warning }
        } = this.props;

        return (
            <div className="ui checkbox">
                <input
                    {...input}
                    id={label + "-" + input.name}
                    type="checkbox"
                    disabled={disabled}
       
                    checked={input.value === true}
                />&nbsp;&nbsp;
                <label htmlFor={label + "-" + input.name}>{label}</label>
            </div>


            // <React.Fragment>
            //     <input 
            //         {...input} 
            //         className={className + (touched ? error || warning ? " is-invalid" : "" : "")} 
            //         placeholder={(placeholder || label || input.name)} 
            //         onFocus={this.disableWheelScroll} 
            //         type={type} disabled={disabled} 
            //         pattern={pattern}
            //         autoComplete="nope"
            //     />
            //     {this.props.children}
            //     {touched &&
            //         (
            //             (error && <span className="invalid-feedback">{(label || input.name) + " " + error}</span>) ||
            //             (warning && <span className="invalid-feedback">{warning}</span>)
            //         )
            //     }
            // </React.Fragment>
        )
    }
}

export default CheckboxField;