import { applyMiddleware, combineReducers, compose, createStore } from 'redux';
import thunk from 'redux-thunk';
import { createBrowserHistory } from 'history';
import { routerMiddleware } from 'connected-react-router';
import createRootReducer from './reducers';

// Create browser history to use in the Redux store
//const baseUrl = document.getElementsByTagName('base')[0].getAttribute('href');
//const history = createBrowserHistory({ basename: baseUrl });
export const history = createBrowserHistory();

export default function configureStore(initialState) {

    /* Moved to reducers file */
    //const reducers = {
    //    counter: Counter.reducer,
    //    weatherForecasts: WeatherForecasts.reducer
    //};    

    const middleware = [
        thunk,
        routerMiddleware(history)
    ];

    // In development, use the browser's Redux dev tools extension if installed
    const enhancers = [];
    const isDevelopment = process.env.NODE_ENV === 'development';
    if (isDevelopment && typeof window !== 'undefined' && window.devToolsExtension) {
        enhancers.push(window.devToolsExtension());
    }


    return createStore(
        createRootReducer(history), // root reducer with router state,
        initialState,
        compose(applyMiddleware(...middleware), ...enhancers)
    );
}
