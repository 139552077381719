
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Container, Row, Col, FormGroup, Label, InputGroup, InputGroupAddon, InputGroupText, Input, Card, CardBody } from 'reactstrap';
//import homepage from './homepage.css'
//import ContactUsForm from './ContactUsForm'
//import actions from './actions'



class TermsofService extends Component {

    constructor(props) {
        super(props);

    }



    componentDidMount() {

        if (this.props) {
            if (this.props.match) {
                if (this.props.match.path == "/TermsofService") {
                    window.scrollTo(0, 0);
                }
            }
        }
    }



    render() {

        const { IsModelForm } = this.props;

        return (

            <div className={IsModelForm ? "" : "my-5 pt-sm-5"}>
            <Container className="formBackground">
              
                        <div className="animated fadeIn">
                            <Row>

                            {IsModelForm ?

                                <br />

                                :

                                <>
                                    </>

                            }
                                <Col xs="12" sm="12" lg="12">
                                    <div className="text-center">
                                        <h1><b>Terms of Service</b></h1>
                                        <hr style={{width:"25%", height:"100%", backgroundColor:"#FFDF00", border:"none", height:"5px"}} />
                                    </div>
                                </Col>
                            </Row>


                            <br />
                            <br />

                         

                                    <Row>
                                        <Col xs="12" sm="12" lg="12">
                                            
                                                <h2><b>Terms of Service </b></h2>
                                      
                                        </Col>
                                    </Row>

                                    <br />

                                    <Row>

                                        <Col xs="12" sm="12" lg="12">

                                        <p>

                                            These Terms of Service ("Terms") govern your access to and use of the application, website, products, 
                                            and services ("Services") provided by GD Valueguard Sdn Bhd ("Insubee", "we", "us," or "our"), 
                                            so please read these Terms carefully before using the Services. 

                                            <br />
                                            <br />

                                            References to "you" in these Terms means you, your duly authorized representatives and any entity 
                                            you may represent in connection with your use of the Services. Any reference made in these Terms to 
                                            "Insubee" shall be deemed to have been made to GD Valueguard Sdn Bhd - 1005622K, its successors, assignees, 
                                            and affiliates, as well as any company that controls Insubee, directly or indirectly, and any other subsidiary 
                                            of that controlling company.

                                            <br />
                                            <br />

                                            Not all of the products or services described on this website are available in all areas of Malaysia  
                                            and you may not be eligible for them. We reserve the right to determine eligibility. 
                                            By accessing or using our Services, you agree to be bound by these Terms and by our Privacy Policy. 
                                            If you do not agree to these Terms, which incorporates the Privacy Policy by reference please do 
                                            not access or otherwise use our Services or any information contained herein. 

                                            <br />
                                            <br />

                                            We reserve the right, at our discretion, to modify these Terms from time to time, which modification 
                                            will be effective upon the posting of the modification to our website, and will apply to causes of action 
                                            arising after the effective date of the change. You should continue to check the website for changes. 
                                            Your continued use of our website or otherwise the Services following the posting of changes to these Terms 
                                            will mean that you accept those changes. By accessing or using our Services, you confirm that: 
                                            (i) you can form a binding contract with us; and 
                                            (ii) you are over the age of 18 as the Services are not intended for children under 18. 
                                            If it comes to our attention through reliable means that a registered user is a child under 18 years of age, 
                                            we will cancel that user's account and/or access to the Services. If you are using the Services on behalf of a 
                                            corporation (or any legal entity other than using it personally), you represent that you are authorized to enter
                                            into an agreement on behalf of that legal entity, and are nevertheless individually bound by these Terms even if 
                                            we have a separate agreement with such corporation. 

                                            <br />
                                            <br />

                                            The headings and captions used in these Terms of Use are inserted for convenience only and will not affect 
                                            the meaning or interpretation of these Terms of Use. The content on the Insubee website and other content 
                                            is for convenience and information purposes only. Nothing therein should be construed as rendering tax, legal, 
                                            investment, or accounting advice. The posting of any prospectus or any other information is not a recommendation 
                                            or opinion for you to buy or sell any product or participate in any transaction.

                                        </p>
                                          

                                        </Col>

                                    </Row>



                        <br />
                        <br />


                        <Row>
                            <Col xs="12" sm="12" lg="12">

                                <h2><b>Warranties and Disclaimers</b></h2>

                            </Col>
                        </Row>

                        <br />

                        <Row>

                            <Col xs="12" sm="12" lg="12">

                                <p>
                                    These Terms of Business set out the basis and extent to which we will provide you with services in relation 
                                    to each and every contract of insurance which we arrange on your behalf. In the event you choose to engage 
                                    our services, you are deemed to have read, understood and agreed to the Terms of Business as set forth hereunder.

                                    <br />
                                    <br />

                                    All work carried out by us is subject to these terms except where changes are expressly agreed in writing by
                                    both parties. We retain the right to amend the Terms of Business at any time and from time to time. 

                                </p>

                            </Col>

                        </Row>


                        <br />
                        <br />


                        <Row>
                            <Col xs="12" sm="12" lg="12">

                                <h2><b>Insubee Rights</b></h2>

                            </Col>
                        </Row>

                        <br />

                        <Row>

                            <Col xs="12" sm="12" lg="12">

                                <p>
                                    We reserve the right to modify or terminate the Site for any reason, without notice at any time. 
                                    Not all services and features are available in every jurisdiction and we are under no obligation to 
                                    make any Services or features available in any jurisdiction.

                                    <br />
                                    <br />

                                    All right, title, and interest in and to Services including worldwide intellectual property rights therein, 
                                    are and will remain the exclusive property of Insubee and its applicable licensors. We reserve all rights 
                                    not expressly granted in and to the Services.

                                </p>

                            </Col>

                        </Row>



                        <br />
                        <br />


                        <Row>
                            <Col xs="12" sm="12" lg="12">

                                <h2><b>Account Information</b></h2>

                            </Col>
                        </Row>

                        <br />

                        <Row>

                            <Col xs="12" sm="12" lg="12">

                                <p>
                                    In order to access the Service, you will have to create an account. 
                                    You hereby represent and warrant that the information you provide to Insubee upon registration is 
                                    accurate and complete. You also hereby agree that you will ensure that this Account Information is kept 
                                    accurate and up-to-date at all times.

                                </p>

                            </Col>

                        </Row>



                        <br />
                        <br />


                        <Row>
                            <Col xs="12" sm="12" lg="12">

                                <h2><b>Privacy and Passwords</b></h2>

                            </Col>
                        </Row>

                        <br />

                        <Row>

                            <Col xs="12" sm="12" lg="12">

                                <p>
                                    The Personal Data Protection Act 2010 (PDPA) is enforced. Please visit www.gdexpress.com for more 
                                    information on the Privacy Notice. You hereby agree to the privacy policy contained in the Privacy Notice.

                                    <br />
                                    <br />

                                    For your protection, if Insubee believes that any unauthorized access may occur or has occurred, 
                                    Insubee may terminate your account access without prior notice to you. You also agree that Insubee 
                                    is permitted to act upon any instructions received using your User ID and password and to consider 
                                    such instructions as authorized by you.

                                </p>

                            </Col>

                        </Row>


                        <br />
                        <br />


                        <Row>
                            <Col xs="12" sm="12" lg="12">

                                <h2><b>Unauthorized Use</b></h2>

                            </Col>
                        </Row>

                        <br />

                        <Row>

                            <Col xs="12" sm="12" lg="12">

                                <p>
                                    Do not misuse our Services. You agree not to use the Services or any aspect or feature thereof for 
                                    any unlawful purpose or in any way that might harm, damage, or disparage any other party. 
                                    Without limiting the preceding sentence, you agree that you will: (i) review and comply with these Terms 
                                    and the Privacy Notice; (ii) comply with all applicable laws, including, without limitation, privacy laws, 
                                    intellectual property laws, export control laws, tax laws, and regulatory requirements; (iii) provide accurate 
                                    information to us and update it as necessary; and (iv) act honestly and in good faith. You agree not to use the 
                                    App in a way that will distract from and/or interfere with your driving.

                                    <br />
                                    <br />

                                    By using the Services you agree not to: (i) create an account for anyone other than yourself 
                                    (unless you represent a company, organization, legal entity or brand); 
                                    (ii) verbally abuse, threaten, harass, intimidate, defame, bully, employ hate speech 
                                    or otherwise threaten to harm any employee or agent of the company; (iii) attempt to use another 
                                    person&apos;s account or create a false identity; (iv) duplicate, license, sublicense, publish, broadcast, 
                                    transmit, distribute, perform, display, sell, rebrand, or otherwise transfer information found in the 
                                    Services, except as permitted in these Terms, or as expressly authorized by us; (v) reverse engineer, 
                                    decompile, disassemble, decipher or otherwise attempt to derive the source code for any underlying 
                                    intellectual property used to provide the Services, or any part thereof; (vi) utilize or copy information, 
                                    content or any data you view on or obtain from the Services to provide any service that is competitive, 
                                    in our sole discretion, with the Services; (vii) adapt, modify or create derivative works based on the 
                                    Services or technology underlying the Services, in whole or part; (viii) rent, lease, loan, trade, sell/resell 
                                    any information in the Services, in whole or part; (ix) use the communication systems provided by the Services 
                                    for any commercial solicitation purposes; (x) sell, sponsor, or otherwise monetize any service or functionality 
                                    in the Services, without our express written consent; (xi) remove any copyright, trademark or other proprietary 
                                    rights notices contained in or on the Services (whether ours or our licensors&apos;); (xii) remove, cover or otherwise 
                                    obscure any form of advertisement included on the Services; (xiii) collect, harvest, use, copy, or transfer 
                                    any information, including, but not limited to, personally identifiable information obtained from the Services
                                    (excluding your User Content, and except if the owner of such information has expressly permitted the same); 
                                    (xiv) share other users&apos; or third party&apos;s information or their User Content without their express consent; 
                                    (xv) infringe or use our brand, logos or trademarks in any business name, email, or URL except as expressly 
                                    permitted by us; (xvi) use or launch any manual or automated system or software, devices, scripts robots, 
                                    other means or processes to access, "scrape," "crawl," "cache," "spider" or any web page or other service 
                                    contained in our Services, or to access the Services in a manner that sends more request messages to our servers 
                                    in a given period of time than a human can reasonably produce in the same period by using a conventional on-line 
                                    web browser; (xvii) use bots or other automated methods to access the Services, add or download contacts, 
                                    send or redirect messages, or perform other similar activities through the Services; (xviii) access, 
                                    via automated or manual means or processes, the Services for purposes of monitoring its availability, 
                                    performance or functionality for any competitive purpose; (xix) engage in "framing," "mirroring," or 
                                    otherwise simulating the appearance or function of the App; (xx) attempt to or actually access the Services 
                                    by any means other than through the interfaces provided by us. This prohibition includes accessing or attempting 
                                    to access the Services using any third-party service, including software-as-a-service platforms that aggregate
                                    access to multiple services; (xxi) engage in any action that directly or indirectly interferes with the proper 
                                    working of or places an unreasonable load on the Services&apos; infrastructure, including, but not limited to, 
                                    sending unsolicited communications to other users or our personnel, attempting to gain unauthorized access 
                                    to the Services, or transmitting or activating computer viruses, Trojan horses, worms, time bombs or any other 
                                    harmful or deleterious software programs through or on the Services; (xxii) interfere or disrupt or game the 
                                    Services, including, but not limited to any servers or networks connected to the Services, or the underlying 
                                    software; and/or (xxiii) otherwise violate the Terms.

                                </p>

                            </Col>

                        </Row>



                        <br />
                        <br />


                        <Row>
                            <Col xs="12" sm="12" lg="12">

                                <h2><b>Underwriting</b></h2>

                            </Col>
                        </Row>

                        <br />

                        <Row>

                            <Col xs="12" sm="12" lg="12">

                                <p>
                                    Your insurance is underwritten by our insurance partners. 
                                    Insubee is an insurance agency that represent insurance companies that underwrite your policies. 
                                    We shall arrange for the insurance policy certificate be sent to you through email once insurer 
                                    has approved your policy.

                                    <br />
                                    <br />

                                    You are responsible to keep copies of all policy documentation as this will be required to administer 
                                    your policy and process any claims.

                                </p>

                            </Col>

                        </Row>




                        <br />
                        <br />


                        <Row>
                            <Col xs="12" sm="12" lg="12">

                                <h2><b>Duty of Disclosure</b></h2>

                            </Col>
                        </Row>

                        <br />

                        <Row>

                            <Col xs="12" sm="12" lg="12">

                                <p>
                                    All quotations are given on the basis of "utmost good faith". You must act with utmost good faith toward 
                                    the insurer and comply with your statutory pre-contractual duties of disclosure under the FSA by ensuring 
                                    that you provide to the insurer honest and accurate information at all times. This obligation applies at the 
                                    time of applying for the policy and continues throughout the duration of the policy as well as any renewal thereof.

                                    <br />
                                    <br />

                                    Pursuant to Paragraph 5 of Schedule 9 of the Financial Services Act 2013, if you are applying for insurance 
                                    offered on Insubee, care not to make a misrepresentation in answering the questions in our purchase flow. 
                                    You must answer all questions fully and accurately. Failure to take reasonable care in answering the questions 
                                    may result in avoidance of your contract of insurance, refusal or reduction of your claim(s), change of terms or 
                                    termination of your contract of insurance. The above duty of disclosure shall continue until the time your contract 
                                    of insurance is entered into, varied of renewed with us. You are required to disclose any other matter that you 
                                    know to be relevant to our decision in accepting the risks and determining the rates, and terms to be applied. 
                                    You also have a duty to tell us immediately if at any time after your contract of insurance has been entered into,
                                    varied or renewed with us any of the information given is inaccurate or has changed.  

                                </p>

                            </Col>

                        </Row>




                        <br />
                        <br />


                        <Row>
                            <Col xs="12" sm="12" lg="12">

                                <h2><b>Your Representations and Warranties</b></h2>

                            </Col>
                        </Row>

                        <br />

                        <Row>

                            <Col xs="12" sm="12" lg="12">

                                <p>
                                    You represent and warrant that: (i) You do not lack legal capacity to enter into a valid, binding and 
                                    enforceable contract like an insurance policy; (ii) Your purchase of an insurance policy using the Services 
                                    is voluntary; and (iii) You have an insurable interest in the property or life insured under an insurance policy 
                                    you purchase using the Services, in that you have a financial or legally sufficient emotional interest that will 
                                    be adversely affected by the happening of a fortuitous event with respect to which coverage is afforded under the
                                    terms and conditions of that insurance policy.

                                </p>

                            </Col>

                        </Row>



                        <br />
                        <br />


                        <Row>
                            <Col xs="12" sm="12" lg="12">

                                <h2><b>Renewals</b></h2>

                            </Col>
                        </Row>

                        <br />

                        <Row>

                            <Col xs="12" sm="12" lg="12">

                                <p>
                                    We may contact you prior to the expiry of any policy to notify you of the then current premium for such policy 
                                    and any changes that have been made to the policy terms. If the insurer no longer offers such a policy, 
                                    or you have ceased to be eligible for such policy we may introduce to 
                                    you other policies which may be of interest, from that insurer and/or from other insurers.

                                </p>

                            </Col>

                        </Row>




                        <br />
                        <br />


                        <Row>
                            <Col xs="12" sm="12" lg="12">

                                <h2><b>Cancellations</b></h2>

                            </Col>
                        </Row>

                        <br />

                        <Row>

                            <Col xs="12" sm="12" lg="12">

                                <p>
                                    Kindly email us at help.support@insubee.com for any policy cancellation. 
                                    In accordance with Insurance Act 1996 (Part XII, Section 141 Assumption of Risks, Subsection 4) 
                                    all refund premium shall pay directly to the policyholder. 

                                </p>

                            </Col>

                        </Row>



                        <br />
                        <br />


                        <Row>
                            <Col xs="12" sm="12" lg="12">

                                <h2><b>Claims </b></h2>

                            </Col>
                        </Row>

                        <br />

                        <Row>

                            <Col xs="12" sm="12" lg="12">

                                <p>
                                    You must notify us immediately in writing of any claim or circumstance that is likely to 
                                    give rise to a claim. Failure to notify within applicable notification period set out in your 
                                    insurance policy may result in insurer denial of claim. 

                                    <br />
                                    <br />

                                    In connection with any claim under your insurance policy, you consent to the release to us by third 
                                    parties of any and all information and documents &minus; whether or not confidential or non-public &minus; 
                                    that we deem pertinent to the claim, including but not limited to personally identifiable information, 
                                    personal financial information, protected health information and any information you have provided to any 
                                    law enforcement agency. You hereby authorize Insubee as your representative for purposes of obtaining release 
                                    to us of any such information from third parties. Any such information released to Insubee will be held 
                                    and used in accordance with our Privacy Policy.

                                    <br />
                                    <br />

                                    The information you submit regarding your insurance policy and the loss is subject to review and verification. 
                                    Insubee reserves the right to request additional information for claim on behalf of the insurance company. 
                                    A claim representative from the insurance company may also be communicating with you regarding your claim.

                                </p>

                            </Col>

                        </Row>




                        <br />
                        <br />


                        <Row>
                            <Col xs="12" sm="12" lg="12">

                                <h2><b>Payment Terms</b></h2>

                            </Col>
                        </Row>

                        <br />

                        <Row>

                            <Col xs="12" sm="12" lg="12">

                                <p>
                                    We shall endeavour to ensure that the pricing, terms of policy and other information on our website in respect 
                                    of policies are accurate, reliable and up to date, but we do not guarantee their accuracy, completeness or 
                                    reliability. The actual premium payable in respect of a policy and the terms of a policy and other information 
                                    shall be such premium, terms and other information as the applicable insurer notifies to you, when they accept 
                                    your application. You agree to pay all premiums plus any premium payment tax, government levy or similar tax, 
                                    duty or stamp in full before the due date stipulated for the applicable policy, renewal or endorsement.

                                    <br />
                                    <br />

                                    No policy is issued until the insurer notifies you that they have issued a policy to you. 
                                    If the insurer declines to accept your application, any premium refund will, where possible be 
                                    refunded to the same card or same payment method that made the payment.

                                    <br />
                                    <br />

                                    You may choose to pay such charges via debit card, credit card, online banking, or myGDEX e-Wallet. 
                                    We will not accept payment by any other method unless mutually agreed to by us and you.

                                </p>

                            </Col>

                        </Row>


                        <br />
                        <br />


                        <Row>
                            <Col xs="12" sm="12" lg="12">

                                <h2><b>Limitation of Liability</b></h2>

                            </Col>
                        </Row>

                        <br />

                        <Row>

                            <Col xs="12" sm="12" lg="12">

                                <p>
                                    You expressly understand and agree that, to the extent permitted by applicable laws, 
                                    we shall not be liable for any direct, indirect, incidental, special, consequential or exemplary damages, 
                                    including but not limited to, damages for loss of profits, goodwill, use, data or other intangible losses 
                                    resulting from the use of or inability to use the Site.

                                    <br />
                                    <br />

                                    To the extent permitted by applicable laws, in no event shall we be liable for lost profits or any special, 
                                    incidental or consequential damages arising out of or in connection with the Site or the Terms 
                                    (however arising including negligence). 

                                    <br />
                                    <br />

                                    You expressly understand and agree that: (a) your use of the services and the purchase and use of 
                                    any products or services are all at your sole risk. The services are provided on an "as is" and 
                                    "as available" basis. To the maximum extent permitted by law, Insubee expressly disclaims all warranties 
                                    and conditions of any kind, whether express or implied, including, but not limited to the implied warranties 
                                    and conditions of merchantability, fitness for a particular purpose and non- infringement. (b) lnsubee does 
                                    not warrant that (i) the services will meet all of your requirements; (ii) the services will be uninterrupted, 
                                    timely, secure or error-free; or (iii) all errors in the software or services will be corrected. (c) 
                                    any material downloaded or otherwise obtained through the use of the services is done at your own discretion 
                                    and risk and you are solely responsible for any damage to your computer or other device or loss of data 
                                    resulting from the download or use of any such material. (d) no advice or information, whether oral or written, 
                                    obtained by you from Insubee or through or from the service shall create any warranty not expressly stated 
                                    in these terms. 

                                </p>

                            </Col>

                        </Row>


                        <br />
                        <br />



                        <Row>
                            <Col xs="12" sm="12" lg="12">

                                <h2><b>Assignment</b></h2>

                            </Col>
                        </Row>

                        <br />

                        <Row>

                            <Col xs="12" sm="12" lg="12">

                                <p>
                                    Insubee reserves the right, in its sole and absolute discretion, to transfer, assign, sublicense 
                                    or pledge in any manner whatsoever, any of its rights and obligations under these Terms to a subsidiary,
                                    affiliate, successor thereof or to any third party whatsoever, without notifying you or receiving your consent. 
                                    You shall not transfer, assign, delegate, sublicense nor pledge in any manner whatsoever, any of your 
                                    rights or obligations under these Terms.

                                </p>

                            </Col>

                        </Row>


                        <br />
                        <br />



                        <Row>
                            <Col xs="12" sm="12" lg="12">

                                <h2><b>Indemnity</b></h2>

                            </Col>
                        </Row>

                        <br />

                        <Row>

                            <Col xs="12" sm="12" lg="12">

                                <p>
                                    You agree to defend, indemnify and hold harmless us, our affiliates, and our and their respective owners, 
                                    officers, directors, employees, agents, and/or licensors, from and against any and all claims, 
                                    damages, obligations, losses, liabilities, costs and expenses (including but not limited to attorney&apos;s fees) 
                                    arising from: (i) your use of the Services; (ii) your violation of these Terms; (iii) your violation of 
                                    any third-party right, including without limitation any copyright, property, publicity or privacy right; 
                                    including all actions taken under your account; (iv) your violation of any applicable law; or (v) your 
                                    negligence or willful misconduct. This defense, hold harmless and indemnification obligation will survive 
                                    any termination of these Terms and your use of the Services.

                                </p>

                            </Col>

                        </Row>




                        <br />
                        <br />



                        <Row>
                            <Col xs="12" sm="12" lg="12">

                                <h2><b>Mandatory Law</b></h2>

                            </Col>
                        </Row>

                        <br />

                        <Row>

                            <Col xs="12" sm="12" lg="12">

                                <p>
                                    These Terms and your use of the Services are governed by and construed in accordance with the law of Malaysia. 

                                    <br />
                                    <br />

                                    The Malaysian courts will have exclusive jurisdiction over any claim arising from, or related to, a visit to 
                                    our website although we retain the right to bring proceedings 
                                    against you for breach of these conditions in your country of residence or any other relevant country.

                                </p>

                            </Col>

                        </Row>



                        <br />
                        <br />



                        <Row>
                            <Col xs="12" sm="12" lg="12">

                                <h2><b>Privacy Notice</b></h2>

                            </Col>
                        </Row>

                        <br />

                        <Row>

                            <Col xs="12" sm="12" lg="12">

                                <p>
                                    The Personal Data Protection Act 2010 (PDPA) is enforced. Please visit www.gdexpress.com for more information 
                                    on the Privacy Notice. You hereby agree to the privacy policy contained in the Privacy Notice.

                                </p>

                            </Col>

                        </Row>




                        <br />
                        <br />



                        <Row>
                            <Col xs="12" sm="12" lg="12">

                                <h2><b>General</b></h2>

                            </Col>
                        </Row>

                        <br />

                        <Row>

                            <Col xs="12" sm="12" lg="12">

                                <p>
                                    We reserve the right to discontinue or modify any aspect of the Services at any time without 
                                    notice to you, unless notice is otherwise required under applicable law. We may take any 
                                    measures to ensure your compliance with the Terms. These Terms, together with the Privacy 
                                    Notice, and any other legal notices published by us on the Services, shall constitute the 
                                    entire agreement between us concerning the Services. If any provision of these Terms is deemed 
                                    invalid by a court of competent jurisdiction, the invalidity of such provision shall not affect 
                                    the validity of the remaining provisions of these Terms, which shall remain in full force and effect. 
                                    No waiver of any term of these Terms shall be deemed a further or continuing waiver of such term or 
                                    any other term, and a party&apos;s failure to assert any right or provision under these not constitute a 
                                    waiver of such right or provision.

                                </p>

                            </Col>

                        </Row>




                        <Row>
                            <Col xs="12" sm="12" lg="12">

                                <h2><b>Privacy Policy</b></h2>

                            </Col>
                        </Row>

                        <br />

                        <Row>

                            <Col xs="12" sm="12" lg="12">

                                <p>Insubee policies are primarily sold online through our website. Customers
                                can go through our product page to purchase, get help from our team, and file claims.
                                Insubee policies are primarily sold online through our website. Customers
                                can go through our product page to purchase, get help from our team, and file claims.
                                Insubee policies are primarily sold online through our website. Customers
                                can go through our product page to purchase, get help from our team, and file claims.
                                Insubee policies are primarily sold online through our website. Customers
                                can go through our product page to purchase, get help from our team, and file claims.
                                Insubee policies are primarily sold online through our website. Customers
                                can go through our product page to purchase, get help from our team, and file claims.
                                Insubee policies are primarily sold online through our website. Customers
                                can go through our product page to purchase, get help from our team, and file claims.
                                Insubee policies are primarily sold online through our website. Customers
                                can go through our product page to purchase, get help from our team, and file claims.
                                Insubee policies are primarily sold online through our website. Customers
                                can go through our product page to purchase, get help from our team, and file claims.
                                            </p>


                            </Col>

                        </Row>


                        </div>
                   

                <br />
                </Container>

            </div>
        );
    }
}



//const mapDispatchToProps = dispatch => {
//return {


// contactUS: (content) => {
// dispatch(actions.contactUs(content));
//}

//}
//}

//const mapStateToProps = state => {

//const { home } = state || {}; // entire Redux store state (the same value returned by a call to store.getState()).  
//return { home };
//};



//export default connect(
//mapStateToProps,
//mapDispatchToProps
//)(ContactUs)

export default TermsofService;
