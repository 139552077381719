const userSettingConstants = {

    GET_ROLE_REQUEST: "GET_ROLE_REQUEST",
    GET_ROLE_SUCCESS: "GET_ROLE_SUCCESS",
    GET_ROLE_FAILURE: "GET_ROLE_FAILURE",

    ADD_ROLE_REQUEST: 'ADD_ROLE_REQUEST',
    ADD_ROLE_SUCCESS: 'ADD_ROLE_SUCCESS',
    ADD_ROLE_FAIL: 'ADD_ROLE_FAIL',

    HIDE_ADD_ROLE_FAIL: 'HIDE_ADD_ROLE_FAIL',
    SHOW_ADD_ROLE_FAIL: 'SHOW_ADD_ROLE_FAIL',

    UPDATE_ROLE_REQUEST: 'UPDATE_ROLE_REQUEST',
    UPDATE_ROLE_SUCCESS: 'UPDATE_ROLE_SUCCESS',
    UPDATE_ROLE_FAIL: 'UPDATE_ROLE_FAIL',

    HIDE_UPDATE_ROLE_FAIL: 'HIDE_UPDATE_ROLE_FAIL',
    SHOW_UPDATE_ROLE_FAIL: 'SHOW_UPDATE_ROLE_FAIL',

    ROLE_DELETE_REQUEST: 'ROLE_DELETE_REQUEST',
    ROLE_DELETE_SUCCESS: 'ROLE_DELETE_SUCCESS',
    ROLE_DELETE_FAIL: 'ROLE_DELETE_FAIL',

    ROLE_HIDE_DELETE_FAIL: 'ROLE_HIDE_DELETE_FAIL',
    ROLE_SHOW_DELETE_FAIL: 'ROLE_SHOW_DELETE_FAIL',
}

export const AccessLevel = [
    { name: "ViewDashboard", value: "ViewDashboard", label: "View Dashboard" },
    { name: "ViewSalePolicy", value: "ViewSalePolicy", label: "View Sale Policy" },
    { name: "EditSalePolicy", value: "EditSalePolicy", label: "Edit Sale Policy" },
    { name: "ExportSalePolicy", value: "ExportSalePolicy", label: "Export Sale Policy" },
    { name: "ViewMarketing", value: "ViewMarketing", label: "View Marketing" },
    { name: "EditMarketing", value: "EditMarketing", label: "Edit Marketing" },
    { name: "ViewCRM", value: "ViewCRM", label: "View CRM" },
    { name: "ResetPassowrdCRM", value: "ResetPassowrdCRM", label: "Reset Passowrd CRM" },
    { name: "ExportCRM", value: "ExportCRM", label: "Export CRM" },
    { name: "ViewFinance", value: "ViewFinance", label: "View Finance" },
    { name: "ExportFinance", value: "ExportFinance", label: "Export Finance" },
    { name: "ViewClaim", value: "ViewClaim", label: "View Claim" },
    { name: "EditClaim", value: "EditClaim", label: "Edit Claim" },
    { name: "ResetAllUserPassword", value: "ResetAllUserPassword", label: "Reset All User Password" },
    { name: "UserCreation", value: "UserCreation", label: "User Creation" },
    { name: "ViewRole", value: "ViewRole", label: "View Role" },
    { name: "EditRole", value: "EditRole", label: "Edit Role" }
];


export default userSettingConstants;