import adminConstants from './constants';
import Swal from 'sweetalert2';

const initialState = {
    isLoading: false,
    showFail: false,
    email: '',
    password: '',
    showSuccess: false
};


export const reducer = (state, action) => {
    state = state || initialState;

    switch (action.type) {
        case adminConstants.ADMIN_LOGIN_REQUEST:
            return {
                loggingIn: true,
                [state]: undefined,
                isLoading: true
            }
        case adminConstants.ADMIN_LOGIN_SUCCESS:
            return {
                loggedIn: action.data.result.accessToken ? true : false,
                token: action.data.result.accessToken,
                // access: action.data.r.Access,
                accountDetails: {
                    userId: action.data.result.userId
                },
                isLoading: false
            }
        case adminConstants.ADMIN_LOGIN_FAILURE:
            return {
                loggingIn: false,
                invalidLogin: true,
                error: action.error,
                isLoading: false
            }
        //GET ROLE
        case adminConstants.GET_ALL_ROLE_REQUEST:
            return {
                ...state,
                roleList: undefined,
                isLoading: true,
            }
        case adminConstants.GET_ALL_ROLE_SUCCESS:
            return {
                ...state,
                roleList: action.data,
                isLoading: false,
            }
        case adminConstants.GET_ALL_ROLE_FAILURE:
            return {
                ...state,
                roleList: undefined,
                isLoading: true,
            }
        //ADMIN USER LIST
        case adminConstants.GET_ADMIN_USER_LIST_REQUEST:
            return {
                ...state,
                getAdminUserSuccess: false,
                adminUserList: undefined,
                isLoading: true,
            }
        case adminConstants.GET_ADMIN_USER_LIST_SUCCESS:
            return {
                ...state,
                getAdminUserSuccess: true,
                adminUserList: action.data,
                isLoading: false,
            }
        case adminConstants.GET_ADMIN_USER_LIST_FAILURE:
            return {
                ...state,
                getAdminUserSuccess: false,
                adminUserList: undefined,
                isLoading: true,
            }
        //ADMIN REGISTER USER
        case adminConstants.ADMIN_SIGNUP_REQUEST:
            return {
                ...state,
                isLoading: true,
                showFail: false,
                showSuccess: false
            }
        case adminConstants.ADMIN_SIGNUP_SUCCESS:
            return {
                ...state,
                isLoading: false,
                showFail: false,
                showSuccess: true,
                email: action.email
            }
        case adminConstants.ADMIN_SHOW_SIGNUP_FAIL:
            return {
                isLoading: false,
                showFail: true,
                showSuccess: false
            }
        case adminConstants.ADMIN_HIDE_SIGNUP_FAIL:
            return {
                isLoading: false,
                showFail: false,
                showSuccess: false
            }
        //ADMIN DELETE USER
        case adminConstants.ADMIN_DELETE_REQUEST:
            return {
                ...state,
                isLoading: true,
                showFail: false,
                showSuccess: false
            }
        case adminConstants.ADMIN_DELETE_SUCCESS:
            return {
                ...state,
                isLoading: false,
                showFail: false,
                showSuccess: true,
                email: action.email
            }
        case adminConstants.ADMIN_DELETE_FAIL:
            return {
                isLoading: false,
                showFail: true,
                showSuccess: false
            }
        case adminConstants.ADMIN_HIDE_DELETE_FAIL:
            return {
                isLoading: false,
                showFail: false,
                showSuccess: false
            }
        //ADMIN USER DETAILS
        case adminConstants.GET_ADMIN_USER_DETAILS_REQUEST:
            return {
                ...state,
                getAdminUserDetailsSuccess: false,
                adminUserDetails: undefined,
                isLoading: true,
            }
        case adminConstants.GET_ADMIN_USER_DETAILS_SUCCESS:
            return {
                ...state,
                getAdminUserDetailsSuccess: true,
                adminUserDetails: action.data,
                isLoading: false,
            }
        case adminConstants.GET_ADMIN_USER_DETAILS_FAILURE:
            return {
                ...state,
                getAdminUserDetailsSuccess: false,
                adminUserDetails: undefined,
                isLoading: true,
            }
        //ADMIN SET PASSWORD
        case adminConstants.ADMIN_SET_PASSWORD_REQUEST:
            return {
                ...state,
                isLoading: true,
                showFail: false,
                showSuccess: false
            }
        case adminConstants.ADMIN_SET_PASSWORD_SUCCESS:
            return {
                ...state,
                isLoading: false,
                showFail: false,
                showSuccess: true,
                email: action.email
            }
        case adminConstants.ADMIN_SET_PASSWORD_FAIL:
            return {
                isLoading: false,
                showFail: true,
                showSuccess: false
            }
        case adminConstants.ADMIN_HIDE_SET_PASSWORD_FAIL:
            return {
                isLoading: false,
                showFail: false,
                showSuccess: false
            }
        //ADMIN UPDATE USER
        case adminConstants.ADMIN_UPDATE_USER_REQUEST:
            return {
                ...state,
                isLoading: true,
                showFail: false,
                showSuccess: false
            }
        case adminConstants.ADMIN_UPDATE_USER_SUCCESS:
            return {
                ...state,
                isLoading: false,
                showFail: false,
                showSuccess: true,
                email: action.email
            }
        case adminConstants.ADMIN_UPDATE_USER_FAIL:
            return {
                isLoading: false,
                showFail: true,
                showSuccess: false
            }
        case adminConstants.ADMIN_HIDE_UPDATE_USER_FAIL:
            return {
                isLoading: false,
                showFail: false,
                showSuccess: false
            }
        //CHANGE PASSWORD
        case adminConstants.ADMIN_CHANGE_PASSWORD_REQUEST:
            return {
                ...state,
                isLoading: false
            }
        case adminConstants.ADMIN_CHANGE_PASSWORD_SUCCESS:
            Swal.fire(
                'Change Password Success',
                '',
                'success'
            )
            return {
                ...state,
                IsSuccess: true,
                isLoading: false
            }
        case adminConstants.ADMIN_CHANGE_PASSWORD_FAIL:
            //Swal.fire(
            //    'Change Password Fail',
            //    '',
            //    'warning'
            //)
            return {
                ...state,
                isLoading: false
            }
        case adminConstants.ADMIN_SHOW_CHANGE_PASSWORD_FAIL:
            //Swal.fire(
            //    'Change Password Fail',
            //    '',
            //    'warning'
            //)
            return {
                ...state,
                isLoading: false
            }
        //ADMIN FORGOT PASSWORD
        case adminConstants.ADMIN_FORGOT_PWD_REQUEST:
            return {
                ...state,
                isLoading: true,
                showFail: false,
                showSuccess: false
            }
        case adminConstants.ADMIN_FORGOT_PWD_SUCCESS:
            return {
                ...state,
                isLoading: false,
                showFail: false,
                showSuccess: true,
                email: action.email
            }
        case adminConstants.SHOW_ADMIN_FORGOT_PWD_FAIL:
            return {
                isLoading: false,
                showFail: true,
                showSuccess: false
            }
        case adminConstants.HIDE_ADMIN_FORGOT_PWD_FAIL:
            return {
                isLoading: false,
                showFail: false,
                showSuccess: false
            }
        //COMMON PASSWORD
        case adminConstants.GET_COMMON_PASSWORD_SUCCESS:
            return {
                ...state,
                commonPasswordList: action.data,
                isLoading: false
            }
        case adminConstants.GET_COMMON_PASSWORD_FAILURE:
            return {
                ...state,
                isLoading: false
            }
        //ADMIN Reset Password
        case adminConstants.ADMIN_RESET_REQUEST:
            return {
                ...state,
                isLoading: true,
                showFail: false
            }
        case adminConstants.ADMIN_RESET_SUCCESS:
            return {
                ...state,
                isLoading: false,
                showSuccess: true,
                showFail: false,
                email: action.email,
                password: action.password
            }
        case adminConstants.ADMIN_SHOW_RESET_FAIL:
            return {
                isLoading: false,
                showFail: true
            }
        case adminConstants.ADMIN_HIDE_RESET_FAIL:
            return {
                isLoading: false,
                showFail: false
            }
        default:
            return state;
    }
}