import React, { Component } from 'react';
import { Button,Carousel, CarouselCaption, NavLink, CarouselIndicators, CarouselItem,CarouselControl, Container, Row, Col, Card, CardBody } from 'reactstrap';

import facebook from '../../image/Footer/facebook.png';
import instagram from '../../image/Footer/instagram.png';
import whatapps from '../../image/Footer/WhatsApp.png';
import Slider1 from "../../image/Blog/RM1MillionPublicLiabilityToBeorNotToBe1903x500.png";

class BlogDetailsRM1MillionPublicLiability extends Component {
    state = {

    }
    constructor(props) {
        super(props)
   
    }


    componentDidMount() {
        window.scrollTo(0, 0);
    }

    render() {
       
        

        return (
            <div>
                <Row>
                    <Col xs="12" sm="12" lg="12">
                        <div className="blog-image">
                            <img alt="" style={{ height: "500px", width: "100%" }} src={Slider1}>
                            </img>
                        </div>
                        <div className="blog-text">
                            <h1 style={{ fontSize: "50px", color: "white" }}>Does Homeowners Insurance Cover Wildfires? </h1>
                            <br />
                            <br />
                            <p> <img src={'../../assets/img/Insubee_Logo.png'} className="fluid" width="20" height="20" /> &nbsp; Team Insubee</p>
                        </div>
                    </Col>
                </Row>

                <br />
                <Row>
                    <Col xs="12" sm="12" lg="11">
                        <div className="container pt-3" style={{paddingLeft:"8em", paddingRight:"0em"}}>

                            <h5><b>RM 1 Million Public Liability: To Be or Not to Be</b></h5>

                            <p>We’ve all seen it in the movies, the news, the articles of businesses getting sued for a variety of 
                            reasons: a customer slips on the floor and files a lawsuit for negligence, psychological damage for looking at a scary advertisement, failure to provide service as guaranteed and many more.
                            </p>
                            <br />

                            <p>While such lawsuits don’t happen very often in Malaysia, people are increasingly becoming aware of their rights should they fall victim to some harm or 
                            their property gets damaged in a place of business.  
                            </p>
                            <br />

                            <p>Being a business owner, means keeping your eyes on the horizon scanning for potential opportunities or threats to your business, to your operations, 
                            to the livelihood of your minion..err employees, and being ready for them. 
                            </p>
                            <br />

                            <p>And as some of you may well know, the new threat today, the one that lurks behind an innocuous disguise of impossibility,
                            but hits harder than a midlife crisis, is the damage lawsuit. 
                            </p>
                            <br />

                            <h5><b>But Who is Going to Save the Day?</b></h5>
                            <p>For every villain, there’s always a hero, and in this case, Public Liability Insurance is always there to 
                            save the day - provided that he is available, that you bought him.  
                            </p>
                            <br />

                            <p>In case you don’t know, Public Liability Insurance is that hero genetically designed to protect your business when things go wrong, particularly when injury 
                            or damage to a third party or third party’s property happens on your territory. 
                            </p>
                            <br />

                            <p>The issue is, different types of industries, sectors, sizes of operations, come with different risks, which require different types of heroes or rather Public Liability Insurance coverage. 
                            I mean you wouldn’t expect Batman to battle in water better than Aquaman could, would you?
                            </p>
                            <br />

                            <p>Jokes aside, the very nature of public liability being dependent on a variety of factors has led to a surge of different
                            policies that can range from RM10,000 up to RM20 million in coverage.
                            </p>
                            <br />

                            <p>So for small businesses working with a limited budget, it is 
                            a struggle to identify which of the very many options is better suited to their needs. 
                            </p>
                            <br />

                            <h5><b>The RM1 Million Question</b></h5>
                            <p>Business owners will be familiar with the popular idea going around that RM 1 million is the ideal amount to cover all your bases. 
                            </p>
                            <br />

                            <p>The conscientious business owner will ask, “do I truly need this? my business is small. 
                            What’s the point of getting the larger coverage when I can get a cheaper one.”
                            </p>
                            <br />

                            <p>If you are running a lean operation – we’re talking small office, 5 to 10 staff, with little to no geographic presence, then yes, you’d be right,
                            opting for a RM 1 million coverage might be more than you need. 
                            </p>
                            <br />

                            <p>But if you take the same scenario above, and top it with 30 customers a day in foot traffic, then the equation changes - drastically. 
                            </p>
                            <br />

                            <p>Why? Because the potential for injury or damage to property increases. And along with the cost of treating the injury or replacing the damaged property comes the litigation costs which,
                            can set you back RM50k to RM500k or more, depending on the injury, the property and the individual. 
                            </p>
                            <br />

                            <p>When you put that into perspective, having to pay RM500 to RM3000 a year to cover yourself starts to make sense.
                            </p>
                            <br />

                            <p>So, if it applies to you an RM1 million public liability is worth it. However, It is always a good idea to consult with a qualified insurance specialist with a deep understanding of the ins and outs of public liability to guide your decision-making. They will of course charge you a fee, however,
                            the benefit of having a customized plan suited to your needs outweighs the cost in the long run. 
                            </p>
                            <br />

                            <p>Sources: 
                            </p>
                            <p>http://www.acpgconsultant.com/main/3143/index.asp?pageid=122634&t=malaysia-public-liability-insurance,-malaysia-third-party-liability-insurance,-malaysia-renovation-liability-insurance,-malaysia-installation-liability-insurance,-construction-liability-insurance 
                            </p>
                            <p>https://senangpks.com.my/view_article?id=26 
                            </p>
                            <p>https://senangpks.com.my/view_article?id=25 
                            </p>
                            <p>http://blog.treadstonerisk.com/8-factors-that-affect-your-business-liability-insurance-cost 
                            </p>
                            <p>https://www.scatterling.co/blog/how-to-protect-your-business-from-being-sued 
                            </p>


                        </div>
                    </Col>
                        <Col xs="12" sm="12" lg="1">
                        <div class="icon-bar">
                            <p> Share 
                            <a href="https://www.facebook.com/GDEXOfficialPage/"><img src={facebook} width="25px" height="25px" /></a>
                            <a href="https://twitter.com/GDEX_Official/" class="twitter"><img src={instagram} width="25px" height="25px" /></a>
                                <a href="https://www.youtube.com/channel/UCei9LfDrXmLVGdEIfQKHflQ" class="whatapps"><img src={whatapps} width="25px" height="25px" /></a>
                            </p>
                                </div>

                    </Col>
                </Row>

                <br />  
                
            </div>
        )
        
    }
}


export default BlogDetailsRM1MillionPublicLiability;