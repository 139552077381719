const accessConstants = {

    ViewDashboard: "ViewDashboard",
    ViewSalePolicy: "ViewSalePolicy",
    EditSalePolicy: "EditSalePolicy",
    ExportSalePolicy: "ExportSalePolicy",
    ViewMarketing: "ViewMarketing",
    EditMarketing: "EditMarketing",
    ViewCRM: "ViewCRM",
    ResetPassowrdCRM: "ResetPassowrdCRM",
    ExportCRM: "ExportCRM",
    ViewFinance: "ViewFinance",
    ExportFinance: "ExportFinance",
    ViewClaim: "ViewClaim",
    EditClaim: "EditClaim",
    ResetAllUserPassword: "ResetAllUserPassword",
    UserCreation: "UserCreation",
    ViewRole: "ViewRole",
    EditRole: "EditRole",

}

export default accessConstants;