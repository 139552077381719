import React from 'react';
import ResetPasswordPresentation from './ResetPasswordPresentation';



const ResetPassword = props => (
    <ResetPasswordPresentation />
);

export default ResetPassword;
