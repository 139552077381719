import Cookies from 'universal-cookie';
import constants from '../_constants/constants';

const cookies = new Cookies();


// isFile=false ?
export function authHeader() {

    let token = sessionStorage.getItem(constants.tokenName);

    if (token) {
        return {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
        };
    } else {
        return {
            'Content-Type': 'application/json'
        };
    }
}

export function authHeaderFile() {

    let token = sessionStorage.getItem(constants.tokenName);

    if (token) {
        return {
            'Authorization': 'Bearer ' + token
        };
    } else {
        return {};
    }
}

export function authHeaderWithoutUser() {


    return {
        'Content-Type': 'application/json'
    };

}