import ProductConstants from './constants';
import constants from '../_constants/constants';
import ProductServices from "./services";
import Swal from 'sweetalert2';
import { push } from 'connected-react-router';


// redux/actions.js
const actions = {
    getLocationByFromPostcode,
    getCorrespondanceLocationByFromPostcode,
    getMasterEntry,
    selectPlan,
    getStateHoliday,
    createPolicy,
    UserCreatePolicy,
    GuestCreatePolicy,
    UserCalculateDiscountVoucher,
    GuestCalculateDiscountVoucher
}


function getCorrespondanceLocationByFromPostcode(postcode) {

    return dispatch => {
        dispatch(request(postcode));
        ProductServices.getLocationByFromPostcode(postcode).then(
            data => {
                dispatch(success(data.result));
            },
            error => {
                dispatch(failure(error));
                Swal.fire({
                    title: 'Invalid Postcode',
                    message: '',
                    type: 'error',
                });
            }
        )
    }

    function request(data) { return { type: ProductConstants.GET_FROMCORRSLOCATION_REQUEST, data } }
    function success(data) { return { type: ProductConstants.GET_FROMCORRSLOCATION_SUCCESS, data } }
    function failure(error) { return { type: ProductConstants.GET_FROMCORRSLOCATION_FAILURE, error } }

}

function getLocationByFromPostcode(postcode) {

    return dispatch => {
        dispatch(request(postcode));
        ProductServices.getLocationByFromPostcode(postcode).then(
            data => {
                dispatch(success(data.result));
            },
            error => {
                dispatch(failure(error));
                Swal.fire({
                    title: 'Invalid Postcode',
                    message: '',
                    type: 'error',
                });
            }
        )
    }

    function request(data) { return { type: ProductConstants.GET_FROMLOCATION_REQUEST, data } }
    function success(data) { return { type: ProductConstants.GET_FROMLOCATION_SUCCESS, data } }
    function failure(error) { return { type: ProductConstants.GET_FROMLOCATION_FAILURE, error } }

}

function getStateHoliday() {

    return dispatch => {
        dispatch(request());
        ProductServices.getStateHoliday().then(
            data => {
                dispatch(success(data.result));
            },
            error => {
                dispatch(failure(error.errorMessage));
            }
        )
    }
    function request(data) { return { type: ProductConstants.GET_STATEHOLIDAY_REQUEST, data } }
    function success(data) { return { type: ProductConstants.GET_STATEHOLIDAY_SUCCESS, data } }
    function failure(error) { return { type: ProductConstants.GET_STATEHOLIDAY_FAILURE, error } }

}


function getMasterEntry() {

    return dispatch => {
        dispatch(request());
        ProductServices.getMasterEntry().then(
            data => {
                dispatch(success(data.result));
            },
            error => {
                dispatch(failure(error.errorMessage));
            }
        )
    }
    function request(data) { return { type: ProductConstants.GET_MASTERENTRY_REQUEST, data } }
    function success(data) { return { type: ProductConstants.GET_MASTERENTRY_SUCCESS, data } }
    function failure(error) { return { type: ProductConstants.GET_MASTERENTRY_FAILURE, error } }

}


function selectPlan(QuoteListModel) {

    return dispatch => {
        dispatch(request(QuoteListModel));
        ProductServices.selectPlan(QuoteListModel).then(
            data => {
                if (data.status == "success") {
                    dispatch(success(data.result));
                }
            },
            error => {

                if (error.statusCode == 5) {
                    let string = error.errorMessage;
                    let substring = "CBC submission";
                    if (string.includes(substring)) {
                        Swal.fire({
                            title: string,
                            message: '',
                            type: 'error',
                        });

                        dispatch(fail(error));
                    }
                    else {
                        dispatch(fail(error));
                    }
                }

                else {
                    dispatch(showFail(error.errorMessage));
                    dispatch(fail(error));
                    Swal.fire({
                        title: error.errorMessage,
                        message: '',
                        type: 'error',
                    });
                }
            }
        )
    }

    function request(data) { return { type: ProductConstants.PRODUCT_GETQUOTE_REQUEST, data } }
    function success(data) { return { type: ProductConstants.PRODUCT_GETQUOTE_SUCCESS, data } }
    function fail(error) { return { type: ProductConstants.PRODUCT_GETQUOTE_FAIL, error } }
    function showFail(error) { return { type: ProductConstants.SHOW_GETQUOTE_FAIL, error } }

}


function createPolicy(Result) {

    return dispatch => {
        //dispatch(request(Result));
        ProductServices.createPolicy(Result).then(
            data => {
                dispatch(success(data.result));
                dispatch(push("/payment"));
            },
            error => {
                dispatch(fail(error));
            }

        )
    }

    //function request(data) { return { type: ProductConstants.PRODUCT_CREATEPOLICY_REQUEST, data } }
    function success(data) { return { type: ProductConstants.PRODUCT_CREATEPOLICY_SUCCESS, data } }
    function fail(error) { return { type: ProductConstants.PRODUCT_CREATEPOLICY_FAIL, error } }

}


function UserCreatePolicy(Result) {

    return dispatch => {
        //dispatch(request(Result));
        dispatch(request(Result));
        ProductServices.UserCreatePolicy(Result).then(
            data => {
                dispatch(success(data.result));
                if (data.result.customerPolicyId) {

                    let customerPolicyIDJSONString = JSON.stringify(data.result.customerPolicyId);
                    sessionStorage.setItem('CustomerPolicyID', customerPolicyIDJSONString);

                }

                if (data.status = "success") {
                    window.sessionStorage.removeItem("quotingReferenceId");
                }


                if (data.result.remark == "Development") {

                    var formdata = new FormData();
                    formdata.append("RefNo", data.result.refNo);
                    formdata.append("PaymentId", data.result.paymentId);
                    formdata.append("Amount", data.result.amount);
                    formdata.append("Status", data.result.status);
                    formdata.append("Signature", data.result.signature);
                    formdata.append("Remark", data.result.remark);
                    formdata.append("TransId", data.result.transId);

                    var requestOptions = {
                        method: 'POST',
                        //headers: myHeaders,
                        body: formdata,
                        redirect: 'follow'
                    };

                    fetch(data.result.responseURL, requestOptions)
                        .then(response => { window.location.href = response.url; })
                        //.then(result => console.log(result))
                        .catch(error => console.log('error', error));
                }

                else {
                    dispatch(push("/payment"));
                }
            },
            error => {
                dispatch(fail(error));
                Swal.fire({
                    title: 'Error',
                    message: '',
                    text: error.errorMessage,
                    type: 'error'
                });
            }

        )
    }
    function request(data) { return { type: ProductConstants.PRODUCT_USERCREATEPOLICY_REQUEST, data } }
    function success(data) { return { type: ProductConstants.PRODUCT_USERCREATEPOLICY_SUCCESS, data } }
    function fail(error) { return { type: ProductConstants.PRODUCT_USERCREATEPOLICY_FAIL, error } }

}


function GuestCreatePolicy(Result) {

    return dispatch => {
        //dispatch(request(Result));
        dispatch(request(Result));
        ProductServices.GuestCreatePolicy(Result).then(
            data => {
                dispatch(success(data.result));
                if (data.result.customerPolicyId) {

                    let customerPolicyIDJSONString = JSON.stringify(data.result.customerPolicyId);
                    sessionStorage.setItem('CustomerPolicyID', customerPolicyIDJSONString);

                }

                if (data.status = "success") {
                    window.sessionStorage.removeItem("quotingReferenceId");
                }

                if (data.result.remark == "Development") {

                    var formdata = new FormData();
                    formdata.append("RefNo", data.result.refNo);
                    formdata.append("PaymentId", data.result.paymentId);
                    formdata.append("Amount", data.result.amount);
                    formdata.append("Status", data.result.status);
                    formdata.append("Signature", data.result.signature);
                    formdata.append("Remark", data.result.remark);
                    formdata.append("TransId", data.result.transId);

                    var requestOptions = {
                        method: 'POST',
                        //headers: myHeaders,
                        body: formdata,
                        redirect: 'follow'
                    };

                    fetch(data.result.responseURL, requestOptions)
                        .then(response => { window.location.href = response.url; })
                        //.then(result => console.log(result))
                        .catch(error => console.log('error', error));
                }

                else {
                    dispatch(push("/payment"));
                }
            },
            error => {
                dispatch(fail(error));
                Swal.fire({
                    title: 'Error',
                    message: '',
                    text: error.errorMessage,
                    type: 'error'
                });
            }

        )
    }

    function request(data) { return { type: ProductConstants.PRODUCT_GUESTCREATEPOLICY_REQUEST, data } }
    function success(data) { return { type: ProductConstants.PRODUCT_GUESTCREATEPOLICY_SUCCESS, data } }
    function fail(error) { return { type: ProductConstants.PRODUCT_GUESTCREATEPOLICY_FAIL, error } }

}


function UserCalculateDiscountVoucher(Result) {

    return dispatch => {
        //dispatch(request(Result));
        ProductServices.UserCalculateDiscountVoucher(Result).then(
            data => {
                dispatch(success(data.result));
                //dispatch(push("/payment"));
            },
            error => {
                dispatch(fail(error));
                Swal.fire({
                    title: 'Error',
                    message: '',
                    text: error.errorMessage,
                    type: 'error'
                });
            }

        )
    }

    function success(data) { return { type: ProductConstants.PRODUCT_USERCALCULATEDISCOUNTVOUCHER_SUCCESS, data } }
    function fail(error) { return { type: ProductConstants.PRODUCT_USERCALCULATEDISCOUNTVOUCHER_FAIL, error } }

}



function GuestCalculateDiscountVoucher(Result) {

    return dispatch => {
        //dispatch(request(Result));
        ProductServices.GuestCalculateDiscountVoucher(Result).then(
            data => {
                dispatch(success(data.result));
            },
            error => {
                dispatch(fail(error));

                Swal.fire({
                    title: 'Error',
                    message: '',
                    text: error.errorMessage,
                    type: 'error'
                });
            }

        )
    }

    function success(data) { return { type: ProductConstants.PRODUCT_GUESTCALCULATEDISCOUNTVOUCHER_SUCCESS, data } }
    function fail(error) { return { type: ProductConstants.PRODUCT_GUESTCALCULATEDISCOUNTVOUCHER_FAIL, error } }

}
export default (actions);