import React from 'react';

import ChangePasswordPresentation from './ChangePasswordPresentation';



const ChangePassword = props => (
    <ChangePasswordPresentation {...props} />
);

export default ChangePassword;