import React, { Component } from 'react';
import { Button, FormGroup, Label, Row, Col, Card, CardBody } from 'reactstrap';
import { Field, reduxForm } from 'redux-form';
import ValidateField from '../GlobalComponents/FormElements/ValidateField';
import { required, email } from '../GlobalComponents/ValidationRules';

class ForgotPasswordForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }

    componentDidMount() {
        window.scrollTo(0, 0);
    }

    render() {
        const { handleSubmit, change, goResetPassword } = this.props;

        return (
            <form onSubmit={handleSubmit} >
                <div className="my-5">
                    <div className="container">
                        <Row className="text-center">
                            <Col xs="12" sm="12" lg="12">
                                <div className="col-sm-12">
                                    <h2 className="font-weight-bold">Forgotten My Password</h2>
                                </div>
                                <div className="row">
                                    <Card className="col-sm-12 border-0">
                                        <CardBody>
                                            <Row form className="text-left justify-content-center">
                                                <Col sm={6}>
                                                    <FormGroup>
                                                        <Label className="col-sm-12">
                                                            Email
                                                        </Label>
                                                        <Col sm={12}>
                                                            <Field className="form-control" placeholder="" name="email" label="Email" component={ValidateField} validate={[required, email]} type="text" />
                                                        </Col>
                                                    </FormGroup>
                                                    <FormGroup>
                                                        <Col sm={6} style={{ float: 'right' }}>
                                                            <Button color="warning" style={{ width: '100%' }} className="btn font-weight-bold btn-sm btn-insubee-orange" size="sm">Reset Password</Button>
                                                        </Col>
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                            
                                        </CardBody>
                                    </Card>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </div>
            </form>

            )
    }
}


ForgotPasswordForm = reduxForm({
    // a unique name for the form
    form: 'ForgotPasswordForm',
    destroyOnUnmount: true,
    initialValues: {

    }
})(ForgotPasswordForm)

export default ForgotPasswordForm;

