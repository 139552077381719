////import React, { Fragment } from "react";
import React, { Fragment,useState, useEffect } from "react";
import { useTable, useSortBy, useFilters, usePagination, useRowSelect } from 'react-table';
import { Table, Row, Col, Button, Input, CustomInput } from 'reactstrap';
import { Filter, DefaultColumnFilter } from './filters';




export default function TableContainer({ columns, data, selectedRows, setSelectedRows, HandleRow }) {
    // Use the state and functions returned from useTable to build your UI
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        page,
        prepareRow,
        //        // below new props related to 'usePagination' hook
        canPreviousPage,
        canNextPage,
        pageOptions,
        pageCount,
        gotoPage,
        nextPage,
        previousPage,
        setPageSize,
        state: { pageIndex, pageSize },
        selectedFlatRows,
        state: { selectedRowPaths }
    } = useTable(
        {
            columns,
            data,
            defaultColumn: { Filter: DefaultColumnFilter },
            initialState: {
                pageIndex: 0, 
                pageSize: 10,
            },
            state: {
                selectedRowPaths: selectedRows
            }
        },
        useFilters,
        useSortBy,
        usePagination,
        usePagination,
        useRowSelect
    );

    const generateSortingIndicator = column => {
        return column.isSorted ? (column.isSortedDesc ? " 🔽" : " 🔼") : ""
    }

    const onChangeInSelect = event => {
        setPageSize(Number(event.target.value))
    }

    const onChangeInInput = event => {
        const page = event.target.value ? Number(event.target.value) - 1 : 0
        gotoPage(page)
    }
    // Render the UI for your table
    return (
        <>
            <Fragment>
                <Table hover {...getTableProps()} className="table table-responsive table-bordered w-100 d-block d-md-table">
                <thead>
                        {
                            //should do slice 1 to skip first headerGroup??
                            headerGroups.map(headerGroup => (
                            <tr {...headerGroup.getHeaderGroupProps()}>
                                {headerGroup.headers.map((column, i) => {
                                    
                                    return column.hideHeader === true ? null : (
                                        <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                                            <div {...column.getSortByToggleProps()}>
                                                {column.render("Header")}
                                                {generateSortingIndicator(column)}
                                            </div>
                                            <Filter column={column} />
                                        </th>
                                    );
                                })}
                            </tr>
                        ))}
                </thead>
                <tbody {...getTableBodyProps()} >
                    {page.map((row) => {
                        prepareRow(row);
                        return (
                            <Fragment key={row.getRowProps().key}>
                                <tr onClick={() => HandleRow(row.original.customerClaimId)}>
                                    {row.cells.map((cell) => {
                                        return (
                                            <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                                        );
                                    })}
                                </tr>
                            </Fragment>
                        );
                    })}
                </tbody>
                </Table>
                <Row style={{ maxWidth: 1000, margin: "0 auto", textAlign: "center" }}>
                <Col md={3}>
                    <Button
                        color="primary"
                        onClick={() => gotoPage(0)}
                        disabled={!canPreviousPage}
                    >
                        {"<<"}
                    </Button>
                    <Button
                        color="primary"
                        onClick={previousPage}
                        disabled={!canPreviousPage}
                    >
                        {"<"}
                    </Button>
                </Col>
                <Col md={2} style={{ marginTop: 7 }}>
                    Page{" "}
                    <strong>
                        {pageIndex + 1} of {pageOptions.length}
                    </strong>
                </Col>
                <Col md={2}>
                    <Input
                        type="number"
                        min={1}
                        style={{ width: 70 }}
                        max={pageOptions.length}
                        defaultValue={pageIndex + 1}
                        onChange={onChangeInInput}
                    />
                </Col>
                <Col md={2}>
                    <CustomInput type="select" id="pageSizeSelect" value={pageSize} onChange={onChangeInSelect}>
                       {[10, 20, 30, 40, 50].map(pageSize => (
                            <option key={pageSize} value={pageSize}>
                                Show {pageSize}
                            </option>
                        ))}
                    </CustomInput>
                </Col>
                <Col md={3}>
                    <Button color="primary" onClick={nextPage} disabled={!canNextPage}>
                        {">"}
                    </Button>
                    <Button
                        color="primary"
                        onClick={() => gotoPage(pageCount - 1)}
                        disabled={!canNextPage}
                    >
                        {">>"}
                    </Button>
                </Col>
            </Row>
            </Fragment>
            
        </>
    );
}

//const TableContainer = ({ columns, data }) => {

//    const {
//        getTableProps,
//        getTableBodyProps,
//        headerGroups,
//        /*rows,*/
//        page,
//        prepareRow,
//        // below new props related to 'usePagination' hook
//        canPreviousPage,
//        canNextPage,
//        pageOptions,
//        pageCount,
//        gotoPage,
//        nextPage,
//        previousPage,
//        setPageSize,
//        state: { pageIndex, pageSize }
//    } = useTable(
//        {
//            columns,
//            data,
//            defaultColumn: { Filter: DefaultColumnFilter },
//            initialState: { pageIndex: 0, pageSize: 1 }
//        },
//        useFilters,
//        useSortBy,
//        usePagination
//    );

//    const generateSortingIndicator = column => {
//        return column.isSorted ? (column.isSortedDesc ? " 🔽" : " 🔼") : ""
//    }

//    const onChangeInSelect = event => {
//        setPageSize(Number(event.target.value))
//    }

//    const onChangeInInput = event => {
//        const page = event.target.value ? Number(event.target.value) - 1 : 0
//        gotoPage(page)
//    }

//    const IndeterminateCheckbox = React.forwardRef(
//        ({ indeterminate, ...rest }, ref) => {
//            const defaultRef = React.useRef()
//            const resolvedRef = ref || defaultRef

//            React.useEffect(() => {
//                resolvedRef.current.indeterminate = indeterminate
//            }, [resolvedRef, indeterminate])

//            return (
//                <>
//                    <input type="checkbox" ref={resolvedRef} {...rest} />
//                </>
//            )
//        }
//    )

//    return (
//        <Fragment>
//            {/*<div class="table-responsive">*/}

//            <Table hover {...getTableProps()}  className="table table-responsive table-bordered w-100 d-block d-md-table">
//                <thead>
//                    {
//                        headerGroups.map(headerGroup => (
//                            <tr {...headerGroup.getHeaderGroupProps()}>
//                                {headerGroup.headers.map((column) => {
//                                    return column.hideHeader === true ? null : (
//                                        <th {...column.getHeaderProps(column.getSortByToggleProps())}>
//                                            <div {...column.getSortByToggleProps()}>
//                                                {column.render("Header")}
//                                                {generateSortingIndicator(column)}
//                                            </div>
//                                            <Filter column={column} />
//                                        </th>
//                                    );
//                                })}
//                            </tr>
//                        ))}
//                </thead>
//                <tbody {...getTableBodyProps()} >
//                    {page.map((row) => {
//                        prepareRow(row);
//                        return (
//                            <Fragment key={row.getRowProps().key}>
//                                <tr>
//                                    {row.cells.map((cell) => {
//                                        return (
//                                            <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
//                                        );
//                                    })}
//                                </tr>
//                            </Fragment>
//                        );
//                    })}
//                </tbody>
//                </Table>
//            {/*</div>*/}
//            <Row style={{ maxWidth: 1000, margin: "0 auto", textAlign: "center" }}>
//                <Col md={3}>
//                    <Button
//                        color="primary"
//                        onClick={() => gotoPage(0)}
//                        disabled={!canPreviousPage}
//                    >
//                        {"<<"}
//                    </Button>
//                    <Button
//                        color="primary"
//                        onClick={previousPage}
//                        disabled={!canPreviousPage}
//                    >
//                        {"<"}
//                    </Button>
//                </Col>
//                <Col md={2} style={{ marginTop: 7 }}>
//                    Page{" "}
//                    <strong>
//                        {pageIndex + 1} of {pageOptions.length}
//                    </strong>
//                </Col>
//                <Col md={2}>
//                    <Input
//                        type="number"
//                        min={1}
//                        style={{ width: 70 }}
//                        max={pageOptions.length}
//                        defaultValue={pageIndex + 1}
//                        onChange={onChangeInInput}
//                    />
//                </Col>
//                <Col md={2}>
//                    <CustomInput type="select" id="pageSizeSelect" value={pageSize} onChange={onChangeInSelect}>
//                        {[10, 20, 30, 40, 50].map(pageSize => (
//                            <option key={pageSize} value={pageSize}>
//                                Show {pageSize}
//                            </option>
//                        ))}
//                    </CustomInput>
//                </Col>
//                <Col md={3}>
//                    <Button color="primary" onClick={nextPage} disabled={!canNextPage}>
//                        {">"}
//                    </Button>
//                    <Button
//                        color="primary"
//                        onClick={() => gotoPage(pageCount - 1)}
//                        disabled={!canNextPage}
//                    >
//                        {">>"}
//                    </Button>
//                </Col>
//            </Row>
//        </Fragment>
        
//    );
//};

//export default TableContainer;