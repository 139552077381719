import ProductConstants from './constants';

const initialState = {
    showSuccess: false,
};


export const reducer = (state, action) => {
    state = state || initialState;

    switch (action.type) {
        //LOCATION
        case ProductConstants.GET_FROMLOCATION_REQUEST:
            return {
                ...state,
                locationList: undefined,
                isLoading: true,
            }
        case ProductConstants.GET_FROMLOCATION_SUCCESS:
            return {
                ...state,
                locationList: action.data,
                isLoading: false,
            }

        //Correspondance LOCATION
        case ProductConstants.GET_FROMCORRSLOCATION_REQUEST:
            return {
                ...state,
                CorrespondancelocationList: undefined,
                isLoading: true,
            }
        case ProductConstants.GET_FROMCORRSLOCATION_SUCCESS:
            return {
                ...state,
                CorrespondancelocationList: action.data,
                isLoading: false,
            }

        //MasterEntry
        case ProductConstants.GET_MASTERENTRY_REQUEST:
            return {
                ...state,
                MasterEntryList: undefined,
                isLoading: true,
            }
        case ProductConstants.GET_MASTERENTRY_SUCCESS:
            return {
                ...state,
                MasterEntryList: action.data,
                isLoading: false,
            }
        case ProductConstants.GET_MASTERENTRY_FAILURE:
            return {
                ...state,
                isLoading: false
            }

        //State Holiday
        case ProductConstants.GET_STATEHOLIDAY_REQUEST:
            return {
                ...state,
                StateHolidayList: undefined,
                isLoading: true,
            }
        case ProductConstants.GET_STATEHOLIDAY_SUCCESS:
            return {
                ...state,
                StateHolidayList: action.data,
                isLoading: false,
            }
        case ProductConstants.GET_STATEHOLIDAY_FAILURE:
            return {
                ...state,
                isLoading: false
            }

        //GetQuote 
        case ProductConstants.PRODUCT_GETQUOTE_REQUEST:
            return {
                ...state,
                QuoteResult: undefined,
                isLoading: true,
                showFail: false,
                Discount: undefined,
                ErrorStatus: undefined,
            }
        case ProductConstants.PRODUCT_GETQUOTE_SUCCESS:
            return {
                ...state,
                QuoteResult: action.data,
                isLoading: false,
                showFail: false,
                Discount: undefined,
                ErrorStatus: undefined,
            }
        case ProductConstants.PRODUCT_GETQUOTE_FAIL:
            return {
                ...state,
                isLoading: false,
                showFail: true,
                ErrorStatus: action.error,
            }
        case ProductConstants.SHOW_GETQUOTE_FAIL:
            return {
                isLoading: false,
                showFail: true
            }
        case ProductConstants.HIDE_GETQUOTE_FAIL:
            return {
                isLoading: false,
                showFail: false
            }



        case ProductConstants.PRODUCT_CREATEPOLICY_SUCCESS:
            return {
                ...state,
                Request: action.data,
                isLoading: false,
                showSuccess: true,
                Discount: undefined,
            }
        case ProductConstants.PRODUCT_CREATEPOLICY_FAIL:
            return {
                isLoading: false,
                showSuccess: false,
            }


        //UserCreatePolicy
        case ProductConstants.PRODUCT_USERCREATEPOLICY_REQUEST:
            return {
                ...state,
                Request: undefined,
                isLoading: true,
                showSuccess: false,
                Discount: undefined,
            }
        case ProductConstants.PRODUCT_USERCREATEPOLICY_SUCCESS:
            return {
                ...state,
                Request: action.data,
                isLoading: true,
                showSuccess: true,
                Discount: undefined,
            }
        case ProductConstants.PRODUCT_USERCREATEPOLICY_FAIL:
            return {
                isLoading: null,
                showSuccess: false,
            }


        //GUESTCreatePolicy
        case ProductConstants.PRODUCT_GUESTCREATEPOLICY_REQUEST:
            return {
                ...state,
                Request: undefined,
                isLoading: true,
                showSuccess: false,
                Discount: undefined,
            }
        case ProductConstants.PRODUCT_GUESTCREATEPOLICY_SUCCESS:
            return {
                ...state,
                Request: action.data,
                isLoading: true,
                showSuccess: true,
                Discount: undefined,
            }
        case ProductConstants.PRODUCT_GUESTCREATEPOLICY_FAIL:
            return {
                isLoading: false,
                showSuccess: false,
            }


        //UserCalculateDiscountVoucher
        case ProductConstants.PRODUCT_USERCALCULATEDISCOUNTVOUCHER_SUCCESS:
            return {
                ...state,
                Discount: action.data,
                isLoading: false,
                showSuccess: true
            }
        case ProductConstants.PRODUCT_USERCALCULATEDISCOUNTVOUCHER_FAIL:
            return {
                isLoading: false,
                showSuccess: false,
            }



        //GuestCalculateDiscountVoucher
        case ProductConstants.PRODUCT_GUESTCALCULATEDISCOUNTVOUCHER_SUCCESS:
            return {
                ...state,
                Discount: action.data,
                isLoading: false,
                showSuccess: true,
                DiscountFailed: false
            }
        case ProductConstants.PRODUCT_GUESTCALCULATEDISCOUNTVOUCHER_FAIL:
            return {
                isLoading: false,
                showSuccess: false,
                DiscountFailed: true
            }

        default:
            return state;
    }
}