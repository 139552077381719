const policyConstants = {

    GET_POLICY_REQUEST: "GET_POLICY_REQUEST",
    GET_POLICY_SUCCESS: "GET_POLICY_SUCCESS",
    GET_POLICY_FAILURE: "GET_POLICY_FAILURE",

    GET_POLICY_DETAILS_REQUEST: "GET_POLICY_DETAILS_REQUEST",
    GET_POLICY_DETAILS_SUCCESS: "GET_POLICY_DETAILS_SUCCESS",
    GET_POLICY_DETAILS_FAILURE: "GET_POLICY_DETAILS_FAILURE",

    DOWNLOAD_POLICY_REQUEST: "DOWNLOAD_POLICY_REQUEST",
    DOWNLOAD_POLICY_SUCCESS: "DOWNLOAD_POLICY_SUCCESS",
    DOWNLOAD_POLICY_FAILURE: "DOWNLOAD_POLICY_FAILURE",
}

export default policyConstants;