import React, { Fragment, useState, useEffect } from "react";
import { useTable, useSortBy, useFilters, usePagination, useRowSelect } from 'react-table';
import { Table, Row, Col, Button, Input, CustomInput } from 'reactstrap';
import { Filter, DefaultColumnFilter } from './filters';
import 'bootstrap/dist/css/bootstrap.min.css';
import styled from 'styled-components';

export default function TableContainer({ columns, data, selectedRows, setSelectedRows,
    pageCount: controlledPageCount,
    fetchDataPageSize, fetchDataPageIndex, triggerChangePageSize, pageSize, totalItems }) {

    //const [dataRows, setData] = useState(data);
    //const [skipPageReset, setSkipPageReset] = useState(false);

    const Styles = styled.div`
  /* This is required to make the table full-width */
  display: block;
  max-width: 100%;

  /* This will make the table scrollable when it gets too small */
  .tableWrap {
    display: block;
    max-width: 100%;
    overflow-x: scroll;
    overflow-y: hidden;
    border-bottom: 1px solid black;
  }

  table {
    /* Make sure the inner table is always as wide as needed */
    width: 100%;
    border-spacing: 0;

    tr {
      :last-child {
        td {
          border-bottom: 0;
        }
      }
    }

    th,
    td {
      margin: 0;
      padding: 0.5rem;
      border-bottom: 1px solid black;
      border-right: 1px solid black;

      /* The secret sauce */
      /* Each cell should grow equally */
      width: 1%;
      /* But "collapsed" cells should be as small as possible */
      &.collapse {
        width: 0.0000000001%;
      }

      :last-child {
        border-right: 0;
      }
    }
  }

  .pagination {
    padding: 0.5rem;
  }
`

    // Editable cell code
    //const updateMyData = (rowIndex, columnId, value) => {
    //    // We also turn on the flag to not reset the page
    //    setSkipPageReset(true);
    //    setData(old =>
    //        old.map((row, index) => {
    //            if (index === rowIndex) {
    //                return {
    //                    ...old[rowIndex],
    //                    [columnId]: value,
    //                };
    //            }
    //            return row;
    //        })
    //    );
    //};

    // Create an editable cell renderer
    //const EditableCell = ({
    //    value: initialValue,
    //    row: { index },
    //    column: { id },
    //    updateMyData, // This is a custom function that we supplied to our table instance
    //}) => {
    //    // We need to keep and update the state of the cell normally
    //    const [value, setValue] = useState(initialValue);


    //    const onChange = e => {
    //        setValue(e.target.value);
    //    };

    //    // We'll only update the external data when the input is blurred
    //    const onBlur = () => {
    //        updateMyData(index, id, value);
    //    };

    //    // If the initialValue is changed external, sync it up with our state
    //    useEffect(() => {
    //        setValue(initialValue);
    //    }, [initialValue]);

    //    // Check to make sure not all columns are editable
    //    if (id === "policy_no") {
    //        //if (initialValue === '') {
    //            return <input value={value} onChange={onChange} onBlur={onBlur} />;
    //        //}

    //    }
    //    return value;
    //};

    // Set our editable cell renderer as the default Cell renderer
    //const defaultColumn = {
    //    Cell: EditableCell,
    //};


    //useEffect(() => {
    //    setSkipPageReset(false);
    //    console.log(dataRows);
    //}, [dataRows]);

    // Use the state and functions returned from useTable to build your UI
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        page,
        prepareRow,
        //        // below new props related to 'usePagination' hook
        canPreviousPage,
        canNextPage,
        pageOptions,
        pageCount,
        gotoPage,
        nextPage,
        previousPage,
        setPageSize,
        state: { pageIndex, selectedRowPaths },
        selectedFlatRows,
    } = useTable(
        {
            columns,
            data,
            //autoResetPage: !skipPageReset,
            //updateMyData,
            //defaultColumn: { Filter: DefaultColumnFilter },
            //defaultColumn,
            initialState: {
                pageIndex: 0, pageSize: pageSize
            },
            manualPagination: true,
            autoResetPage: false,
            pageCount: controlledPageCount,
        },
        useFilters,
        useSortBy,
        usePagination,
        usePagination,
        useRowSelect,

    );


    const generateSortingIndicator = column => {
        return column.isSorted ? (column.isSortedDesc ? " 🔽" : " 🔼") : ""
    }

    const onChangeInSelect = event => {
        setPageSize(Number(event.target.value))
        triggerChangePageSize(Number(event.target.value))
    }

    const onChangeInInput = event => {
        const page = event.target.value ? Number(event.target.value) - 1 : 0
        gotoPage(page)
    }

    // Listen for changes in pagination and use the state to fetch our new data
    React.useEffect(() => {
        /*console.log(pageIndex);*/
        fetchDataPageIndex(pageIndex)
    }, [pageIndex])

    // Listen for changes in pagination and use the state to fetch our new data
    React.useEffect(() => {
        //console.log(pageSize);
        fetchDataPageSize(pageSize)
    }, [pageSize])


    // Render the UI for your table
    return (
        <>
            <Fragment>
                {/*<Styles>*/}
                <Row>
                    <div className="col-sm-12">Total of records : {totalItems} </div>
                </Row>
                <Table hover {...getTableProps()} className="table table-responsive table-bordered d-sm-table">
                    <thead>
                        {
                            //should do slice 1 to skip first headerGroup??
                            headerGroups.map(headerGroup => (
                                <tr {...headerGroup.getHeaderGroupProps()}>
                                    {headerGroup.headers.map((column, i) => {

                                        return column.hideHeader === true ? null : (
                                            <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                                                <div {...column.getSortByToggleProps()}>
                                                    {column.render("Header")}
                                                    {generateSortingIndicator(column)}
                                                </div>
                                                <Filter column={column} />
                                            </th>
                                        );
                                    })}
                                </tr>
                            ))}
                    </thead>
                    <tbody {...getTableBodyProps()} >
                        {page.map((row) => {
                            prepareRow(row);
                            return (
                                <Fragment key={row.getRowProps().key}>
                                    <tr>
                                        {row.cells.map((cell) => {
                                            return (
                                                <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                                            );
                                        })}
                                    </tr>
                                </Fragment>
                            );
                        })}
                    </tbody>
                </Table>
                {/*</Styles>*/}
                <Row style={{ maxWidth: 1000, margin: "0 auto", textAlign: "center" }}>
                    <Col md={3}>
                        <Button
                            color="primary"
                            onClick={() => gotoPage(0)}
                            disabled={!canPreviousPage}
                        >
                            {"<<"}
                        </Button>
                        <Button
                            className="ml-1"
                            color="primary"
                            onClick={() => gotoPage(pageIndex - 1)}
                            disabled={!canPreviousPage}
                        >
                            {"<"}
                        </Button>
                    </Col>
                    <Col md={2} style={{ marginTop: 7 }}>
                        Page{" "}
                        <strong>
                            {pageIndex + 1} of {controlledPageCount}
                        </strong>
                    </Col>
                    <Col md={2}>
                        <Input
                            type="number"
                            min={1}
                            style={{ width: 70 }}
                            max={pageOptions.length}
                            defaultValue={pageIndex + 1}
                            onChange={onChangeInInput}
                        />
                    </Col>
                    <Col md={2}>
                        <CustomInput type="select" id="pageSizeSelect" value={pageSize} onChange={onChangeInSelect}>
                            {[25, 50, 75, 100, 500].map(pageSize => (
                                <option key={pageSize} value={pageSize}>
                                    Show {pageSize}
                                </option>
                            ))}
                        </CustomInput>
                    </Col>
                    <Col md={3}>
                        <Button color="primary" onClick={() => gotoPage(pageIndex + 1)} disabled={!canNextPage}>
                            {">"}
                        </Button>
                        <Button
                            color="primary"
                            className="ml-1"
                            onClick={() => gotoPage(pageCount - 1)}
                            disabled={!canNextPage}
                        >
                            {">>"}
                        </Button>
                    </Col>
                </Row>
            </Fragment>

        </>
    );
}