import { connect } from 'react-redux';
import ProductSelectedPlanPresentation from './ProductSelectedPlanPresentation';
import actions from './actions'


function mapStateToProps(state) {

    const { router, product } = state || {}; // entire Redux store state (the same value returned by a call to store.getState()).

    return {
        router,
        product 
    }
}

function mapDispatchToProps(dispatch) {
    return {
        selectPlan: (content) => { dispatch(actions.selectPlan(content)) },
    }
}

const ProductSelectedPlan = connect(mapStateToProps, mapDispatchToProps)(ProductSelectedPlanPresentation);

export default ProductSelectedPlan;
