import React from 'react';
import { connect } from 'react-redux'
import actions from './actions'
import SweetAlert from 'sweetalert2-react';
import { Form, Button, Label, FormGroup } from 'reactstrap'

import { Field, reduxForm, FormSection } from 'redux-form'

import ForgotPasswordForm from './ForgotPasswordForm';

class ForgotPasswordPresentation extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            email: '',
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.goResetPassword = this.goResetPassword.bind(this);
    }

    handleChange(event) {
        this.setState({ email: event.target.value });
    }

    goResetPassword() {
        this.props.history.push('/ResetPassword');
    }

    handleSubmit(forgotModel) {
        var forgotObj = {
            Email: forgotModel.email
        }
        this.props.forgotpassword(forgotObj);
    }

    componentDidMount() {
        window.scrollTo(0, 0);

    }

    render() {

        const { message, hideForgotPasswordFail, goResetPassword } = this.props;

        return (
                <ForgotPasswordForm
                onSubmit={this.handleSubmit}
                goResetPassword={this.goResetPassword}
                />
        );
    }
}

const mapDispatchToProps = dispatch => {
    return {
        forgotpassword: (content) => {

            dispatch(actions.forgotpassword(content))
        }
    }
}

const mapStateToProps = state => {

    const { account } = state || {}; // entire Redux store state (the same value returned by a call to store.getState()).   



    return { account };
};


export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ForgotPasswordPresentation)
