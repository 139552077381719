import React from 'react';
import actions from './actions'
import { connect } from 'react-redux'
import { Col, Input, Form, FormGroup, Row, Button, Label } from 'reactstrap'
import SweetAlert from 'sweetalert2-react';

class CompleteRegistrationPresentation extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            email: '',
            code: '',
            errorMessage: '',
            IsDisable: true,
            display: true
        };
        this.handleLogin = this.handleLogin.bind(this);
    }

    componentDidMount() {

        this.setState({
            email: this.props.router.location.query.email,
            code: this.props.router.location.query.code
        });
    }

    handleLogin(event) {

        this.setState({
            display: false

        });
        this.props.gotoHome();
    }

    
    render() {

        const { password } = this.state;
        const { message, router, hideSignupFail, login } = this.props;
        const emails = router.location.query.email; //params.get('email');
        //const code = router.location.query.code;

        return (
            <div className="container">

                <SweetAlert
                    type="success"
                    show={this.state.display === true ? true : false}
                    title="Account Activation Successfully. Please login to use our service."
                    confirmButtonText={"OK"}
                    onConfirm={
                        () => {
                            this.handleLogin();
                        }}
                />

                
            </div>
        );
    }
}


const mapDispatchToProps = dispatch => {
    return {
        gotoHome: (content) => {
            dispatch(actions.gotoHome(content))
        }
    }
}

const mapStateToProps = state => {

    const { message, router } = state || {}; // entire Redux store state (the same value returned by a call to store.getState()).       

    return { message, router };
};


export default connect(
    mapStateToProps,
    mapDispatchToProps
)(CompleteRegistrationPresentation)
