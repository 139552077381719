import accountConstants from './constants';

const initialState = {
    isLoading: false,
    showFail: false,
    email: '',
    password: '',
    showSuccess: false
};


export const reducer = (state, action) => {
    state = state || initialState;

    switch (action.type) {
        case accountConstants.USER_SIGNUP_REQUEST:
            return {
                ...state,
                isLoading: true,
                showFail: false
            }
        case accountConstants.USER_SIGNUP_SUCCESS:
            return {
                ...state,
                isLoading: false,
                showFail: false,
                email: action.email
            }
        case accountConstants.SHOW_SIGNUP_FAIL:
            return {
                isLoading: false,
                showFail: true
            }
        case accountConstants.HIDE_SIGNUP_FAIL:
            return {
                isLoading: false,
                showFail: false
            }
        case accountConstants.USER_LOGIN_REQUEST:
            return {
                loggingIn: true,
                [state]: undefined,
                isLoading: true
            }
        case accountConstants.USER_LOGIN_SUCCESS:
            return {
                loggedIn: action.data.result.accessToken ? true : false,
                token: action.data.result.accessToken,
                // access: action.data.r.Access,
                 accountDetails: {
                     userId: action.data.result.userId
                 },
                isLoading: false
            }
        case accountConstants.USER_LOGIN_FAILURE:
            return {
                loggingIn: false,
                invalidLogin: true,
                error: action.error,
                isLoading: false
            }



        //Reset Password
        case accountConstants.USER_RESET_REQUEST:
            return {
                ...state,
                isLoading: true,
                showFail: false
            }
        case accountConstants.USER_RESET_SUCCESS:
            return {
                ...state,
                isLoading: false,
                showSuccess: true,
                showFail: false,
                email: action.email,
                password: action.password
            }
        case accountConstants.SHOW_RESET_FAIL:
            return {
                isLoading: false,
                showFail: true
            }
        case accountConstants.HIDE_RESET_FAIL:
            return {
                isLoading: false,
                showFail: false
            }
        //LOCATION
        case accountConstants.GET_FROMLOCATION_REQUEST:
            return {
                ...state,
                locationList: undefined,
                isLoading: true,
            }
        case accountConstants.GET_FROMLOCATION_SUCCESS:
            return {
                ...state,
                locationList: action.data,
                isLoading: false,
            }
        //NATIONALITY
        case accountConstants.GET_NATIONALITY_SUCCESS:
            return {
                ...state,
                nationalityList: action.data,
                isLoading: false
            }
        case accountConstants.GET_NATIONALITY_FAILURE:
            return {
                ...state,
                isLoading: false
            }
        //COMMON PASSWORD
        case accountConstants.GET_COMMON_PASSWORD_SUCCESS:
            return {
                ...state,
                commonPasswordList: action.data,
                isLoading: false
            }
        case accountConstants.GET_COMMON_PASSWORD_FAILURE:
            return {
                ...state,
                isLoading: false
            }
        default:
            return state;
    }
}