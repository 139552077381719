import React from 'react';
import { connect } from 'react-redux';
import actions from './actions';
import SweetAlert from 'sweetalert2-react';
//import MyProfileForm from './MyProfileForm';
import { Button, FormGroup, Label, Input, Row, Col, Card, CardBody, InputGroup, InputGroupText } from 'reactstrap';
import "./style.css";
import EditProfileForm from "./EditProfileForm";

class MyProfilePresentation extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            email: "",
            firstName: "",
            lastName: "",
            phoneNumber:"",
            citizenshipId: 0,
            identityNumber: "",
            gender: "",
            address1: "",
            postcode: "",
            city: "",
            state: "",
            countryId: 0,
            cityData:[],
            nationalityData: [],
            editProfile: false,
            editSuccess:false,
        };
        this.goEditProfile = this.goEditProfile.bind(this);
        this.goChangePassword = this.goChangePassword.bind(this);
        this.getPostcode = this.getPostcode.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    getPostcode(postcode) {
        this.props.getLocationByFromPostcode(postcode);
    }

    goEditProfile() {
        this.setState({
            editProfile: true
        });
    }

    goChangePassword() {
        this.props.history.push('/ChangePassword');
    }

    handleSubmit(editProfileModel) {
        let getCity = "";
        if (typeof editProfileModel.city === 'object') {
            getCity = editProfileModel.city.label;
        } else {
            getCity = editProfileModel.city;
        }
        var editObj = {
            FirstName: editProfileModel.givenName,
            LastName: editProfileModel.familyName,
            PhoneNumber: editProfileModel.mobileNo,
            CitizenshipId: editProfileModel.citizenShip.value,
            IdentityNumber: editProfileModel.identityNo,
            Gender: editProfileModel.gender,
            Address1: editProfileModel.billingAddress,
            Postcode: editProfileModel.postcode,
            City: getCity,
            State: editProfileModel.state,
            CountryId: editProfileModel.citizenShip.value,
        }
        this.props.updateProfile(editObj);

    }

    componentDidMount() {
        Promise.all([
            this.props.getProfile(),
            this.props.getNationalityList()
        ])
        
    }

    componentDidUpdate(prevProps) {

        if (typeof this.props.user.userData !== 'undefined' && this.props.user.userProfileSuccess !== prevProps.user.userProfileSuccess) {
            if (this.props.user.userProfileSuccess == true) {
                this.setState({
                    email: this.props.user.userData.email,
                    firstName: this.props.user.userData.firstName,
                    lastName: this.props.user.userData.lastName,
                    phoneNumber: this.props.user.userData.phoneNumber,
                    citizenshipId: this.props.user.userData.citizenshipId,
                    identityNumber: this.props.user.userData.identityNumber,
                    gender: this.props.user.userData.gender,
                    address1: this.props.user.userData.address1,
                    postcode: this.props.user.userData.postcode,
                    city: this.props.user.userData.city,
                    state: this.props.user.userData.state,
                    countryId: this.props.user.userData.countryId
                })
            }
        }

        if (typeof this.props.account.nationalityList !== 'undefined' && this.props.account.nationalityList !== prevProps.account.nationalityList) {
            this.setState({
                nationalityData: this.props.account.nationalityList.result
            });
        }

        if (typeof this.props.account.locationList !== 'undefined' && this.props.account.locationList !== prevProps.account.locationList) {
            this.setState({
                cityData: this.props.account.locationList
            });
        }
        
    }

    render() {
        let displayNationality = "";

        let findNationality = this.state.nationalityData && this.state.nationalityData.filter(x => x.countryId === this.state.countryId);
        if (findNationality.length > 0) {
            displayNationality = findNationality[0].countryName;
        }
       
        return (

            <div className="my-3">
                {
                    this.state.editProfile ?
                        <EditProfileForm onSubmit={this.handleSubmit} resetForm={this.props.getProfile}
                            profile={this.props.user.userData} nationality={this.state.nationalityData}
                            handlePostcode={this.getPostcode} cityData={this.state.cityData}
                            /> :
                        <div className="container">
                            <Row>
                                <Col xs="12" sm="12" lg="12">
                                    <div className="col-sm-12">
                                        <h2 className="font-weight-bold text-center">My Profile</h2>
                                    </div>

                                    <div className="row">
                                        <Card className="col-sm-12 border-0">
                                            <CardBody>
                                                <FormGroup row className="lessMarginBottom">
                                                    <Label
                                                        className="customLabel"
                                                        sm={3}
                                                    >
                                                        Email Address
                                                    </Label>
                                                    <Label
                                                        className="customLabel "
                                                        sm={{ offset: 1, size: 7 }}
                                                    >
                                                        {this.state.email}
                                                    </Label>
                                                </FormGroup>
                                                <FormGroup row className="lessMarginBottom">
                                                    <Label
                                                        className="customLabel"
                                                        sm={3} xs={12}
                                                    >

                                                    </Label>
                                                    <Col sm={{ offset: 1, size: 3 }} xs={12}>
                                                        <Button color="warning" className="btn font-weight-bold btn-sm btn-insubee-orange w-100" size="sm" onClick={this.goChangePassword}>Change Password</Button>
                                                    </Col>
                                                </FormGroup>
                                            </CardBody>
                                        </Card>
                                    </div>

                                    <div className="row col-sm-12">
                                        <div className="col-sm-9 col-xs-12"><h4 className="font-weight-bold ">Personal Information</h4></div>
                                        {/*<div className="col-sm-3 col-xs-12 text-right"><Button color="warning" className="btn font-weight-bold w-100 btn-sm btn-insubee-orange" size="sm">Edit Profile</Button></div>*/}
                                    </div>
                                    <div className="col-sm-12"><hr className="mt-0"></hr></div>
                                    <div className="row">
                                        <Card className="col-sm-12 border-0">
                                            <CardBody>
                                                <FormGroup row className="lessMarginBottom">
                                                    <Label
                                                        className="customLabel"
                                                        sm={3}
                                                    >
                                                        Given Name
                                                    </Label>
                                                    <Label
                                                        className="customLabel "
                                                        sm={{ offset: 1, size: 7 }}
                                                    >
                                                        {this.state.firstName}
                                                    </Label>
                                                </FormGroup>
                                                <FormGroup row className="lessMarginBottom">
                                                    <Label
                                                        className="customLabel"
                                                        sm={3}
                                                    >
                                                        Family Name
                                                    </Label>
                                                    <Label
                                                        className="customLabel "
                                                        sm={{ offset: 1, size: 7 }}
                                                    >
                                                        {this.state.lastName}
                                                    </Label>
                                                </FormGroup>
                                                <FormGroup row className="lessMarginBottom">
                                                    <Label
                                                        className="customLabel"
                                                        sm={3}
                                                    >
                                                        Citizenship
                                                    </Label>
                                                    <Label
                                                        className="customLabel "
                                                        sm={{ offset: 1, size: 7 }}
                                                    >
                                                        {displayNationality}
                                                    </Label>
                                                </FormGroup>
                                                <FormGroup row className="lessMarginBottom">
                                                    <Label
                                                        className="customLabel "
                                                        sm={3}
                                                    >
                                                        NRIC / Passport
                                                    </Label>
                                                    <Label
                                                        className="customLabel "
                                                        sm={{ offset: 1, size: 7 }}
                                                    >
                                                        {this.state.identityNumber}
                                                    </Label>
                                                </FormGroup>
                                                <FormGroup row className="lessMarginBottom">
                                                    <Label
                                                        className="customLabel "
                                                        sm={3}
                                                    >
                                                        Gender
                                                    </Label>
                                                    <Label
                                                        className="customLabel "
                                                        sm={{ offset: 1, size: 7 }}
                                                    >
                                                        {this.state.gender}
                                                    </Label>
                                                </FormGroup>
                                                <FormGroup row className="lessMarginBottom">
                                                    <Label
                                                        className="customLabel "
                                                        sm={3}
                                                    >
                                                        Mobile No
                                                    </Label>
                                                    <Label
                                                        className="customLabel "
                                                        sm={{ offset: 1, size: 7 }}
                                                    >
                                                        {this.state.phoneNumber}
                                                    </Label>
                                                </FormGroup>
                                                <FormGroup row className="lessMarginBottom">
                                                    <Label
                                                        className="customLabel "
                                                        sm={3}
                                                    >
                                                        Billing Address
                                                    </Label>
                                                    <Label
                                                        className="customLabel "
                                                        sm={{ offset: 1, size: 7 }}
                                                    >
                                                        {this.state.address1}
                                                    </Label>
                                                </FormGroup>
                                                <FormGroup row className="lessMarginBottom">
                                                    <Label
                                                        className="customLabel "
                                                        sm={3}
                                                    >
                                                        Postcode
                                                    </Label>
                                                    <Label
                                                        className="customLabel "
                                                        sm={{ offset: 1, size: 7 }}
                                                    >
                                                        {this.state.postcode}
                                                    </Label>
                                                </FormGroup>
                                                <FormGroup row className="lessMarginBottom">
                                                    <Label
                                                        className="customLabel "
                                                        sm={3}
                                                    >
                                                        City
                                                    </Label>
                                                    <Label
                                                        className="customLabel "
                                                        sm={{ offset: 1, size: 7 }}
                                                    >
                                                        {this.state.city}
                                                    </Label>
                                                </FormGroup>
                                                <FormGroup row className="lessMarginBottom">
                                                    <Label
                                                        className="customLabel"
                                                        sm={3}
                                                    >
                                                        State
                                                    </Label>
                                                    <Label
                                                        className="customLabel "
                                                        sm={{ offset: 1, size: 7 }}
                                                    >
                                                        {this.state.state}
                                                    </Label>
                                                </FormGroup>
                                            </CardBody>
                                        </Card>
                                    </div>

                                    <div className="col-sm-12">
                                        <h4 className="font-weight-bold">Extra Information</h4>
                                        <hr className="mt-0"></hr>
                                    </div>

                                    <div className="row">
                                        <Card className="col-sm-12 border-0">
                                            <CardBody>
                                                <FormGroup row className="lessMarginBottom">
                                                    <Label
                                                        className="customLabel"
                                                        sm={3}>
                                                        Company Name
                                                    </Label>
                                                    <Label
                                                        className="customLabel "
                                                        sm={{ offset: 1, size: 7 }}
                                                    >

                                                    </Label>
                                                </FormGroup>
                                                <FormGroup row className="lessMarginBottom">
                                                    <Label
                                                        className="customLabel"
                                                        sm={3}>
                                                        Business Registration No
                                                    </Label>
                                                    <Label
                                                        className="customLabel "
                                                        sm={{ offset: 1, size: 7 }}
                                                    >

                                                    </Label>
                                                </FormGroup>
                                                <FormGroup row className="lessMarginBottom">
                                                    <Label
                                                        className="customLabel"
                                                        sm={3} xs={12}
                                                    >

                                                    </Label>
                                                    <Col sm={{ offset: 1, size: 3 }} xs={12}>
                                                        <Button color="warning" className="btn font-weight-bold btn-sm btn-insubee-orange w-100" size="sm" onClick={this.goEditProfile}>Edit Profile</Button>
                                                    </Col>
                                                </FormGroup>
                                            </CardBody>
                                        </Card>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                }
                
            </div>
        );
    }
}


const mapDispatchToProps = dispatch => {
    return {
        getProfile: (content) => {
            dispatch(actions.getProfile(content));
        },
        getNationalityList: (content) => {
            dispatch(actions.getNationalityList(content));
        },
        getLocationByFromPostcode: (content) => {
            if (content != '' && content != null) {
                if (content.length >= 5) {
                    dispatch(actions.getLocationByFromPostcode(content));
                }
            }
        },
        updateProfile: (content) => {
            dispatch(actions.updateProfile(content))
        },
    }
}

const mapStateToProps = state => {

    const { user,account,router } = state || {};

    return { user, account,router };
};


export default connect(
    mapStateToProps,
    mapDispatchToProps
)(MyProfilePresentation)
