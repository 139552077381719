
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Button, Container, Row, Col, FormGroup, Label, InputGroup, InputGroupAddon, InputGroupText, Input, Card, CardBody, Collapse, CardHeader } from 'reactstrap';
import InsuBeeLogo from "../../image/InsubeeLogo.png";
import { Link } from "react-router-dom";
import { useTable } from "react-table";
import SubmitLogo from "../../image/Policy/SubmitRemovebg.png";
import TableContainer from './TableContainer';
import 'bootstrap/dist/css/bootstrap.min.css';
import { SelectColumnFilter } from './filters';
import { hot } from 'react-hot-loader';
import "./style.css";
import { numberWithCommas } from "../GlobalComponents/_function";


class ClaimsListPresentation extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            pages: 0,
            columns:
                [
                    //{
                    //    id: "selection",

                    //    // The header can use the table's getToggleAllRowsSelectedProps method
                    //    // to render a checkbox
                    //    Header: ({ getToggleAllRowsSelectedProps }) => (
                    //        <div>
                    //            <input type="checkbox" {...getToggleAllRowsSelectedProps()} />
                    //        </div>
                    //    ),
                    //    // The cell can use the individual row's getToggleRowSelectedProps method
                    //    // to the render a checkbox
                    //    Cell: ({ row }) => (
                    //        <div>
                    //            <input type="checkbox" {...row.getToggleRowSelectedProps()} />
                    //        </div>
                    //    )
                    //},
                    {
                        Header: "Products",
                        accessor: "productName",
                        disableFilters: true,
                        //Cell: ({ row }) => (
                        //    <React.Fragment>
                        //        <div onClick={() => this.GetPolicy(row.original.customerClaimId)} className="claimProductName">
                        //            <>{row.original.productName}</>
                        //            </div>
                        //    </React.Fragment>
                        //)
                    },
                    {
                        Header: "Submission Date",
                        accessor: "createdDate",
                        disableSortBy: true,
                        disableFilters: true
                    },
                    {
                        Header: "Submission No",
                        accessor: "submissionNo",
                        disableFilters: true,
                        Filter: SelectColumnFilter,
                        filter: 'equals' // by default, filter: 'text', but in our case we don't want to filter options like text, we want to find exact match of selected option.
                    },
                    {
                        Header: "Claim No",
                        accessor: "claimNo",
                        disableFilters: true,
                        Cell: ({ row }) => {
                            if (row.original.claimNo) {
                                return (
                                    <div>
                                        {row.original.claimNo}
                                    </div>
                                );
                            } else {
                                return (
                                    <div className="text-center">
                                        -
                                    </div>
                                );

                            }

                        }
                    },
                    {
                        Header: "Policy No",
                        accessor: "policyNo",
                        disableFilters: true,
                        Cell: ({ row }) => {
                            if (row.original.policyNo) {
                                return (
                                    <div>
                                        {row.original.policyNo}
                                    </div>
                                );
                            } else {
                                return (
                                    <div className="text-center">
                                        -
                                    </div>
                                );

                            }

                        }
                    },
                    {
                        Header: "Claim Amount",
                        accessor: "claimAmount",
                        disableFilters: true,
                        Cell: ({ row }) => (
                            <div>
                                {"RM " + numberWithCommas(row.original.claimAmount.toFixed(2))}
                            </div>
                        )
                    },
                    {
                        Header: "Status",
                        accessor: "claimStatus",
                        disableFilters: true
                    }
                ]

        }

        this.goSubmitClaims = this.goSubmitClaims.bind(this);
        this.GetPolicy = this.GetPolicy.bind(this);
    }

    GetPolicy(claimID) {

        if (claimID) {
            window.localStorage.setItem('claimID', claimID);
            this.props.history.push('/ClaimsDetail');
            /*this.props.getClaimbyID(claimID);*/
        }
    }

    goSubmitClaims() {
        this.props.history.push('/SubmitClaims');
    }

    componentDidMount() {
        window.scrollTo(0, 0);
        this.props.getAllClaim(1, 10);
    }


    render() {

        const { ClaimStore } = this.props;


        console.log(ClaimStore);

        let data = [];

        if (ClaimStore.ClaimList) {
            data = ClaimStore.ClaimList.itemViewListModel;
        }

        /*console.log(ClaimStore.ClaimList);*/

        return (

            <div className="my-5 pt-sm-5">
                <Container className="formBackground">

                    {/*<section class="bgc-gray">*/}
                    {/*    <br />*/}
                    {/*    <br />*/}
                    {/*        <div class="row">*/}
                    {/*            <div class="col-12 col-sm-12 col-lg-12">*/}
                    {/*                <div class="col-sm-12 mt-3">*/}
                    {/*                    <h2 class="font-weight-bold text-center">COMING SOON</h2>*/}
                    {/*                </div>*/}
                    {/*            </div>*/}
                    {/*        </div>*/}
                    {/*    <br />*/}
                    {/*    <br />*/}
                    {/*                </section>*/}

                    <div>
                        <Row>

                            <Col xs="12" sm="12" lg="12">
                                <div className="text-center">
                                    <h1><b>My Claims</b></h1>
                                    <br />
                                    <Button color="warning" className="btn font-weight-bold btn-insubee-orange" onClick={this.goSubmitClaims}>
                                        <img src={SubmitLogo} height="40" /> Submit Claim</Button>

                                </div>
                            </Col>

                        </Row>

                    </div>


                    {data == "" ?
                    <div className=" my-5 pt-sm-5 text-center">
                        <div style={{ backgroundColor: "rgb(33,33,33)", border: "none", height: "90px", display: "block", marginLeft: "auto", marginRight: "auto", width: "50%", padding: "30px" }}>

                            <p style={{ color: "rgb(255,223,0)" }}> There is no claim for now </p>

                        </div>

                    </div>

                        :
                    <div className=" my-5 pt-sm-5 text-center">
                            <TableContainer columns={this.state.columns} data={data} HandleRow={this.GetPolicy} />

                        <div className=" my-5 pt-sm-5">

                            <div id="main-menu">
                                <Card className="mb-0">
                                    <CardHeader id="headingOne">
                                        <a href="#sub-menu1" className="list-group-item active jDguzg" data-toggle="collapse" data-parent="#main-menu">
                                            <label color="link" className="text-left m-0 p-0" aria-controls="collapseOne">
                                                <h5 className="m-0 p-0">
                                                        Make Claims - Step by Step
                                            </h5>
                                            </label>
                                        </a>
                                    </CardHeader>

                                    <div className="collapse list-group-level1" id="sub-menu1">
                                        <CardBody style={{ fontSize: "16px" }}>
                                            <a href="#sub-sub-menu1" className="list-group-item" data-toggle="collapse" data-parent="#sub-menu1">Step 1</a>
                                            <div className="collapse list-group-level2" id="sub-sub-menu1">
                                                <a className="list-group-item" data-parent="#sub-sub-menu1">Use the Quote box in our homepage or click on Send Package in the top menu bar, then key in the details,
                                                    pay online and your consignment note will be ready for printing.</a>
                                            </div>
                                            <a href="#sub-sub-menu2" className="list-group-item" data-toggle="collapse" data-parent="#sub-menu1">Step 2</a>
                                            <div className="collapse list-group-level2" id="sub-sub-menu2">
                                                <a className="list-group-item" data-parent="#sub-sub-menu2">Use the Quote box in our homepage or click on Send Package in the top menu bar,
                                                    then key in the details, pay online and your consignment note will be ready for printing.</a>
                                            </div>
                                            <a href="#sub-sub-menu3" className="list-group-item" data-toggle="collapse" data-parent="#sub-menu1">Step 3</a>
                                            <div className="collapse list-group-level2" id="sub-sub-menu3">
                                                <a className="list-group-item" data-parent="#sub-sub-menu3">Use the Quote box in our homepage or click on Send Package in the top menu bar,
                                                    then key in the details, pay online and your consignment note will be ready for printing.</a>
                                            </div>
                                            <a href="#sub-sub-menu4" className="list-group-item" data-toggle="collapse" data-parent="#sub-menu1">Step 4</a>
                                            <div className="collapse list-group-level2" id="sub-sub-menu4">
                                                <a className="list-group-item" data-parent="#sub-sub-menu4">Use the Quote box in our homepage or click on Send Package in the top menu bar,
                                                    then key in the details, pay online and your consignment note will be ready for printing.</a>
                                            </div>
                                            <a href="#sub-sub-menu5" className="list-group-item" data-toggle="collapse" data-parent="#sub-menu1">Step 5</a>
                                            <div className="collapse list-group-level2" id="sub-sub-menu5">
                                                <a className="list-group-item" data-parent="#sub-sub-menu5">Use the Quote box in our homepage or click on Send Package in the top menu bar,
                                                    then key in the details, pay online and your consignment note will be ready for printing.</a>
                                            </div>


                                        </CardBody>
                                    </div>
                                </Card>
                            </div>


                        </div>
                    </div>
                    }

                    <br />
                </Container>

            </div>
        );
    }
}

export default hot(module)(ClaimsListPresentation);