import React from 'react';
import { connect } from 'react-redux'
import actions from './actions'
import SweetAlert from 'sweetalert2-react';
import LoginForm from './LoginForm';
import { Field, reduxForm, FormSection } from 'redux-form';
import { encrypt } from "../_helpers/crypto";
import useAnalyticsEventTracker from '../useAnalyticsEventTracker';

const gaEventTracker = useAnalyticsEventTracker('Login');
class LoginFormPresentation extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            Email: '',
            Password: '',
            RememberMe: '',
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.goSignUp = this.goSignUp.bind(this);
        this.goForgotPassword = this.goForgotPassword.bind(this);
    }

    goSignUp() {
        this.props.history.push('/SignUp');
    }

    goForgotPassword() {
        this.props.history.push('/ForgotPassword');
    }

    handleChange(event) {
         const target = event.target;
        const value = target.name === 'RememberMe' ? target.checked : target.value;
        const name = target.name;

        this.setState({
            [name]: value
        });
    }

    handleSubmit(loginModel) {
        var loginObj = {
            Email: loginModel.email,
            Password: encrypt(loginModel.Password),
            RememberMe: true
        }
        this.props.login(loginObj);
        gaEventTracker('user login');
    }

    render() {
        const { message} = this.props;
        return (
            <>
                <LoginForm
                    onSubmit={this.handleSubmit}
                    message={message}
                    goSignUp={this.goSignUp}
                    goForgotPassword={this.goForgotPassword}
                />
            </>
        );
    }
}

const mapDispatchToProps = dispatch => {
    return {
        login: (content) => {

            dispatch(actions.login(content))
        }
    }
}

const mapStateToProps = state => {

    const { account } = state || {}; // entire Redux store state (the same value returned by a call to store.getState()).

    return { account };
};


export default connect(
    mapStateToProps,
    mapDispatchToProps
)(LoginFormPresentation)

