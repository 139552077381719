import policyConstants from './constants';
import userConstants from '../User/constants';
import constants from '../_constants/constants';
import policyServices from "./services";
import userServices from "../User/services";
import { toast } from 'react-toastify';
import { push } from 'connected-react-router';
import Cookies from 'universal-cookie';
import Swal from 'sweetalert2';
import moment from 'moment';
import Browser from '../_helpers/browser';
import print from 'print-js';
import fileDownload from 'js-file-download';
const cookies = new Cookies();

const actions = {
    getProfile,
    getPolicy,
    getPolicyDetails,
    downloadCustomerPolicy,
    printDocumentFromBlob,
}

function downloadFileFromBlob(blob, fileName) {
    const dataURL = window.URL.createObjectURL(blob);


    if (Browser.isIE() || Browser.isEdge() || Browser.isMobile()) {
        var fileName = "policy"

        const link = document.createElement('a');
        link.href = dataURL;
        link.setAttribute(
            fileName,
            fileName + '.pdf',
        );
        document.body.appendChild(link);
        link.click();

        // Clean up and remove the link
        link.parentNode.removeChild(link);
    }
    else {
        //window.open(dataURL);
        fileDownload(blob, fileName + '.pdf');
    }
}

function printDocumentFromBlob(blob) {
    const dataURL = window.URL.createObjectURL(blob);


    if (Browser.isIE() || Browser.isEdge() || Browser.isMobile()) {
        var fileName = "policy"

        const link = document.createElement('a');
        link.href = dataURL;
        link.setAttribute(
            'download',
            fileName + '.pdf',
        );
        document.body.appendChild(link);
        link.click();
        //print(link);
    }
    else {
        //window.open(dataURL);
        print(dataURL);
    }
}

function downloadCustomerPolicy(customerPolicyId,policyNo) {

    return dispatch => {
        dispatch(request(customerPolicyId,policyNo));
        policyServices.downloadCustomerPolicy(customerPolicyId).then(
            data => {
                dispatch(success(data.result));
                //printDocumentFromBlob(data);
                downloadFileFromBlob(data, policyNo);
            },
            error => {
                dispatch(failure(error));
                Swal.fire({
                    title: 'Invalid status',
                    message: error,
                    type: 'error',
                });
            }
        )
    }

    function request(data) { return { type: policyConstants.DOWNLOAD_POLICY_REQUEST, data } }
    function success(data) { return { type: policyConstants.DOWNLOAD_POLICY_SUCCESS, data } }
    function failure(error) { return { type: policyConstants.DOWNLOAD_POLICY_FAILURE, error } }

}

function getProfile() {
    return dispatch => {

        dispatch(request());
        userServices.getProfile().then(
            data => {
                dispatch(success(data.result));
            },
            error => {
                dispatch(failure(error.errorMessage));

            }
        )
    }

    function request(data) { return { type: userConstants.GET_PROFILE_REQUEST, data } }
    function success(data) { return { type: userConstants.GET_PROFILE_SUCCESS, data } }
    function failure(error) { return { type: userConstants.GET_PROFILE_FAILURE, error } }
}

function getPolicy(statusId,page,rowPerPage) {

    return dispatch => {
        dispatch(request(statusId, page, rowPerPage));
        policyServices.getPolicy(statusId, page, rowPerPage).then(
            data => {
                dispatch(success(data.result));
            },
            error => {
                dispatch(failure(error));
                Swal.fire({
                    title: 'Invalid status',
                    message: error,
                    type: 'error',
                });
            }
        )
    }

    function request(data) { return { type: policyConstants.GET_POLICY_REQUEST, data } }
    function success(data) { return { type: policyConstants.GET_POLICY_SUCCESS, data } }
    function failure(error) { return { type: policyConstants.GET_POLICY_FAILURE, error } }

}

function getPolicyDetails(id) {

    return dispatch => {
        dispatch(request(id));
        policyServices.getPolicyDetails(id).then(
            data => {
                dispatch(success(data.result));
            },
            error => {
                dispatch(failure(error));
                Swal.fire({
                    title: 'Invalid status',
                    message: error,
                    type: 'error',
                });
            }
        )
    }

    function request(data) { return { type: policyConstants.GET_POLICY_DETAILS_REQUEST, data } }
    function success(data) { return { type: policyConstants.GET_POLICY_DETAILS_SUCCESS, data } }
    function failure(error) { return { type: policyConstants.GET_POLICY_DETAILS_FAILURE, error } }

}

export default (actions);


