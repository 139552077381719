const userConstants = {

    GET_PROFILE_REQUEST: "GET_PROFILE_REQUEST",
    GET_PROFILE_SUCCESS: "GET_PROFILE_SUCCESS",
    GET_PROFILE_FAILURE: "GET_PROFILE_FAILURE",

    //Profile update
    POST_UPDATEPROFILE_REQUEST: "POST_UPDATEPROFILE_REQUEST",
    POST_UPDATEPROFILE_SUCCESS: "POST_UPDATEPROFILE_SUCCESS",
    POST_UPDATEPROFILE_FAILURE: "POST_UPDATEPROFILE_FAILURE",
    SHOW_UPDATEPROFILE_FAIL: "SHOW_UPDATEPROFILE_FAIL",

    //Change Password
    USER_CHANGE_PASSWORD_REQUEST: "USER_CHANGE_PASSWORD_REQUEST",
    USER_CHANGE_PASSWORD_SUCCESS: "USER_CHANGE_PASSWORD_SUCCESS",
    USER_CHANGE_PASSWORD_FAIL: "USER_CHANGE_PASSWORD_FAIL",
    USER_SHOW_CHANGE_PASSWORD_FAIL: "USER_SHOW_CHANGE_PASSWORD_FAIL",

}

export default userConstants;