import React, { Component } from 'react';
import { HashRouter, Route, Switch } from 'react-router-dom';
// import { renderRoutes } from 'react-router-config';
import Cookies from 'universal-cookie';

const loading = () => <div className="animated fadeIn pt-3 text-center">Loading...</div>;

// Containers
const DefaultLayout = React.lazy(() => import('./CoreUi/containers/DefaultLayout'));

// Pages
const Login = React.lazy(() => import('../Admin/Login'));
const ForgotPassword = React.lazy(() => import('../Admin/ForgotPassword'));
const ResetPassword = React.lazy(() => import('../Admin/ResetPassword'));
const CompleteRegistration = React.lazy(() => import('../Admin/CompleteRegistration'));
const FailVerification = React.lazy(() => import('../Admin/FailVerificationPresentation'));
//const Login = React.lazy(() => import('./CoreUi/views/Pages/Login'));
const Register = React.lazy(() => import('./CoreUi/views/Pages/Register'));
const Page404 = React.lazy(() => import('./CoreUi/views/Pages/Page404'));
const Page500 = React.lazy(() => import('./CoreUi/views/Pages/Page500'));
const cookies = new Cookies();
class App extends Component {



    render() {

        //let isLogin = cookies.get("insubeeToken") ? true : false;
        let isLogin = sessionStorage.getItem("insubeeToken") ? true : false;
        
        return (

            <React.Suspense fallback={loading()}>
                <Switch>
                    <Route path="/admin/login" name="Login" render={props => <Login {...props} />} />
                    <Route path="/admin/resetpassword" name="Reset Password" render={props => <ResetPassword {...props} />} />
                    <Route path="/admin/forgotpassword" name="Forgot Password" render={props => <ForgotPassword {...props} />} />
                    <Route path="/admin/completeregistration" name="Complete Registration" render={props => <CompleteRegistration {...props} />} />
                    <Route path="/admin/FailVerification" name="Fail Verification" render={props => <FailVerification {...props} />} />
                    <Route path="/admin" name="Admin" render={props => <DefaultLayout {...props} />} />
                </Switch>
            </React.Suspense>

        );
    }
}

export default App;
