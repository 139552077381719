import constants from '../_constants/constants';
import Cookie from 'universal-cookie'
import { handleResponse, handleBlobResponse } from "../_helpers/handleResponse";
import { authHeader, authHeaderFile, authHeaderWithoutUser } from '../_helpers/authHeader';

const cookies = new Cookie();

const ClaimServices = {
    getAllClaim,
    submitClaim,
    getClaimbyID,
    getUserPolicy,
    downloadCustomerClaim,
    CancelClaim,
}

function CancelClaim(customerClaimId) {
    const requestOptions = {
        method: "PUT",
        headers: authHeader()
    };
    const url = "/api/Claim/CancelClaim/" + customerClaimId;

    return fetch(url, requestOptions).then(
        (response) => handleResponse(response, requestOptions, url)
    ).then(data => {
        return data;
    });
}

function downloadCustomerClaim(customerClaimId) {
    const requestOptions = {
        method: "GET",
        headers: authHeader()
    };
    const url = "/api/Claim/DownloadCustomerClaim/" + customerClaimId;

    return fetch(url, requestOptions).then(
        (response) => handleBlobResponse(response, requestOptions, url)

    ).then(data => {
        return data;
    });
}

function getAllClaim(page, size) {
    const requestOptions = {
        method: "GET",
        headers: authHeader()
    };

    const url = "/api/Claim/GetClaim?pageNumber=" + page + "&pageSize=" + size;

    return fetch(url, requestOptions).then(
        (response) => handleResponse(response, requestOptions, url)
    ).then(data => {
        return data;
    });
}

function submitClaim(formData) {
    const requestOptions = {

        method: "POST",
        headers: authHeaderFile(),
        body: formData
    };


    return fetch(constants.apiSubmitClaim, requestOptions).then(
        (response) => handleResponse(response, requestOptions, constants.apiSubmitClaim)
    ).then(data => {
        return data;
    });
}

function getClaimbyID(ClaimID) {
    const requestOptions = {
        method: "GET",
        headers: authHeader()
    };

    const url = "/api/Claim/GetClaim/" + ClaimID;

    return fetch(url, requestOptions).then(
        (response) => handleResponse(response, requestOptions, url)
    ).then(data => {
        return data;
    });
}

function getUserPolicy() {
    const requestOptions = {
        method: "GET",
        headers: authHeader()
    };

    return fetch(constants.apiGetUserPolicy, requestOptions).then(
        (response) => handleResponse(response, requestOptions, constants.apiGetUserPolicy)
    ).then(data => {
        return data;
    });
}

export default ClaimServices;
