import React from 'react';
import { connect } from 'react-redux';
import actions from './actions';
import SweetAlert from 'sweetalert2-react';
import { Button, FormGroup, Label, Input, Row, Col, Card, CardBody, InputGroup, InputGroupText, Table, CardHeader } from 'reactstrap';
import "./style.css";
import { hot } from 'react-hot-loader';

import { numberWithCommas } from "../GlobalComponents/_function";
import TermsofService from "../Static/TermsofService";
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import Swal from 'sweetalert2';
import PdfWording from "../../file/Tokio-Marine-SME-BizOne-Policy Wording-Eng.pdf";
import PdfDisclosure from "../../file/TM-SME-BIZONE-Disclosure-Sheet-V3.pdf";
import PdfDeclaration from "../../file/TMIM-Important-Notice-Declaration.pdf";
import step from '../../image/Product/step.svg'
import stepActive from '../../image/Product/stepActive.svg'
import { scroller } from "react-scroll";



class ProductDetailsPresentation extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            ShowTC: false,
            SameAddress: "",
            DisclaimerList: [

                {
                    ID: 1,
                    text: "Has the insurance now proposed been declined, cancelled, refused renewal or subjected to any increased in premiums special terms by any other insurance company?",
                    DisableButton: true,
                    ButtonYes: false,
                    ButtonNo: false
                },
                {
                    ID: 2,
                    text: "In respect of risks you wish to insure against, have you ever sustained any loss for the past 3 years?",
                    DisableButton: true,
                    ButtonYes: false,
                    ButtonNo: false
                },
                {
                    ID: 3,
                    text: "Is there any insurance in force covering any of the property to be insured against with this or any other insurance company?",
                    DisableButton: true,
                    ButtonYes: false,
                    ButtonNo: false
                },
                {
                    ID: 4,
                    text: "Is there any hazardous trade carried on or hazardous goods stored therein or near the premises, or is there any circumstances increasing the risk you now wish to insure?",
                    DisableButton: true,
                    ButtonYes: false,
                    ButtonNo: false
                },
                {
                    ID: 5,
                    text: "Is your premise located in flood prone area?",
                    DisableButton: true,
                    ButtonYes: false,
                    ButtonNo: false
                },
                {
                    ID: 6,
                    text: "Is there any intruder alarm flitted in the premises?",
                    DisableButton: true,
                    ButtonYes: false,
                    ButtonNo: false
                },
                {
                    ID: 7,
                    text: "Does the proposer have a reliable accounting or auditing system and have sufficient safeguards against any dishonetly of its employees?",
                    DisableButton: true,
                    ButtonYes: false,
                    ButtonNo: false
                },

            ],
            active: false,
            ShowRequired: false,
        };

        this.onClickDisclaimerYES = this.onClickDisclaimerYES.bind(this);
        this.onClickDisclaimerNO = this.onClickDisclaimerNO.bind(this);
        this.toggle = this.toggle.bind(this);
        this.handleClass = this.handleClass.bind(this);
        this.checkDisclaimer = this.checkDisclaimer.bind(this);
    }

    checkDisclaimer() {

        let newDisclaimerList1 = this.state.DisclaimerList.filter(x => (
            x.DisableButton == true
        ))

        if (newDisclaimerList1) {

            var first = newDisclaimerList1[0];

            scroller.scrollTo("row " + first.ID, {
                duration: 800,
                delay: 0,
                smooth: "easeInOutQuart",
                offset: -300
            });

            this.setState({ ShowRequired: true });
        }

    }

    handleClass() {

        this.setState({ active: !this.state.active });
    };

    onClickDisclaimerYES(ID) {

        let newDisclaimerList

        if (ID == 6 || ID == 7) {

            newDisclaimerList = this.state.DisclaimerList.map(x => (
                x.ID == ID ? { ...x, ButtonYes: true, ButtonNo: false, DisableButton: false } : x
            ))

        }

        else {

            Swal.fire({
                title: '',
                message: '',
                html: 'Thank you for your interest in purchasing Business Insurance. We are unable to proceed with your application based on your declaration. Kindly email us at ' +
                    '<span style="color:DodgerBlue;">help.support@insubee.com</span>' + ' to get protected.',
                type: 'warning',
            });


            newDisclaimerList = this.state.DisclaimerList.map(x => (
                x.ID == ID ? { ...x, ButtonYes: true, ButtonNo: false, DisableButton: true } : x
            ))

        }


        this.setState({
            DisclaimerList: newDisclaimerList,
        })
    }

    onClickDisclaimerNO(ID) {

        let newDisclaimerList;

        if (ID == 6 || ID == 7) {

            Swal.fire({
                title: '',
                message: '',
                html: 'Thank you for your interest in purchasing Business Insurance. We are unable to proceed with your application based on your declaration. Kindly email us at ' +
                    '<span style="color:DodgerBlue;">help.support@insubee.com</span>' + ' to get protected.',
                type: 'warning',
            });

            newDisclaimerList = this.state.DisclaimerList.map(x => (
                x.ID == ID ? { ...x, ButtonYes: false, ButtonNo: true, DisableButton: true } : x
            ))

        }

        else {

            newDisclaimerList = this.state.DisclaimerList.map(x => (
                x.ID == ID ? { ...x, ButtonYes: false, ButtonNo: true, DisableButton: false } : x
            ))
        }


        this.setState({
            DisclaimerList: newDisclaimerList,
        })
    }

    toggle() {
        this.setState({
            ShowTC: !this.state.ShowTC,
        })
    }

    componentDidMount() {
        window.scrollTo(0, 0);
        const CorrespondanceAddress = localStorage.getItem('CorrespondanceAddress');
        const CorrespondanceAddressObj = JSON.parse(CorrespondanceAddress);
        this.setState({
            SameAddress: CorrespondanceAddressObj,
        })
    }

    render() {

        const { productResultSummary, BackToSelectPlan, CreatePolicy, OnClickApplyDiscount, handleChange, Discount, reducer, product, ErrorMsgDnC } = this.props;

        let discounVoucherAmount = 0;
        let IsDisable;
        let searchDisableButton = this.state.DisclaimerList.filter(x => x.DisableButton === true);

        if (searchDisableButton.length == 0) {

            IsDisable = false;
        }
        else {

            IsDisable = true;
        }

        let totalPremiumAmount = productResultSummary.totalPremiumAmount;
        let serviceTaxAmount = productResultSummary.serviceTaxAmount;
        let stampDutyAmount = productResultSummary.stampDutyAmount;
        let finalTotalPremium = productResultSummary.finalTotalPremium;
        let CorrespondanceAddress = "Same as location of risk";

        if (Discount) {

            discounVoucherAmount = Discount.discounVoucherAmount;
            finalTotalPremium = Discount.finalTotalPremium;
            serviceTaxAmount = Discount.serviceTaxAmount;
            stampDutyAmount = Discount.stampDutyAmount;
            totalPremiumAmount = Discount.totalPremiumAmount;
        }


        if (this.state.SameAddress == false) {
            CorrespondanceAddress = productResultSummary.CorrespondanceAddress1 + " " + productResultSummary.CorrespondanceAddress2 + " " + productResultSummary.CorrespondanceAddress3 + " " + productResultSummary.CorrespondancePostcode + " " + productResultSummary.CorrespondanceCity + " " + productResultSummary.CorrespondanceState;
        }

        else {

            CorrespondanceAddress = "Same as location of risk";
        }

        return (

            <div className="gqProductContainer">
                <div className="backgroundIMG">
                    <div className="PLanContentContainer">

                        <div className="progress-container gqForm">
                            <div className="steps-progress">
                                <div className="steps activeStep"><img src={stepActive} /></div><hr />
                                <div className="steps"><img src={stepActive} /></div><hr />
                                <div className="steps"><img src={step} /></div>
                            </div>
                            <div className="steps-progress hideProgress">
                                <div style={{ marginLeft: "-10px" }}><span>Get Quote</span></div><hr />
                                <div style={{ marginLeft: "-8px" }}><span>Details</span></div><hr />
                                <div style={{ marginRight: "-8px" }}><span>Payment</span></div>
                            </div>
                        </div>

                        <div className="BackButton mt-4 mb-3">
                            <Button onClick={BackToSelectPlan} className="btnInsubee">
                                Back
                            </Button>
                        </div>

                        <React.Fragment>
                            <TermsAndCondition
                                isOpenModel={this.state.ShowTC}
                                toggleModel={this.toggle}

                            //reducer={userSetting}
                            />
                        </React.Fragment>


                        <div className="summaryFormContainer">

                            <div style={{ padding: "4rem 4rem 2rem 4rem" }}>

                                <div className="summaryPersonalInfo">
                                    <FormGroup row >
                                        {(() => {
                                            if (productResultSummary.GuestEmailAddress !== "") {

                                                return (
                                                    <>
                                                        <Label className="customLabel bold" sm={4}> Guest Identity Number </Label>
                                                        <Label className="customLabel" sm={{ size: 7 }}> : {productResultSummary.GuestIdentityNumber} </Label>

                                                        <Label className="customLabel bold" sm={4}> Guest Email </Label>
                                                        <Label className="customLabel" sm={{ size: 7 }}> : {productResultSummary.GuestEmailAddress} </Label>

                                                        <Label className="customLabel bold" sm={4}> Guest Name </Label>
                                                        <Label className="customLabel" sm={{ size: 7 }}> : {productResultSummary.GuestName} </Label>

                                                        <Label className="customLabel bold" sm={4}> Guest Mobile No. </Label>
                                                        <Label className="customLabel" sm={{ size: 7 }}> : {productResultSummary.GuestMobileNo} </Label>
                                                    </>
                                                )
                                            }
                                        })()}

                                        {/*<Label className="customLabel bold" sm={4}>Marital Status </Label>*/}
                                        {/*<Label className="customLabel" sm={{ size: 7 }}> : {productResultSummary.maritalStatus} </Label>*/}

                                        <Label className="customLabel bold" sm={4}>Business Name </Label>
                                        <Label className="customLabel" sm={{ size: 7 }}> : {productResultSummary.CompanyName} </Label>

                                        <Label className="customLabel bold" sm={4}> Business Registration Number </Label>
                                        <Label className="customLabel" sm={{ size: 7 }}> : {productResultSummary.BusinessRegistrationNo} </Label>
                                    </FormGroup>
                                </div>


                                <div className="summaryLocationInfo mt-5">
                                    <h4><b>Location of Risk</b></h4>
                                    <FormGroup row >
                                        <Label className="customLabel bold" sm={4}> Address 1 </Label>
                                        <Label className="customLabel" sm={{ size: 7 }}> : {productResultSummary.Address1} </Label>

                                        <Label className="customLabel bold" sm={4}> Address 2 </Label>
                                        <Label className="customLabel" sm={{ size: 7 }}> : {productResultSummary.Address2} </Label>

                                        <Label className="customLabel bold" sm={4}> Address 3 </Label>
                                        <Label className="customLabel" sm={{ size: 7 }}> : {productResultSummary.Address3} </Label>

                                        <Label className="customLabel bold" sm={4}> Postcode </Label>
                                        <Label className="customLabel" sm={{ size: 7 }}> : {productResultSummary.postcode} </Label>

                                        <Label className="customLabel bold" sm={4}> City </Label>
                                        <Label className="customLabel" sm={{ size: 7 }}> : {productResultSummary.city} </Label>

                                        <Label className="customLabel bold" sm={4}> State </Label>
                                        <Label className="customLabel" sm={{ size: 7 }}> : {productResultSummary.state} </Label>

                                        <Label className="customLabel bold" sm={4}> Country </Label>
                                        <Label className="customLabel" sm={{ size: 7 }}> : {productResultSummary.Country} </Label>

                                        <Label className="customLabel bold" sm={4}> Correspondence Address </Label>
                                        <Label className="customLabel" sm={{ size: 7 }}> : {CorrespondanceAddress} </Label>

                                        <Label className="customLabel bold" sm={4}> Business Occupation </Label>
                                        <Label className="customLabel" sm={{ size: 7 }}> : {productResultSummary.BusinessOccupation} </Label>

                                        <Label className="customLabel bold" sm={4}> Business Trade Occupation </Label>
                                        <Label className="customLabel" sm={{ size: 7 }}> : {productResultSummary.BusinessTradeOccupation} </Label>

                                        <Label className="customLabel bold" sm={4}> Property Owner </Label>
                                        <Label className="customLabel" sm={{ size: 7 }}> : {productResultSummary.PropertyOwner} </Label>

                                        <Label className="customLabel bold" sm={4}> Building / Property Value </Label>
                                        <Label className="customLabel" sm={{ size: 7 }}> : {numberWithCommas(Number(productResultSummary.BuildingPropertyValue).toFixed(2))} </Label>
                                    </FormGroup>
                                </div>


                                <div className="summaryRenovationInfo mt-5 mb-4">
                                    <h4><b>Renovation / Features Value</b></h4>
                                    <FormGroup row >
                                        <Label className="customLabel bold" sm={4}> Stock in trade </Label>
                                        <Label className="customLabel" sm={{ size: 7 }}> : RM {numberWithCommas(Number(productResultSummary.StockInTradeValue).toFixed(2))} </Label>

                                        <Label className="customLabel bold" sm={4}> Furnitures, fixtures and fitting </Label>
                                        <Label className="customLabel" sm={{ size: 7 }}> : RM {numberWithCommas(Number(productResultSummary.FurnitureValue).toFixed(2))} </Label>

                                        <Label className="customLabel bold" sm={4}> Office Equiqment </Label>
                                        <Label className="customLabel" sm={{ size: 7 }}> : RM {numberWithCommas(Number(productResultSummary.OfficeEquipmentValue).toFixed(2))} </Label>

                                        <Label className="customLabel bold" sm={4}> Miscellaneous item </Label>
                                        <Label className="customLabel" sm={{ size: 7 }}> : RM {numberWithCommas(Number(productResultSummary.MiscellaneousItemValue).toFixed(2))} </Label>

                                        <Label className="customLabel bold" sm={4}> Building Age </Label>
                                        <Label className="customLabel" sm={{ size: 7 }}> : {productResultSummary.BuildingAge} </Label>

                                        <Label className="customLabel bold" sm={4}> Building Storey </Label>
                                        <Label className="customLabel" sm={{ size: 7 }}> : {productResultSummary.BuildingStorey} </Label>

                                        <Label className="customLabel bold" sm={4}> Building Constructed Year </Label>
                                        <Label className="customLabel" sm={{ size: 7 }}> : {productResultSummary.BuildingConstructedYear}</Label>

                                        <Label className="customLabel bold" sm={4}> Period of Insurance </Label>
                                        <Label className="customLabel" sm={{ size: 7 }}> : {productResultSummary.CoverageStartDate} - {productResultSummary.EndDate} </Label>

                                        <Label className="customLabel bold" sm={4}> Financial Institution </Label>
                                        <Label className="customLabel" sm={{ size: 7 }}> : {productResultSummary.FinancialInstitutionName ? productResultSummary.FinancialInstitutionName : "-"} </Label>

                                        <Label className="customLabel bold" sm={4}> Financial Institution Type</Label>
                                        <Label className="customLabel" sm={{ size: 7 }}> : {productResultSummary.FinancialInstitutionTypeDisplayName ? productResultSummary.FinancialInstitutionTypeDisplayName : "-"} </Label>


                                    </FormGroup>
                                </div>

                                <Table striped bordered hover>
                                    <thead>
                                        <tr>
                                            <th></th>
                                            <th>Coverage</th>
                                            <th>Premium</th>
                                        </tr>
                                    </thead>
                                    <tbody>

                                        {productResultSummary.ListofSelectedPlan.map((Plan) => (

                                            <TableBody
                                                Plan={Plan}
                                            />

                                        ))}
                                    </tbody>
                                </Table>


                                <div className="summaryVoucher">
                                    <div style={{ float: "left", width: "50%" }}>
                                        <span><b>Enter your voucher or discount code</b></span>
                                    </div>
                                    <div style={{ float: "right", width: "50%", padding: "10px", position: "relative" }}>
                                        <Input className="form-control voucherinput" name="voucher" label="Voucher" type="text" onChange={handleChange} />
                                        <Button color="dark" className="voucherBtn mt-2 mt-sm-0 btn font-weight-bold btn-sm " size="sm" onClick={OnClickApplyDiscount}>Apply</Button>
                                    </div>
                                </div>


                                <div className="summaryPriceCal mt-5 mb-4">
                                    <FormGroup row >
                                        <Label className="customLabel bold" sm={4}> Premium </Label>
                                        <Label className="customLabel" sm={{ size: 7 }}> : RM {numberWithCommas(totalPremiumAmount.toFixed(2))} </Label>

                                        <Label className="customLabel bold" sm={4}> Discount </Label>
                                        {discounVoucherAmount != 0 ?
                                            <Label className="customLabel" sm={{ size: 7 }}> : -RM {numberWithCommas(discounVoucherAmount.toFixed(2))} </Label>
                                            :
                                            <Label className="customLabel" sm={{ size: 7 }}> : RM 0.00 </Label>
                                        }

                                        <Label className="customLabel bold" sm={4}> Service Tax (6%) </Label>
                                        <Label className="customLabel" sm={{ size: 7 }}> : RM {numberWithCommas(serviceTaxAmount.toFixed(2))} </Label>

                                        <Label className="customLabel bold" sm={4}> Stamp Duty </Label>
                                        <Label className="customLabel" sm={{ size: 7 }}> : RM {numberWithCommas(stampDutyAmount.toFixed(2))}</Label>

                                        <Label className="customLabel bold" sm={4}><b>Total</b></Label>
                                        <Label className="customLabel" sm={{ size: 7 }}> : <b>RM {numberWithCommas(finalTotalPremium.toFixed(2))}</b> </Label>

                                    </FormGroup>
                                </div>


                                <div className="container mb-4">
                                    <div id="main-menu">
                                        <Card className="mb-0">
                                            <CardHeader id="headingOne">
                                                <a href="#sub-menu1" className="list-group-item active jDguzg" onClick={() => this.handleClass()} data-toggle="collapse" data-parent="#main-menu" style={{ backgroundColor: 'rgb(0,0,0,0)' }}>
                                                    {/*<label color="link" className="text-left m-0 p-0" aria-controls="collapseOne">*/}
                                                    {/*    <span className="m-0 p-0">Disclaimer</span>*/}
                                                    {/*</label>*/}
                                                    {/*<div className={this.state.active == true ? 'caretAfter1' : 'caretBefore1'}></div>*/}
                                                    <div className="row">
                                                        <div className="DisclaimerLeft">
                                                            <span className=""><b>Disclaimer</b></span>
                                                        </div>
                                                        <div className="DisclaimerRight">
                                                            <span className="" id="Y1"><b>Yes</b></span>
                                                            <span className="" id="Y2"><b>No</b></span>
                                                        </div>
                                                    </div>
                                                </a>
                                            </CardHeader>

                                            <div className="collapse list-group-level1 show" id="sub-menu1">
                                                <CardBody style={{ fontSize: "16px" }}>
                                                    {this.state.DisclaimerList.map((disclaimer) => (

                                                        <div className={"row " + disclaimer.ID}>
                                                            <div className="DRowID">{disclaimer.ID}.</div>
                                                            <div className="DRowTxt">
                                                                {disclaimer.text}
                                                                {disclaimer.DisableButton == true && this.state.ShowRequired == true ?
                                                                    <>
                                                                        <br />
                                                                        <span style={{ color: "red", fontSize: "12px" }}>*Required</span>
                                                                    </>
                                                                    :
                                                                    <></>
                                                                }
                                                            </div>

                                                            <div className="DRowBtn">
                                                                <div className="ButtonDiv">
                                                                    <div onClick={() => this.onClickDisclaimerYES(disclaimer.ID)}>
                                                                        <div className={disclaimer.ButtonYes == true ? 'new-ButtonBox' : 'ButtonBox'} style={{ textAlign: "center" }}>
                                                                            <label></label>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="ButtonDiv">
                                                                    <div onClick={() => this.onClickDisclaimerNO(disclaimer.ID)}>
                                                                        <div className={disclaimer.ButtonNo == true ? 'new-ButtonBox' : 'ButtonBox'} style={{ textAlign: "center" }}>
                                                                            <label></label>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        //<Row className="mb-3">
                                                        //    <Col xs="8" sm="8" lg="8">
                                                        //        {disclaimer.text}
                                                        //    </Col>
                                                        //    <Col xs="2" sm="2" lg="2">
                                                        //        <div onClick={() => this.onClickDisclaimerYES(disclaimer.ID)}>
                                                        //            <div className={disclaimer.ButtonYes == true ? 'new-ButtonBox' : 'ButtonBox'} style={{textAlign:"center"}}>
                                                        //                <b>Yes</b>
                                                        //            </div>
                                                        //        </div>
                                                        //    </Col>
                                                        //    <Col xs="2" sm="2" lg="2">
                                                        //        <div onClick={() => this.onClickDisclaimerNO(disclaimer.ID)}>
                                                        //            <div className={disclaimer.ButtonNo == true ? 'new-ButtonBox' : 'ButtonBox'} style={{ textAlign: "center" }}>
                                                        //                <b>No</b>
                                                        //            </div>
                                                        //        </div>
                                                        //    </Col>
                                                        //</Row>
                                                    ))}
                                                </CardBody>
                                            </div>
                                        </Card>
                                    </div>
                                </div>


                                <div className="container">

                                    <h4 style={{ color: 'black' }}><b>Declaration & Confirmation</b></h4>
                                    <span style={{ color: 'black' }}>You are required to carefully read and tick the following declarations to complete your purchase:</span>

                                    <FormGroup check className="mt-3">
                                        <Input id="checkDAC" type="checkbox" name="checkDAC" onChange={this.props.handleCheckBox} style={{ width: "auto" }} />
                                        <Label style={{ color: 'black' }} for="checkDAC" check> I hereby confirm that i have read, and agree to the <a href={PdfDeclaration} target="_blank" style={{ color: 'rgb(255, 223, 0)' }}>Important Notice, Declarations</a>, <a href={PdfWording} target="_blank" style={{ color: 'rgb(255, 223, 0)' }}>Policy Contract</a> and <a href={PdfDisclosure} target="_blank" style={{ color: 'rgb(255, 223, 0)' }}>Product Disclosure Sheet</a>.</Label>
                                    </FormGroup>

                                    {ErrorMsgDnC == true ?
                                        <span style={{ color: "red", fontSize: "12px" }}>*Please make sure you have check and agree to the Important Notice, Declarations, Policy Contract and Product Disclosure Sheet.</span>
                                        :
                                        <></>
                                    }

                                </div>

                            </div>

                            <div className="TotalPaymenyContn">
                                <div className="row" style={{ width: "800px" }}>
                                    <div className="" style={{ width: "70%" }}>
                                        <h4 style={{ color: 'white' }}><b>Total : RM {numberWithCommas(finalTotalPremium.toFixed(2))}</b></h4>
                                        <span style={{ color: 'white' }}>By clicking Pay button, I agree and accept the</span><span style={{ color: 'rgb(255, 223, 0)' }} onClick={this.toggle}> Terms and Condition</span>
                                    </div>
                                    <div className="" style={{ width: "30%", paddingTop: "20px" }}>
                                        {IsDisable == true ?
                                            /*<Button className="SummaryPayBtn" disabled={true}>Pay</Button>*/
                                            <Button className="SummaryPayBtn" onClick={this.checkDisclaimer}>Pay</Button>
                                            :
                                            /*<Button color="warning" className={"SummaryPayBtn" + (product.isLoading ? "spinning" : "")} disabled={product.isLoading} onClick={CreatePolicy}>{product.isLoading ? "Paying" : "Pay"}</Button>*/
                                            <Button className={"SummaryPayBtn" + (product.isLoading ? "spinning" : "")} disabled={product.isLoading} onClick={CreatePolicy}>{product.isLoading ? "Paying" : "Pay"}</Button>
                                        }
                                    </div>
                                </div>
                            </div>

                        </div>


                    </div>
                </div>
            </div>
        )
    }

}


class TermsAndCondition extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            IsModelForm: true
        }

        this.toggle = this.toggle.bind(this);
    }


    toggle(e) {
        this.props.toggleModel(e);
    }

    render() {

        const { PickUpData, rearrangePickUpList, CustomerPickUpData } = this.props;
        return (
            <Modal isOpen={this.props.isOpenModel} toggle={this.toggle} contentClassName="CustomModal-content" className="CustomModal-dialog">
                <ModalHeader toggle={this.toggle} className="CustomModal-header"></ModalHeader>
                <ModalBody>

                    <TermsofService
                        IsModelForm={this.state.IsModelForm}
                    />

                </ModalBody>
            </Modal>
        )
    }
}

class TableBody extends React.Component {
    constructor(props) {
        super(props);
        this.state = {

        };
    }


    render() {

        const { Plan } = this.props;

        //let coverage = 0;
        //let premium = 0;
        //let premiumInTransit = 0;
        //let premiumInPremise = 0;
        //let totalPremium = 0;

        //if (Plan.coverage !== null) {
        //    coverage = parseInt(Plan.coverage);
        //}
        //if (Plan.premium !== null) {
        //    premium = parseInt(Plan.premium);
        //}
        //if (Plan.premiumInTransit !== null) {
        //    premiumInTransit = parseInt(Plan.premiumInTransit);
        //}
        //if (Plan.premiumInPremise !== null) {
        //    premiumInPremise = parseInt(Plan.premiumInPremise);
        //}
        //if (Plan.totalPremium !== null) {
        //    totalPremium = parseInt(Plan.totalPremium);
        //}


        return (
            <>

                {(() => {
                    if (Plan.addOnCoveragePlanId !== 3) {
                        if (Plan.coverage !== null && Plan.premium !== null) {
                            return (
                                <tr>
                                    <td>{Plan.addOnCoveragePlanName}</td>
                                    <td>RM {numberWithCommas(Number(Plan.coverage).toFixed(2))}</td>
                                    <td>RM {numberWithCommas(Number(Plan.premium).toFixed(2))}</td>
                                </tr>
                            )
                        }
                    }

                    else {
                        if (Plan.premiumInTransit !== null && Plan.premiumInPremise !== null) {
                            return (
                                <tr>
                                    <td>{Plan.addOnCoveragePlanName}</td>
                                    <td>In transit: RM {numberWithCommas(Number(Plan.premiumInTransit).toFixed(2))}
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                        In premise: RM {numberWithCommas(Number(Plan.premiumInPremise).toFixed(2))}
                                    </td>
                                    <td>RM {numberWithCommas(Number(Plan.totalPremium).toFixed(2))}</td>
                                </tr>
                            )
                        }
                    }
                })()}

            </>
        )


    }

}

export default hot(module)(ProductDetailsPresentation);