import userSettingConstants from './constants';
import constants from '../_constants/constants';
import userSettingService from "./services";
import { toast } from 'react-toastify';
import { push } from 'connected-react-router';
import Cookies from 'universal-cookie';
import Swal from 'sweetalert2';
import moment from 'moment';
import { reset } from 'redux-form';

const cookies = new Cookies();

// redux/actions.js
const actions = {
    getRoleList,
    addRole,
    updateRole,
    deleteRole
}

function addRole(roleModel) {

    return dispatch => {
        dispatch(request(roleModel));
        userSettingService.addRole(roleModel).then(
            data => {
                if (data.status == "success") {
                    dispatch(success(data.result, roleModel.roleName));
                    Swal.fire(
                        'Add Role Success',
                        '',
                        'success'
                    );
                    dispatch(reset('RolePresentation'));
                }
            },
            error => {
                Swal.fire({
                    type: 'error',

                    text: error.errorMessage,

                })
                dispatch(fail(error.errorMessage));
                dispatch(showFail(error.errorMessage));
            }
        )
    }

    function request(data) {
        return {
            type: userSettingConstants.ADD_ROLE_REQUEST,
            data
        }
    }
    function success(data, email) {
        return {
            type: userSettingConstants.ADD_ROLE_SUCCESS,
            data,
            email
        }
    }
    function fail(error) {
        return {
            type: userSettingConstants.ADD_ROLE_FAIL,
            error
        }
    }
    function showFail(error) {
        return {
            type: userSettingConstants.SHOW_ADD_ROLE_FAIL,
            error
        }
    }
}

function updateRole(roleModel) {

    return dispatch => {
        dispatch(request(roleModel));
        userSettingService.updateRole(roleModel).then(
            data => {
                if (data.status == "success") {
                    dispatch(success(data.result, roleModel.roleName));
                    Swal.fire(
                        'Update Role Success',
                        '',
                        'success'
                    );
                    dispatch(reset('RolePresentation'));
                }
            },
            error => {
                Swal.fire({
                    type: 'error',

                    text: error.errorMessage,

                })
                dispatch(fail(error.errorMessage));
                dispatch(showFail(error.errorMessage));
            }
        )
    }

    function request(data) {
        return {
            type: userSettingConstants.UPDATE_ROLE_REQUEST,
            data
        }
    }
    function success(data, email) {
        return {
            type: userSettingConstants.UPDATE_ROLE_SUCCESS,
            data
        }
    }
    function fail(error) {
        return {
            type: userSettingConstants.UPDATE_ROLE_FAIL,
            error
        }
    }
    function showFail(error) {
        return {
            type: userSettingConstants.SHOW_UPDATE_ROLE_FAIL,
            error
        }
    }
}

function deleteRole(id) {

    return dispatch => {
        dispatch(request(id));
        userSettingService.deleteRole(id).then(
            data => {
                if (data.status == "success") {
                    dispatch(success(data.result));
                    Swal.fire(
                        'Role Delete Success',
                        '',
                        'success'
                    );
                }
            },
            error => {
                Swal.fire({
                    type: 'error',

                    text: error.errorMessage,

                })
                dispatch(fail(error.errorMessage));
                dispatch(showFail(error.errorMessage));
            }
        )
    }

    function request(data) {
        return {
            type: userSettingConstants.ROLE_DELETE_REQUEST,
            data
        }
    }
    function success(data, email) {
        return {
            type: userSettingConstants.ROLE_DELETE_SUCCESS,
            data,
            email
        }
    }
    function fail(error) {
        return {
            type: userSettingConstants.ROLE_DELETE_FAIL,
            error
        }
    }
    function showFail(error) {
        return {
            type: userSettingConstants.ROLE_SHOW_DELETE_FAIL,
            error
        }
    }
}


function getRoleList(page, rowPerPage) {

    return dispatch => {
        dispatch(request(page, rowPerPage));
        userSettingService.getRoleList(page, rowPerPage).then(
            data => {
                dispatch(success(data.result));
            },
            error => {
                dispatch(failure(error));
                Swal.fire({
                    title: 'Invalid status',
                    message: error,
                    type: 'error',
                });
            }
        )
    }

    function request(data) { return { type: userSettingConstants.GET_ROLE_REQUEST, data } }
    function success(data) { return { type: userSettingConstants.GET_ROLE_SUCCESS, data } }
    function failure(error) { return { type: userSettingConstants.GET_ROLE_FAILURE, error } }

}



export default (actions);


