
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Container, Row, Col, Card, CardBody, CardHeader, Collapse, label } from 'reactstrap';
import { NavLink } from 'reactstrap';
import { Link } from 'react-router-dom';
import { HashLink as SectionLink } from 'react-router-hash-link';
import "./style.css";

class faq extends Component {

    constructor(props) {
        super(props);
        this.state = {
            active: [false, false, false]
        };
        this.toggleClass = this.toggleClass.bind(this);
    }



    componentDidMount() {
        window.scrollTo(0, 0);
    }

    toggleClass(e) {
        const prevState = this.state.active;
        if (prevState[e] === true) {
            prevState[e] = false;
        }
        else {
            prevState[e] = true;
        }

        this.setState({ active: prevState });
    };

    render() {


        return (
            <div className="my-5 pt-sm-5">
                <div className="container">
                    
                    <div className="dQCja-D">
                                <div id="main-menu" className="list-group">
                                        <a href="#sub-menu1" className="list-group-item active jDguzg" onClick={() =>this.toggleClass(0)} data-toggle="collapse" data-parent="#main-menu">
                                            <span className={this.state.active[0] == true ? 'caretAfter' : 'caretBefore'}></span><span className="FaqMenu__TopLink-wfht8p-4 eqfJYu"><b>General</b></span></a>
                                        <div className="collapse list-group-level2" id="sub-menu1">
                                            <a className="list-group-item" data-parent="#sub-menu1">
                                                <SectionLink className="sectionLink" to="faq#AboutUs" style={{ textDecoration: "none" }}>About Us</SectionLink>
                                                </a>
                                            <a className="list-group-item" data-parent="#sub-menu1">
                                                <SectionLink className="sectionLink" to="faq#InsubeeProduct" style={{ textDecoration: "none" }}>Insubee Products</SectionLink>
                                                </a>
                                            <a className="list-group-item" data-parent="#sub-menu1">
                                                <SectionLink className="sectionLink" to="faq#InsuranceProvider" style={{ textDecoration: "none" }}>Insurance Provider</SectionLink>
                                                </a>
                                            <a className="list-group-item" data-parent="#sub-menu1">
                                                <SectionLink className="sectionLink" to="faq#ContactUs" style={{ textDecoration: "none" }}>Contact Us</SectionLink>
                                               </a>                            
                                        </div>
                                        <a href="#sub-menu2" className="list-group-item active jDguzg" onClick={() => this.toggleClass(1)} data-toggle="collapse" data-parent="#main-menu">
                                            <span className={this.state.active[1] == true ? 'caretAfter' : 'caretBefore'}></span><span className="FaqMenu__TopLink-wfht8p-4 eqfJYu"><b>Business Insurance</b></span></a>
                                        <div className="collapse list-group-level2" id="sub-menu2">
                                            <a className="list-group-item" data-parent="#sub-menu2">
                                                <SectionLink className="sectionLink" to="faq#Coverage" style={{textDecoration:"none"}}>Coverage</SectionLink>
                                                </a>
                                            <a className="list-group-item" data-parent="#sub-menu2">
                                                <SectionLink className="sectionLink" to="faq#Policy" style={{ textDecoration: "none" }}>Policy</SectionLink>
                                                </a>
                                            <a className="list-group-item" data-parent="#sub-menu2">
                                                <SectionLink className="sectionLink" to="faq#Payment" style={{ textDecoration: "none" }}>Payment</SectionLink>
                                                </a>
                                            <a className="list-group-item" data-parent="#sub-menu2">
                                                <SectionLink className="sectionLink" to="faq#Claims" style={{ textDecoration: "none" }}>Claims</SectionLink>
                                                </a>
                                        </div>
                                    </div>
                        
                    </div>    


                    <div className="ddTRpX">       
                        <section id="AboutUs" className="QuestionsSection__SectionContainer-sc-125iz77-3 ROBvi">
                        </section>
                                <section id="general" className="QuestionsSection__SectionContainer-sc-125iz77-3 iVZZxQ">
                                    <h2 className="QuestionsSection__QuestionsSectionTitle-sc-125iz77-0 cwriKl">
                                        General
                            </h2>
                        </section>
                        <section id="InsubeeProduct" className="QuestionsSection__SectionContainer-sc-125iz77-3 ROBvi">
                        </section>
                            <section className="QuestionsSection__SectionContainer-sc-125iz77-3 ROBvi">
                                    <h3 className="QuestionsSection__QuestionTitle-sc-125iz77-1 cIeUle">
                                        About Insubee
                                </h3>
                                    <div className="QuestionsSection__QuestionAnswer-sc-125iz77-2 czqVdt">
                                        <p className="FaqUS__AnswerParagraph-sc-1dqtm9i-2 iDmszu">
                                        Insubee is an online insurance platform offering bite-size insurance. We aim to simplify insurance by working with insurers to curate 
                                        affordable and simple policies to cover risks that you truly need to worry about.
                                </p>
                                    </div>
                        </section>
                        <section id="InsuranceProvider" className="QuestionsSection__SectionContainer-sc-125iz77-3 ROBvi">
                        </section>
                                <section className="QuestionsSection__SectionContainer-sc-125iz77-3 ROBvi">
                                    <h3 className="QuestionsSection__QuestionTitle-sc-125iz77-1 cIeUle">
                                        What policies do Insubee offer?
                                </h3>
                                    <div className="QuestionsSection__QuestionAnswer-sc-125iz77-2 czqVdt">
                                        <p className="FaqUS__AnswerParagraph-sc-1dqtm9i-2 iDmszu">
                                        We are currently offering <a href="/Business" style={{ color: "#005FFF" }}>business insurance</a>.
                                        We are continuously exploring new products together with insurance providers to customise simple and affordable policies to meet your needs. 

                                        Follow us on Instagram and Facebook to get the latest updates.
                                </p>
                                    </div>
                            </section>
                            <section id="ContactUs" className="QuestionsSection__SectionContainer-sc-125iz77-3 ROBvi">
                            </section>
                                <section className="QuestionsSection__SectionContainer-sc-125iz77-3 ROBvi">
                                    <h3 className="QuestionsSection__QuestionTitle-sc-125iz77-1 cIeUle">
                                        Who underwrites the insurance policy?
                                </h3>
                                    <div className="QuestionsSection__QuestionAnswer-sc-125iz77-2 czqVdt">
                                        <p className="FaqUS__AnswerParagraph-sc-1dqtm9i-2 iDmszu">
                                        We work with the top insurance providers such as Allianz General Insurance Company (Malaysia) Berhad, Tokio Marine Insurance (Malaysia) Berhad 
                                        and MSIG Insurance (Malaysia) Bhd to tailor make policies just for you. 
                                </p>
                                    </div>
                           
                            </section>
                            <section className="QuestionsSection__SectionContainer-sc-125iz77-3 ROBvi">
                            </section>
                                 <section className="QuestionsSection__SectionContainer-sc-125iz77-3 ROBvi">
                                    <h3 className="QuestionsSection__QuestionTitle-sc-125iz77-1 cIeUle">
                                        How can I contact you?
                                </h3>
                                    <div className="QuestionsSection__QuestionAnswer-sc-125iz77-2 czqVdt">
                                        <p className="FaqUS__AnswerParagraph-sc-1dqtm9i-2 iDmszu">
                                        You can send us an email at help.support@insubee.com. 
                                </p>
                                    </div>
                                </section>

                            <br />

                            <section id="Coverage" className="QuestionsSection__SectionContainer-sc-125iz77-3 ROBvi">
                            </section>
                            <section className="QuestionsSection__SectionContainer-sc-125iz77-3 iVZZxQ">
                                <h2 className="QuestionsSection__QuestionsSectionTitle-sc-125iz77-0 cwriKl">
                                    Business Insurance 
                            </h2>
                            </section>
                            <section className="QuestionsSection__SectionContainer-sc-125iz77-3 ROBvi">
                                <h3 className="QuestionsSection__QuestionTitle-sc-125iz77-1 cIeUle">
                                    What does SME Business policy cover? 
                                </h3>
                                <div className="QuestionsSection__QuestionAnswer-sc-125iz77-2 czqVdt">
                                    <p className="FaqUS__AnswerParagraph-sc-1dqtm9i-2 iDmszu">
                                        SME Business policy covers your property damage caused by fire, lightning, storm, flood, vandalism, earthquakes, aircraft damage and explosion. 
                                        In case your home becomes unavailable, we will also cover inconvenient cost. This policy also covers you for losses due to theft and other optional covers based on your needs. 
                                </p>
                                </div>
                            </section>
                            <section className="QuestionsSection__SectionContainer-sc-125iz77-3 ROBvi">
                                <h3 className="QuestionsSection__QuestionTitle-sc-125iz77-1 cIeUle">
                                    How do I add extra coverage? 
                                </h3>
                                <div className="QuestionsSection__QuestionAnswer-sc-125iz77-2 czqVdt">
                                    <p className="FaqUS__AnswerParagraph-sc-1dqtm9i-2 iDmszu">
                                        You can also get extra coverage by purchasing a basic pack or premier pack. Add on packs provides additional coverage such as: 
                                        <ul>
                                            <li style={{ fontSize: "18px" }}>Burglary - Provides protection against incidents of theft to your property </li>
                                            <li style={{ fontSize: "18px" }}>Money - Cover loss of money whilst in transit and whilst in the premises</li>
                                            <li style={{ fontSize: "18px" }}>Glass - Indemnify for any glass broken by fracture extending through the entire thickness of the glass as a result of an accident</li>
                                            <li style={{ fontSize: "18px" }}>Public Liability - Covers legal liability to third party as a result of an accident happening in connection with your business operations </li>
                                            <li style={{ fontSize: "18px" }}>Employer&apos;s Liability - Indemnifies against liability at law to pay compensation and cost and expenses to the employees in respect
                                                of accidental bodily injury or disease arising out of or in the course of employment</li>
                                        </ul>
                                </p>
                                </div>
                            </section>
                            <section id="Policy" className="QuestionsSection__SectionContainer-sc-125iz77-3 ROBvi">
                            </section>
                            <section id="general_how_are_you_using_my_premium_dollars" className="QuestionsSection__SectionContainer-sc-125iz77-3 ROBvi">
                                <h3 className="QuestionsSection__QuestionTitle-sc-125iz77-1 cIeUle">
                                    Can I increase or decrease coverages?
                                </h3>
                                <div className="QuestionsSection__QuestionAnswer-sc-125iz77-2 czqVdt">
                                    <p className="FaqUS__AnswerParagraph-sc-1dqtm9i-2 iDmszu">
                                        Absolutely. If you do not wish to have add on basic pack or premier pack which offers fixed coverage, you&apos;ll able to select and adjust the coverage and coverage amount that is suitable for you. 
                                </p>
                                </div>
                            </section>
                            <section id="Payment" className="QuestionsSection__SectionContainer-sc-125iz77-3 ROBvi">
                            </section>
                            <section className="QuestionsSection__SectionContainer-sc-125iz77-3 ROBvi">
                                <h3 className="QuestionsSection__QuestionTitle-sc-125iz77-1 cIeUle">
                                    When will I receive my policy? 
                                </h3>
                                <div className="QuestionsSection__QuestionAnswer-sc-125iz77-2 czqVdt">
                                    <p className="FaqUS__AnswerParagraph-sc-1dqtm9i-2 iDmszu">
                                        After buying a policy, we&apos;ll send you a copy of your new policy by email withing 3 working days. You&apos;ll also be able to download it from our Insubee website. 
                                </p>
                                </div>
                            </section>
                            <section className="QuestionsSection__SectionContainer-sc-125iz77-3 ROBvi">
                            </section>
                            <section className="QuestionsSection__SectionContainer-sc-125iz77-3 ROBvi">
                                <h3 className="QuestionsSection__QuestionTitle-sc-125iz77-1 cIeUle">
                                    How do I pay for my insurance?  
                                </h3>
                                <div className="QuestionsSection__QuestionAnswer-sc-125iz77-2 czqVdt">
                                    <p className="FaqUS__AnswerParagraph-sc-1dqtm9i-2 iDmszu">
                                        You can pay us via debit card, credit card, online banking or through various e-wallet options. 
                                </p>
                                </div>
                            </section>
                            <section id="Claims" className="QuestionsSection__SectionContainer-sc-125iz77-3 ROBvi">
                            </section>
                            <section className="QuestionsSection__SectionContainer-sc-125iz77-3 ROBvi">
                                <h3 className="QuestionsSection__QuestionTitle-sc-125iz77-1 cIeUle">
                                    What to I do in the event of claim? 
                                </h3>
                                <div className="QuestionsSection__QuestionAnswer-sc-125iz77-2 czqVdt">
                                    <p className="FaqUS__AnswerParagraph-sc-1dqtm9i-2 iDmszu">
                                        You must notify us via email to help.support@insubee.com with full details of the incident or accident as soon as possible. 
                                        If burglary or break-on is involved, you are required to lodge a police report immediately. 
                                </p>
                                </div>
                            </section>

                    </div>    
                    
                </div>


                </div>
           

        );
    }
}



export default connect()(faq);
