import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Modal, ModalBody, ModalFooter, ModalHeader, Row, Col, Card, CardBody, Badge, Container, Label, Input, InputGroup, InputGroupText } from 'reactstrap';
import { NavLink } from 'reactstrap';
import { Link } from 'react-router-dom';

import $ from "jquery";
import Browser from '../../_helpers/browser';


import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';

//import facebook from '../../../image/Footer/facebook.png';
//import instagram from '../../../image/Footer/instagram.png';
import whatapps from '../../../image/Footer/WhatsApp.png';
import facebook from '../../../image/NavBar/fbIcon.svg'
import instagram from '../../../image/NavBar/igIcon.svg'



import moment from 'moment';
import DesignJustForYou from '../../../image/NavBar/DesignJustForYou.png'
import BackedbyChampions from '../../../image/NavBar/BackedbyChampions.png'
import EasyPayment from '../../../image/NavBar/EasyPayment.png'
import InsubeeLogoFooterVariation from "../../../image/Footer/InsubeeLogoFooter.png";
import InsubeeLogoFooter from "../../../image/InsubeeLogoFooter.svg";
//const propTypes = {
//    children: PropTypes.node,
//};

const defaultProps = {};

class DefaultFooter extends Component {
    constructor(props) {
        super(props);


    }

    componentDidMount() {
        /*$('.nav-tabs a[href="#installation-tab"]').tab('show');*/

        if (Browser.isMobile()) {
            $(function () {
                $(".various" + new Date().getTime() % 3).css("display", "block");
            });
        }
    }

    render() {

        const currentPagePath = window.location.pathname;

        return (

            <>

                {
                    currentPagePath === "/HomeI" ? (

                        <footer>
                            <div className="main-footer-variation">
                                <div className="footerVariation">
                                    <div class="leftSide-footerVariation">
                                        <img src={InsubeeLogoFooterVariation} alt="Logo" class="mb-3"/>
                                        <p>Insubee.com website and its services are owned and operated by GD Valueguard Sdn. Bhd. Reg. No. : 201201021130 (1005622-K), a registered and licensed insurance agent.</p>
                                    </div>
                                    <div className="right-footerVariation">
                                        <a href="/TermsofService" style={{ color: "white" }}>Term of Service</a>
                                        <a href="/PrivacyPolicy" style={{ color: "white" }}>Privacy Policy</a>
                                    </div>
                                </div>
                                <div className="">
                                    <p style={{ fontSize: "14px" }}>2024 GD Valueguard Sdn. Bhd. (1005622-K) All Rights Reserved.</p>
                                </div>
                            </div>
                        </footer>

                    ) : currentPagePath === "/HomeII" ? (

                            <footer>
                                <div className="main-footer-variation">
                                    <div className="footerVariation">
                                        <div class="leftSide-footerVariation">
                                            <img src={InsubeeLogoFooterVariation} alt="Logo" class="mb-3" />
                                            <p>Insubee.com website and its services are owned and operated by GD Valueguard Sdn. Bhd. Reg. No. : 201201021130 (1005622-K), a registered and licensed insurance agent.</p>
                                        </div>
                                        <div className="right-footerVariation">
                                            <a href="/TermsofService" style={{ color: "white" }}>Term of Service</a>
                                            <a href="/PrivacyPolicy" style={{ color: "white" }}>Privacy Policy</a>
                                        </div>
                                    </div>
                                    <div className="">
                                        <p style={{ fontSize: "14px" }}>2024 GD Valueguard Sdn. Bhd. (1005622-K) All Rights Reserved.</p>
                                    </div>
                                </div>
                            </footer>

                    ) : (
                        <footer>

                            <div className="pageBottom">
                                <div className="BenefitsList">
                                    <div className="various0">
                                        <img src={DesignJustForYou} />
                                        <h6>Design Just For You</h6>
                                        <span>Insubee work with insurance companies to design policies that fit just for you.</span>
                                    </div>
                                    <div className="various1">
                                        <img src={BackedbyChampions} />
                                        <h6>Backed by Champions</h6>
                                        <span>Insubee policies are underwritten by top insurance companies.</span>
                                    </div>
                                    <div className="various2">
                                        <img src={EasyPayment} />
                                        <h6>Easy Payment</h6>
                                        <span>Insubee accept payment through online banking, credit card and also e-wallets.</span>
                                    </div>
                                </div>
                            </div>

                            <div className="row primary">

                                <div className="column about">
                                    <img src={InsubeeLogoFooter} />
                                    <span style={{ marginTop: "-20px" }}>
                                        Insubee.com website and its services are owned and operated by
                                        GD Valueguard Sdn. Bhd. Reg. No. : 201201021130 (1005622-K),
                                        a registered and licensed insurance agent.
                                    </span>
                                    <div className="social">
                                        <div className="row">
                                            <div className="col-md-1">
                                                <a href="https://www.facebook.com/myinsubee/" target="_blank" > <img src={facebook} /></a>
                                            </div>
                                            <div className="col-md-1">
                                                <a href="https://www.instagram.com/myinsubee/" target="_blank" ><img src={instagram} /></a>
                                            </div>
                                            <div className="col-md-1">
                                                <a href="https://wa.me/60127850633" target="_blank" > <img src={whatapps} style={{ width: "25.8px", height: "25.8px" }} /></a>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="column links company-column">
                                    <Input type="checkbox" id="COMPANY" />
                                    <Label for="COMPANY" className="FaqQ2"><b>COMPANY</b>
                                        <ul className="dply-none">
                                            <li>
                                                <a href="/AboutUs">About Us</a>
                                            </li>
                                            <li>
                                                <a href="/TermsofService">Term of Service</a>
                                            </li>
                                            <li>
                                                <a href="/PrivacyPolicy">Privacy Policy</a>
                                            </li>
                                            <li>
                                                <a href="/Business">Products</a>
                                            </li>
                                            <li>
                                                <a href="/ContactUs">Contact Us</a>
                                            </li>

                                        </ul>
                                    </Label>
                                </div>

                                <div className="column links resources-column">
                                    <Input type="checkbox" id="RESOURCES" />
                                    <Label for="RESOURCES"><b>RESOURCES</b>
                                        <ul className="dply-none">
                                            <li>
                                                <a href="/Faq">FAQs</a>
                                            </li>
                                            <li>
                                                <a href="/Blog">Blogs</a>
                                            </li>

                                        </ul>
                                    </Label>
                                </div>

                                <div className="column links payment-column">
                                    <Input type="checkbox" id="PAYMENT" />
                                    <Label for="PAYMENT"><b>PAYMENT METHOD</b>
                                        <ul className="dply-none">
                                            <li>
                                                <img src={'../../assets/img/Footer/Master-card.png'} width="50" />
                                                <img src={'../../assets/img/Footer/Visa.png'} height="50" width="50" style={{ marginLeft: "15px" }} />
                                                <img src={'../../assets/img/Footer/fpx.png'} height="50" width="50" />
                                                <img src={'../../assets/img/Footer/grab-pay.png'} height="50" width="50" style={{ marginLeft: "15px" }} />
                                                <img src={'../../assets/img/Footer/maybank-qr-pay.jpg'} height="30" />
                                                <img src={'../../assets/img/Footer/touch-n-go.png'} height="50" width="50" style={{ marginLeft: "11px" }} />
                                                <img src={'../../assets/img/Footer/boost.png'} height="50" width="50" />
                                                <img src={'../../assets/img/Footer/shopee-pay.png'} height="50" width="50" style={{ marginLeft: "18px" }} />
                                            </li>


                                        </ul>
                                    </Label>
                                </div>

                                <div className="column subscribe">
                                    <span><b>WE ALSO MAKE EMAILS.</b></span>
                                    <div>
                                        <label>Receive email updates on stuff you’ll probably want to know about.</label>
                                    </div>
                                    <InputGroup className="input-group mt-3">
                                        <Input className="footer-email-input" id="emailID" name="email" label="email" type="text" placeholder="Enter your email" style={{ fontSize: "13px" }} />
                                        <InputGroupText style={{ borderRightColor: "#575757", borderLeftColor: "#e9ecef", borderRadius: "0px 50px 50px 0px" }}><FontAwesomeIcon icon={faArrowRight} /></InputGroupText>
                                    </InputGroup>

                                    {/*<input type="email" placeholder="Your email id here" />*/}
                                </div>


                            </div>

                            <div className="row copyright">
                                {/*<div class="footer-menu">*/}

                                {/*</div>*/}
                                <span>2022 GD Valueguard Sdn. Bhd. (1005622-K) All Rights Reserved.</span>
                            </div>
                        </footer>
                    )
                }

            </>

        );
    }
}

//DefaultFooter.propTypes = propTypes;
DefaultFooter.defaultProps = defaultProps;

export default DefaultFooter;