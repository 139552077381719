import React, { Component } from 'react';
import { Button, FormGroup, Label, Input, Row, Col, Card, CardBody, InputGroup, InputGroupText } from 'reactstrap';
import "./style.css";
import { Field, reduxForm } from 'redux-form';
import ValidateField from "../GlobalComponents/FormElements/ValidateField";
import { required, email, alphabets, passwordsMatch, onlyNumbersWithMaxLength, alphaNumeric, phoneNumberNormalize, number } from '../GlobalComponents/ValidationRules';
import DropdownSelectField from "../GlobalComponents/FormElements/DropdownSelectField";
import { hot } from "react-hot-loader";

//const PostcodeNormalize = onlyNumbersWithMaxLength(5);

class SubmitClaimsForm extends React.Component {

    constructor(props) {
        super(props);
        this.state = {

        };
        this.handleChange = this.handleChange.bind(this);
    }

    handleChange(event) {

        const target = event.target;
        const value = target.value;

        const name = target.name;

        this.setState({
            [name]: value
        });
    }

    componentDidMount() {
        window.scrollTo(0, 0);
    }

    render() {
        const { policyList, handleSubmit, changeHandler, buttonDisabled, clear } = this.props;

        return (
            <form onSubmit={handleSubmit} >
                <div className="my-3">
                    <div className="container">
                        <Row>
                            <Col xs="12" sm="12" lg="12">

                                <div className="row">
                                    <Card className="col-sm-12 border-0">
                                        <CardBody>
                                            <FormGroup row>
                                                <Label
                                                    className="customLabel customRequired"
                                                    sm={3}
                                                >
                                                    Date
                                                </Label>
                                                <Col sm={{ offset: 1, size: 7 }}>
                                                    <Field className="form-control" placeholder="" name="date" label="date" component={ValidateField} disabled/>
                                                </Col>
                                            </FormGroup>
                                            <FormGroup row>
                                                <Label
                                                    className="customLabel customRequired"
                                                    sm={3}
                                                >
                                                    Policy No.
                                                </Label>
                                                <Col sm={{ offset: 1, size: 7 }}>
                                                    <Field
                                                        name="PolicyNo"
                                                        component={DropdownSelectField}
                                                        label="Policy No."
                                                        filter={false}
                                                        validate={[required]}
                                                        type="text"
                                                        data={policyList}
                                                        placeholder="Please select"
                                                        valueField="value"
                                                        textField="label"
                                                    />
                                                </Col>
                                            </FormGroup>
                                            <FormGroup row>
                                                <Label className="customLabel customRequired" sm={3}>
                                                    Please download your claim form here
                                                </Label>
                                                <Col sm={{ offset: 1, size: 7 }} style={{paddingTop: "15px"}}>
                                                    <a href="/assets/NoticeofClaimMHS3.pdf" target="_blank" className="customButtonLink">
                                                        <b>Download</b>
                                                </a>
                                                </Col>
                                            </FormGroup>
                                            <FormGroup row>
                                                <Label
                                                    className="customLabel customRequired"
                                                    sm={3}
                                                >
                                                    Claim Amount (RM)
                                                </Label>
                                                <Col sm={{ offset: 1, size: 7 }}>
                                                    <Field className="form-control" name="ClaimAmount" label="ClaimAmount" component={ValidateField} type="text" validate={[required, number]} />
                                                </Col>
                                            </FormGroup>

                                            <Card style={{ border: "2px solid", textAlign:"center", marginTop: "3%"}}>
                                                <CardBody>
                                                    <FormGroup>
                                                        <Label for="forFileUpload">
                                                            Please submit your completed claim form here
                                                        </Label>
                                                        <br />
                                                        <Input
                                                            id="forFileUpload"
                                                            name="claimFile"
                                                            type="file"
                                                            accept="application/pdf"
                                                            onChange={changeHandler}
                                                            multiple
                                                            className="form-control-file"
                                                            //style={{marginLeft: "40%", marginRight: "40%"}}
                                                        />
                                                    </FormGroup>


                                                </CardBody>
                                            </Card>
                                            

                                        </CardBody>
                                    </Card>
                                </div>

                                <br></br>


                            </Col>
                        </Row>
                        <Row>

                        </Row>
                    </div>
                </div>

                <div className="col-sm-12">
                    <Button color="warning" className="col-sm-2 btn font-weight-bold btn-sm btn-insubee-orange" onClick={clear} size="sm">Reset</Button>

                    <Button color="warning" className="col-sm-2 mt-sm-0 mt-4 ml-sm-2 font-weight-bold btn-sm btn btn-insubee-orange" size="sm" onClick={handleSubmit} disabled={buttonDisabled} >Apply</Button>
                </div>

            </form>
        )
    }
}


SubmitClaimsForm = reduxForm({
    // a unique name for the form
    form: 'SubmitClaimsForm',
    destroyOnUnmount: true,
    initialValues: {

    }
})(SubmitClaimsForm)


export default hot(module)(SubmitClaimsForm);