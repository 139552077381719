import React from 'react';
import { connect } from 'react-redux'
import ProductGetQuoteForm from './ProductGetQuoteForm';
import { Field, reduxForm, FormSection, change, untouch } from 'redux-form';
import { hot } from 'react-hot-loader';
import "./style.css";
import ProductSelectedPlan from './ProductSelectedPlan';
import ProductDetails from './ProductDetails';
import Swal from 'sweetalert2';
import moment from 'moment';
import EditAddOnForm from './EditAddOnForm';

class ProductGetQuotePresentation extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            SelectedPlan: false,
            IsSummary: false,
            IsOwn: false,
            DisableFinancialInstitutionType: true,
            GetFromLocalStorage: false,
            //IsAllowedQuote: false,
            NonPremiumClick: true,
            IsQuoteList: [
                {
                    addOnCoveragePlanId: 1,
                    IsQuote: true,
                    ShowErrorMsg: false,
                    DisableModelViewButton: true
                },
                {
                    addOnCoveragePlanId: 2,
                    IsQuote: false,
                    ShowErrorMsg: false,
                    DisableModelViewButton: true
                },
                {
                    addOnCoveragePlanId: 3,
                    IsQuote: false,
                    ShowErrorMsg: false,
                    DisableModelViewButton: true
                },
                {
                    addOnCoveragePlanId: 4,
                    IsQuote: false,
                    ShowErrorMsg: false,
                    DisableModelViewButton: true
                },
                {
                    addOnCoveragePlanId: 5,
                    IsQuote: false,
                    ShowErrorMsg: false,
                    DisableModelViewButton: true
                },
                {
                    addOnCoveragePlanId: 6,
                    IsQuote: false,
                    ShowErrorMsg: false,
                    DisableModelViewButton: true
                }
            ],
            torefresh: false,
            checkDAC: false,
            ErrorMsgDnC: false,//Error Message for Declaration & Confirmation
            RemoveBtnFinancialInstitution: false,
            RemoveBtnFinancialInstitutionType: false,

        };

        this.handleSubmit = this.handleSubmit.bind(this);
        this.onStartDateChange = this.onStartDateChange.bind(this);
        this.onClickPropertyOwner = this.onClickPropertyOwner.bind(this);
        this.onClickFinancialInst = this.onClickFinancialInst.bind(this);
        this.editToggle = this.editToggle.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.PremiumCheck = this.PremiumCheck.bind(this);
        this.CancelAddOnCoverage = this.CancelAddOnCoverage.bind(this);
        this.OnClickSummary = this.OnClickSummary.bind(this);
        this.CreatePolicy = this.CreatePolicy.bind(this);
        this.handleCheckBox = this.handleCheckBox.bind(this);
        this.OnClickApplyDiscount = this.OnClickApplyDiscount.bind(this);
        this.onClickBack = this.onClickBack.bind(this);
        this.onYearChange = this.onYearChange.bind(this);
        this.BackToSelectPlan = this.BackToSelectPlan.bind(this);
        this.removeValue = this.removeValue.bind(this);
    }

    removeValue(event, name) {

        if (name == "FinancialInstitution") {
            this.props.qtyChange("ProductGetQuoteForm", "FinancialInstitution.financialInstitutionId", 'undefined');
            this.props.qtyChange("ProductGetQuoteForm", "FinancialInstitution.financialInstitutionName", 'Please Select');
            this.props.qtyChange("ProductGetQuoteForm", "FinancialInstitutionType.financialInstitutionTypeValue", 'undefined');
            this.props.qtyChange("ProductGetQuoteForm", "FinancialInstitutionType.financialInstitutionTypeDisplayName", 'Please Select');
            this.setState({
                DisableFinancialInstitutionType: true,
                RemoveBtnFinancialInstitution: false,
                RemoveBtnFinancialInstitutionType: false
            });
        }

        if (name == "FinancialInstitutionType") {
            this.props.qtyChange("ProductGetQuoteForm", "FinancialInstitutionType.financialInstitutionTypeValue", 'undefined');
            this.props.qtyChange("ProductGetQuoteForm", "FinancialInstitutionType.financialInstitutionTypeDisplayName", 'Please Select');
            this.setState({
                RemoveBtnFinancialInstitutionType: false
            });
        }

    }

    BackToSelectPlan() {

        this.setState({
            IsSummary: false,
            SelectedPlan: true,
            voucher: "",
            Discount: ""
        });
    }

    onYearChange(year) {

        this.setState({
            BuildingConstructedYear: year
        });

    }

    onClickBack() {

        //let ArrayForIsQuote = this.state.IsQuoteList.map(x => (
        //    x.addOnCoveragePlanId !== 1 ? { ...x, IsQuote: false } : x
        //))

        this.setState({
            SelectedPlan: false,
            GetFromLocalStorage: true,
            //IsQuoteList: ArrayForIsQuote,
            //IsAllowedQuote: false
        });
    }

    OnClickApplyDiscount() {

        let FromGetQuoteModel = this.state.getQuoteModelList;
        let FromSelectedPlanResult = this.state.SelectedPlanResult;

        var DiscountObj = {

            totalPremiumAmount: FromSelectedPlanResult.totalPremiumAmount,
            discountVoucherCode: this.state.voucher,
            //productId: FromSelectedPlanResult.ProductId,
            productId: 1,
            guestEmailAddress: FromGetQuoteModel.GuestEmailAddress
        }


        if (sessionStorage.getItem("insubeeToken") == undefined) {

            this.props.GuestCalculateDiscountVoucher(DiscountObj)
        }

        else {

            this.props.UserCalculateDiscountVoucher(DiscountObj)
        }

        this.setState({
            HandleDiscountFailed: false
        });
    }

    handleCheckBox(event) {

        const target = event.target;
        const value = target.checked;

        const name = target.name;
        this.setState({
            [name]: value,
            //Error Message for Declaration & Confirmation
            ErrorMsgDnC: false
        });
    }

    componentDidMount() {

        window.sessionStorage.removeItem("customerPolicyID");

        this.props.getMasterEntry();
        this.props.getStateHoliday();

        if (sessionStorage.getItem("insubeeToken") == undefined) {

            // for seesion key check if got seesion then nonind add into local sotrage else insert 
            function createGuid() {
                return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
                    var r = Math.random() * 16 | 0, v = c === 'x' ? r : (r & 0x3 | 0x8);
                    return v.toString(16);
                });
            }
            var guid = createGuid()

            this.setState({
                GuestSession: guid
            });

        }
    }

    CreatePolicy() {

        if (this.state.checkDAC == false) {

            this.setState({
                //Error Message for Declaration & Confirmation
                ErrorMsgDnC: true
            });

            Swal.fire({
                title: '',
                text: 'Please make sure you have check and agree to the Important Notice, Declarations, Policy Contract and Product Disclosure Sheet.',
                type: 'info',
            });

        }

        else {

            const CorrespondanceAddress = localStorage.getItem('CorrespondanceAddress');
            const CorrespondanceAddressObj = JSON.parse(CorrespondanceAddress);

            const CustomerPolicyID = sessionStorage.getItem('customerPolicyID');
            const CPID = JSON.parse(CustomerPolicyID);

            let FromGetQuoteModel = this.state.getQuoteModelList;
            let FromSelectedPlanResult = this.state.SelectedPlanResult;

            let DiscountCode = "";

            if (this.state.voucher) {
                DiscountCode = this.state.voucher
            }

            var LocationRiskList = {

                Address1: FromGetQuoteModel.LocationRisk.Address1,
                Address2: FromGetQuoteModel.LocationRisk.Address2,
                Address3: FromGetQuoteModel.LocationRisk.Address3,
                Postcode: FromGetQuoteModel.LocationRisk.Postcode,
                City: FromGetQuoteModel.LocationRisk.City,
                State: FromGetQuoteModel.LocationRisk.State,
                CountryId: 130,
                CorrespondanceAddress1: FromGetQuoteModel.LocationRisk.CorrespondanceAddress1,
                CorrespondanceAddress2: FromGetQuoteModel.LocationRisk.CorrespondanceAddress2,
                CorrespondanceAddress3: FromGetQuoteModel.LocationRisk.CorrespondanceAddress3,
                CorrespondancePostcode: FromGetQuoteModel.LocationRisk.CorrespondancePostcode,
                CorrespondanceCity: FromGetQuoteModel.LocationRisk.CorrespondanceCity,
                CorrespondanceState: FromGetQuoteModel.LocationRisk.CorrespondanceState,
                CorrespondanceCountryId: 130,
                SameInfo: CorrespondanceAddressObj

            }

            var PolicyToCreate = {

                CompanyName: FromGetQuoteModel.CompanyName,
                BusinessRegistrationNo: FromGetQuoteModel.BusinessRegistrationNo,
                BusinessOccupationId: FromGetQuoteModel.BusinessOccupationId,
                BusinessTradeOccupationId: FromGetQuoteModel.BusinessTradeOccupationId,
                PropertyOwnerTypeId: FromGetQuoteModel.PropertyOwnerTypeId,
                BuildingPropertyValue: FromGetQuoteModel.BuildingPropertyValue,
                StockInTradeValue: FromGetQuoteModel.StockInTradeValue,
                FurnitureValue: FromGetQuoteModel.FurnitureValue,
                OfficeEquipmentValue: FromGetQuoteModel.OfficeEquipmentValue,
                MiscellaneousItemValue: FromGetQuoteModel.MiscellaneousItemValue,
                BuildingAge: FromGetQuoteModel.BuildingAge,
                BuildingStorey: FromGetQuoteModel.BuildingStorey,
                CoverageStartDate: moment(FromGetQuoteModel.CoverageStartDate).format("YYYY-MM-DD"),
                CoverageEndDate: FromGetQuoteModel.CoverageEndDate,
                GuestIdentityNumber: FromGetQuoteModel.GuestIdentityNumber,
                GuestEmailAddress: FromGetQuoteModel.GuestEmailAddress,
                GuestName: FromGetQuoteModel.GuestName,
                GuestMobileNo: FromGetQuoteModel.GuestMobileNo,
                GuestSessionKey: FromGetQuoteModel.GuestSessionKey,
                ProductId: FromGetQuoteModel.ProductId,
                GuestNationality: FromGetQuoteModel.GuestNationality,
                GuestIdentificationType: FromGetQuoteModel.GuestIdentificationType,
                DiscountVoucherCode: DiscountCode,
                CustomerPolicyId: CPID == null ? 0 : CPID,
                FinancialInstitutionId: FromGetQuoteModel.FinancialInstitutionId,
                FinancialInstitutionName: FromGetQuoteModel.FinancialInstitutionName,
                FinancialInstitutionType: FromGetQuoteModel.FinancialInstitutionType,
                //FinancialInstitutionTypeValue: FromGetQuoteModel.FinancialInstitutionTypeValue,
                //FinancialInstitutionTypeDisplayName: FromGetQuoteModel.FinancialInstitutionTypeDisplayName,
                BuildingConstructedYear: FromGetQuoteModel.BuildingConstructedYear,
                QuotingReferenceId: FromSelectedPlanResult.quotingReferenceId,
                totalPremiumAmount: FromSelectedPlanResult.totalPremiumAmount,
                serviceTaxAmount: FromSelectedPlanResult.serviceTaxAmount,
                stampDutyAmount: FromSelectedPlanResult.stampDutyAmount,
                finalTotalPremium: FromSelectedPlanResult.finalTotalPremium,
                LocationRisk: LocationRiskList,
                selectedCoveragePlan: FromSelectedPlanResult.selectedCoveragePlan,
                salutation: FromGetQuoteModel.salutation,
                maritalStatus: FromGetQuoteModel.maritalStatus
            }

            if (sessionStorage.getItem("insubeeToken") == undefined) {

                this.props.GuestCreatePolicy(PolicyToCreate)
            }

            else {

                this.props.UserCreatePolicy(PolicyToCreate)
            }

            this.setState({
                //Error Message for Declaration & Confirmation
                ErrorMsgDnC: false
            });

            localStorage.removeItem('selectedCoveragePlan');
        }

    }

    OnClickSummary() {


        let FromGetQuoteModel = this.state.getQuoteModelList;
        let FromSelectedPlanResult = this.state.SelectedPlanResult;

        var productResultSummary = {

            CompanyName: FromGetQuoteModel.CompanyName,
            BusinessRegistrationNo: FromGetQuoteModel.BusinessRegistrationNo,
            Address1: FromGetQuoteModel.LocationRisk.Address1,
            Address2: FromGetQuoteModel.LocationRisk.Address2,
            Address3: FromGetQuoteModel.LocationRisk.Address3,
            postcode: FromGetQuoteModel.LocationRisk.Postcode,
            city: FromGetQuoteModel.LocationRisk.City,
            state: FromGetQuoteModel.LocationRisk.State,
            Country: FromGetQuoteModel.LocationRisk.Country,
            //CorrespondanceAddress
            CorrespondanceAddress1: FromGetQuoteModel.LocationRisk.CorrespondanceAddress1,
            CorrespondanceAddress2: FromGetQuoteModel.LocationRisk.CorrespondanceAddress2,
            CorrespondanceAddress3: FromGetQuoteModel.LocationRisk.CorrespondanceAddress3,
            CorrespondancePostcode: FromGetQuoteModel.LocationRisk.CorrespondancePostcode,
            CorrespondanceCity: FromGetQuoteModel.LocationRisk.CorrespondanceCity,
            CorrespondanceState: FromGetQuoteModel.LocationRisk.CorrespondanceState,
            CorrespondanceCountry: FromGetQuoteModel.LocationRisk.CorrespondanceCountry,
            BusinessOccupation: FromGetQuoteModel.BusinessOccupation,
            BusinessTradeOccupation: FromGetQuoteModel.BusinessTradeOccupationLabel,
            PropertyOwner: FromGetQuoteModel.PropertyOwner,
            BuildingPropertyValue: FromGetQuoteModel.BuildingPropertyValue,
            StockInTradeValue: FromGetQuoteModel.StockInTradeValue,
            FurnitureValue: FromGetQuoteModel.FurnitureValue,
            OfficeEquipmentValue: FromGetQuoteModel.OfficeEquipmentValue,
            MiscellaneousItemValue: FromGetQuoteModel.MiscellaneousItemValue,
            BuildingAge: FromGetQuoteModel.BuildingAge,
            BuildingStorey: FromGetQuoteModel.BuildingStorey,
            CoverageStartDate: moment(FromGetQuoteModel.CoverageStartDate).format("DD/MM/YYYY"),
            EndDate: FromGetQuoteModel.EndDate,
            plan: FromGetQuoteModel.planName,
            ListofSelectedPlan: FromSelectedPlanResult.selectedCoveragePlan,
            totalPremiumAmount: FromSelectedPlanResult.totalPremiumAmount,
            serviceTaxAmount: FromSelectedPlanResult.serviceTaxAmount,
            stampDutyAmount: FromSelectedPlanResult.stampDutyAmount,
            finalTotalPremium: FromSelectedPlanResult.finalTotalPremium,
            GuestIdentityNumber: FromGetQuoteModel.GuestIdentityNumber,
            GuestEmailAddress: FromGetQuoteModel.GuestEmailAddress,
            GuestName: FromGetQuoteModel.GuestName,
            GuestMobileNo: FromGetQuoteModel.GuestMobileNo,
            FinancialInstitutionName: FromGetQuoteModel.FinancialInstitutionName,
            FinancialInstitutionTypeDisplayName: FromGetQuoteModel.FinancialInstitutionTypeDisplayName,
            BuildingConstructedYear: FromGetQuoteModel.BuildingConstructedYear,
            salutation: FromGetQuoteModel.salutation,
            maritalStatus: FromGetQuoteModel.maritalStatus
        }


        this.setState({
            IsSummary: true,
            SelectedPlan: false,
            productResultSummary: productResultSummary

        });
    }

    handleSubmit(getQuoteModel) {

        const { businessOccupation } = this.props;

        const CorrespondanceAddress = localStorage.getItem('CorrespondanceAddress');
        const CorrespondanceAddressObj = JSON.parse(CorrespondanceAddress);

        const CustomerPolicyID = sessionStorage.getItem('customerPolicyID');
        const CPID = JSON.parse(CustomerPolicyID);

        const quoteReferenceId = sessionStorage.getItem('quotingReferenceId');
        const qRID = JSON.parse(quoteReferenceId);

        const SelectedCoveragePlan = localStorage.getItem('selectedCoveragePlan');
        const coveragePlan = JSON.parse(SelectedCoveragePlan);

        let correspondanceCity = "";
        let financialInstitutionId = 0;
        let financialInstitutionName = "";
        let financialInstitutionTypeValue = "";
        let financialInstitutionTypeDisplayName = "";

        if (getQuoteModel.CorrespondanceCity) {
            if (getQuoteModel.CorrespondanceCity !== 'undefined') {
                if (getQuoteModel.CorrespondanceCity.label) {
                    correspondanceCity = getQuoteModel.CorrespondanceCity.label;
                }
                else {
                    correspondanceCity = getQuoteModel.CorrespondanceCity;
                }
            }
        }

        if (getQuoteModel.FinancialInstitution) {
            if (getQuoteModel.FinancialInstitution.financialInstitutionId !== 'undefined') {
                financialInstitutionId = getQuoteModel.FinancialInstitution.financialInstitutionId;
                financialInstitutionName = getQuoteModel.FinancialInstitution.financialInstitutionName;

                if (getQuoteModel.FinancialInstitutionType) {
                    if (getQuoteModel.FinancialInstitutionType.financialInstitutionTypeValue == 'undefined') {
                        Swal.fire({
                            title: 'Please select Financial Institution Type',
                            text: 'Financial Institution Type is required.',
                            type: 'error',
                        });
                        return false;
                    }

                    else {
                        financialInstitutionTypeValue = getQuoteModel.FinancialInstitutionType.financialInstitutionTypeValue;
                        financialInstitutionTypeDisplayName = getQuoteModel.FinancialInstitutionType.financialInstitutionTypeDisplayName;
                    }
                }
            }
        }

        var RiskLocationList = {

            Address1: getQuoteModel.Address1,
            Address2: getQuoteModel.Address2,
            Address3: getQuoteModel.Address3,
            Postcode: getQuoteModel.postcode,
            City: getQuoteModel.city.label ? getQuoteModel.city.label : getQuoteModel.city,
            State: getQuoteModel.state,
            CountryId: 130,
            CorrespondanceAddress1: typeof getQuoteModel.CorrespondanceAddress1 == "undefined" ? "" : getQuoteModel.CorrespondanceAddress1,
            CorrespondanceAddress2: typeof getQuoteModel.CorrespondanceAddress2 == "undefined" ? "" : getQuoteModel.CorrespondanceAddress2,
            CorrespondanceAddress3: typeof getQuoteModel.CorrespondanceAddress3 == "undefined" ? "" : getQuoteModel.CorrespondanceAddress3,
            CorrespondancePostcode: typeof getQuoteModel.CorrespondancePostcode == "undefined" ? "" : getQuoteModel.CorrespondancePostcode,
            //CorrespondanceCity: typeof getQuoteModel.CorrespondanceCity == "undefined" ? "" : getQuoteModel.CorrespondanceCity,
            CorrespondanceCity: correspondanceCity,
            CorrespondanceState: typeof getQuoteModel.CorrespondanceState == "undefined" ? "" : getQuoteModel.CorrespondanceState,
            CorrespondanceCountry: typeof getQuoteModel.CorrespondancePostcode == "undefined" ? "" : "Malaysia",
            CorrespondanceCountryId: 130,
            SameInfo: CorrespondanceAddressObj,
            Country: "Malaysia",

        }

        var getQuoteObj = {

            CompanyName: getQuoteModel.CompanyName.trim(),
            //BusinessRegistrationNo: getQuoteModel.BusinessRegistrationNo.trim(),
            BusinessRegistrationNo: getQuoteModel.BusinessRegistrationNo.replace(/ /g, ''),
            BusinessOccupation: businessOccupation.label,
            BusinessOccupationId: businessOccupation.value,
            BusinessTradeOccupationId: getQuoteModel.BusinessTradeOccupation.value ? getQuoteModel.BusinessTradeOccupation.value : getQuoteModel.BusinessTradeOccupationID,
            BusinessTradeOccupationLabel: getQuoteModel.BusinessTradeOccupation.label ? getQuoteModel.BusinessTradeOccupation.label : getQuoteModel.BusinessTradeOccupation,
            PropertyOwnerTypeId: this.state.PropertyOwnerTypeId,
            PropertyOwner: this.state.PropertyOwnerName,
            BuildingPropertyValue: typeof getQuoteModel.BuildingPropertyQuantity == "undefined" ? "0" : getQuoteModel.BuildingPropertyQuantity + "000",
            StockInTradeValue: typeof getQuoteModel.StockInTradeQuantity == "undefined" ? "0" : getQuoteModel.StockInTradeQuantity + "000",
            FurnitureValue: typeof getQuoteModel.FFFQuantity == "undefined" ? "0" : getQuoteModel.FFFQuantity + "000",
            OfficeEquipmentValue: typeof getQuoteModel.OfficeEquipmentQuantity == "undefined" ? "0" : getQuoteModel.OfficeEquipmentQuantity + "000",
            MiscellaneousItemValue: typeof getQuoteModel.MiscellaneousItemQuantity == "undefined" ? "0" : getQuoteModel.MiscellaneousItemQuantity + "000",
            BuildingAge: typeof getQuoteModel.BuildingAge == "undefined" ? "0" : getQuoteModel.BuildingAge,
            BuildingStorey: typeof getQuoteModel.BuildingStorey == "undefined" ? "0" : getQuoteModel.BuildingStorey,
            CoverageStartDate: moment(this.state.startDate).format("YYYY-MM-DD"),
            EndDate: this.state.endDate,
            CoverageEndDate: this.state.endDateFormatForCreatePolicy,
            GuestEmailAddress: typeof getQuoteModel.guestEmail == "undefined" ? "" : getQuoteModel.guestEmail.trim(),
            GuestName: typeof getQuoteModel.guestName == "undefined" ? "" : getQuoteModel.guestName.trim(),
            GuestMobileNo: typeof getQuoteModel.guestMobileNo == "undefined" ? "" : "+60" + getQuoteModel.guestMobileNo,
            GuestSessionKey: this.state.GuestSession == "undefined" ? "" : this.state.GuestSession,
            //GuestIdentityNumber: typeof getQuoteModel.IdentificationType == "undefined" ? "" : getQuoteModel.guestIC,
            GuestIdentityNumber: typeof getQuoteModel.guestIC == "undefined" ? "" : getQuoteModel.guestIC.replace(/ /g, ''),
            ProductId: 1,
            GuestNationality: "MALAYSIA",
            GuestIdentificationType: "1",
            GuestIdentificationTypeName: "NRIC",
            //GuestIdentificationType: typeof getQuoteModel.IdentificationType == "undefined" ? "" : getQuoteModel.IdentificationType.value.toString(),
            //GuestIdentificationTypeName: typeof getQuoteModel.IdentificationType == "undefined" ? "" : getQuoteModel.IdentificationType.label.toString(),
            //salutation: typeof getQuoteModel.salutation == "undefined" ? "" : getQuoteModel.salutation.label.toString(),
            //salutationID: typeof getQuoteModel.salutation == "undefined" ? "" : getQuoteModel.salutation.value.toString(),
            //maritalStatus: typeof getQuoteModel.maritalStatus == "undefined" ? "" : getQuoteModel.maritalStatus.label.toString(),
            //maritalStatusID: typeof getQuoteModel.maritalStatus == "undefined" ? "" : getQuoteModel.maritalStatus.value.toString(),
            salutation: "",
            salutationID: "",
            maritalStatus: "",
            maritalStatusID: "",
            DiscountVoucherCode: "",
            CustomerPolicyId: CPID == null ? 0 : CPID,
            FinancialInstitutionId: financialInstitutionId,
            FinancialInstitutionName: financialInstitutionName,
            FinancialInstitutionType: financialInstitutionTypeValue,
            FinancialInstitutionTypeDisplayName: financialInstitutionTypeDisplayName,
            //FinancialInstitutionTypeValue: getQuoteModel.FinancialInstitutionType.financialInstitutionTypeValue,
            BuildingConstructedYear: moment(this.state.BuildingConstructedYear).format("YYYY"),
            QuotingReferenceId: qRID == null ? "" : qRID,
            LocationRisk: RiskLocationList,
            SelectedCoveragePlan: coveragePlan ? coveragePlan : [
                {
                    selected: true,
                    addOnCoveragePlanId: 1,
                    coverage: 0
                }
            ],
            planId: 3,
            planName: "Flexi",
            UserId: sessionStorage.getItem("userId") ? sessionStorage.getItem("userId") : 0,
        }

        this.setState({
            SelectedPlan: true,
            getQuoteModelList: getQuoteObj,
            GetFromLocalStorage: false
        });

        let getQuoteJSONString = JSON.stringify(getQuoteObj);
        localStorage.setItem('productFormDetails', getQuoteJSONString);

        let QuoteModel = getQuoteObj;

        //Object.assign(QuoteModel, { planId: 3, planName: "Flexi" })

        this.setState({
            //IsAllowedQuote: true,
            selectedPlan: 3
        });

        this.props.selectPlan(QuoteModel)


    }

    onStartDateChange(date) {

        let FutureDate = moment(date).add(1, "y");

        FutureDate = FutureDate.subtract(1, "d");

        this.props.qtyChange("ProductGetQuoteForm", "endDate", moment(FutureDate).format("DD/MM/YYYY"));


        this.setState({
            startDate: date,
            endDate: moment(FutureDate).format("DD/MM/YYYY"),
            endDateFormatForCreatePolicy: moment(FutureDate).format("YYYY-MM-DD")
        });

    }

    onClickPropertyOwner(event) {

        if (event.value == "1") {

            this.setState({
                PropertyOwnerTypeId: 1,
                selectedRowPropertyOwner: event.value,
                IsOwn: false,
                PropertyOwnerName: event.label,
            });

            this.props.qtyChange("ProductGetQuoteForm", "BuildingPropertyQuantity", "");
            this.props.untouchChange("ProductGetQuoteForm", "BuildingPropertyQuantity");
        }

        if (event.value == "2") {

            this.setState({
                PropertyOwnerTypeId: 2,
                selectedRowPropertyOwner: event.value,
                IsOwn: true,
                PropertyOwnerName: event.label
            });
        }

    };

    onClickFinancialInst(event, name) {

        if (name == "FinancialInstitution") {
            if (event.financialInstitutionId) {
                this.props.qtyChange("ProductGetQuoteForm", "FinancialInstitutionType.financialInstitutionTypeValue", 'undefined');
                this.props.qtyChange("ProductGetQuoteForm", "FinancialInstitutionType.financialInstitutionTypeDisplayName", 'Please Select');
                this.setState({
                    DisableFinancialInstitutionType: false,
                    RemoveBtnFinancialInstitution: true
                });
            }
        }

        if (name == "FinancialInstitutionType") {
            if (event.financialInstitutionTypeValue) {
                this.setState({
                    DisableFinancialInstitutionType: false,
                    RemoveBtnFinancialInstitutionType: true
                });
            }
        }


    }

    componentDidUpdate(previousProps, previousState) {


    }

    componentWillReceiveProps(nextProps) {

        let SelectedPlanResult = [];
        let NewSelectedPlan = [];


        if (typeof nextProps.product !== 'undefined') {

            //Check whether using the previous QuotingReferenceId
            //If statusCode = 5, will remove the previous QuotingReferenceId and call getQuote again.
            if (nextProps.product.ErrorStatus) {
                if (nextProps.product.ErrorStatus.statusCode == 5) {

                    let string = nextProps.product.ErrorStatus.errorMessage;
                    let substring = "CBC submission";
                    if (string.includes(substring)) {

                        this.setState({
                            SelectedPlan: false,
                            GetFromLocalStorage: true,
                        });
                    }

                    else {
                        var getQuoteObj = this.state.getQuoteModelList;
                        getQuoteObj.QuotingReferenceId = ""

                        this.props.selectPlan(getQuoteObj)
                    }
                }
            }

            else {

                if (nextProps.product.QuoteResult) {


                    if (this.state.NonPremiumClick == true) {
                        SelectedPlanResult = nextProps.product.QuoteResult;

                        NewSelectedPlan = SelectedPlanResult.selectedCoveragePlan.map(e => {

                            let ListofQuote = this.state.IsQuoteList.find(el => el.addOnCoveragePlanId == e.addOnCoveragePlanId);

                            e.IsQuote = ListofQuote.IsQuote;
                            e.DisableModelViewButton = ListofQuote.DisableModelViewButton;
                            e.ShowErrorMsg = ListofQuote.ShowErrorMsg;
                            return e;

                        });

                        SelectedPlanResult.selectedCoveragePlan = NewSelectedPlan;
                        //SelectedPlanResult.quotingReferenceId = nextProps.product.QuoteResult.quotingReferenceId;

                        this.setState({
                            SelectedPlanResult: SelectedPlanResult,
                            quotingReferenceId: nextProps.product.QuoteResult.quotingReferenceId,
                            //IsAllowedQuote: false
                        });
                    }

                    else {
                        //For Premium check
                        let CheckedPremiumResult = nextProps.product.QuoteResult.selectedCoveragePlan;

                        let Amount = CheckedPremiumResult.find(x => x.addOnCoveragePlanId == this.state.addOnCoveragePlanId);

                        if (Amount) {
                            this.setState({
                                Amount: Amount
                            });

                        }

                        this.setState({
                            quotingReferenceId: nextProps.product.QuoteResult.quotingReferenceId
                        });

                    }

                    //Save in sessionStorage, so that when user click back btn, able to use back the quotingReferenceId
                    let quotingReferenceIdJSONString = JSON.stringify(nextProps.product.QuoteResult.quotingReferenceId);
                    sessionStorage.setItem('quotingReferenceId', quotingReferenceIdJSONString);

                }

                if (nextProps.product.Discount) {

                    this.setState({
                        Discount: nextProps.product.Discount
                    });

                }
            }
        }
    }

    editToggle(addOnCoveragePlanId) {

        if (addOnCoveragePlanId.target) {

            this.setState({
                editModal: !this.state.editModal,
            });
        }

        else {

            let FindSelectedPlan = this.state.SelectedPlanResult

            let SelectedComponentList = FindSelectedPlan.selectedCoveragePlan.find(x => x.addOnCoveragePlanId == addOnCoveragePlanId);
            let newArrayForErrorMsg;

            if (SelectedComponentList.addOnCoveragePlanId !== 3) {

                if (SelectedComponentList.coverage !== null) {
                    let coverage = SelectedComponentList.coverage.toString();
                    this.props.qtyChange("EditAddOnForm", "coverage" + addOnCoveragePlanId, coverage.slice(0, -3));
                }

            }

            else {

                if (SelectedComponentList.coverageInPremise !== null && SelectedComponentList.coverageInTransit !== null) {
                    let coverageInPremise = SelectedComponentList.coverageInPremise.toString();
                    let coverageInTransit = SelectedComponentList.coverageInTransit.toString();
                    this.props.qtyChange("EditAddOnForm", "coverageInPremise", coverageInPremise.slice(0, -3));
                    this.props.qtyChange("EditAddOnForm", "coverageInTransit", coverageInTransit.slice(0, -3));
                }

            }

            //after close tab will clear the error message
            newArrayForErrorMsg = this.state.IsQuoteList.map(x => (
                x.addOnCoveragePlanId == addOnCoveragePlanId ? { ...x, ShowErrorMsg: false, DisableModelViewButton: true } : x
            ))

            if (newArrayForErrorMsg) {
                FindSelectedPlan.selectedCoveragePlan.map(e => {

                    let ErrorMsg = newArrayForErrorMsg.find(el => el.addOnCoveragePlanId == e.addOnCoveragePlanId);

                    e.ShowErrorMsg = ErrorMsg.ShowErrorMsg;
                    e.DisableModelViewButton = ErrorMsg.DisableModelViewButton;

                    return e;

                });
            }


            this.setState({
                editModal: !this.state.editModal,
                addOnCoveragePlanId: addOnCoveragePlanId,
                SelectedComponentList: SelectedComponentList,
                NonPremiumClick: true,
                DisableModelViewButton: true
            });

        }

    }

    handleChange(event, id) {

        const target = event.target;
        const value = target.value;

        const name = target.name;

        this.setState({
            [name]: value,
        });
    }

    PremiumCheck(addOnCoveragePlanId, ButtonName) {

        const { coverageValues } = this.props;

        let coverage;
        let coverageInTransit;
        let coverageInPremise;
        let Result = this.state.getQuoteModelList;

        Result.quotingReferenceId = this.state.quotingReferenceId;

        if (addOnCoveragePlanId !== 3) {

            if (addOnCoveragePlanId == 2) {
                coverage = coverageValues.coverage2 + "000";
            }

            if (addOnCoveragePlanId == 4) {
                coverage = coverageValues.coverage4 + "000";
            }

            if (addOnCoveragePlanId == 5) {
                coverage = coverageValues.coverage5 + "000";
            }

            if (addOnCoveragePlanId == 6) {
                coverage = coverageValues.coverage6 + "000";
            }
            //coverage = coverageFromModel + "000";
            let ValidateDupObj = Result.SelectedCoveragePlan.find(x => x.addOnCoveragePlanId == addOnCoveragePlanId);

            if (ValidateDupObj) {

                let newArray = Result.SelectedCoveragePlan.map(el => (
                    el.addOnCoveragePlanId == addOnCoveragePlanId ? { ...el, coverage: coverage, selected: true } : el
                ))

                Result.SelectedCoveragePlan = newArray;

                this.setState({
                    NonPremiumClick: false,
                    ConfirmResult: Result,
                    RefreshCalculation: true
                });
            }

            else {

                Result.SelectedCoveragePlan.push({ addOnCoveragePlanId: addOnCoveragePlanId, coverage: coverage, selected: true });
                this.setState({
                    NonPremiumClick: false,
                    ConfirmResult: Result,
                    RefreshCalculation: true
                });
            }
        }

        else {

            coverageInTransit = coverageValues.coverageInTransit + "000";
            coverageInPremise = coverageValues.coverageInPremise + "000";
            let ValidateDupObj = Result.SelectedCoveragePlan.find(x => x.addOnCoveragePlanId == addOnCoveragePlanId);


            if (ValidateDupObj) {

                let newArray = Result.SelectedCoveragePlan.map(el => (
                    el.addOnCoveragePlanId == addOnCoveragePlanId ? { ...el, coverageInTransit: coverageInTransit, coverageInPremise: coverageInPremise, selected: true } : el
                ))

                Result.SelectedCoveragePlan = newArray;

                this.setState({
                    NonPremiumClick: false,
                    ConfirmResult: Result,
                });
            }

            else {

                Result.SelectedCoveragePlan.push({ addOnCoveragePlanId: addOnCoveragePlanId, coverageInTransit: coverageInTransit, coverageInPremise: coverageInPremise, selected: true });
                this.setState({
                    NonPremiumClick: false,
                    ConfirmResult: Result,
                });
            }
        }

        let selectedCoveragePlanJSONString = JSON.stringify(Result.SelectedCoveragePlan);
        localStorage.setItem('selectedCoveragePlan', selectedCoveragePlanJSONString);

        if (ButtonName == "Premium") {
            this.props.selectPlan(Result)
        }

        if (ButtonName == "continue") {

            // code same as edittoggle 
            let FindSelectedPlan = this.state.SelectedPlanResult

            let SelectedComponentList = FindSelectedPlan.selectedCoveragePlan.find(x => x.addOnCoveragePlanId == addOnCoveragePlanId);

            this.setState({
                editModal: !this.state.editModal,
                addOnCoveragePlanId: addOnCoveragePlanId,
                SelectedComponentList: SelectedComponentList,
                NonPremiumClick: true,
                DisableModelViewButton: true
            });

            //after user checked premium then click continue button.
            if (this.state.editModal !== false) {

                let newArray = this.state.IsQuoteList.map(x => (
                    x.addOnCoveragePlanId == addOnCoveragePlanId ? { ...x, IsQuote: true } : x
                ))

                this.props.selectPlan(Result)

                this.setState({
                    IsQuoteList: newArray
                });

            }

        }
    }

    CancelAddOnCoverage(addOnCoveragePlanId) {

        let Result = this.state.getQuoteModelList;

        let newArray = Result.SelectedCoveragePlan.filter(x => x.addOnCoveragePlanId !== addOnCoveragePlanId)

        let newArrayForIsQuote = this.state.IsQuoteList.map(x => (
            x.addOnCoveragePlanId == addOnCoveragePlanId ? { ...x, IsQuote: false, DisableModelViewButton: true } : x
        ))

        Result.SelectedCoveragePlan = newArray;

        this.setState({
            editModal: !this.state.editModal,
            IsQuoteList: newArrayForIsQuote,
            ConfirmResult: Result,
        });

        if (addOnCoveragePlanId == 2) {
            this.props.qtyChange("ProductEditModelView", "coverage2", null);
            this.props.untouchChange("ProductEditModelView", "coverage2");
        }

        if (addOnCoveragePlanId == 3) {
            this.props.qtyChange("ProductEditModelView", "coverageInTransit", null);
            this.props.untouchChange("ProductEditModelView", "coverageInTransit");
            this.props.qtyChange("ProductEditModelView", "coverageInPremise", null);
            this.props.untouchChange("ProductEditModelView", "coverageInPremise");
        }

        if (addOnCoveragePlanId == 4) {
            this.props.qtyChange("ProductEditModelView", "coverage4", null);
            this.props.untouchChange("ProductEditModelView", "coverage4");
        }

        if (addOnCoveragePlanId == 5) {
            this.props.qtyChange("ProductEditModelView", "coverage5", null);
            this.props.untouchChange("ProductEditModelView", "coverage5");
        }

        if (addOnCoveragePlanId == 6) {
            this.props.qtyChange("ProductEditModelView", "coverage6", null);
            this.props.untouchChange("ProductEditModelView", "coverage6");
        }

        this.props.selectPlan(Result)

    }

    render() {
        const { product, businessOccupation } = this.props;

        let ListOfBusinessOccupation = [];
        let ListOfBusinessTradeOccupation = [];
        let ListOfPropertyOwner = [];
        let ListOfFinancialInstitution = [];
        let ListOfFinancialInstitutionType = [];
        let SelectedBusinessTradeOcc;
        let ListOfStateHoliday;
        let SCL;//SelectedComponentList
        let identificationTypes;

        if (product.MasterEntryList) {
            ListOfBusinessOccupation = product.MasterEntryList.businessOccupation;
            ListOfBusinessTradeOccupation = product.MasterEntryList.businessTradeOccupation;
            ListOfPropertyOwner = product.MasterEntryList.propertyOwnerType;
            ListOfFinancialInstitution = product.MasterEntryList.financialInstitutions;
            ListOfFinancialInstitutionType = product.MasterEntryList.financialInstitutionType;
            identificationTypes = product.MasterEntryList.identificationTypes;
        }

        if (product.StateHolidayList) {
            ListOfStateHoliday = product.StateHolidayList;
        }

        if (businessOccupation) {
            SelectedBusinessTradeOcc = ListOfBusinessTradeOccupation.filter(x => x.businessOccupationId == businessOccupation.value);
        }

        if (this.state.torefresh === true) {
            this.setState({
                torefresh: false
            })
        }

        if (this.state.SelectedComponentList) {
            SCL = this.state.SelectedComponentList;

        }

        return (
            <>
                {this.state.SelectedPlan == false && this.state.IsSummary == false &&
                    <ProductGetQuoteForm
                        onSubmit={this.handleSubmit}
                        ListOfBusinessOccupation={ListOfBusinessOccupation}
                        //BusinessOccupationonClick={this.BusinessOccupationonClick}
                        SelectedBusinessTradeOcc={SelectedBusinessTradeOcc}
                        ListOfPropertyOwner={ListOfPropertyOwner}
                        onClickPropertyOwner={this.onClickPropertyOwner}
                        onClickFinancialInst={this.onClickFinancialInst}
                        //selectedRow={this.state.selectedRow}
                        //selectedRowPropertyOwner={this.state.selectedRowPropertyOwner}
                        IsOwn={this.state.IsOwn}
                        ListOfStateHoliday={ListOfStateHoliday}
                        onStartDateChange={this.onStartDateChange}
                        startDate={this.state.startDate}
                        GetFromLocalStorage={this.state.GetFromLocalStorage}
                        ListOfFinancialInstitution={ListOfFinancialInstitution}
                        ListOfFinancialInstitutionType={ListOfFinancialInstitutionType}
                        onYearChange={this.onYearChange}
                        BuildingConstructedYear={this.state.BuildingConstructedYear}
                        identificationTypes={identificationTypes}
                        removeValue={this.removeValue}
                        DisableFinancialInstitutionType={this.state.DisableFinancialInstitutionType}
                        RemoveBtnFinancialInstitution={this.state.RemoveBtnFinancialInstitution}
                        RemoveBtnFinancialInstitutionType={this.state.RemoveBtnFinancialInstitutionType}
                    />
                }


                {this.state.SelectedPlan !== false && this.state.IsSummary == false &&

                    <ProductSelectedPlan
                        SelectedPlanResult={this.state.SelectedPlanResult}
                        editToggle={this.editToggle}
                        onClickBack={this.onClickBack}
                        OnClickSummary={this.OnClickSummary}
                    //selectedPlan={this.state.selectedPlan}
                    />
                }

                {this.state.IsSummary == true && this.state.SelectedPlan == false &&
                    <ProductDetails
                        productResultSummary={this.state.productResultSummary}
                        //BackToSelectPlan={this.BackToSelectPlan}
                        CreatePolicy={this.CreatePolicy}
                        OnClickApplyDiscount={this.OnClickApplyDiscount}
                        handleChange={this.handleChange}
                        Discount={this.state.Discount}
                        product={product}
                        handleCheckBox={this.handleCheckBox}
                        BackToSelectPlan={this.BackToSelectPlan}
                        ErrorMsgDnC={this.state.ErrorMsgDnC}
                    />
                }


                {(() => {
                    if (SCL) {
                        return (
                            <>
                                <EditAddOnForm
                                    editToggle={this.editToggle}
                                    isOpen={this.state.editModal}
                                    SelectedComponentList={SCL}
                                    addOnCoveragePlanId={this.state.addOnCoveragePlanId}
                                    PremiumCheck={this.PremiumCheck}
                                    //handleChange={this.handleChange}
                                    Amount={this.state.Amount}
                                    CancelAddOnCoverage={this.CancelAddOnCoverage}
                                    torefresh={this.state.torefresh}
                                />
                            </>
                        )
                    }
                })()}


            </>
        );
    }
}

export default hot(module)(ProductGetQuotePresentation);