import React from 'react'

class RadioValidate extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        const {
            input,
            label,
            options,
            disabled,
            meta: { touched, error, warning }
        } = this.props;
        const hasError = touched && error;

        return (
            <React.Fragment>

                {options.map(o =>
                    <div key={o.value} className={"custom-control custom-radio " + (this.props.inline == true ? "custom-control-inline" : "")}>
                        <input type="radio" {...input} id={input.name + "-" + o.value} value={o.value} checked={o.value === input.value} disabled={disabled} className="custom-control-input" />
                        <label htmlFor={input.name + "-" + o.value} className="radioButton custom-control-label">{o.title}</label>

                    </div>

                )}
                {hasError && <span className="invalid-feedback2">{(label || input.name) + " " + error}</span>}

            </React.Fragment>
        )
    }
}

RadioValidate.defaultProps = {
    inline: true
}

export default RadioValidate;