import React from 'react';
import { connect } from 'react-redux'
import actions from './actions'
import Swal from 'sweetalert2';
import SignUpForm from './SignUpForm';
import { Field, reduxForm, FormSection } from 'redux-form';
import useAnalyticsEventTracker from '../useAnalyticsEventTracker';
import { encrypt } from "../_helpers/crypto";
const gaEventTracker = useAnalyticsEventTracker('Sign Up');

class SignUpFormPresentation extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            nationalityid: '',
            identytypeid: '',
            locationid: '',
            district: '',
            postcode: '',
            states: '',
            countryid: '130',
            countryname: '',
            checkPDPA: false,
            checkTNC: false,
        };

        this.changeCheckBox = this.changeCheckBox.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleNationalityChange = this.handleNationalityChange.bind(this);
    }

    changeCheckBox(event) {
        const target = event.target;
        const value = target.checked;

        const name = target.name;

        this.setState({
            [name]: value
        });

    }

    handleNationalityChange = selectedOption => {
        this.setState(
            {
                selectedOption,
                nationalityid: selectedOption.value,
            }
        )
    }

    //handleBlurFromPostcode(event) {=
    //    this.props.getLocationByFromPostcode(this.state.postcode)
    //}

    //handleChange(event) {
    //    const target = event.target;
    //    const value = target.value;

    //    const name = target.name;

    //    this.setState({
    //        [name]: value
    //    });
    //}

    handleSubmit(signUpModel) {

        if (this.state.checkTNC == true && this.state.checkPDPA == true) {

            let commonPassword = this.props.account.commonPasswordList.split("\r\n");
            if (commonPassword.includes(signUpModel.Password)) {
                Swal.fire({
                    title: 'Register Account',
                    text: 'The password is too common.',
                    type: 'error',
                });
                return false;
            }
            var registerObj = {
                UserName: signUpModel.givenName,
                Email: signUpModel.email,
                Password: encrypt(signUpModel.Password),
                FirstName: signUpModel.givenName,
                LastName: signUpModel.familyName,
                PhoneNumber: signUpModel.mobileNo,
                CitizenshipId: signUpModel.citizenShip.value,
                IdentityNumber: signUpModel.identityNo,
                IdentificationType: signUpModel.idType,
                Gender: signUpModel.gender,
                Salutation: signUpModel.salutation.value,
                MaritalStatus: signUpModel.maritalStatus.value,
                Address1: signUpModel.billingAddress,
                Postcode: signUpModel.postcode,
                City: signUpModel.city.label,
                State: signUpModel.state,
                CountryId: signUpModel.citizenShip.value,
            }
            this.props.signup(registerObj);
            gaEventTracker('account registration');
        } else {
            Swal.fire({
                title: 'Register Account',
                text: 'Please make sure you have check and agree to the Terms and Conditions.',
                type: 'info',
            });
        }
        

    }

    componentWillReceiveProps(nextProps) {
        //if (typeof nextProps.account.commonPasswordList !== 'undefined') {
        //    if (this.props.account.commonPasswordList !== nextProps.account.commonPasswordList) {
        //        this.setState({
        //            commonPasswordList: nextProps.account.commonPasswordList.split("\r\n")
        //        })
        //    }
        //}
    }
    
    componentDidMount() {
        Promise.all([
            this.props.getCommonPasswordList(),
            this.props.getNationalityList(),
        ])
    }

    render() {
        const { account,hideSignupFail } = this.props;

        let nationalList = account.nationalityList;
        let nationalityOption = [];
        let locationoptions = [];
        let countryOption = [];

        if (typeof nationalList !== 'undefined') {

            nationalityOption = nationalList.result.map(function (obj) {
                return { value: obj.countryId, label: obj.nationality }
            });


            countryOption = nationalList.result.map(function (obj) {
                return { value: obj.countryId, label: obj.countryName }
            });

        }

        

        return (
            <>
                <SignUpForm
                    onSubmit={this.handleSubmit}
                    nationalityOption={nationalityOption}
                    countryOption={countryOption}
                    handleCheckBox={this.changeCheckBox}
                />
            </>
        );
    }
}

const mapDispatchToProps = dispatch => {
    return {
        signup: (content) => {
            dispatch(actions.signup(content))
        },
        getNationalityList: (content) => {
            dispatch(actions.getNationalityList(content));
        },
        getCommonPasswordList: (content) => {
            dispatch(actions.getCommonPasswordList(content));
        },
    }
}

const mapStateToProps = state => {

    const { router, account } = state || {}; // entire Redux store state (the same value returned by a call to store.getState()).

    return { router, account  };
};


export default connect(
    mapStateToProps,
    mapDispatchToProps
)(SignUpFormPresentation)

