// This is the Main Container for Listing, which will have sub component Presentation
// StudentListing -> StudentListingPresentation

import { connect } from 'react-redux';
import PolicyListPresentation from './PolicyListPresentation';
import actions from './actions';

// Data return from reducer (Redux store).  ( /store/configureStore)
function mapStateToProps(state) {
     

    return {  };
}


// Calling Api via dispatching action
// Triggered from child Presentation
function mapDispatchToProps(dispatch) {
    return {
        

    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(PolicyListPresentation);
