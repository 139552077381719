import React, { Component } from 'react';
import { Button,Carousel, CarouselCaption, NavLink, CarouselIndicators, CarouselItem,CarouselControl, Container, Row, Col, Card, CardBody } from 'reactstrap';

import facebook from '../../image/Footer/facebook.png';
import instagram from '../../image/Footer/instagram.png';
import whatapps from '../../image/Footer/WhatsApp.png';
import Slider1 from "../../image/Blog/InsuranceAsaBackbone1903x500.png";

class BlogDetailsInsuranceAsABackbone extends Component {
    state = {

    }
    constructor(props) {
        super(props)
   
    }


    componentDidMount() {
        window.scrollTo(0, 0);
    }

    render() {
       
        

        return (
            <div>
                <Row>
                    <Col xs="12" sm="12" lg="12">
                        <div className="blog-image">
                            <img alt="" style={{ height: "500px", width: "100%" }} src={Slider1}>
                            </img>
                        </div>
                        <div className="blog-text">
                            <h1 style={{ fontSize: "50px", color: "white" }}>Does Homeowners Insurance Cover Wildfires? </h1>
                            <br />
                            <br />
                            <p> <img src={'../../assets/img/Insubee_Logo.png'} className="fluid" width="20" height="20" /> &nbsp; Team Insubee</p>
                        </div>
                    </Col>
                </Row>

                <br />
                <Row>
                    <Col xs="12" sm="12" lg="11">
                        <div className="container pt-3" style={{paddingLeft:"8em", paddingRight:"0em"}}>

                            <h5><b>Insurance as a Backbone for Business Resilience</b></h5>

                            <p>As businesses work to recover from the impact wrought by the pandemic, resilience begins to take center stage as a necessary component for long-term growth. 
                            SMEs, especially are burdened by the growing challenges of operating in a complex ever-evolving globalised
                            economy. 
                            </p>
                            <br />

                            <p>Larger concerns for workplace health, business sustainability, cyber threats, heightened regulatory controls among 
                            others highlight the need for a business to prepare for and respond to disruptive events. 
                            </p>
                            <br />

                            <p>The challenge for businesses - SMEs especially - comes in identifying how best to integrate resilience, as each business has its own needs and challenges. 
                            And it is for this reason, that insurance has emerged as one of the best foundations for business resiliency.
                            </p>
                            <br />

                            <h5><b>Resilience By Mitigation</b></h5>
                            <p>The essence of business resilience is defined by how resistant a business is to external or internal threats. Unforeseen events cannot be prevented but they can be managed. Business insurance, 
                            therefore, plays a significant role because it lessens the impact of negative events. 
                            </p>
                            <br />

                            <p>Consider, for example, damage caused by fire or floods. Insured enterprises have an advantage over the
                            non-insured because they can recoup their losses through insurance proceeds. 
                            Other business threats such as public liability lawsuits, employee injuries, or even inventory 
                            damages are also covered by insurance. By subscribing to a comprehensive or targeted insurance policy, 
                            businesses can easily shrug off potential issues cropping up with greater ease.
                            </p>
                            <br />

                            <h5><b>Sustainability</b></h5>
                            <p>One often ignored but rather crucial benefit of insurance is credibility. By subscribing to an insurance plan, business owners demonstrate good risk management, 
                            which not only influences the success of loan applications but also increases investor interest. 
                            </p>
                            <br />

                            <p>And that’s not all, the icing on the cake is that there are a lot of affordable insurance plans which can further be customised to the needs of a business. Affordable plans free up financial resources which can go towards investing in better technology, 
                            innovating new products, expanding production - essentially providing latitude for income-generating activities.
                            </p>
                            <br />

                            <h5><b>Financial Resilience</b></h5>
                            <p>Insurance can go a long way to ensuring the financial resiliency of a business. As a general rule, SMEs are more vulnerable to business interruptions, closures, 
                            and loss of income as a result of reduced volume of activity and limited financial resources. 
                            </p>
                            <br />

                            <p>When insurance is out of the picture, entrepreneurs are more likely to resort to costly measures to cope with the unexpected. This includes selling off assets, 
                            using up savings, taking up more loans, reducing household expenditure, and more. 
                            </p>
                            <br />

                            <p>These actions only serve to damage the foundations of a business and may also affect the livelihoods of family and loved ones. This brings us to our last point. 
                            </p>
                            <br />

                            <p>Growing businesses - especially - need insurance. For a small business growing out of the cradle and flourishing with great speed to take on new offices, new employees, technology and assets, insurance stops becoming an option, it becomes a necessity. The reason being financial losses from an unfortunate event 
                            is likely to exceed what can be covered with informal risk management and mitigation measures. 
                            </p>
                            <br />

                            <p>It takes great effort to grow a business to become a thriving entity, resilience should therefore be pursued as a backbone to weather stormy events. As such, entrepreneurs should seriously consider investing in 
                            insurance coverage as it provides a springboard to jump back into operations after a setback.
                            </p>
                            <br />

                            <h5><b>What is the best insurance for a small business?</b></h5>
                            <p>Entrepreneurs on the hunt for insurance will quickly realise one startling fact: the insurance industry is flooded with a large number of insurance products. It is easy to become frustrated by the overwhelming flood of 
                            information, and it may dissuade some from getting the protection they need. 
                            </p>
                            <br />

                            <p>To help with your decision-making, here are the best insurances plans for small businesses: 
                            </p>
                            <br />

                            <p><u>Fire and Special Perils Insurance</u>
                            </p>
                            <p>This type of insurance covers loss or damage to property caused by fire. 
                            </p>
                            <br />

                            <p><u>Burglary Insurance</u>
                            </p>
                            <p>This type of insurance covers loss or damage to property as a result of theft.
                            </p>
                            <br />

                            <p><u>Money Insurance</u>
                            </p>
                            <p>This type of insurance provides coverage for loss or damage of money during transit or money stored in your premises.
                            </p>
                            <br />

                            <p><u>Public Liability Insurance</u>
                            </p>
                            <p>This type of insurance coverage covers costs for lawsuits as a result of loss or damage to a third party or their property due to negligence on your premises. 
                            </p>
                            <br />

                            <p><u>Employer’s Liability Insurance</u>
                            </p>
                            <p>This type of insurance covers you in case any of your employees sustain injuries at work.
                            </p>
                            <br />

                            <p><u>All Risks Insurance</u>
                            </p>
                            <p>All risks cover you in the event of a loss or damage to your property as a result of a misfortune or accident. 
                            </p>
                            <br />

                            <p><u>Fire Consequational Loss Insurance</u>
                            </p>
                            <p>When loss or damage to property due to fire interrupts your business or affects your gross profit, this type of insurance has you covered. 
                            </p>
                            <br />

                            <p><u>Group Personal Accident Insurance</u>
                            </p>
                            <p>You and your employees are compensated for bodily injuries with this type of insurance for accidents that lead to death, disablement or require surgery.
                            </p>
                            <br />

                            <p><u>Electronic Equipment Insurance</u>
                            </p>
                            <p>If you are cleaning, overhauling, or shifting your computers or electronic equipment and they get damaged, this insurance covers your loss. 
                            </p>
                            <br />

                            <p><u>Machinery Breakdown Insurance </u>
                            </p>
                            <p>When the unexpected leads to physical loss or damage to your machinery, this type of insurance has you covered. 
                            </p>
                            <br />

                            <p><u>Fidelity Guarantee</u>
                            </p>
                            <p>This type of insurance covers you when 
                            dishonesty or fraud committed by employees leads to monetary losses. 
                            </p>
                            <br />

                            <p>To speak to an expert on what type of insurance is most suitable for your business, 
                            contact us at help.support@insubee.com
                            </p>
                            <br />

                        </div>
                    </Col>
                        <Col xs="12" sm="12" lg="1">
                        <div class="icon-bar">
                            <p> Share 
                            <a href="https://www.facebook.com/GDEXOfficialPage/"><img src={facebook} width="25px" height="25px" /></a>
                            <a href="https://twitter.com/GDEX_Official/" class="twitter"><img src={instagram} width="25px" height="25px" /></a>
                                <a href="https://www.youtube.com/channel/UCei9LfDrXmLVGdEIfQKHflQ" class="whatapps"><img src={whatapps} width="25px" height="25px" /></a>
                            </p>
                                </div>

                    </Col>
                </Row>

                <br />  
                
            </div>
        )
        
    }
}


export default BlogDetailsInsuranceAsABackbone;