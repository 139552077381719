import adminClaimConstants from './constants';

const initialState = {

};

export const adminClaimStore = (state, action) => {
    state = state || initialState;

    switch (action.type) {


        //DOWNLOAD CLAIM PAYMENT ADVICE
        case adminClaimConstants.DOWNLOAD_CLAIMPAYMENTADVICE_REQUEST:
            return {
                ...state,
                downloadClaimPaymentAdviceSuccess: false,
                isLoading: true,
            }
        case adminClaimConstants.DOWNLOAD_CLAIMPAYMENTADVICE_SUCCESS:
            return {
                ...state,
                downloadClaimPaymentAdviceSuccess: true,
                isLoading: false,
            }
        case adminClaimConstants.DOWNLOAD_CLAIMPAYMENTADVICE_FAILURE:
            return {
                ...state,
                downloadClaimPaymentAdviceSuccess: false,
                isLoading: true,
            }

        //Admin Update Claim
        case adminClaimConstants.UPDATE_ADMINCLAIM_REQUEST:
            return {
                ...state,
                request: undefined,
                isLoading: true,
                updateSuccess: false,
            }
        case adminClaimConstants.UPDATE_ADMINCLAIM_SUCCESS:
            return {
                ...state,
                request: action.data,
                isLoading: false,
                updateSuccess: true,
            }
        case adminClaimConstants.UPDATE_ADMINCLAIM_FAILURE:
            return {
                ...state,
                isLoading: false,
                updateSuccess: false,
            }

        //Admin Get Claim
        case adminClaimConstants.GET_ADMINCLAIM_REQUEST:
            return {
                ...state,
                AdminClaimList: undefined,
                isLoading: true,
                updateSuccess: "",
            }
        case adminClaimConstants.GET_ADMINCLAIM_SUCCESS:
            return {
                ...state,
                AdminClaimList: action.data,
                isLoading: false,
                updateSuccess: "",
            }
        case adminClaimConstants.GET_ADMINCLAIM_FAILURE:
            return {
                ...state,
                isLoading: false,
                updateSuccess: "",
            }


        //Admin Get Claim Details
        case adminClaimConstants.GET_CLAIMDETAILS_REQUEST:
            return {
                ...state,
                AdminClaimDetails: undefined,
                isLoading: true,
                updateSuccess: "",
            }
        case adminClaimConstants.GET_CLAIMDETAILS_SUCCESS:
            return {
                ...state,
                AdminClaimDetails: action.data,
                isLoading: false,
                updateSuccess: "",
            }
        case adminClaimConstants.GET_CLAIMDETAILS_FAILURE:
            return {
                ...state,
                isLoading: false,
                updateSuccess: "",
            }


        default:
            return state;
    }
}