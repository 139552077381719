// This is the Main Container for Listing, which will have sub component Presentation
// StudentListing -> StudentListingPresentation

import { connect } from 'react-redux';
import PolicyDetailsPresentation from './PolicyDetailsPresentation';
import actions from './actions';

// Data return from reducer (Redux store).  ( /store/configureStore)
function mapStateToProps(state) {

    const { user, policy, router } = state || {};

    return { user, policy, router };

}


// Calling Api via dispatching action
// Triggered from child Presentation
function mapDispatchToProps(dispatch) {
    return {
        getPolicyDetails: (id) => { dispatch(actions.getPolicyDetails(id)) },
        downloadCustomerPolicy: (id,policyNo) => { dispatch(actions.downloadCustomerPolicy(id,policyNo))}
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(PolicyDetailsPresentation);
