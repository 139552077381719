import accountConstants from './constants';
import constants from '../_constants/constants';
import accountServices from "./services";
import { toast } from 'react-toastify';
import { push } from 'connected-react-router';
import Cookies from 'universal-cookie';
import Swal from 'sweetalert2';
import moment from 'moment';

const cookies = new Cookies();

// redux/actions.js
const actions = {
    login,
    signup,
    resetpassword,
    forgotpassword,
    hideSignupFail,
    gotoHome,
    getLocationByFromPostcode,
    getNationalityList,
    getCommonPasswordList
}


function gotoHome() {
    return dispatch => {
        dispatch(push('/'));

    }
}

function getLocationByFromPostcode(postcode) {

    return dispatch => {
        dispatch(request(postcode));
        accountServices.getLocationByFromPostcode(postcode).then(
            data => {
                dispatch(success(data.result));
            },
            error => {
                dispatch(failure(error));
                Swal.fire({
                    title: 'Invalid Postcode',
                    message: '',
                    type: 'error',
                });
            }
        )
    }

    function request(data) { return { type: accountConstants.GET_FROMLOCATION_REQUEST, data } }
    function success(data) { return { type: accountConstants.GET_FROMLOCATION_SUCCESS, data } }
    function failure(error) { return { type: accountConstants.GET_FROMLOCATION_FAILURE, error } }

}

function getNationalityList() {
    return dispatch => {

        accountServices.getNationalityList().then(
            data => {
                dispatch(success(data));
            },
            error => {
                dispatch(failure(error));

            }
        )
    }

    function success(data) { return { type: accountConstants.GET_NATIONALITY_SUCCESS, data } }
    function failure(error) { return { type: accountConstants.GET_NATIONALITY_FAILURE, error } }

}

function getCommonPasswordList() {
    return dispatch => {

        accountServices.getCommonPasswordList().then(
            data => {
                dispatch(success(data));
            },
            error => {
                dispatch(failure(error));

            }
        )
    }

    function success(data) { return { type: accountConstants.GET_COMMON_PASSWORD_SUCCESS, data } }
    function failure(error) { return { type: accountConstants.GET_COMMON_PASSWORD_FAILURE, error } }

}


function login(loginDetails) {
    return dispatch => {
        dispatch(request(loginDetails));

        accountServices.login(loginDetails).then(
            data => {
                const setCookiesOptions = {
                    path: "/",
                }
                cookies.set(constants.tokenName, data.result["accessToken"], setCookiesOptions);
                cookies.set(constants.userId, data.result["userId"], setCookiesOptions);
                cookies.set(constants.userName, data.result["userName"], setCookiesOptions);
                cookies.set(constants.isVerified, data.result["isVerified"], setCookiesOptions);
                cookies.set(constants.refreshToken, data.result["refreshToken"], setCookiesOptions);

                sessionStorage.setItem(constants.tokenName, data.result["accessToken"]);
                sessionStorage.setItem(constants.userId, data.result["userId"]);
                sessionStorage.setItem(constants.userTypeId, data.result["userTypeId"]);
                sessionStorage.setItem(constants.userName, data.result["userName"]);
                sessionStorage.setItem(constants.roleName, data.result["roleName"]);
                sessionStorage.setItem(constants.isVerified, data.result["isVerified"]);
                sessionStorage.setItem(constants.refreshToken, data.result["refreshToken"]);
                sessionStorage.setItem(constants.adminAccess, JSON.stringify(data.result["access"]));

                dispatch(success(data));                
                
                Swal.fire({
                    title:'Login Success',
                    message:'',
                    type: 'success',
                    timer: 900,
                }).then((result) => {
                    window.location.href = "/policy";
                })
                

            },
            error => {

                Swal.fire({
                    type: 'error',
                    html: error.errorMessage !== undefined ? error.errorMessage : "Unhandled Exception"


                })
                dispatch(failure(error));
            }
        )
    }

    function request(data) {
        return {
            type: accountConstants.USER_LOGIN_REQUEST,
            data
        }
    }
    function success(data) {
        return {
            type: accountConstants.USER_LOGIN_SUCCESS,
            data
        }
    }
    function failure(error) {
        return {
            type: accountConstants.USER_LOGIN_FAILURE,
            error
        }
    }
}

function resetpassword(resetModel) {

    return dispatch => {
        dispatch(request(resetModel));
        accountServices.resetpassword(resetModel).then(
            data => {
                dispatch(success(data.result, resetModel.Email, resetModel.Password));
                Swal.fire(
                    'Reset Password Success',
                    'Please login again to your Insubee account.',
                    'success'

                );
                dispatch(push('/'));
            },
            error => {
                Swal.fire({
                    icon: 'error',

                    text: error.errorMessage,

                })
                dispatch(fail(error.errorMessage))
                dispatch(showFail(error.errorMessage));
            }
        )
    }

    function request(data) { return { type: accountConstants.USER_RESET_REQUEST, data } }
    function success(data, email, password) { return { type: accountConstants.USER_RESET_SUCCESS, data, email, password } }
    function fail(error) { return { type: accountConstants.USER_RESET_FAIL, error } }
    function showFail(error) { return { type: accountConstants.SHOW_RESET_FAIL, error } }
}

function signup(registerModel) {

    return dispatch => {
        dispatch(request(registerModel));
        accountServices.signup(registerModel).then(
            data => {
                if (data.status == "success") {
                    dispatch(success(data.result, registerModel.Email));
                    Swal.fire(
                        'Account Registration Success',
                        'Please check your email for account activation',
                        'success'
                    );
                    dispatch(push('/login'));
                }
            },
            error => {
                Swal.fire({
                    type: 'error',

                    text: error.errorMessage,

                })
                dispatch(fail(error.errorMessage));
                dispatch(showFail(error.errorMessage));
            }
        )
    }

    function request(data) {
        return {
            type: accountConstants.USER_SIGNUP_REQUEST,
            data
        }
    }
    function success(data, email) {
        return {
            type: accountConstants.USER_SIGNUP_SUCCESS,
            data,
            email
        }
    }
    function fail(error) {
        return {
            type: accountConstants.USER_SIGNUP_FAIL,
            error
        }
    }
    function showFail(error) {
        return {
            type: accountConstants.SHOW_SIGNUP_FAIL,
            error
        }
    }
}

function forgotpassword(forgotModel) {

    return dispatch => {
        dispatch(request(forgotModel));
        accountServices.forgotpassword(forgotModel).then(
            data => {
                dispatch(success(data.result, forgotModel.Email));
                Swal.fire({
                    title: 'Forgot Password',
                    text: 'An email has been sent to ' + forgotModel.Email + ' for reset password.',
                    type: 'success'
                });
            },
            error => {
                Swal.fire({
                    icon: 'error',

                    text: error.errorMessage,

                })
                dispatch(fail(error.errorMessage));
                dispatch(showFail(error.errorMessage));
            }
        )

    }

    function request(data) { return { type: accountConstants.USER_SIGNUP_REQUEST, data } }
    function success(data, email) { return { type: accountConstants.USER_SIGNUP_SUCCESS, data, email } }
    function fail(error) { return { type: accountConstants.USER_SIGNUP_FAIL, error } }
    function showFail(error) { return { type: accountConstants.SHOW_SIGNUP_FAIL, error } }
}

function hideSignupFail() {
    return dispatch => {
        dispatch(hideFail());
    }
    function hideFail(error) {
        return {
            type: accountConstants.HIDE_SIGNUP_FAIL,
            error
        }
    }
}

export default (actions);


