import { connect } from 'react-redux';
import PaymentSuccessPresentation from './PaymentSuccessPresentation';
import actions from './actions';

function mapStateToProps(state) {
    const { payment } = state;

    return {
        payment

    }
}

function mapDispatchToProps(dispatch) {
    return {
        getPaymentSuccess: (transId) => {
            dispatch(actions.getPaymentSuccess(transId));
        },
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(PaymentSuccessPresentation);