import { combineReducers } from 'redux'
import { connectRouter } from 'connected-react-router'

import { reducer as formReducer } from 'redux-form';
import * as Account from '../App/Account/reducer';
import * as Home from '../App/Home/reducer';
import * as User from '../App/User/reducer';
import * as Product from '../App/Product/reducer';
import * as Policy from '../App/Policy/reducer';
import * as Payment from '../App/Payment/reducer';
import * as Admin from '../App/Admin/reducer';
import * as SalesPolicy from '../App/SalesPolicy/reducer';
import * as Marketing from '../App/Marketing/reducer';
import * as Claims from '../App/Claims/reducer';
import * as Finance from '../App/Finance/reducer';
import * as UserSetting from '../App/UserSetting/reducer';
import * as AdminClaims from '../App/AdminClaim/reducer';

import * as CRM from '../App/CRM/reducer';
import * as Dashboard from '../App/Dashboard/reducer';

export default (history) => combineReducers({
    router: connectRouter(history),
    form: formReducer,
    account: Account.reducer,
    home: Home.HomeStore,
    user: User.reducer,
    product: Product.reducer,
    policy: Policy.reducer,
    payment: Payment.reducer,
    admin: Admin.reducer,
    salesPolicy: SalesPolicy.reducer,
    marketing: Marketing.reducer,
    ClaimStore: Claims.claimStore,
    Finance: Finance.reducer,
    userSetting: UserSetting.reducer,
    AdminClaimStore: AdminClaims.adminClaimStore,
    crm: CRM.reducer,
    dashboard: Dashboard.reducer
})