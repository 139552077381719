const salesPolicyConstants = {

    GET_SALES_POLICY_REQUEST: "GET_SALES_POLICY_REQUEST",
    GET_SALES_POLICY_SUCCESS: "GET_SALES_POLICY_SUCCESS",
    GET_SALES_POLICY_FAILURE: "GET_SALES_POLICY_FAILURE",

    GET_SALES_POLICY_DETAILS_REQUEST: "GET_SALES_POLICY_DETAILS_REQUEST",
    GET_SALES_POLICY_DETAILS_SUCCESS: "GET_SALES_POLICY_DETAILS_SUCCESS",
    GET_SALES_POLICY_DETAILS_FAILURE: "GET_SALES_POLICY_DETAILS_FAILURE",

    UPDATE_SALES_POLICY_REQUEST: "UPDATE_SALES_POLICY_REQUEST",
    UPDATE_SALES_POLICY_SUCCESS: "UPDATE_SALES_POLICY_SUCCESS",
    UPDATE_SALES_POLICY_FAILURE: "UPDATE_SALES_POLICY_FAILURE",
    SHOW_UPDATE_SALES_POLICY_FAIL: 'SHOW_UPDATE_SALES_POLICY_FAIL',
    HIDE_UPDATE_SALES_POLICY_FAIL: "HIDE_UPDATE_SALES_POLICY_FAIL",

    GET_SALES_POLICY_DOCUMENT_REQUEST: "GET_SALES_POLICY_DOCUMENT_REQUEST",
    GET_SALES_POLICY_DOCUMENT_SUCCESS: "GET_SALES_POLICY_DOCUMENT_SUCCESS",
    GET_SALES_POLICY_DOCUMENT_FAILURE: "GET_SALES_POLICY_DOCUMENT_FAILURE",

    DOWNLOAD_INVOICE_DOCUMENT_REQUEST: "DOWNLOAD_INVOICE_DOCUMENT_REQUEST",
    DOWNLOAD_INVOICE_DOCUMENT_SUCCESS: "DOWNLOAD_INVOICE_DOCUMENT_SUCCESS",
    DOWNLOAD_INVOICE_DOCUMENT_FAILURE: "DOWNLOAD_INVOICE_DOCUMENT_FAILURE",

    GENERATE_INVOICE_DOCUMENT_REQUEST: "GENERATE_INVOICE_DOCUMENT_REQUEST",
    GENERATE_INVOICE_DOCUMENT_SUCCESS: "GENERATE_INVOICE_DOCUMENT_SUCCESS",
    GENERATE_INVOICE_DOCUMENT_FAILURE: "GENERATE_INVOICE_DOCUMENT_FAILURE",

    REGEN_SYMBO_POLICY_REQUEST: "REGEN_SYMBO_POLICY_REQUEST",
    REGEN_SYMBO_POLICY_SUCCESS: "REGEN_SYMBO_POLICY_SUCCESS",
    REGEN_SYMBO_POLICY_FAILURE: "REGEN_SYMBO_POLICY_FAILURE",

    RESEND_EMAIL_INVOICE_REQUEST: "RESEND_EMAIL_INVOICE_REQUEST",
    RESEND_EMAIL_INVOICE_SUCCESS: "RESEND_EMAIL_INVOICE_SUCCESS",
    RESEND_EMAIL_INVOICE_FAILURE: "RESEND_EMAIL_INVOICE_FAILURE",
}

export default salesPolicyConstants;