import { connect } from 'react-redux';
import PaymentPresentation from './PaymentPresentation';


function mapStateToProps(state) {
    const { payment, product } = state;

    return {
        payment,
        product
    }
}

function mapDispatchToProps(dispatch) {
    return {

    }
}

const Payment = connect(mapStateToProps, mapDispatchToProps)(PaymentPresentation);

export default Payment;