import dashboardConstants from './constants';
import Swal from 'sweetalert2';

const initialState = {
    isLoading: false,
    showFail: false,
    showSuccess: false
};


export const reducer = (state, action) => {
    state = state || initialState;

    switch (action.type) {
        //DASHBOARD
        case dashboardConstants.GET_DASHBOARD_REQUEST:
            return {
                ...state,
                getDashboardSuccess: false,
                dashboardList: undefined,
                isLoading: true,
            }
        case dashboardConstants.GET_DASHBOARD_SUCCESS:
            return {
                ...state,
                getDashboardSuccess: true,
                dashboardList: action.data,
                isLoading: false,
            }
        case dashboardConstants.GET_DASHBOARD_FAILURE:
            return {
                ...state,
                getDashboardSuccess: false,
                dashboardList: undefined,
                isLoading: true,
            }
        
        default:
            return state;
    }
}