import React, { Component } from 'react';
import { connect } from 'react-redux';
import actions from './actions';
import {
    Button, Container, Row, Col, Form, FormGroup, Label, InputGroup, InputGroupAddon, InputGroupText, Input,
    Card, CardBody, Collapse, CardHeader, Dropdown, DropdownToggle, DropdownMenu, DropdownItem, Tooltip
} from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileUpload, faLock, faEye, faFilePdf, faSave, faEdit, faSearch, faFilter, faTrash, faPlus, faKey } from '@fortawesome/free-solid-svg-icons';
import 'bootstrap/dist/css/bootstrap.min.css';
import { numberWithCommas } from "../GlobalComponents/_function";
import DateRangePicker from "@wojtekmaj/react-daterange-picker";
import DropdownList from "react-widgets/DropdownList";
import moment from 'moment';
import Swal from 'sweetalert2';
import { reset } from 'redux-form';
import accessConstants from "../_constants/access_constant";
import TableContainer from './TableContainer';
import Cookies from 'universal-cookie';
import RolePresentation from './RolePresentation';
import { AccessLevel } from './constants';

const cookies = new Cookies();

class RoleListPresentation extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            showModal: false,
            isNew: false,
            isEdit: false,
            selectedRole: [],
            data: [],
            pages: 0,
            currentPage: 0,
            totalItems: 0,
            totalPages: 1,
            rowPerPage: 25,

        };
        this.triggerChangePageSize = this.triggerChangePageSize.bind(this);
        this.fetchDataPageIndex = this.fetchDataPageIndex.bind(this);
        this.fetchDataPageSize = this.fetchDataPageSize.bind(this);
        this.handleDelete = this.handleDelete.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    fetchDataPageIndex(pageIndex) {
        this.setState({
            pages: pageIndex + 1
        })
        this.props.getRoleList(pageIndex + 1, this.state.rowPerPage);
    }

    fetchDataPageSize(pageSize) {
        this.setState({
            rowPerPage: pageSize
        })
        this.props.getRoleList(this.state.pages + 1, pageSize);
    }

    triggerChangePageSize(value) {
        this.setState({
            rowPerPage: value,
            pages: 0
        });
        this.props.getRoleList(1, value);
    }

    handleDelete(id,roleName) {
        Swal.fire({
            title: 'Are you sure?',
            text: "You are deleting role with " + roleName,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes'
        }).then((result) => {
            if (result.value === true) {
                this.props.deleteRole(id);
            } else if (result.dismiss === Swal.DismissReason.cancel) {

            }
        })
    }

    handleSubmit(roleModel) {
        if (this.state.isEdit) {
            
            var access = AccessLevel.reduce(function (c, n) {
                if (roleModel.AccessLevel[n.name] == true) {
                    c.push({
                        ClaimType: n.name,
                        ClaimValue: n.name
                    });
                }
                return c;
            }, []);

            var editObj = {
                id: roleModel.id,
                roleName: roleModel.roleName,
                claims: access
            }
            this.props.updateRole(editObj);


        } else {
            
            var access = AccessLevel.reduce(function (c, n) {
                if (roleModel.AccessLevel[n.name] == true) {
                    c.push({
                        ClaimType: n.name,
                        ClaimValue: n.name
                    });
                }
                return c;
            }, []);


            var registerObj = {
                roleName: roleModel.roleName,
                claims: access
            }
            this.props.addRole(registerObj);
        }
    }

    componentDidUpdate(prevProps) {
        if (typeof this.props.userSetting.showSuccess !== 'undefined') {
            if (this.props.userSetting.showSuccess !== prevProps.userSetting.showSuccess) {
                if (this.props.userSetting.showSuccess) {
                    this.props.getRoleList(this.state.pages, this.state.rowPerPage);
                }

            }
        }

    }


    componentWillReceiveProps(nextProps) {


        if (typeof nextProps.userSetting.roleList !== 'undefined') {
            if (this.props.userSetting.roleList !== nextProps.userSetting.roleList) {
                this.setState({
                    data: nextProps.userSetting.roleList.itemViewListModel,
                    totalPages: nextProps.userSetting.roleList.totalPages,
                    currentPage: nextProps.userSetting.roleList.currentPage,
                    totalItems: nextProps.userSetting.roleList.totalItems,
                });
            }
        }


        if (typeof nextProps.userSetting.showSuccess !== 'undefined') {
            if (this.props.userSetting.showSuccess !== nextProps.userSetting.showSuccess) {
                if (nextProps.userSetting.showSuccess == true) {
                    this.setState({
                        showModal: false
                    });
                }
            }

        }

    }



    componentDidMount() {
        window.scrollTo(0, 0);
        this.props.getRoleList(1, this.state.rowPerPage);
    }


    render() {

        let startIndex = (this.state.currentPage - 1) * this.state.rowPerPage;
        let columns =
            [
                {
                    Header: 'No',
                    Cell: ({ row, flatRows }) => {
                        return flatRows.indexOf(row) + startIndex + 1;
                    },
                },
                {
                    Header: "Name",
                    accessor: "roleName",
                    disableFilters: true
                },
                {
                    Header: "Action",
                    accessor: "id",
                    disableFilters: true,
                    Cell: row => {
                        return (
                            <div className="text-center" style={{ display: 'flex' }}>
                                <FontAwesomeIcon size="1x" style={{ cursor: 'pointer' }}
                                    icon={faEdit} onClick={() =>
                                        this.setState({
                                            showModal: true,
                                            isEdit: true,
                                            isNew: false,
                                            selectedRole: row.row.original
                                        })} >

                                </FontAwesomeIcon>
                                <FontAwesomeIcon size="1x" style={{ cursor: 'pointer', marginLeft: '20px' }}
                                    icon={faTrash} onClick={
                                        () =>
                                            this.handleDelete(row.row.original.id, row.row.original.roleName)
                                    } />


                            </div>

                        )

                    },
                },

            ];

        var claimAccess = JSON.parse(sessionStorage.getItem("adminAccess"));
        var accessEditRole = true;
        claimAccess.map((accessLevel) => {
            if (accessLevel.claimName === accessConstants.EditRole && accessLevel.claimValue === false) {
                accessEditRole = false;
                columns = columns.filter(function (e) { return e.Header !== 'Action' });
            }
        });

        return (
            <div className="">
                <div className="row">
                    <div className="col-sm-9">
                        <h3>Role Management</h3>
                    </div>
                    <div className="col-sm-3 text-right">
                        <Button
                            color="dark" onClick={() => {
                                this.setState({
                                    showModal: true,
                                    isNew: true,
                                    isEdit: false,
                                    selectedRole: []
                                })
                            }}
                        >
                            <span><FontAwesomeIcon size="1x" style={{ color: 'white' }} icon={faPlus} /></span> Create Role
                        </Button>
                    </div>
                </div>
                <br></br>
                <div className="row">
                    <div className="col-sm-12" >
                        <TableContainer columns={columns} data={this.state.data} totalItems={this.state.totalItems}
                            fetchDataPageIndex={this.fetchDataPageIndex} fetchDataPageSize={this.fetchDataPageSize}
                            triggerChangePageSize={this.triggerChangePageSize} pageCount={this.state.totalPages} pageSize={this.state.rowPerPage}
                        />


                    </div>
                    <RolePresentation
                        onSubmit={this.handleSubmit}
                        isEdit={this.state.isEdit}
                        isNew={this.state.isNew}
                        selectedRole={this.state.selectedRole}
                        showModal={this.state.showModal}
                        onHide={() =>
                            this.setState({
                                showModal: false,
                                isNew: false,
                                isEdit: false,
                                selectedRole: []
                            })

                        }
                    />

                </div>
            </div>

        );
    }
}

const mapDispatchToProps = dispatch => {
    return {
        getRoleList: (pageNumber, pageSize) => {
            dispatch(actions.getRoleList(pageNumber, pageSize));
        },
        addRole: (model) => {
            dispatch(actions.addRole(model));
        },
        updateRole: (model) => {
            dispatch(actions.updateRole(model));
        },
        deleteRole: (id) => {
            dispatch(actions.deleteRole(id));
        },
    }
}

const mapStateToProps = state => {

    const { userSetting, router } = state || {};

    return { userSetting, router };
};


export default connect(
    mapStateToProps,
    mapDispatchToProps
)(RoleListPresentation)
