import salesPolicyConstants from './constants';

const initialState = {
    isLoading: false,
    salesPolicyList: [],
};


export const reducer = (state, action) => {
    state = state || initialState;

    switch (action.type) {
        //SALES POLICY LIST
        case salesPolicyConstants.GET_SALES_POLICY_REQUEST:
            return {
                ...state,
                policySuccess: false,
                salesPolicyList: undefined,
                isLoading: true,
            }
        case salesPolicyConstants.GET_SALES_POLICY_SUCCESS:
            return {
                ...state,
                policySuccess: true,
                salesPolicyList: action.data,
                isLoading: false,
            }
        case salesPolicyConstants.GET_SALES_POLICY_FAILURE:
            return {
                ...state,
                policySuccess: false,
                salesPolicyList: undefined,
                isLoading: true,
            }
        //SALES POLICY DETAILS
        case salesPolicyConstants.GET_SALES_POLICY_DETAILS_REQUEST:
            return {
                ...state,
                salesPolicyDetailsSuccess: false,
                salesPolicyDetails: undefined,
                isLoading: true,
            }
        case salesPolicyConstants.GET_SALES_POLICY_DETAILS_SUCCESS:
            return {
                ...state,
                salesPolicyDetailsSuccess: true,
                salesPolicyDetails: action.data,
                isLoading: false,
            }
        case salesPolicyConstants.GET_SALES_POLICY_DETAILS_FAILURE:
            return {
                ...state,
                salesPolicyDetailsSuccess: false,
                salesPolicyDetails: undefined,
                isLoading: true,
            }
        //UPDATE SALES POLICY
        case salesPolicyConstants.UPDATE_SALES_POLICY_REQUEST:
            return {
                ...state,
                isLoading: true,
                showFail: false,
                showSuccess: false,
            }
        case salesPolicyConstants.UPDATE_SALES_POLICY_SUCCESS:
            return {
                ...state,
                isLoading: false,
                showSuccess: true,
                showFail: false,
                policyNo: action.policyNo
            }
        case salesPolicyConstants.UPDATE_SALES_POLICY_FAILURE:
            return {
                ...state,
                isLoading: true,
                showFail: false,
                showSuccess: false
            }
        case salesPolicyConstants.HIDE_UPDATE_SALES_POLICY_FAIL:
            return {
                isLoading: false,
                showFail: false
            }
        case salesPolicyConstants.SHOW_UPDATE_SALES_POLICY_FAIL:
            return {
                isLoading: false,
                showFail: true
            }
        //SALES POLICY DOCUMENT
        case salesPolicyConstants.GET_SALES_POLICY_DOCUMENT_REQUEST:
            return {
                ...state,
                salesPolicyDocumentSuccess: false,
                isLoading: true,
            }
        case salesPolicyConstants.GET_SALES_POLICY_DOCUMENT_SUCCESS:
            return {
                ...state,
                salesPolicyDocumentSuccess: true,
                isLoading: false,
            }
        case salesPolicyConstants.GET_SALES_POLICY_DOCUMENT_FAILURE:
            return {
                ...state,
                salesPolicyDocumentSuccess: false,
                isLoading: true,
            }
        //DOWNLOAD INVOICE
        case salesPolicyConstants.DOWNLOAD_INVOICE_DOCUMENT_REQUEST:
            return {
                ...state,
                invoiceDocumentSuccess: false,
                isLoading: true,
            }
        case salesPolicyConstants.DOWNLOAD_INVOICE_DOCUMENT_SUCCESS:
            return {
                ...state,
                invoiceDocumentSuccess: true,
                isLoading: false,
            }
        case salesPolicyConstants.DOWNLOAD_INVOICE_DOCUMENT_FAILURE:
            return {
                ...state,
                invoiceDocumentSuccess: false,
                isLoading: true,
            }
        //GENERATE INVOICE DOCUMENT
        case salesPolicyConstants.GENERATE_INVOICE_DOCUMENT_REQUEST:
            return {
                ...state,
                generateInvoiceDocumentSuccess: false,
                isLoading: true,
            }
        case salesPolicyConstants.GENERATE_INVOICE_DOCUMENT_SUCCESS:
            return {
                ...state,
                generateInvoiceDocumentSuccess: true,
                isLoading: false,
            }
        case salesPolicyConstants.GENERATE_INVOICE_DOCUMENT_FAILURE:
            return {
                ...state,
                generateInvoiceDocumentSuccess: false,
                isLoading: true,
            }
        //REGEN SYMBO POLICY
        case salesPolicyConstants.REGEN_SYMBO_POLICY_REQUEST:
            return {
                ...state,
                regenSymboPolicySuccess: false,
                isLoading: true,
            }
        case salesPolicyConstants.REGEN_SYMBO_POLICY_SUCCESS:
            return {
                ...state,
                regenSymboPolicySuccess: true,
                isLoading: false,
            }
        case salesPolicyConstants.REGEN_SYMBO_POLICY_FAILURE:
            return {
                ...state,
                regenSymboPolicySuccess: false,
                isLoading: true,
            }
        //RESEND INVOICE EMAIL
        case salesPolicyConstants.RESEND_INVOICE_EMAIL_REQUEST:
            return {
                ...state,
                resendEmailInvoiceSuccess: false,
                isLoading: true,
            }
        case salesPolicyConstants.RESEND_INVOICE_EMAIL_SUCCESS:
            return {
                ...state,
                resendEmailInvoiceSuccess: true,
                isLoading: false,
            }
        case salesPolicyConstants.RESEND_INVOICE_EMAIL_FAILURE:
            return {
                ...state,
                resendEmailInvoiceSuccess: false,
                isLoading: true,
            }
        default:
            return state;
    }
}