import { Home } from "../../components/Home";
import Payment from "../Payment/Payment";
import PolicyDetails from "../Policy/PolicyDetails";
import RoleList from "../UserSetting/RoleList";

const constants = {
    secretKey: "fThWmZq4",
    tokenName: "insubeeToken",
    tokenAdminName: "insubeeAdminToken",
    userId: "userId",
    userTypeId: "userTypeId",
    userName: "userName",
    roleName: "roleName",
    isVerified: "isVerified",
    refreshToken: "refreshToken",
    adminRefreshToken: "adminRefreshToken",
    adminAccess: "adminAccess",
    externalLogin: "externalLogin",
    externalLoginError: "externalLoginError",

    //Home
    apiGetNationalityList: "/api/Home/Country",
    apiSendEmail: "api/Contact/SendEmail",
    apiSendUserInterest: "api/Contact/SendUserInterest",
    getCommonPasswordTxt:"assets/10_million_password_list_top_100000.txt",

    // Account
    apiSignup: "/api/Account/Register",
    apiLogin: "/api/Account/Login",
    apiRefreshToken: "/api/account/RefreshToken",
    apiForgotPassword: "/api/Account/ForgotPassword",
    apiResetPassword: "/api/Account/ResetPassword",
    apiGetProfile: "/api/Account/GetProfile",
    apiChangePassword:"/api/Account/ChangePassword",
    apiUpdateProfile:"/api/Account/UpdateProfile",

    //Product
    apigetMasterEntry: "/api/Home/MasterEntry",
    apigetQuote: "api/Product/GetQuote",
    apigetStateHoliday: "api/Home/DayExclude",

    //Policy
    apiGetPolicy: "api/Policy/GetPolicy",
    apiCreatePolicy: "api/Policy/CreatePolicy",
    apiUserCreatePolicy: "api/Policy/UserCreatePolicy",
    apiGuestCreatePolicy: "api/Policy/GuestCreatePolicy",
    apiGetUserPolicy: "api/Policy/GetUserPolicy",

    //Voucher
    apiUserCalculateDiscountVoucher: "api/Policy/UserCalculateDiscountVoucher",
    apiGuestCalculateDiscountVoucher: "api/Policy/GuestCalculateDiscountVoucher",

    // Payment
    apiGetPaymentSuccess: "Payment/GetPaymentSuccess",

    // Admin Login
    apiAdminLogin: "/api/Admin/Login",
    apiRefreshTokenAdmin: "/api/Admin/RefreshToken",
    apiAdminResetPassword: "/api/Admin/ResetPassword",

    // Admin Sales Policy
    apiAdminGetSalesPolicy: "api/SalesPolicy/GetPolicy",
    apiAdminUpdateSalesPolicy: "api/SalesPolicy/UpdatePolicy",
    apiAdminRegisterUser: "api/Admin/Register",
    apiAdminSetPassword: "api/Admin/SetPassword",
    apiAdminUpdateUser: "api/Admin/Edit",
    apiAdminDeleteUser: "api/Admin/DeleteUser",
    apiAdminChangePassword : "api/Admin/ChangePassword",
    apiAdminForgotPassword: "api/Admin/ForgotPassword",

    // Admin Discount Voucher
    apiAddDiscountVoucher: "api/DiscountVoucher/AddDiscountVoucher",
    apiUpdateDiscountVoucher: "api/DiscountVoucher/UpdateDiscountVoucher",

    // Claim
    apiSubmitClaim: "api/Claim/SubmitClaim",
    apiAdminUpdateClaim: "api/Claim/AdminUpdateClaim",

    //Role
    apiDeleteRole: "api/Role/Delete",
    apiAddRole: "api/Role/AddRole",
    apiUpdateRole: "api/Role/UpdateRole",

    //CRM
    apiresetCustomerPassword: "api/Admin/ResetCustomerPassword",

}

export default constants;