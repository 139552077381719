import claimConstants from './constants';

const initialState = {

};

export const claimStore = (state, action) => {
    state = state || initialState;

    switch (action.type) {

        //Get Claim
        case claimConstants.GET_ALLCLAIM_REQUEST:
            return {
                ...state,
                ClaimList: undefined,
                isLoading: true,
            }
        case claimConstants.GET_ALLCLAIM_SUCCESS:
            return {
                ...state,
                ClaimList: action.data,
                isLoading: false,
            }
        case claimConstants.GET_ALLCLAIM_FAILURE:
            return {
                ...state,
                isLoading: false
            }

        //Submit Claim
        case claimConstants.SUBMIT_CLAIM_REQUEST:
            return {
                ...state,
                request: undefined,
                isLoading: true,
            }
        case claimConstants.SUBMIT_CLAIM_SUCCESS:
            return {
                ...state,
                request: action.data,
                isLoading: false,
            }
        case claimConstants.SUBMIT_CLAIM_FAILURE:
            return {
                ...state,
                isLoading: false
            }

        //Get Policy By ID
        case claimConstants.GET_CLAIM_REQUEST:
            return {
                ...state,
                ClaimDetail: undefined,
                isLoading: true,
            }
        case claimConstants.GET_CLAIM_SUCCESS:
            return {
                ...state,
                ClaimDetail: action.data,
                isLoading: false,
            }
        case claimConstants.GET_CLAIM_FAILURE:
            return {
                ...state,
                isLoading: false
            }

        //Get user policy for claim submission
        case claimConstants.GET_USERPOLICY_REQUEST:
            return {
                ...state,
                UserPolicyList: undefined,
                isLoading: true,
            }
        case claimConstants.GET_USERPOLICY_SUCCESS:
            return {
                ...state,
                UserPolicyList: action.data,
                isLoading: false,
            }
        case claimConstants.GET_USERPOLICY_FAILURE:
            return {
                ...state,
                isLoading: false
            }

        //DOWNLOAD CLAIM
        case claimConstants.DOWNLOAD_CLAIM_REQUEST:
            return {
                ...state,
                downloadClaimSuccess: false,
                isLoading: true,
            }
        case claimConstants.DOWNLOAD_CLAIM_SUCCESS:
            return {
                ...state,
                downloadClaimSuccess: true,
                isLoading: false,
            }
        case claimConstants.DOWNLOAD_CLAIM_FAILURE:
            return {
                ...state,
                downloadClaimSuccess: false,
                isLoading: true,
            }

        //Cancel CLAIM
        case claimConstants.CANCEL_CLAIM_REQUEST:
            return {
                ...state,
                cancelClaimSuccess: false,
                isLoading: true,
            }
        case claimConstants.CANCEL_CLAIM_SUCCESS:
            return {
                ...state,
                cancelClaimSuccess: true,
                isLoading: false,
            }
        case claimConstants.CANCEL_CLAIM_FAILURE:
            return {
                ...state,
                cancelClaimSuccess: false,
                isLoading: true,
            }

        default:
            return state;
    }
}