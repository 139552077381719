import paymentConstants from './constants';
import constants from '../_constants/constants';
import paymentServices from "./services";
import { toast } from 'react-toastify';
import { push } from 'connected-react-router';
import Cookies from 'universal-cookie';
import Swal from 'sweetalert2';
import moment from 'moment';

const cookies = new Cookies();

// redux/actions.js
const actions = {
    getPaymentSuccess
}



function getPaymentSuccess(transId) {
    return dispatch => {

        paymentServices.getPaymentSuccess(transId).then(
            data => {
                dispatch(success(data));
            },
            error => {
                dispatch(failure(error));

            }
        )
    }

    function success(data) { return { type: paymentConstants.GET_PAYMENT_PAGE_SUCCESS, data } }
    function failure(error) { return { type: paymentConstants.GET_PAYMENT_PAGE_FAIL, error } }

}


export default (actions);
