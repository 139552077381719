import React from 'react';
import { connect } from 'react-redux'
import actions from './actions'
import SweetAlert from 'sweetalert2-react';
import { Col, Input, Form, FormGroup, Row, Button, Label } from 'reactstrap'
import { Field, reduxForm, FormSection } from 'redux-form'
import { push } from 'connected-react-router';

class FailVerificationPresentation extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            display: true
        };

        this.handleLogin = this.handleLogin.bind(this);
    }


    handleLogin(event) {

        this.setState({
            display: false

        });
        this.props.gotoHome();
    }


    render() {




        return (
            <div className="container">

                <SweetAlert
                    type="error"
                    show={this.state.display === true ? true : false}

                    title="This link is no longer valid. Please go to forgot password if necessary."
                    confirmButtonText={"OK"}
                    onConfirm={() => { this.handleLogin() }}
                />


            </div>
        );
    }
}


const mapDispatchToProps = dispatch => {
    return {
        gotoHome: (content) => {
            dispatch(actions.gotoHome(content))
        }

    }
}

const mapStateToProps = state => {

    const { message, router } = state || {}; // entire Redux store state (the same value returned by a call to store.getState()).       

    return { message, router };
};


export default connect(
    mapStateToProps,
    mapDispatchToProps
)(FailVerificationPresentation)
