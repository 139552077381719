const ProductConstants = {

    GET_FROMLOCATION_REQUEST: "GET_FROMLOCATION_REQUEST",
    GET_FROMLOCATION_SUCCESS: "GET_FROMLOCATION_SUCCESS",
    GET_FROMLOCATION_FAILURE: "GET_FROMLOCATION_FAILURE",

    GET_FROMCORRSLOCATION_REQUEST: "GET_FROMCORRSLOCATION_REQUEST",
    GET_FROMCORRSLOCATION_SUCCESS: "GET_FROMCORRSLOCATION_SUCCESS",
    GET_FROMCORRSLOCATION_FAILURE: "GET_FROMCORRSLOCATION_FAILURE",

    GET_MASTERENTRY_REQUEST: "GET_MASTERENTRY_REQUEST",
    GET_MASTERENTRY_SUCCESS: "GET_MASTERENTRY_SUCCESS",
    GET_MASTERENTRY_FAILURE: "GET_MASTERENTRY_FAILURE",

    GET_STATEHOLIDAY_REQUEST: "GET_STATEHOLIDAY_REQUEST",
    GET_STATEHOLIDAY_SUCCESS: "GET_STATEHOLIDAY_SUCCESS",
    GET_STATEHOLIDAY_FAILURE: "GET_STATEHOLIDAY_FAILURE",

    PRODUCT_GETQUOTE_REQUEST: 'PRODUCT_GETQUOTE_REQUEST',
    PRODUCT_GETQUOTE_SUCCESS: 'PRODUCT_GETQUOTE_SUCCESS',
    PRODUCT_GETQUOTE_FAIL: 'PRODUCT_GETQUOTE_FAIL',

    SHOW_GETQUOTE_FAIL: 'SHOW_GETQUOTE_FAIL',
    HIDE_GETQUOTE_FAIL: 'HIDE_GETQUOTE_FAIL',

    //PRODUCT_CREATEPOLICY_REQUEST: 'PRODUCT_CREATEPOLICY_REQUEST',
    PRODUCT_CREATEPOLICY_SUCCESS: 'PRODUCT_CREATEPOLICY_SUCCESS',
    PRODUCT_CREATEPOLICY_FAIL: 'PRODUCT_CREATEPOLICY_FAIL',

    PRODUCT_USERCREATEPOLICY_REQUEST: 'PRODUCT_USERCREATEPOLICY_REQUEST',
    PRODUCT_USERCREATEPOLICY_SUCCESS: 'PRODUCT_USERCREATEPOLICY_SUCCESS',
    PRODUCT_USERCREATEPOLICY_FAIL: 'PRODUCT_USERCREATEPOLICY_FAIL',

    PRODUCT_GUESTCREATEPOLICY_REQUEST: 'PRODUCT_GUESTCREATEPOLICY_REQUEST',
    PRODUCT_GUESTCREATEPOLICY_SUCCESS: 'PRODUCT_GUESTCREATEPOLICY_SUCCESS',
    PRODUCT_GUESTCREATEPOLICY_FAIL: 'PRODUCT_GUESTCREATEPOLICY_FAIL',

    //PRODUCT_GUESTCREATEPOLICY_SUCCESS: 'PRODUCT_GUESTCREATEPOLICY_SUCCESS',
    //PRODUCT_GUESTCREATEPOLICY_FAIL: 'PRODUCT_GUESTCREATEPOLICY_FAIL',

    PRODUCT_USERCALCULATEDISCOUNTVOUCHER_SUCCESS: 'PRODUCT_USERCALCULATEDISCOUNTVOUCHER_SUCCESS',
    PRODUCT_USERCALCULATEDISCOUNTVOUCHER_FAIL: 'PRODUCT_USERCALCULATEDISCOUNTVOUCHER_FAIL',

    PRODUCT_GUESTCALCULATEDISCOUNTVOUCHER_SUCCESS: 'PRODUCT_GUESTCALCULATEDISCOUNTVOUCHER_SUCCESS',
    PRODUCT_GUESTCALCULATEDISCOUNTVOUCHER_FAIL: 'PRODUCT_GUESTCALCULATEDISCOUNTVOUCHER_FAIL',
}

export default ProductConstants;