import React from 'react';
import { Collapse, Container, Navbar, Col, Nav, NavItem, NavLink, NavbarBrand, NavbarToggler, } from 'reactstrap';
import { connect } from 'react-redux'
import { Link } from 'react-router-dom';
import './DefaultLayoutNavMenu.css';
import { Badge, UncontrolledDropdown, DropdownItem, DropdownMenu, DropdownToggle } from 'reactstrap';
import './DefaultLayoutNavMenu.css';
import InsuBeeLogo from "../../../image/InsubeeLogo.svg";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { faCaretDown } from '@fortawesome/free-solid-svg-icons';
import { faUser } from '@fortawesome/free-solid-svg-icons';

import LoginColored from "../../../image/Home/login_colored.png";
import NoLogin from "../../../image/Home/without_login.png";
import Cookies from 'universal-cookie';
import EmailDotIcon from '../../../image/NavBar/email-outline-badged.svg'
import EmailIcon from '../../../image/NavBar/email.svg'
import EmailIconHover from '../../../image/NavBar/emailHover.svg'


const cookies = new Cookies();
class DefaultLayoutNavMenu extends React.Component {
    constructor(props) {
        super(props);

        this.toggle = this.toggle.bind(this);
        this.state = {
            isOpen: false,
            logout: true,
            getted: false
        };
    }
    toggle() {
        this.setState({
            isOpen: !this.state.isOpen
        });
    }

    componentDidMount() {
        if (sessionStorage.getItem("insubeeToken") !== undefined) {

        }

    }

    signOut(e) {
        cookies.remove("insubeeToken");
        cookies.remove("refreshToken");
        cookies.remove("isVerified");
        cookies.remove("userId");
        cookies.remove("userName");
        cookies.remove("roleName");
        cookies.remove("adminAccess");

        sessionStorage.removeItem("insubeeToken");
        sessionStorage.removeItem("refreshToken");
        sessionStorage.removeItem("isVerified");
        sessionStorage.removeItem("userId");
        sessionStorage.removeItem("userTypeId");
        sessionStorage.removeItem("userName");
        sessionStorage.removeItem("roleName");
        sessionStorage.removeItem("adminAccess");
        this.setState({
            logout: true
        });
        window.location.href = "/";
    }
    render() {

        const currentPagePath = window.location.pathname;

        return (

            <>
                {
                    currentPagePath === "/HomeI" ? (
                        <nav className="navbar navbar-expand-lg navbar-light bg-light fixed-top">
                            <div style={{ width: "100%" }}>
                                <div className="flexContainer variation-header">
                                    <div id="InsuBeeLogo">
                                        <a className="navbar-brand" href="/" style={{ marginBottom: "20px" }}><img src={InsuBeeLogo} className="fluid customLogoSize" /></a>
                                    </div>
                                    <div id="NavItems">
                                        <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNavAltMarkup" aria-controls="navbarNavAltMarkup" aria-expanded="false" aria-label="Toggle navigation">
                                            <span className="navbar-toggler-icon"></span>
                                        </button>
                                        <div className="collapse navbar-collapse navBar-bgImg" id="navbarNavAltMarkup">
                                            <div className="navbar-nav fNavItems fNavItems-variation" style={{ }}>
                                                <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNavAltMarkup" aria-controls="navbarNavAltMarkup" aria-expanded="false" aria-label="Toggle navigation">
                                                    <span className="navbar-toggler-icon"></span>
                                                </button>
                                                <a className="fNItems2" href="/HomeI#discoverTheDifference">Why Choose Us</a>
                                                <a className="fNItems2" href="/Faq">FAQS</a>



                                                <a className="fNItems2 roundedButton EIcon" href="/ContactUs">
                                                    <b>Contact Us</b>
                                                </a>


                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </nav>
                    ) :
                        currentPagePath === "/HomeII" ?
                            (
                                <nav className="navbar navbar-expand-lg navbar-light bg-light fixed-top">
                                    <div style={{ width: "100%" }}>
                                        <div className="flexContainer variation-header">
                                            <div id="InsuBeeLogo">
                                                <a className="navbar-brand" href="/" style={{ marginBottom: "20px" }}><img src={InsuBeeLogo} className="fluid customLogoSize" /></a>
                                            </div>
                                            <div id="NavItems">
                                                <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNavAltMarkup" aria-controls="navbarNavAltMarkup" aria-expanded="false" aria-label="Toggle navigation">
                                                    <span className="navbar-toggler-icon"></span>
                                                </button>
                                                <div className="collapse navbar-collapse navBar-bgImg" id="navbarNavAltMarkup">
                                                    <div className="navbar-nav fNavItems fNavItems-variationB" style={{ }}>
                                                        <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNavAltMarkup" aria-controls="navbarNavAltMarkup" aria-expanded="false" aria-label="Toggle navigation">
                                                            <span className="navbar-toggler-icon"></span>
                                                        </button>
                                                        <a className="fNItems2" href="/HomeII#discoverTheDifferenceB">Why Choose Us</a>
                                                        <a className="fNItems2" href="/Faq">FAQS</a>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </nav>
                            ) :
                            (
                                <nav className="navbar navbar-expand-lg navbar-light bg-light fixed-top">

                                    <div className="pageTop">
                                        <div className="Float1">
                                            <b>Enjoy 10% off your first order when you sign up to our emails</b>
                                        </div>

                                        {sessionStorage.getItem("insubeeToken") && sessionStorage.getItem("userTypeId") == 2 ?
                                            <div className="Float2">
                                                <div class="dropdown">
                                                    <span>My Account</span>
                                                    <div class="dropdown-content">
                                                        <a className="LSu" href="" onClick={e => this.signOut(e)}><FontAwesomeIcon icon={faUser} style={{ fontSize: '1rem' }} /> <b>Sign Out</b></a>
                                                        <a className="LSu" href="/Policy"> <b>My Policies</b></a>
                                                        <a className="LSu" href="/ClaimsList"> <b>My Claims</b></a>
                                                        <a className="LSu" href="/MyProfile"> <b>My Profile</b></a>
                                                    </div>
                                                </div>
                                                {/*<a className="LSu" href="" onClick={e => this.signOut(e)}><FontAwesomeIcon icon={faUser} style={{ fontSize: '1rem' }} /> <b>Sign Out</b></a>*/}
                                            </div>
                                            :
                                            <div className="Float2">
                                                <a className="LSu" href="/Login"><FontAwesomeIcon icon={faUser} style={{ fontSize: '1rem' }} /> <b>Sign Up / Login </b></a>
                                            </div>
                                        }
                                    </div>

                                    <div style={{ width: "100%" }}>
                                        <div className="flexContainer">
                                            <div id="InsuBeeLogo">
                                                <a className="navbar-brand" href="/" style={{ marginBottom: "20px" }}><img src={InsuBeeLogo} className="fluid customLogoSize" /></a>
                                            </div>
                                            <div id="NavItems">
                                                <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNavAltMarkup" aria-controls="navbarNavAltMarkup" aria-expanded="false" aria-label="Toggle navigation">
                                                    <span className="navbar-toggler-icon"></span>
                                                </button>
                                                <div className="collapse navbar-collapse navBar-bgImg" id="navbarNavAltMarkup">
                                                    <div className="navbar-nav fNavItems">
                                                        <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNavAltMarkup" aria-controls="navbarNavAltMarkup" aria-expanded="false" aria-label="Toggle navigation">
                                                            <span className="navbar-toggler-icon"></span>
                                                        </button>
                                                        <a className="fNItems2" href="/Business">SME Biz One <span className="sr-only">(current)</span></a>
                                                        <a className="fNItems2" href="/Blog">Your Inspiration</a>
                                                        <a className="fNItems2" href="/ContactUs">Contact</a>
                                                        <a className="fNItems2 login-display" href="/Login">Sign Up / Login</a>
                                                        <a className="fNItems3 disabled" style={{ paddingTop: "0.8rem" }} href="#">
                                                            <span>
                                                                <img src={EmailDotIcon} id="emailDotIcon" />
                                                                <small style={{ fontSize: "14px", textAlign: "left", marginLeft: "47px" }}><b>Help.support@insubee.com</b></small>
                                                                <small style={{ fontSize: "13px", fontFamily: "Open Sans", textAlign: "left", marginLeft: "47px" }}>Drop us a line</small>
                                                            </span>
                                                        </a>

                                                        <a className="fNItems2 roundedButton EIcon" href="/ProductGetQuote">
                                                            <img src={EmailIcon} className="mr-2" />
                                                            <img src={EmailIconHover} className="mr-2" />
                                                            <b>Get Quote</b>
                                                        </a>
                                                        {/*<a className="fNItems2 roundedButton" href="/ProductDetails"><img src={EmailIcon} /> <b>Get Quote</b></a>*/}

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </nav>
                            )
                }
            </>


        );
    }
}



const mapDispatchToProps = dispatch => {
    return {

        //getDashboardData: () => {

        //    dispatch(actions.getDashboardData())

        //},

    }
}







const mapStateToProps = state => {

    //const { dashboard } = state || {}; // entire Redux store state (the same value returned by a call to store.getState()).  
    //return { dashboard };
};



export default DefaultLayoutNavMenu;