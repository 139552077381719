import React, { Component } from 'react';
import { Button, FormGroup, Label, Input, Row, Col, Card, CardBody, InputGroup, InputGroupAddon } from 'reactstrap';
import "./style.css";

import { hot } from 'react-hot-loader';


import { numberWithCommas } from "../GlobalComponents/_function";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import step from '../../image/Product/step.svg'
import stepActive from '../../image/Product/stepActive.svg'

import ReactTooltip from 'react-tooltip';

//const PostcodeNormalize = onlyNumbersWithMaxLength(5);

class ProductSelectedPlanPresentation extends Component {

    constructor(props) {
        super(props);
        this.state = {
            //DisableButton: true
            //editModal: false,
        };

        //this.editToggle = this.editToggle.bind(this);
        //this.handleSubmit = this.handleSubmit.bind(this);
        //this.Nextpage = this.Nextpage.bind(this);
    }


    //Nextpage() {
    //    let path = `/ProductDetailsPresentation`;
    //    this.props.history.push(path);
    //}

    componentDidMount() {
        window.scrollTo(0, 0);
    }

    render() {

        const { OnclickSelectPlan, ListOfPlan, SelectedPlanResult, editToggle, onIncrement, onDecrement, onClickBack, OnClickSummary, ShowErrorMsg, DisableModelViewButton, selectedPlan } = this.props;
        const { isOpen, SelectedComponentList, addOnCoveragePlanId, PremiumCheck, handleChange, Amount, CancelAddOnCoverage } = this.props;
        let DisableButton = false;
        let FireAddOn;
        let OtherAddOn;
        let ShowTxt;

        if (SelectedPlanResult) {

            let searchIsQuote = SelectedPlanResult.selectedCoveragePlan.filter(x => x.IsQuote == true);
            FireAddOn = SelectedPlanResult.selectedCoveragePlan.find(x => x.addOnCoveragePlanId == 1);
            OtherAddOn = SelectedPlanResult.selectedCoveragePlan.filter(x => x.addOnCoveragePlanId !== 1);

            ShowTxt = OtherAddOn.filter(x => x.premium !== null || x.totalPremium !== null);


            if (searchIsQuote.length < 2) {

                DisableButton = true;
            }

            else {

                DisableButton = false;
            }
        }

        return (

            <>
                <div className="gqProductContainer">
                    <div className="backgroundIMG">
                        <div className="PLanContentContainer">

                            <div className="progress-container gqForm">
                                <div className="steps-progress">
                                    <div className="steps"><img src={stepActive} /></div><hr />
                                    <div className="steps"><img src={step} /></div><hr />
                                    <div className="steps"><img src={step} /></div>
                                </div>
                                <div className="steps-progress hideProgress">
                                    <div style={{ marginLeft: "-10px" }}><span>Get Quote</span></div><hr />
                                    <div style={{ marginLeft: "-8px" }}><span>Details</span></div><hr />
                                    <div style={{ marginRight: "-8px" }}><span>Payment</span></div>
                                </div>
                            </div>

                            <div className="BackButton mt-4 mb-3">
                                <Button onClick={onClickBack} className="btnInsubee">
                                    Back
                            </Button>
                            </div>

                            <div className="row">


                                <div className="columnLeft">
                                    <div className="Itembox">

                                        {(() => {
                                            if (FireAddOn) {
                                                return (

                                                    <div className="ItemBoxContent">

                                                        <div className="columnRight">
                                                            <img src={FireAddOn.imageUrl} className="CustImg" />
                                                        </div>

                                                        <div className="columnLeft">
                                                            <ReactTooltip place="bottom" effect="float" backgroundColor="white" textColor="black" className="toolTipCustom" />
                                                            <span style={{ fontSize: "35px", marginRight: "10px" }}><b>{FireAddOn.addOnCoveragePlanName}</b></span>
                                                            <span data-tip="Cover loss or damage to  to your property due to fire, storm, tempest, flood, aircraft damage, impact damage, bursting or overflowing of water tanks, riot, strike, malicious damage, earthquake or an explosion.">
                                                                <FontAwesomeIcon icon={faInfoCircle} style={{ fontSize: '1rem' }} />
                                                            </span>
                                                            <span style={{ float: "right", marginTop: "20px" }}><b>RM {numberWithCommas(FireAddOn.premium.toFixed(2))}</b></span>
                                                            <hr style={{ marginTop: "0", marginBottom: "10px", borderTop: "2px solid #707070" }} />
                                                            <span style={{ float: "right" }}>Coverage</span><br /><br />
                                                            <span>Sum Insured</span>
                                                            <span style={{ float: "right" }}><b>RM {numberWithCommas(FireAddOn.coverage.toFixed(2))}</b></span>



                                                            <div className="mt-4 AdditionalfontSize" style={{ width: "450px" }}>
                                                                <span><b>ADDITIONAL COVER PERILS WARRANTIES AS BELOW, SUBJECT TO THEIR
                                                RESPECTIVE CLAUSES WARRANTIES ENDORSEMENTS ATTACHED</b></span>
                                                                <br />
                                                                <span>HERETO :-</span>
                                                                <br />
                                                                <span>CODE DESCRIPTION</span><br />
                                                                <p>
                                                                    <span>P001 AIRCRAFT DAMAGE</span>
                                                                    <span>P002 EARTHQUAKE AND VOLCANIC ERUPTION</span>
                                                                    <span>P003 STORM TEMPEST</span>
                                                                    <span>P004 FLOOD</span>
                                                                    <span>P05C EXPLOSION (NON-INDUSTRIAL WITHOUT BOILERS)</span>
                                                                    <span>P06B IMPACT DAMAGE (INCLUDING INSURED'S OWN VEHICLES)</span>
                                                                    <span>P07A BURSTING OR OVERFLOWING OF WATER TANKS APPARATUS OR PIPES (BUILDINGS EXCEEDING FIVE (5) STOREYS (INCLUDING MEZZANINE)</span>
                                                                    <span>P12B RIOT STRIKE AND MALICIOUS DAMAGE</span>
                                                                </p>
                                                            </div>


                                                        </div>

                                                    </div>

                                                )
                                            }
                                        })()}

                                    </div>

                                    <div className="mt-5 mb-1 ml-3">
                                        <h3 className="mb-0"><b>Add-Ons</b></h3>
                                        <span>Please select at least 1</span>
                                    </div>


                                    {(() => {
                                        if (OtherAddOn) {

                                            /*SelectedPlanResult.selectedCoveragePlan.shift();*/

                                            return (

                                                //<div className="nonselectable">
                                                //    <img src={fire} style={{ width: "137.46px", height: "137.46px" }} />
                                                //    <br />
                                                //    <span style={{ fontSize: "18px" }}><b>Property & Stock Is Damaged</b></span>

                                                //    <Button className="nonselectableBtn" onClick={OnClickSummary} disabled={true} ><b>+ This Coverage</b></Button>
                                                //</div>
                                                <div className="AddOnbox mb-5">
                                                    {
                                                        OtherAddOn.map(plan =>
                                                        //<AddOnComponents
                                                        //    plan={plan}
                                                        //    SelectedPlanID={SelectedPlanResult.planId}
                                                        //    editToggle={editToggle}
                                                        //    DisableButton={DisableButton}
                                                        ///>
                                                        {
                                                            let ToolTipTxt = "";
                                                            let BtnTxt = "";

                                                            if (plan.addOnCoveragePlanId == 2) {
                                                                ToolTipTxt = "Cover your business assets and damaged to property due to theft.";
                                                            }

                                                            else if (plan.addOnCoveragePlanId == 3) {
                                                                ToolTipTxt = "Cover loss of money whilst in transit or kept in your premises";
                                                            }

                                                            else if (plan.addOnCoveragePlanId == 4) {
                                                                ToolTipTxt = "Cover for any glass broken due to accident.";
                                                            }

                                                            else if (plan.addOnCoveragePlanId == 5) {
                                                                ToolTipTxt = "Covers your legal liability to third party as a result of an accident happening during the period of cover in connection with your business operations.";
                                                            }
                                                            else {
                                                                ToolTipTxt = "Covers you as an employer from your legal liability to pay compensation for bodily injury or illness to employees arising in the course of their employment.";
                                                            }

                                                            if (plan.IsQuote == true) {
                                                                BtnTxt = "Edit Coverage";
                                                            }
                                                            else {
                                                                BtnTxt = "+ This Coverage";
                                                            }

                                                            return (
                                                                <div key={plan.addOnCoveragePlanId} className={plan.IsQuote == true ? "selectable Quoted" : "selectable"}>
                                                                    <img src={plan.imageUrl} style={{ width: "137.46px", height: "137.46px" }} />
                                                                    <br />
                                                                    <ReactTooltip place="bottom" effect="float" backgroundColor="white" textColor="black" className="toolTipCustom" />
                                                                    <span style={{ fontSize: "18px", marginRight: "7px" }}><b>{plan.addOnCoveragePlanName}</b></span>
                                                                    <span data-tip={ToolTipTxt}>
                                                                        <FontAwesomeIcon icon={faInfoCircle} style={{ fontSize: '1rem' }} />
                                                                    </span>
                                                                    <Button className={plan.IsQuote == true ? "QuotedBtn" : "selectableBtn"} onClick={() => plan.addOnCoveragePlanId !== 1 ? editToggle(plan.addOnCoveragePlanId) : () => false}><b>{BtnTxt}</b></Button>
                                                                </div>
                                                            )
                                                            //}

                                                            //else {
                                                            //    return (
                                                            //        <div key={plan.addOnCoveragePlanId} className="selectable">
                                                            //            <img src={plan.imageUrl} style={{ width: "137.46px", height: "137.46px" }} />
                                                            //            <br />
                                                            //            <ReactTooltip place="bottom" effect="float" backgroundColor="white" textColor="black" className="toolTipCustom" /> 
                                                            //            <span style={{ fontSize: "18px", marginRight: "7px" }}><b>{plan.addOnCoveragePlanName}</b></span>
                                                            //            <span data-tip={ToolTipTxt}>
                                                            //                <FontAwesomeIcon icon={faInfoCircle} style={{ fontSize: '1rem' }} />
                                                            //            </span>
                                                            //            <Button className="selectableBtn" onClick={() => plan.addOnCoveragePlanId !== 1 ? editToggle(plan.addOnCoveragePlanId) : () => false}><b>+ This Coverage</b></Button>
                                                            //        </div>
                                                            //     )
                                                            //}

                                                        }

                                                        )
                                                    }
                                                </div>
                                            )
                                        }
                                    })()}


                                </div>


                                <div className="columnRight">
                                    <div className="Pricebox">

                                        {(() => {
                                            if (OtherAddOn) {

                                                /*SelectedPlanResult.selectedCoveragePlan.shift();*/

                                                return (

                                                    <div className="PriceBoxContent">
                                                        <h4><b>Price Detail</b></h4>
                                                        <br />

                                                        <div className="mb-4">
                                                            <span>Fire</span>
                                                            <span style={{ float: "right" }}><b>RM {numberWithCommas(FireAddOn.premium.toFixed(2))}</b></span>
                                                        </div>

                                                        <div className="mb-3" className={ShowTxt.length >= 1 ? 'mb-3' : 'AddOnNone'}>
                                                            <span><b>Add-Ons</b></span>
                                                        </div>


                                                        {
                                                            OtherAddOn.map(plan =>
                                                            //<AddOnComponents
                                                            //    plan={plan}
                                                            //    SelectedPlanID={SelectedPlanResult.planId}
                                                            //    editToggle={editToggle}
                                                            //    DisableButton={DisableButton}
                                                            ///>  

                                                            {
                                                                if (plan.addOnCoveragePlanId == 3 && plan.totalPremium != null) {
                                                                    let SUMInsured = plan.coverageInPremise + plan.coverageInTransit;
                                                                    return (
                                                                        <div key={plan.addOnCoveragePlanId}>
                                                                            <div className="mb-0">
                                                                                <span>{plan.addOnCoveragePlanName}</span>
                                                                                <span style={{ float: "right" }}><b>RM {numberWithCommas(Number(plan.totalPremium).toFixed(2))}</b></span>
                                                                            </div>
                                                                            <div className="mb-3">
                                                                                <span style={{ fontSize: "12px" }}>(Sum Insured RM{numberWithCommas(SUMInsured.toFixed(2))})</span>
                                                                            </div>
                                                                        </div>
                                                                    )
                                                                }

                                                                else {
                                                                    if (plan.premium != null) {
                                                                        return (
                                                                            <div key={plan.addOnCoveragePlanId}>
                                                                                <div className="mb-0">
                                                                                    <span>{plan.addOnCoveragePlanName}</span>
                                                                                    <span style={{ float: "right" }}><b>RM {numberWithCommas(Number(plan.premium).toFixed(2))}</b></span>
                                                                                </div>
                                                                                <div className="mb-3">
                                                                                    <span style={{ fontSize: "12px" }}>(Sum Insured RM{numberWithCommas(plan.coverage.toFixed(2))})</span>
                                                                                </div>
                                                                            </div>
                                                                        )
                                                                    }
                                                                }
                                                            }

                                                            )
                                                        }


                                                        <hr style={{ marginTop: "0", marginBottom: "10px", borderTop: "2px solid #707070" }} />

                                                        <br />
                                                        <span style={{ fontSize: "18px" }}><b>Total Price</b></span>
                                                        <span style={{ float: "right", fontSize: "18px" }}><b>RM {numberWithCommas(Number(SelectedPlanResult.totalPremiumAmount).toFixed(2))}</b></span>


                                                        {/*<Button color="warning" onClick={OnClickSummary} className="btn font-weight-bold btn-insubee-orange w-100" size="sm" disabled={DisableButton == true ? true : false}>Get Quote</Button>*/}

                                                        <Button className="btnInsubee mt-4" onClick={OnClickSummary} disabled={DisableButton == true ? true : false}>Get Quote</Button>

                                                    </div>
                                                )
                                            }
                                        })()}

                                    </div>
                                </div>

                            </div>

                        </div>
                    </div>
                </div>


            </>
        )
    }
}


export default hot(module)(ProductSelectedPlanPresentation)

