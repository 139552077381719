import constants from '../_constants/constants';
import Cookie from 'universal-cookie'
import { handleResponse, handleBlobResponse } from "../_helpers/handleResponse";
import { authHeader, authHeaderFile, authHeaderWithoutUser } from '../_helpers/authHeader';

const cookies = new Cookie();

const policyServices = {
    getPolicy,
    getPolicyDetails,
    downloadCustomerPolicy
}


function downloadCustomerPolicy(customerPolicyId) {
    const requestOptions = {
        method: "GET",
        headers: authHeader()
    };
    const url = "/api/Policy/DownloadCustomerPolicy/" + customerPolicyId;

    return fetch(url, requestOptions).then(
        (response) => handleBlobResponse(response, requestOptions, url)

    ).then(data => {
        return data;
    });
}

function getPolicy(statusId,page,size) {
    const requestOptions = {
        method: "GET",
        headers: authHeader()
    };
    const url = "/api/Policy/GetPolicy?statusId=" + statusId + "&pageNumber=" + page + "&pageSize="+size;

    return fetch(url, requestOptions).then(
        (response) => handleResponse(response, requestOptions, url)
    ).then(data => {
        return data;
    });
}

function getPolicyDetails(id) {
    const requestOptions = {
        method: "GET",
        headers: authHeader()
    };
    const url = "/api/Policy/GetPolicy/"+id;

    return fetch(url, requestOptions).then(
        (response) => handleResponse(response, requestOptions, url)
    ).then(data => {
        return data;
    });
}

export default policyServices;
