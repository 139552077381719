import CryptoJS from "crypto-js";
import constants from "../_constants/constants";
import configData from '../_helpers/key';
import JSEncrypt from 'jsencrypt';

// encrypt
export const encrypt = (pwd) => {
    var ky = configData.ky;
    var encrypt1 = new JSEncrypt();
    encrypt1.setPublicKey(ky);
    var encrypted = encrypt1.encrypt(pwd);
    return encrypted;
};
