import React from 'react';
import { Container } from 'reactstrap';
import DefaultLayoutNavMenu from './DefaultLayoutNavMenu';
import DefaultLayoutSideNavMenu from './DefaultLayoutSideNavMenu';
import DefaultFooter from './DefaultFooter';
import WhatsAppWidget from 'react-whatsapp-widget';
import 'react-whatsapp-widget/dist/index.css';
export default props => (
    <div>
        <DefaultLayoutNavMenu />
        {/*{*/}
        {/*    sessionStorage.getItem("insubeeToken") ?*/}
        {/*        <DefaultLayoutSideNavMenu />*/}
        {/*        : null*/}
        {/*}*/}
        <div className="page-wrapper pt-4">
            {props.children}
        </div>
        <DefaultFooter />
        <WhatsAppWidget phoneNumber='60127850633' textReplyTime="Typically replies within a day" message="Hello! Do you need help with your getting the right insurance? Reply to this message if you have any questions. We would love to help." companyName="Insubee " />
    </div>
);
