import financeConstants from './constants';

const initialState = {
    isLoading: false,
};


export const reducer = (state, action) => {
    state = state || initialState;

    switch (action.type) {
        //GENERATE REPORT
        case financeConstants.GENERATE_REPORT_REQUEST:
            return {
                ...state,
                generateReportSuccess: false,
                isLoading: true,
            }
        case financeConstants.GENERATE_REPORT_SUCCESS:
            return {
                ...state,
                generateReportSuccess: true,
                isLoading: false,
            }
        case financeConstants.GENERATE_REPORT_FAILURE:
            return {
                ...state,
                generateReportSuccess: false,
                isLoading: true,
            }
        default:
            return state;
    }
}