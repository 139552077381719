import crmConstants from './constants';
import Swal from 'sweetalert2';

const initialState = {
    isLoading: false,
    showFail: false,
    showSuccess: false
};


export const reducer = (state, action) => {
    state = state || initialState;

    switch (action.type) {
        //CUSTOMER LIST
        case crmConstants.GET_CUSTOMER_LIST_REQUEST:
            return {
                ...state,
                getCustomerListSuccess: false,
                customerList: undefined,
                isLoading: true,
            }
        case crmConstants.GET_CUSTOMER_LIST_SUCCESS:
            return {
                ...state,
                getCustomerListSuccess: true,
                customerList: action.data,
                isLoading: false,
            }
        case crmConstants.GET_CUSTOMER_LIST_FAILURE:
            return {
                ...state,
                getCustomerListSuccess: false,
                customerList: undefined,
                isLoading: true,
            }
        
        //CUSTOMER DETAILS
        case crmConstants.GET_CUSTOMER_DETAILS_REQUEST:
            return {
                ...state,
                getCustomerDetailsSuccess: false,
                customerDetails: undefined,
                isLoading: true,
            }
        case crmConstants.GET_CUSTOMER_DETAILS_SUCCESS:
            return {
                ...state,
                getCustomerDetailsSuccess: true,
                customerDetails: action.data,
                isLoading: false,
            }
        case crmConstants.GET_CUSTOMER_DETAILS_FAILURE:
            return {
                ...state,
                getCustomerDetailsSuccess: false,
                customerDetails: undefined,
                isLoading: true,
            }
        //RESET CUSTOMER PASSWORD
        case crmConstants.ADMIN_RESET_CUSTOMER_PASSWORD_REQUEST:
            return {
                ...state,
                isLoading: true,
                showFail: false,
                showSuccess: false
            }
        case crmConstants.ADMIN_RESET_CUSTOMER_PASSWORD_SUCCESS:
            return {
                ...state,
                isLoading: false,
                showFail: false,
                showSuccess: true,
            }
        case crmConstants.ADMIN_RESET_CUSTOMER_PASSWORD_FAIL:
            return {
                isLoading: false,
                showFail: true,
                showSuccess: false
            }
        case crmConstants.ADMIN_HIDE_RESET_CUSTOMER_PASSWORD_FAIL:
            return {
                isLoading: false,
                showFail: false,
                showSuccess: false
            }
        default:
            return state;
    }
}