import moment from 'moment';

const required = value => (value || typeof value === 'number' ? undefined : 'Required')

//need to pass in parameter value
const maxLength = max => value =>
    value && value.length > max ? `must be ${max} characters or less` : undefined

const minLength = min => value =>
    value && value.length < min ? `must be ${min} characters or more` : undefined

const exactLength = exactLength => value =>
    value && value.length != exactLength ? `must be exact ${exactLength} characters` : undefined

const number = value =>
    value && isNaN(Number(value)) ? 'must be a number' : undefined

const minValue = min => value =>
    value && value < min ? `must be at least ${min}` : undefined

const maxValue = max => value =>
    value && value > max ? `must not more than ${max}` : undefined

const email = value =>
    value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)
        ? ' is not valid.'
        : undefined

const tooYoung = value =>
    value && value < 13
        ? 'You do not meet the minimum age requirement!'
        : undefined

const aol = value =>
    value && /.+@aol\.com/.test(value)
        ? 'Really? You still use AOL for your email?'
        : undefined

const alphaNumeric = value =>
    value && /[^a-zA-Z0-9 ]/i.test(value)
        ? 'Only alphanumeric characters'
        : undefined

const alphabets = value =>
    value && !/^[a-zA-Z ]*$/.test(value)
        ? 'Only alphabet characters'
        : undefined

const alphabetsWithSpecialCharacters = value =>
    value && !/^[a-zA-Z_@./#&+-]*$/.test(value)
        ? 'Only alphabet characters'
        : undefined

const phoneNumber = value =>

    (value && !/^([1-9][0-9]{9})$/i.test(value) && !/^([1-9][0-9]{8})$/i.test(value) && !/^([2-9][0-9]{7})$/i.test(value))
        ? 'Invalid'
        : undefined

const passwordsMatch = (value, allValues, props, name) => {
    if (props.values.Password !== value) {
        return "and password do not match."
    }

    return undefined;
}

const ResetpasswordsMatch = (value, allValues, props, name) => {
    if (props.values.Password !== value) {
        return "and New Password do not match."
    }

    return undefined;
}

const passwordRequirements = (value) => {
    var containLowerCase = new RegExp("^(?=.*[a-z])");
    var containUpperCase = new RegExp("^(?=.*[A-Z])");
    var containNumericCase = new RegExp("^(?=.*[0-9])");
    var containSpecialCase = new RegExp("^(?=.*[!@#\$%\^&\*])");

    if (!containLowerCase.test(value)) {
        return " must have one lowercase."
    }

    if (!containUpperCase.test(value)) {
        return " must have one uppercase."
    }

    if (!containNumericCase.test(value)) {
        return " must have one numeric character."
    }

    if (!containSpecialCase.test(value)) {
        return " must have one special character."
    }

    return undefined;
}

const identityNumberValidate = (value) => {
    if (value.match(/^(\d{2})(\d{2})(\d{2})-?(\d{2})-?(\d{4}$)/)) {
        const minAge = 12;

        var ICyear = RegExp.$1;
        var ICmonth = RegExp.$2;
        var ICday = RegExp.$3;
        var PBCode = RegExp.$4;
        var lastFourDigits = RegExp.$5;

        const dateNow = new Date();
        const minYear = (dateNow.getFullYear() - 100).toString().substr(2, 2);

        //add full year for IC first two digit to perform valid date function.
        if (ICyear <= minYear) {
            var newICDate = "20" + ICyear + "-" + ICmonth + "-" + ICday;
        } else {
            var newICDate = "19" + ICyear + "-" + ICmonth + "-" + ICday;
        }

        var dateFormat = "YYYY-MM-DD";
        var validDate = moment(newICDate, dateFormat, true).isValid();

        if (!validDate)
            return "not a valid format."

        const yearsOld = (moment(newICDate, dateFormat).fromNow()).substr(0, 2);

        if (yearsOld <= minAge)
            return "must be more than 12 years old."

        return undefined;
    }
}

const shipmentWeight = (value) =>
    value && value > 99999 ? `Weight cannot exceed 99999 kg` : undefined;


const numberRequired = (value) => {

    if (value <= 0) {
        return "must more than 0."
    }

    return undefined;
}

const percentageRequired = (value) => {
    var val = parseFloat(value);
    if (isNaN(val) || val < 0 || val > 100) {
        return "must between 0 - 100 %"
    }
}


// Please note that this is for normalize function use.
const onlyNumbers = (value) => {
    return value.replace(/[^0-9]+/g, '');
}

const onlyFloatNumbers = deciamlPlaces => value => {

    if (value.charAt(0) == ".") {
        return "0" + value;
    }

    var decimalRegex = new RegExp("(\\.[\\d]{" + deciamlPlaces + "}).", "g");

    return value.replace(/[^\d.]/g, '').replace(/(\..*)\./g, '$1').replace(decimalRegex, '$1')
}

const onlyNumbersWithMaxLength = max => value => {
    if (value.length < max) {
        return value.replace(/[^0-9]+/g, '');
    }
}

const trimString = (value) => {
    return value.replace(/ /g, '')
}

const onlyAlphanumericWithMaxLength = max => value => {
    if (value.length <= max) {
        return value.replace(/[^A-Za-z0-9_@./!@#$%^,?()&*/ ]/g, '');
    }
}

const identityNumberNormalize = (value) => {
    if (value.length <= 12) {
        return value.replace(/[^0-9]+/g, '');
    }
}

const numberWithRange = (min, max) => value => {

    if (value != "") {
        if (value >= min && value <= max) {
            return value.replace(/[^0-9]+/g, '');
        }
    } else {
        return value.replace(/[^0-9]+/g, '');
    }
}

const phoneNumberNormalize = (value) => {
    if (value.length <= 10) {
        // if(value.charAt(0) != "0" && value.charAt(0) != "6")
        if (value.charAt(0) != "0") {
            return value.replace(/[^0-9]+/g, '');
        }
    }
}

const numberWithRangeBurglary = (min, max) => value => {

    if (value != "") {

        if (value > max) {

            return max;
        }
        else {
            return value.replace(/[^0-9]+/g, '');
        }

    } else {
        return value.replace(/[^0-9]+/g, '');
    }
}

const numberWithRangeMoney = (min, max) => value => {

    if (value != "") {

        if (value > max) {

            return max;
        }
        else {
            return value.replace(/[^0-9]+/g, '');
        }

    } else {
        return value.replace(/[^0-9]+/g, '');
    }
}

const numberWithRangeGlass = (min, max) => value => {

    if (value != "") {

        if (value > max) {

            return max;
        }
        else {
            return value.replace(/[^0-9]+/g, '');
        }

    } else {
        return value.replace(/[^0-9]+/g, '');
    }
}

const numberWithRangePublicLiability = (min, max) => value => {

    if (value != "") {

        if (value > max) {

            return max;
        }
        else {
            return value.replace(/[^0-9]+/g, '');
        }

    } else {
        return value.replace(/[^0-9]+/g, '');
    }
}

const numberWithRangeEmployerLiability = (min, max) => value => {

    if (value != "") {

        if (value > max) {

            return max;
        }
        else {
            return value.replace(/[^0-9]+/g, '');
        }

    } else {
        return value.replace(/[^0-9]+/g, '');
    }
}

const numberWithRangeBuildingPropertyQuantity = (min, max) => value => {

    if (value != "") {

        if (value > max) {

            return max;
        }
        else {
            return value.replace(/[^0-9]+/g, '');
        }

    } else {
        return value.replace(/[^0-9]+/g, '');
    }
}

const formatCurrency = (num) => {
    return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
}
export {
    required, maxLength, minLength, number, minValue, maxValue, email, exactLength, onlyAlphanumericWithMaxLength, shipmentWeight, percentageRequired,
    phoneNumber, alphaNumeric, alphabets, alphabetsWithSpecialCharacters, passwordsMatch, ResetpasswordsMatch, passwordRequirements, onlyNumbers, onlyFloatNumbers, identityNumberValidate, numberRequired,
    identityNumberNormalize, onlyNumbersWithMaxLength, numberWithRange, phoneNumberNormalize, formatCurrency, numberWithRangeBurglary, numberWithRangeMoney, numberWithRangeGlass,
    numberWithRangePublicLiability, numberWithRangeEmployerLiability, numberWithRangeBuildingPropertyQuantity, trimString
}