import React from 'react'
import DropdownList from 'react-widgets/DropdownList'
import "react-widgets/styles.css";
class DropdownSelectField extends React.Component {
    render() {
        const { input, data, valueField, textField, filter, label, busy, groupBy, className, required, errorClassName, messages, placeholder, disabled, allowCreate, onCreate, defaultValue,
            meta: { touched, error, warning }
        } = this.props;

        const hasError = touched && error;

        return (
            <React.Fragment>

                {/*<div className="lowerindex">*/}
                {/*    <label className="textboxlabel">{label !== undefined ? label.toUpperCase() : label}{required === "true" ? <span className="text-red"> *</span> : ""}</label>*/}
                {/*</div>*/}
                <DropdownList {...input}


                    {...input}
                    className={className}
                    data={data}
                    valueField={valueField}
                    messages={messages}
                    placeholder={placeholder}
                    containerClassName={hasError ? "is-invalid" : ""}
                    textField={textField}
                    groupBy={groupBy}
                    onChange={input.onChange}
                    allowCreate={allowCreate}
                    onCreate={onCreate}
                    filter={filter}
                    busy={busy}
                    disabled={disabled}
                    required={required}
                    errorClassName={errorClassName}
                />
                {hasError && <span className={errorClassName !== undefined ? errorClassName : "invalid-feedback2"}>{(label) + " " + error}</span>}
            </React.Fragment>
        )
    }
}

export default DropdownSelectField;
