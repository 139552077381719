import React, { Component, Suspense } from 'react';
import { Redirect } from 'react-router';

import { Route, Switch } from 'react-router-dom';
import { Container } from 'reactstrap';

import DefaultLayoutContainer from './DefaultLayoutContainer';
import 'jquery';
import 'bootstrap/dist/js/bootstrap';

import Home from '../../Home/Home';
import HomeA from '../../Home/HomeA';
import HomeB from '../../Home/HomeB';
import Login from '../../Account/Login';
import SignUp from '../../Account/SignUp';
import ContactUs from '../../Home/ContactUs';
import AboutUs from '../../Home/AboutUs';
import faq from '../../Static/faq';
import TermsofService from '../../Static/TermsofService';
import ForgotPassword from '../../Account/ForgotPassword';
import ResetPassword from '../../Account/ResetPassword';
import MyProfile from '../../User/MyProfile';
import ChangePassword from '../../User/ChangePassword';
import PolicyList from '../../Policy/PolicyList';
import PolicyDetails from '../../Policy/PolicyDetails';
import ProductList from '../../Product/ProductList';
import Blog from '../../Home/Blog';
import BlogDetailsKeysToAccelerateBusiness from '../../Home/BlogDetailsKeysToAccelerateBusiness';
import BlogDetailsRM1MillionPublicLiability from '../../Home/BlogDetailsRM1MillionPublicLiability';
import BlogDetailsInsuranceAsABackbone from '../../Home/BlogDetailsInsuranceAsABackbone';
import BlogDetailsDoesBusinessInsuranceCoverFloods from '../../Home/BlogDetailsDoesBusinessInsuranceCoverFloods';
import ClaimsList from '../../Claims/ClaimsList';
import ClaimsDetail from '../../Claims/ClaimsDetail';
import SubmitClaims from '../../Claims/SubmitClaims';
import PrivacyPolicy from '../../Static/PrivacyPolicy';
import ProductGetQuote from '../../Product/ProductGetQuote';
import Payment from '../../Payment/Payment';
import PaymentSuccess from '../../Payment/PaymentSuccess';
import CompleteRegistration from '../../Account/CompleteRegistration';
import FailVerification from '../../Account/FailVerificationPresentation';

import AdminLogin from '../CoreUi/views/Pages/Login';

import Cookies from 'universal-cookie';


const cookies = new Cookies();


export default props => (

    <div className="defaultLayout">

        <DefaultLayoutContainer>

            <Switch>
                
                <Route path="/PrivacyPolicy" name="PrivacyPolicy" component={PrivacyPolicy} />
                <Route path="/Blog" name="Blog" exact component={Blog} />
                <Route path="/BlogDetailsKeysToAccelerateBusiness" name="BlogDetailsKeysToAccelerateBusiness" exact component={BlogDetailsKeysToAccelerateBusiness} />
                <Route path="/BlogDetailsRM1MillionPublicLiability" name="BlogDetailsRM1MillionPublicLiability" exact component={BlogDetailsRM1MillionPublicLiability} />
                <Route path="/BlogDetailsInsuranceAsABackbone" name="BlogDetailsInsuranceAsABackbone" exact component={BlogDetailsInsuranceAsABackbone} />
                <Route path="/BlogDetailsDoesBusinessInsuranceCoverFloods" name="BlogDetailsDoesBusinessInsuranceCoverFloods" exact component={BlogDetailsDoesBusinessInsuranceCoverFloods} />
                <Route path="/ContactUs" name="ContactUs" exact component={ContactUs} />
                <Route path="/Faq" name="faq" component={faq} />
                <Route path="/TermsofService" name="TermsofService" component={TermsofService} />
                <Route path="/Login" name="Login" component={Login} />
                <Route path="/SignUp" name="Signup" component={SignUp} />
                <Route path="/AboutUs" name="AboutUs" component={AboutUs} />
                <Route path="/ForgotPassword" name="ForgotPassword" component={ForgotPassword} />
                <Route path="/ResetPassword" name="ResetPassword" component={ResetPassword} />
                <Route path="/Business" name="ProductList" component={ProductList} />
                <Route path="/ProductGetQuote" name="ProductGetQuote" exact component={ProductGetQuote} />
                <Route path="/CompleteRegistration" name="CompleteRegistration" exact component={CompleteRegistration} />
                <Route path="/FailVerification" name="CompleteRegistration" exact component={FailVerification} />




                {
                    sessionStorage.getItem("insubeeToken") ?
                        <Route path="/Policy" name="PolicyList" component={PolicyList} />
                        : null
                }
                {
                    sessionStorage.getItem("insubeeToken") ?
                        <Route path="/SubmitClaims" name="SubmitClaims" exact component={SubmitClaims} />
                        : null
                }
                {
                    sessionStorage.getItem("insubeeToken") ?
                        <Route path="/ClaimsDetail" name="ClaimsDetail" exact component={ClaimsDetail} />
                        : null
                }
                {
                    sessionStorage.getItem("insubeeToken") ?
                        <Route path="/ClaimsList" name="ClaimsList" exact component={ClaimsList} />
                        : null
                }
                {
                    sessionStorage.getItem("insubeeToken") ?
                        <Route path="/MyProfile" name="MyProfile" component={MyProfile} />
                        : null
                }
                {
                    sessionStorage.getItem("insubeeToken") ?
                        <Route path="/ChangePassword" name="ChangePassword" component={ChangePassword} />
                        : null
                }
                {
                    sessionStorage.getItem("insubeeToken") ?
                        <Route path="/PolicyDetails" name="PolicyDetails" component={PolicyDetails} />
                        : null
                }
                {/*{*/}
                {/*    cookies.get("insubeeToken") ?*/}
                {/*        <Route path="/Payment" name="Payment" component={Payment} />*/}
                {/*        : null*/}
                {/*}*/}
                {/*{*/}
                {/*    cookies.get("insubeeToken") ?*/}
                {/*        <Route path="/Paymentsuccess" name="PaymentSuccess" component={PaymentSuccess} />*/}
                {/*        : null*/}
                {/*}*/}
                <Route path="/Payment" name="Payment" component={Payment} />
                <Route path="/Paymentsuccess" name="PaymentSuccess" component={PaymentSuccess} />
                <Route path="/HomeI" name="HomeA" component={HomeA} />
                <Route path="/HomeII" name="HomeB" component={HomeB} />
                <Route path="/" name="Home" component={Home} />
            </Switch>
           
        </DefaultLayoutContainer>

    </div>
);

