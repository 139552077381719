import userConstants from './constants';
import accountConstants from "../Account/constants";
import constants from '../_constants/constants';

import userServices from "./services";
import accountServices from "../Account/services";

import { toast } from 'react-toastify';
import { push } from 'connected-react-router';
import Cookies from 'universal-cookie';
import Swal from 'sweetalert2';
import moment from 'moment';

const cookies = new Cookies();

// redux/actions.js
const actions = {
    getProfile,
    getLocationByFromPostcode,
    getNationalityList,
    changePassword,
    updateProfile,
    getCommonPasswordList
}

function getCommonPasswordList() {
    return dispatch => {

        accountServices.getCommonPasswordList().then(
            data => {
                dispatch(success(data));
            },
            error => {
                dispatch(failure(error));

            }
        )
    }

    function success(data) { return { type: accountConstants.GET_COMMON_PASSWORD_SUCCESS, data } }
    function failure(error) { return { type: accountConstants.GET_COMMON_PASSWORD_FAILURE, error } }

}

function getLocationByFromPostcode(postcode) {

    return dispatch => {
        dispatch(request(postcode));
        accountServices.getLocationByFromPostcode(postcode).then(
            data => {
                dispatch(success(data.result));
            },
            error => {
                dispatch(failure(error));
                Swal.fire({
                    title: 'Invalid Postcode',
                    message: '',
                    type: 'error',
                });
            }
        )
    }

    function request(data) { return { type: accountConstants.GET_FROMLOCATION_REQUEST, data } }
    function success(data) { return { type: accountConstants.GET_FROMLOCATION_SUCCESS, data } }
    function failure(error) { return { type: accountConstants.GET_FROMLOCATION_FAILURE, error } }

}

function getNationalityList() {
    return dispatch => {

        accountServices.getNationalityList().then(
            data => {
                dispatch(success(data));
            },
            error => {
                dispatch(failure(error));

            }
        )
    }

    function success(data) { return { type: accountConstants.GET_NATIONALITY_SUCCESS, data } }
    function failure(error) { return { type: accountConstants.GET_NATIONALITY_FAILURE, error } }

}

function updateProfile(editProfileModel) {

    return dispatch => {
        dispatch(request(editProfileModel));
        userServices.updateProfile(editProfileModel).then(
            data => {
                if (data.status == "success") {
                    dispatch(success(data.result));
                    Swal.fire({
                        title:'Update Profile Success',
                        message:'',
                        type:'success',
                    }).then((result) => {
                            window.location.href = "/myProfile";
                    })
                    
                }
            },
            error => {
                Swal.fire({
                    type: 'error',

                    text: error.errorMessage,

                })
                dispatch(fail(error.errorMessage));
                dispatch(showFail(error.errorMessage));
            }
        )
    }

    function request(data) {
        return {
            type: userConstants.POST_UPDATEPROFILE_REQUEST,
            data
        }
    }
    function success(data, email) {
        return {
            type: userConstants.POST_UPDATEPROFILE_SUCCESS,
            data,
            email
        }
    }
    function fail(error) {
        return {
            type: userConstants.POST_UPDATEPROFILE_FAILURE,
            error
        }
    }
    function showFail(error) {
        return {
            type: userConstants.SHOW_UPDATEPROFILE_FAIL,
            error
        }
    }
}

function getProfile() {
    return dispatch => {

        dispatch(request());
        userServices.getProfile().then(
            data => {
                dispatch(success(data.result));
            },
            error => {
                dispatch(failure(error.errorMessage));

            }
        )
    }

    function request(data) { return { type: userConstants.GET_PROFILE_REQUEST, data } }
    function success(data) { return { type: userConstants.GET_PROFILE_SUCCESS, data } }
    function failure(error) { return { type: userConstants.GET_PROFILE_FAILURE, error } }
}

function changePassword(changePasswordModel) {

    return dispatch => {
        dispatch(request(changePasswordModel));
        userServices.changePassword(changePasswordModel).then(
            data => {
                dispatch(success(data.result));
                Swal.fire({
                    title: 'Change Password Success',
                    text: 'Please login again.',
                    type: 'success',
                    allowOutsideClick: false,
                }).then((result) => {
                    if (result.value) {
                        cookies.remove(constants.tokenName, { path: '/' });
                        cookies.remove(constants.userId, { path: '/' });
                        cookies.remove(constants.userName, { path: '/' });
                        cookies.remove(constants.roleName, { path: '/' });
                        cookies.remove(constants.isVerified, { path: '/' });
                        cookies.remove(constants.refreshToken, { path: '/' });
                        cookies.remove(constants.adminAccess, { path: '/' });

                        sessionStorage.removeItem(constants.tokenName);
                        sessionStorage.removeItem(constants.userId);
                        sessionStorage.removeItem(constants.userTypeId);
                        sessionStorage.removeItem(constants.userName);
                        sessionStorage.removeItem(constants.roleName);
                        sessionStorage.removeItem(constants.isVerified);
                        sessionStorage.removeItem(constants.refreshToken);
                        sessionStorage.removeItem(constants.adminAccess);

                        window.location.href = "/";
                    }
                });
                
            },
            error => {
                Swal.fire({
                    type: 'error',

                    text: error.errorMessage,

                })
                dispatch(fail(error.errorMessage))
                dispatch(showFail(error.errorMessage));
            }
        )
    }

    function request(data) { return { type: userConstants.USER_CHANGE_PASSWORD_REQUEST, data } }
    function success(data) { return { type: userConstants.USER_CHANGE_PASSWORD_SUCCESS, data} }
    function fail(error) { return { type: userConstants.USER_CHANGE_PASSWORD_FAIL, error } }
    function showFail(error) { return { type: userConstants.USER_SHOW_CHANGE_PASSWORD_FAIL, error } }
}


export default (actions);


