import React from 'react';
import { connect } from 'react-redux';
import actions from './actions';
import SweetAlert from 'sweetalert2-react';
import { Button, FormGroup, Label, Input, Row, Col, Card, CardBody, InputGroup, InputGroupText } from 'reactstrap';
import "./style.css";
import ExpiredLogo from "../../image/Policy/expired.PNG";
import SubmitLogo from "../../image/Policy/SubmitRemovebg.png";
import RenewLogo from "../../image/Policy/RenewRemovebg.png";
import PdfLogo from "../../image/Policy/icons8_pdf.png";
import { numberWithCommas } from "../GlobalComponents/_function";
import moment from 'moment';

class PolicyDetailsPresentation extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            policyId :0
        };
    }

    componentDidMount() {
        const policyId = window.localStorage.getItem('customerPolicyId');
        this.setState({
            policyId: policyId
        });
        window.scrollTo(0, 0);
        this.props.getPolicyDetails(policyId);
    }

    render() {

        let { policy } = this.props;

        let policyDetails = [];

        if (policy.policyDetails) {
            policyDetails = policy.policyDetails;
        }


        return (
            <div className="my-3">
                <div className="container">
                    <Row>
                        <Col xs="12" sm="12" lg="12">
                            <div className="col-sm-12">
                                <h2 className="font-weight-bold text-center">Policy Details</h2>
                            </div>
                            <br/>
                            <div className="row justify-content-center">
                                <div className="col-sm-10">
                                    <Card style={{ border: '7px solid rgba(169,176,180,0.1)',paddingLeft:'1rem',paddingRight:'1rem'}}>
                                        <CardBody>
                                            <div className="row">
                                                <div className="col-sm-8 col-xs-12">
                                                    <h3 className="font-weight-bold">SME BIZ ONE</h3>
                                                    <p>Business</p>
                                                </div>
                                                
                                                <div className="col-sm-4 text-right col-xs-12 mt-sm-0 mt-2">
                                                    {/*{*/}
                                                    {/*    policyDetails.statusId === 4 ? <div className="mini-stat-icon align-self-center rounded-circle">*/}
                                                    {/*        <img src={ExpiredLogo} height="60" />*/}
                                                    {/*    </div> : <Button color="warning" className="btn font-weight-bold btn-insubee-orange w-100" >*/}
                                                    {/*        <img src={RenewLogo} height="40" /> Renew Policy</Button>*/}
                                                    {/*}*/}
                                                    {/*<Button color="warning" className="btn font-weight-bold btn-insubee-orange w-100 mt-2">*/}
                                                    {/*    <img src={SubmitLogo} height="40" /> Submit Claim</Button>*/}
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-sm-4">
                                                    <p className="mb-0">Policy No</p>
                                                    <p style={{ fontSize: '20px' }}><strong>{policyDetails.policyNo ? policyDetails.policyNo : "Pending"}</strong></p>
                                                </div>
                                                <div className="col-sm-4">
                                                    <p className="mb-0">Premium Amount</p>
                                                    <p style={{ fontSize: '20px' }}><strong>RM {policyDetails.totalPremium ? numberWithCommas(policyDetails.totalPremium.toFixed(2)) : "0.00"}</strong></p>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-sm-4">
                                                    <p className="mb-0">Status</p>
                                                    <p style={{ fontSize: '20px' }}><strong>{policyDetails.status}</strong></p>
                                                </div>
                                                <div className="col-sm-4">
                                                    <p className="mb-0">Coverage Amount</p>
                                                    <p style={{ fontSize: '20px' }}><strong>RM {policyDetails.totalCoverage ? numberWithCommas(policyDetails.totalCoverage.toFixed(2)) : "0.00"}</strong></p>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-sm-4">
                                                    <p className="mb-0">Premium Due Date</p>
                                                    <p style={{ fontSize: '20px' }}><strong>{policyDetails.premiumDueDate !== null ? policyDetails.premiumDueDate:"Pending"}</strong></p>
                                                </div>
                                                <div className="col-sm-4">
                                                    <p className="mb-0">Policy Holder</p>
                                                    <p style={{ fontSize: '20px' }}><strong>{policyDetails.policyHolderName}</strong></p>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-sm-4">
                                                    <p className="mb-0">Coverage Period</p>
                                                    <p style={{ fontSize: '20px' }}><strong>{moment(policyDetails.coverageStartDate).format('DD/MM/YYYY') + " - " + moment(policyDetails.coverageEndDate).format('DD/MM/YYYY')}</strong></p>
                                                </div>
                                            </div>
                                            <br></br>
                                            <br></br>
                                            <br></br>
                                            <div className="row mt-3">
                                                <div className="col-sm-12">
                                                    <Card>
                                                        <CardBody style={{ padding: '10px 10px 10px 10px' }}>
                                                            <div className="d-flex">
                                                                <div className="policy-info text-left" style={{ alignSelf: 'center' }}>
                                                                    Policy
                                                                </div>
                                                                {
                                                                    policyDetails.policyDocumentUrl !== null ?
                                                                        <div className="ml-auto">
                                                                            {/*<a href={policyDetails.policyDocumentUrl} target="_blank"><img style={{ cursor: 'pointer' }} src={PdfLogo} height="40" /></a> */}
                                                                            <img style={{ cursor: 'pointer' }} onClick={e => this.props.downloadCustomerPolicy(policyDetails.customerPolicyId, policyDetails.policyNo)} src={PdfLogo} height="40" />
                                                                        </div>
                                                                        :
                                                                        <div className="ml-auto">
                                                                            <div className="policy-info text-left" style={{ alignSelf: 'center', fontSize: '16px', fontFamily: 'Open Sans' }}>Pending</div>
                                                                        </div>
                                                                }
                                                            </div>
                                                        </CardBody>
                                                    </Card>
                                                </div>
                                            </div>
                                            <div className="row mt-3">
                                                <div className="col-sm-12">
                                                    <Card>
                                                        <CardBody style={{ padding: '10px 10px 10px 10px' }}>
                                                            <div className="d-flex">
                                                                <div className="policy-info text-left" style={{ alignSelf: 'center' }}>
                                                                    Product Disclosure Sheet - SME BIZ ONE
                                                                </div>
                                                                <div className="ml-auto">
                                                                    
                                                                    <a href="/assets/TM_SME_BIZONE_Disclosure_Sheet_V3.pdf" target="_blank">
                                                                        <img src={PdfLogo} height="40" />
                                                                        </a>
                                                                </div>
                                                            </div>
                                                        </CardBody>
                                                    </Card>
                                                </div>
                                            </div>
                                        </CardBody>
                                    </Card>
                                </div>
                            </div>
                           
                        </Col>
                    </Row>
                </div>
            </div>
            )

    }
}
export default (PolicyDetailsPresentation);