import React, { Component } from 'react';
import { Button,Carousel, CarouselCaption, NavLink, CarouselIndicators, CarouselItem,CarouselControl, Container, Row, Col, Card, CardBody } from 'reactstrap';

import facebook from '../../image/Footer/facebook.png';
import instagram from '../../image/Footer/instagram.png';
import whatapps from '../../image/Footer/WhatsApp.png';
import Slider1 from "../../image/Blog/BusinessInsuranceCoverFloods1903x500.png";

class BlogDetailsDoesBusinessInsuranceCoverFloods extends Component {
    state = {

    }
    constructor(props) {
        super(props)
   
    }


    componentDidMount() {
        window.scrollTo(0, 0);
    }

    render() {
       
        

        return (
            <div>
                <Row>
                    <Col xs="12" sm="12" lg="12">
                        <div className="blog-image">
                            <img alt="" style={{ height: "500px", width: "100%" }} src={Slider1}>
                            </img>
                        </div>
                        <div className="blog-text">
                            <h1 style={{ fontSize: "50px", color: "white" }}>Does Homeowners Insurance Cover Wildfires? </h1>
                            <br />
                            <br />
                            <p> <img src={'../../assets/img/Insubee_Logo.png'} className="fluid" width="20" height="20" /> &nbsp; Team Insubee</p>
                        </div>
                    </Col>
                </Row>

                <br />
                <Row>
                    <Col xs="12" sm="12" lg="11">
                        <div className="container pt-3" style={{paddingLeft:"8em", paddingRight:"0em"}}>

                            <h5><b>Does Homeowners Insurance Cover Wildfires? </b></h5>

                            <p>According to the Department of Irrigation and Drainage Malaysia, due to the large number of 
                            rivers running through the country, roughly 9 percent of the total land area is under flood risk.
                            </p>
                            <br />

                            <p>If you factor in cyclical monsoons between November and February as well as the rapid pace of 
                            urbanisation affecting the land's ability to absorb water, you have a country increasingly 
                                flood-prone — which is further exacerbated by climate change. 
                            </p>
                            <br />

                            <p>In 2014, when the floods swept through Kelantan, more than 13,000 SMEs were affected. In 2017,
                            subsequent floods cost the state more the RM30 million in damages. 
                            </p>
                            <br />

                            <p>Kelantan isn’t the only state affected, Johor, Selangor, Melaka, and 
                            other Malaysian states experience floods each year.
                            </p>
                            <br />

                            <p>
                                So what can businesses do to protect themselves from the negative impact of floods? 
                                Can they take preventive measures? Yes. Are they enough? Maybe not. 
                            </p>
                            <br />

                            <p>
                                And this is where insurance comes in, but the question is, does business insurance cover floods? 
                            </p>
                            <br />

                            <p>The short answer is yes, but with a few caveats.
                            </p>
                            <br />

                            <p>
                                In this article, we’ll be exploring some of the conditions in which business insurance covers floods,
                                and also dive into some precautionary measures one can take to lessen flood impact. 
                            </p>
                            <br />

                            <h5><b>Preparation Walks Hand in Hand with Survival</b></h5>
                            <p>There’s no doubt as to the dangers of floods, they’ve become an increasing threat to our way of life. As such, having an insurance plan is almost a necessity. But – as they always say – prevention is better than cure.
                            So having sufficient preparations in the worst-case scenario is always ideal. 
                            </p>
                            <br />

                            <p>Businesses can employ several preventive and protective measures to safeguard assets from flood damage. 
                            </p>
                            <br />

                            <p>These can range from adopting a business continuity plan to ensure continued operations, 
                            to moving essential business equipment to a higher level. 
                            </p>
                            <br />

                            <p>It is also wise to invest in flood-resistant protection measures, as well as having a flood emergency kit ready and easily 
                            accessible on-site with all the necessary items and tools you’ll need to tide you over. 
                            </p>
                            <br />

                            <p>At the end of the day, it is difficult to anticipate a flood, 
                            which makes having insurance even more important to mitigate the losses to your business.  
                            </p>
                            <br />

                            <h5><b>The Insurance Option.</b></h5>
                            <p>It is easy to assume that your insurance plan will cover flood damage, after all, it classifies as a disaster. Right? 
                            </p>
                            <br />

                            <p>Not so. Depending on the insurance plan you subscribe to, it is more than likely that you’ll need to pay an additional premium to cover flood damages. 
                            </p>
                            <br />

                            <p>When reviewing your insurance policy, it is important to understand what you are insured against and what you aren’t. You need to be aware of details such as what is considered flood damage, 
                            or whether you are covered if the damage is insured under different conditions. 
                            </p>
                            <br />

                            <p>As a general rule, a flood is considered as the overflowing of water out of its artificial (e.g man-made water bodies) or natural (e.g lakes, rivers) course; 
                            or water that bursts out of (or) overflows from water mains, or water accumulated from a source 
                            outside the property insured.
                            </p>
                            <br />

                            <p>And so, damage caused by wind or by hail, and even by subsidence or landslips will not be considered as flood damage.
                            </p>
                            <br />

                            <p>You also need to be aware of how much your insurance company is liable to pay, which can vary from individual to individual. 
                            </p>
                            <br />

                            <p>At the end of the day, the question you have to ask yourself is, how far am you willing to go to protect your business? If you are certain that the chances of a flood occurring are low and you are working within a limited budget, then you may forego paying the premium to cover flood damage. 
                            Still, with the acceleration of climate change, it’s always better to be safer than sorry.
                            </p>
                            <br />

                            <p>Sources:
                            </p>
                            <p>https://www.malaymail.com/news/what-you-think/2021/11/05/reducing-greenhouse-gas-emissions-is-not-enough-we-also-need-to-adapt-to-th/2018615 
                            </p>
                            <p>https://www.brinknews.com/19987/ 
                            </p>
                            <p>main.pdf (sciencedirectassets.com)
                            </p>
                            <p>https://www.smecorp.gov.my/index.php/my/news/3709-changing-flood-risks-for-urban-sme-communities 
                            </p>
                            <p>https://www.thestar.com.my/news/focus/2021/10/31/bracing-for-floods 
                            </p>
                           
                        </div>
                    </Col>
                        <Col xs="12" sm="12" lg="1">
                        <div class="icon-bar">
                            <p> Share 
                            <a href="https://www.facebook.com/GDEXOfficialPage/"><img src={facebook} width="25px" height="25px" /></a>
                            <a href="https://twitter.com/GDEX_Official/" class="twitter"><img src={instagram} width="25px" height="25px" /></a>
                                <a href="https://www.youtube.com/channel/UCei9LfDrXmLVGdEIfQKHflQ" class="whatapps"><img src={whatapps} width="25px" height="25px" /></a>
                            </p>
                                </div>

                    </Col>
                </Row>

                <br />  
                
            </div>
        )
        
    }
}


export default BlogDetailsDoesBusinessInsuranceCoverFloods;