import React from 'react';

class PaymentPresentation extends React.Component {
    constructor(props) {
        super(props);

        this.state = {

        }

        this.formSubmit = React.createRef();
    }

    

    componentDidMount() {

        const { payment, product } = this.props;

        //do submit to ipay88
        if (typeof product !== 'undefined') {
            if (typeof product.Request !== 'undefined') {
                this.formSubmit.current.submit();
            }
        }

        //this.props.history.push('/PaymentSuccess');
    }

    render() {
        const { payment, product } = this.props;
        var hiddenInput = [];

        if (typeof product !== 'undefined') {
            if (typeof product.Request !== 'undefined') {
                var keyIdx = 0;
                for (var key in product.Request) {
                    if (product.Request.hasOwnProperty(key)) {
                        hiddenInput.push(<input key={keyIdx} type="hidden" name={key} value={product.Request[key] != null ? product.Request[key] : ""} />);
                        keyIdx++;
                    }
                }
            }
        }


        return (
            <div className="app dashboard-app-body animated fadeIn">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-12 bg-white body-boxShadow" style={{ paddingTop: "15px", paddingBottom: "15px" }}>
                            <form method="POST" action="https://payment.ipay88.com.my/epayment/entry.asp" ref={this.formSubmit}>

                                {hiddenInput.length > 0 &&
                                    hiddenInput
                                }
                                {/*<input type="hidden" name="MerchantCode" value="M36403"/>*/}
                                {/*<input type="hidden" name="PaymentId" value=""/>*/}
                                {/*<input type="hidden" name="RefNo" value="IST000011"/>*/}
                                {/*<input type="hidden" name="Amount" value="485.25"/>*/}
                                {/*<input type="hidden" name="Currency" value="MYR"/>*/}
                                {/*<input type="hidden" name="ProdDesc" value="SME Biz One"/>*/}
                                {/*<input type="hidden" name="UserName" value="Mengwei"/>*/}
                                {/*<input type="hidden" name="UserEmail" value="mengwei@gdexpress.com"/>*/}
                                {/*<input type="hidden" name="UserContact" value="0169611718"/>*/}
                                {/*<input type="hidden" name="Remark" value=""/>*/}
                                {/*<input type="hidden" name="Lang" value="UTF-8"/>*/}
                                {/*<input type="hidden" name="SignatureType" value="SHA256"/>*/}
                                {/*<input type="hidden" name="Signature" value="9c8207890ab6196f2d8a3bfc4617c0115531abb273a2d12569303adca67420ad"/>*/}
                                {/*<input type="hidden" name="ResponseURL" value="https://insubee-dev.azurewebsites.net/Payment/ResponseUri"/>*/}
                                {/*<input type="hidden" name="BackendURL" value="https://insubee-dev.azurewebsites.net/Payment/BackendUri" />*/}
                                {/*<input type="submit" value="Proceed with Payment" name="Submit"/>*/}
                            </form>


                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default PaymentPresentation;
