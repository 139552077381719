import userConstants from './constants';
import accountConstants from '../Account/constants';
import Swal from 'sweetalert2'
const initialState = {
    showSuccess: false,
};


export const reducer = (state, action) => {
    state = state || initialState;

    switch (action.type) {
        
        //get my profile
        case userConstants.GET_PROFILE_REQUEST:
            return {
                ...state,
                userProfileSuccess: false,
                userData: undefined,
                isLoading: true
            }
        case userConstants.GET_PROFILE_SUCCESS:
            return {
                ...state,
                userProfileSuccess: true,
                userData: action.data,
                isLoading: false
            }
        case userConstants.GET_PROFILE_FAILURE:
            return {
                ...state,
                userProfileSuccess: false,
                userData: undefined,
                isLoading: false
            }
        //CHANGE PASSWORD
        case userConstants.USER_CHANGE_PASSWORD_REQUEST:
            return {
                ...state,
                isLoading: false
            }
        case userConstants.USER_CHANGE_PASSWORD_SUCCESS:
            Swal.fire(
                'Change Password Success',
                '',
                'success'
            )
            return {
                ...state,
                IsSuccess: true,
                isLoading: false
            }
        case userConstants.USER_CHANGE_PASSWORD_FAIL:
            Swal.fire(
                'Change Password Fail',
                '',
                'warning'
            )
            return {
                ...state,
                isLoading: false
            }
        case userConstants.USER_SHOW_CHANGE_PASSWORD_FAIL:
            Swal.fire(
                'Change Password Fail',
                '',
                'warning'
            )
            return {
                ...state,
                isLoading: false
            }
        //PROFILE UPDATE
        case userConstants.POST_UPDATEPROFILE_REQUEST:
            return {
                ...state,
                isLoading: false,
                updateProfileSuccess:false
            }
        case userConstants.POST_UPDATEPROFILE_SUCCESS:
            Swal.fire(
                'Update Profile Success',
                '',
                'success'
            )
            return {
                ...state,
                isLoading: false,
                updateProfileSuccess: true
            }
        case userConstants.POST_UPDATEPROFILE_FAILURE:
            Swal.fire(
                'Update Profile Fail',
                '',
                'warning'
            )
            return {
                ...state,
                isLoading: false,
                updateProfileSuccess: false
            }
        
        //LOCATION
        case accountConstants.GET_FROMLOCATION_REQUEST:
            return {
                ...state,
                locationList: undefined,
                isLoading: true,
            }
        case accountConstants.GET_FROMLOCATION_SUCCESS:
            return {
                ...state,
                locationList: action.data,
                isLoading: false,
            }
        //NATIONAL
        case accountConstants.GET_NATIONALITY_SUCCESS:
            return {
                ...state,
                nationalityList: action.data,
                isLoading: false
            }
        case accountConstants.GET_NATIONALITY_FAILURE:
            return {
                ...state,
                isLoading: false
            }
        //COMMON PASSWORD
        case accountConstants.GET_COMMON_PASSWORD_SUCCESS:
            return {
                ...state,
                commonPasswordList: action.data,
                isLoading: false
            }
        case accountConstants.GET_COMMON_PASSWORD_FAILURE:
            return {
                ...state,
                isLoading: false
            }
        default:
            return state;
    }
}
