import constants from '../_constants/constants';
import Cookie from 'universal-cookie'
import { handleResponse } from "../_helpers/handleResponse";
import { authHeader, authHeaderFile, authHeaderWithoutUser } from '../_helpers/authHeader';

const cookies = new Cookie();

const ProductServices = {
    getLocationByFromPostcode,
    getMasterEntry,
    selectPlan,
    getStateHoliday,
    createPolicy,
    UserCreatePolicy,
    GuestCreatePolicy,
    UserCalculateDiscountVoucher,
    GuestCalculateDiscountVoucher
}


function getLocationByFromPostcode(postcode) {
    const requestOptions = {
        method: "GET",
        headers: authHeader()
    };

    const url = "/api/Home/LocationByPostcode/" + postcode;

    return fetch(url, requestOptions).then(
        (response) => handleResponse(response, requestOptions, url)
    ).then(data => {
        return data;
    });
}


function getStateHoliday() {
    const requestOptions = {
        method: "GET",
        headers: authHeader()
    };

    return fetch(constants.apigetStateHoliday, requestOptions).then(
        (response) => handleResponse(response, requestOptions, constants.apigetStateHoliday)
    ).then(data => {
        return data;
    });
}

function getMasterEntry() {
    const requestOptions = {
        method: "GET",
        headers: authHeader()
    };

    return fetch(constants.apigetMasterEntry, requestOptions).then(
        (response) => handleResponse(response, requestOptions, constants.apigetMasterEntry)
    ).then(data => {
        return data;
    });
}


function selectPlan(QuoteListModel) {
    const requestOptions = {

        method: "POST",
        headers: authHeader(),
        body: JSON.stringify(QuoteListModel)
    };


    return fetch(constants.apigetQuote, requestOptions).then(
        (response) => handleResponse(response, requestOptions, constants.apigetQuote)
    ).then(data => {
        return data;
    });
}


function createPolicy(Result) {
    const requestOptions = {

        method: "POST",
        headers: authHeader(),
        body: JSON.stringify(Result)
    };


    return fetch(constants.apiCreatePolicy, requestOptions).then(
        (response) => handleResponse(response, requestOptions, constants.apiCreatePolicy)
    ).then(data => {
        return data;
    });
}


function UserCreatePolicy(Result) {
    const requestOptions = {

        method: "POST",
        headers: authHeader(),
        body: JSON.stringify(Result)
    };


    return fetch(constants.apiUserCreatePolicy, requestOptions).then(
        (response) => handleResponse(response, requestOptions, constants.apiUserCreatePolicy)
    ).then(data => {
        return data;
    });
}


function GuestCreatePolicy(Result) {
    const requestOptions = {

        method: "POST",
        headers: authHeader(),
        body: JSON.stringify(Result)
    };


    return fetch(constants.apiGuestCreatePolicy, requestOptions).then(
        (response) => handleResponse(response, requestOptions, constants.apiGuestCreatePolicy)
    ).then(data => {
        return data;
    });
}


function UserCalculateDiscountVoucher(Result) {
    const requestOptions = {

        method: "POST",
        headers: authHeader(),
        body: JSON.stringify(Result)
    };


    return fetch(constants.apiUserCalculateDiscountVoucher, requestOptions).then(
        (response) => handleResponse(response, requestOptions, constants.apiUserCalculateDiscountVoucher)
    ).then(data => {
        return data;
    });
}


function GuestCalculateDiscountVoucher(Result) {
    const requestOptions = {

        method: "POST",
        headers: authHeader(),
        body: JSON.stringify(Result)
    };


    return fetch(constants.apiGuestCalculateDiscountVoucher, requestOptions).then(
        (response) => handleResponse(response, requestOptions, constants.apiGuestCalculateDiscountVoucher)
    ).then(data => {
        return data;
    });
}


export default ProductServices;
