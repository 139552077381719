import HomeConstants from './constants';
import constants from '../_constants/constants';
import HomeServices from "./services";
import Swal from 'sweetalert2';
import { push } from 'connected-react-router';
import { hot } from "react-hot-loader";
import { change, reset } from "redux-form";
import { toast } from 'react-toastify';

const actions = {
    SendEmail,
    SendUserInterest,
    getMasterEntry,
}


function SendEmail(emailDetails) {

    return dispatch => {
        //dispatch(request(Result));
        HomeServices.SendEmail(emailDetails).then(
            data => {
                dispatch(success(data));
                Swal.fire({
                    title: 'Thank you for reaching out!',
                    message: '',
                    type: 'success',
                });
                dispatch(reset('ContactUsForm'));
                
            },
            error => {
                dispatch(fail(error));
            }

        )
    }

    function success(data) { return { type: HomeConstants.HOME_SENDEMAIL_SUCCESS, data } }
    function fail(error) { return { type: HomeConstants.HOME_SENDEMAIL_FAIL, error } }

}

function SendUserInterest(userInterest) {

    return dispatch => {
        //dispatch(request(Result));
        HomeServices.SendUserInterest(userInterest).then(
            data => {
                dispatch(success(data));
                Swal.fire({
                    title: 'Thank you for getting in touch, we will get back to you soon',
                    message: '',
                    type: 'success',
                });
                dispatch(reset('LandingInfoForm'));

            },
            error => {
                dispatch(fail(error));
            }

        )
    }

    function success(data) { return { type: HomeConstants.HOME_USERINTEREST_SUCCESS, data } }
    function fail(error) { return { type: HomeConstants.HOME_USERINTEREST_FAIL, error } }

}

function getMasterEntry() {

    return dispatch => {
        dispatch(request());
        HomeServices.getMasterEntry().then(
            data => {
                dispatch(success(data.result));
            },
            error => {
                dispatch(failure(error.errorMessage));
            }
        )
    }
    function request(data) { return { type: HomeConstants.GET_MASTERENTRY_REQUEST, data } }
    function success(data) { return { type: HomeConstants.GET_MASTERENTRY_SUCCESS, data } }
    function failure(error) { return { type: HomeConstants.GET_MASTERENTRY_FAILURE, error } }

}

export default hot(module)(actions);