
import React from 'react';
import RoleListPresentation from './RoleListPresentation';
import { connect } from 'react-redux';

// Data return from reducer (Redux store).  ( /store/configureStore)
function mapStateToProps(state) {

    //const { cart } = state || {}; // entire Redux store state (the same value returned by a call to store.getState()).       


    return {};
}

// Calling Api via dispatching action
// Triggered from child Presentation
function mapDispatchToProps(dispatch) {
    return {

    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(RoleListPresentation);
