import Cookies from 'universal-cookie';
import constants from '../_constants/constants';
import { authHeader, authHeaderFile } from './authHeader';
import Swal from 'sweetalert2';
//import accountServices from '../Account/services';

const cookies = new Cookies();

export async function handleResponse(response, requestOptions, url) {
    if (response.status === 401) {

        //temporary return homepage, wait for refresh token db
        //Swal.fire({
        //    title: 'Session Timeout',
        //    message: '',
        //    type: 'error',
        //    timer: 900,
        //}).then((result) => {
        //    window.location.href = "/";
        //})
        

        let apiResponeReturnData;

        const refreshRequestOptions = {
            method: "GET",
        };

        var accessToken = sessionStorage.getItem(constants.tokenName);
        var refreshToken = sessionStorage.getItem(constants.refreshToken);

        await fetch(constants.apiRefreshToken + "?accessToken=" + encodeURIComponent(accessToken) + "&refreshToken=" + encodeURIComponent(refreshToken), refreshRequestOptions).then(
            async (tokenResponse) => {
                return await tokenResponse.json().then(async tokenData => {
                    if (!tokenResponse.ok) {
                        window.location.href = "/";
                        return Promise.reject(tokenData);
                    }

                    const setCookiesOptions = {
                        path: "/",

                    }

                    cookies.set(constants.refreshToken, tokenData.result.refreshToken, setCookiesOptions);
                    cookies.set(constants.tokenName, tokenData.result.accessToken, setCookiesOptions);

                    sessionStorage.setItem(constants.refreshToken, tokenData.result.refreshToken);
                    sessionStorage.setItem(constants.tokenName, tokenData.result.accessToken);

                    if (tokenData.status === "success") {
                        requestOptions.headers = authHeader();

                        if (url.includes("submitClaim")) {
                            requestOptions.headers = authHeaderFile();
                        }

                        await fetch(url, requestOptions).then(
                            async (recallResponse) => {
                                return await recallResponse.json().then(apiData => {
                                    if (!recallResponse.ok) {
                                        return Promise.reject(apiData);
                                    }

                                    apiResponeReturnData = apiData;

                                    return apiData;
                                });
                            }
                        );
                    }
                });
            }
        );

        return apiResponeReturnData;
    }
    else {
        return response.json().then(data => {
            if (!response.ok) {
                return Promise.reject(data);
            }

            return data;
        });
    }
}

export async function handleBlobResponse(response, requestOptions, url) {
    if (response.status === 401) {
        let apiResponeReturnData;

        const refreshRequestOptions = {
            method: "GET",
        };

        var accessToken = sessionStorage.getItem(constants.tokenName);
        var refreshToken = sessionStorage.getItem(constants.refreshToken);

        await fetch(constants.apiRefreshToken + "?accessToken=" + encodeURIComponent(accessToken) + "&refreshToken=" + encodeURIComponent(refreshToken), refreshRequestOptions).then(
            async (tokenResponse) => {
                return await tokenResponse.json().then(async tokenData => {
                    if (!tokenResponse.ok) {
                        //accountServices.logout();
                        window.location.href = "/";
                        return Promise.reject(tokenData);
                    }

                    const setCookiesOptions = {
                        path: "/",

                    }

                    cookies.set(constants.refreshToken, tokenData.result.refreshToken, setCookiesOptions);
                    cookies.set(constants.tokenName, tokenData.result.accessToken, setCookiesOptions);

                    sessionStorage.setItem(constants.refreshToken, tokenData.result.refreshToken);
                    sessionStorage.setItem(constants.tokenName, tokenData.result.accessToken);

                    if (tokenData.status === "success") {
                        requestOptions.headers = authHeader();

                        await fetch(url, requestOptions).then(
                            async (recallResponse) => {
                                return await recallResponse.blob().then(apiData => {
                                    if (!recallResponse.ok) {
                                        return Promise.reject(apiData);
                                    }

                                    apiResponeReturnData = apiData;

                                    return apiData;
                                });
                            }
                        );
                    }
                });
            }
        );

        return apiResponeReturnData;
    } else {
        return response.blob().then(data => {
            if (!response.ok) {
                const error = (data && data.e) || response.statusText;

                return Promise.reject(error);
            }
            return data;
        })
    }
}

// export function handleBlobResponse(response) {
//     return response.blob().then(data => {
//         if (response.ok) {
//             return data;
//         } else {
//             if (response.status === 401) {
//                 return Promise.reject("Unauthorized access. Please re-login to try again.");
//             } else if (response.status === 500) {
//                 return Promise.reject("Server side error. Please contact admin to resolve your probelm.");
//             } else {
//                 const error = (data && data.e) || response.statusText;
//                 return Promise.reject(error);
//             }
//         }
//     })
// }

export function handleError(error) {
    // const error = (data && data.e) || response.statusText;
    return Promise.reject(error && error.message);
}
