const claimConstants = {

    GET_ALLCLAIM_REQUEST: 'GET_ALLCLAIM_REQUEST',
    GET_ALLCLAIM_SUCCESS: 'GET_ALLCLAIM_SUCCESS',
    GET_ALLCLAIM_FAILURE: 'GET_ALLCLAIM_FAILURE',

    SUBMIT_CLAIM_REQUEST: 'SUBMIT_CLAIM_REQUEST',
    SUBMIT_CLAIM_SUCCESS: 'SUBMIT_CLAIM_SUCCESS',
    SUBMIT_CLAIM_FAILURE: 'SUBMIT_CLAIM_FAILURE',

    GET_CLAIM_REQUEST: 'GET_CLAIM_REQUEST',
    GET_CLAIM_SUCCESS: 'GET_CLAIM_SUCCESS',
    GET_CLAIM_FAILURE: 'GET_CLAIM_FAILURE',

    GET_USERPOLICY_REQUEST: 'GET_USERPOLICY_REQUEST',
    GET_USERPOLICY_SUCCESS: 'GET_USERPOLICY_SUCCESS',
    GET_USERPOLICY_FAILURE: 'GET_USERPOLICY_FAILURE',

    DOWNLOAD_CLAIM_REQUEST: 'DOWNLOAD_CLAIM_REQUEST',
    DOWNLOAD_CLAIM_SUCCESS: 'DOWNLOAD_CLAIM_SUCCESS',
    DOWNLOAD_CLAIM_FAILURE: 'DOWNLOAD_CLAIM_FAILURE',

    CANCEL_CLAIM_REQUEST: 'CANCEL_CLAIM_REQUEST',
    CANCEL_CLAIM_SUCCESS: 'CANCEL_CLAIM_SUCCESS',
    CANCEL_CLAIM_FAILURE: 'CANCEL_CLAIM_FAILURE',
}

export default claimConstants;