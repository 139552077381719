import React, { Component } from 'react';
import { Button, FormGroup, Label, Input, Row, Col, Card, CardBody, InputGroup, InputGroupText } from 'reactstrap';
import "./style.css";
import { Field, reduxForm } from 'redux-form';
import ValidateField from "../GlobalComponents/FormElements/ValidateField";
import { required, passwordsMatch} from '../GlobalComponents/ValidationRules';
import Swal from 'sweetalert2';
import PasswordStrengthBar from 'react-password-strength-bar';


class ResetPasswordForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            IsDisable: true,
        };
        this.handleChange = this.handleChange.bind(this);
        this.onchangePasswordMeter = this.onchangePasswordMeter.bind(this);
    }

    handleChange(event) {

        const target = event.target;
        const value = target.value;

        const name = target.name;

        this.setState({
            [name]: value
        });
    }

    onchangePasswordMeter(event) {

       
    }

    render() {
        const { handleSubmit, change, email } = this.props;
        const { Password } = this.state;
        change("email", email);
        return (
            <form onSubmit={handleSubmit} >
                <div className="my-5">
                    <div className="container">
                        <Row className="text-center">
                            <Col xs="12" sm="12" lg="12">
                                <div className="col-sm-12">
                                    <h2 className="font-weight-bold">Set New Password</h2>
                                </div>
                                <div className="row">
                                    <Card className="col-sm-12 border-0">
                                        <CardBody>
                                            <Row form className="text-left justify-content-center">
                                                <Col sm={6}>
                                                    <FormGroup>
                                                        <Label className="col-sm-12">
                                                            New Password
                                                        </Label>
                                                        <Col sm={12}>
                                                            <Field className="form-control" name="Password" id="password" label="Password" autoComplete="nope" component={ValidateField} validate={[required]} onChange={this.handleChange} type="password" />
                                                            <PasswordStrengthBar
                                                                onChangeScore={this.onchangePasswordMeter}
                                                                minLength={8}
                                                                password={Password} />
                                                        </Col>
                                                    </FormGroup>
                                                    <FormGroup>
                                                        <Label className="col-sm-12">
                                                            Confirm Password
                                                        </Label>
                                                        <Col sm={12}>
                                                            <Field className="form-control" name="confirmPassword" label="Confirm Password" id="confirmPassword" autoComplete="nope" component={ValidateField} validate={[required, passwordsMatch]} type="password" />
                                                        </Col>
                                                    </FormGroup>
                                                    <FormGroup>
                                                        <Col sm={6} style={{ float:'right'}}>
                                                            <Button color="warning" style={{width:'100%'}} className="btn font-weight-bold btn-sm btn-insubee-orange" size="sm">Apply</Button>
                                                        </Col>
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                        </CardBody>
                                    </Card>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </div>
            </form>

        );
    }






}



ResetPasswordForm = reduxForm({
    // a unique name for the form
    form: 'ResetPasswordForm',
    destroyOnUnmount: true,
    initialValues: {

    }
})(ResetPasswordForm)





export default ResetPasswordForm;
