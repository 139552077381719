// SignUpContainer
import React from 'react';

import CompleteRegistrationPresentation from './CompleteRegistrationPresentation';



const CompleteRegistration = props => (
    <CompleteRegistrationPresentation />
);

export default CompleteRegistration;
