import React from 'react'
import { Col, Input, Form, FormGroup, Row, Button, Label } from 'reactstrap'
class ValidateField extends React.Component {
    constructor(props) {
        super(props);

        this.disableWheelScroll = this.disableWheelScroll.bind(this);
    }

    shouldComponentUpdate(nextProps) {
        const { meta, input } = nextProps;


        return true;
    }

    disableWheelScroll(e) {
        if (this.props.type == "number") {
            e.target.addEventListener('mousewheel', function (e) {
                e.preventDefault();
            })
        }
    }

    render() {
        const {
            input,
            placeholder,
            className,
            label,
            type,
            disabled,
            pattern,
            minQty,
            maxLength,
            minLength,
            min,
            errorclassname,
            required,
            max,
            meta: { touched, error, warning }
        } = this.props;


        let customizeErrorMessage = null;

        if (input.name.includes('qty') && error) {
            customizeErrorMessage = `Min order ${minQty} packs`;
        }

        return (
            <React.Fragment>

                {/*<div className="lowerindex">*/}
                {/*    <label className="textboxlabel">{label !== undefined ? label.toUpperCase() : label}{required === "true" ? <span className="text-red"> *</span> : ""}</label>*/}
                {/*</div>*/}
                <input

                    {...input}

                    className={className + (touched ? error || warning ? " is-invalid" : "" : "")}

                    placeholder={(placeholder)}
                    onFocus={this.disableWheelScroll}
                    type={type} disabled={disabled}
                    pattern={pattern}
                    autoComplete="off"
                    maxLength={maxLength}
                    minLength={minLength}
                    min={min}
                    max={max}
                    errorclassname={errorclassname}
                    required={required}
                />
                {this.props.children}
                {touched && customizeErrorMessage === null &&
                    (
                    (error && <span className={errorclassname !== undefined ? errorclassname : "invalid-feedback2"}>{(label || input.name) + " " + error}</span>) ||
                        (warning && <span className="invalid-feedback">{warning}</span>)
                    )
                }

                {customizeErrorMessage !== null &&
                    <span className="invalid-feedback">{customizeErrorMessage}</span>
                }
            </React.Fragment>
        )
    }
}

export default ValidateField;