const Browser = {
    // Firefox 1.0+
    isFirefox: () => {
        return typeof InstallTrigger !== 'undefined'
    },
    // Internet Explorer 6-11
    isIE: () => {
        return navigator.userAgent.indexOf('MSIE') !== -1 || !!document.documentMode
    },
    // Edge 20+
    isEdge: () => {
        return !Browser.isIE() && !!window.StyleMedia
    },
    // Chrome 1+
    isChrome: (context = window) => {
        return !!context.chrome
    },
    // At least Safari 3+: "[object HTMLElementConstructor]"
    isSafari: () => {
        return Object.prototype.toString.call(window.HTMLElement).indexOf('Constructor') > 0 ||
            navigator.userAgent.toLowerCase().indexOf('safari') !== -1
    },
    isMobile: () => {
        return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|Mobile|mobile|CriOS/i.test(navigator.userAgent)
    }
}

export default Browser
