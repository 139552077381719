import React from 'react';
import { connect } from 'react-redux';
import actions from './actions';
import SweetAlert from 'sweetalert2-react';
import PaginationComponent from "react-reactstrap-pagination";
import { Button, FormGroup, Label, Input, Row, Col, Card, CardBody, InputGroup, InputGroupText } from 'reactstrap';
import "./style.css";
import ExpiringLogo from "../../image/Policy/expiring.png";
import ExpiredLogo from "../../image/Policy/expired.PNG";
import "./hover.css";
import { numberWithCommas } from "../GlobalComponents/_function";

class PolicyListPresentation extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            status: 0,
            userData:[],
            policydata: [],
            rowPerPage: 10,
            name:""
        };
        this.goPolicyDetails = this.goPolicyDetails.bind(this);
        this.checkStatus = this.checkStatus.bind(this);
        this.handleSelected = this.handleSelected.bind(this);
    }

    goPolicyDetails(customerPolicyId) {
        window.localStorage.setItem('customerPolicyId', customerPolicyId);
        this.props.history.push('/PolicyDetails');
    }

    checkStatus(e,status, name) {
        e.preventDefault();
        this.setState({
            status: status,
            name: name
        })
        this.props.getPolicy(status, 1, this.state.rowPerPage);
        window.scrollTo(0, 0);
    }

    handleSelected(page) {
        this.props.getPolicy(this.state.status,page, this.state.rowPerPage);
        window.scrollTo(0, 0);
    }

    componentDidMount() {
        Promise.all([
            this.props.getProfile(),
            this.props.getPolicy(this.state.status, 1, this.state.rowPerPage)
        ]);
        
        window.scrollTo(0, 0);
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.policy.policyList !== 'undefined') {
            if (this.props.policy.policyList !== nextProps.policy.policyList) {
                if (typeof nextProps.policy.policyList !== 'undefined') {
                    this.setState({
                        policydata: nextProps.policy.policyList
                    });
                }
            }
        }
        if (nextProps.policy.userData !== 'undefined') {
            if (this.props.policy.userData !== nextProps.policy.userData) {
                if (typeof nextProps.policy.userData !== 'undefined') {
                    this.setState({
                        userData: nextProps.policy.userData
                    });
                }
            }
        }
    }

    //componentDidUpdate(prevProps) {

    //    if (typeof this.props.policy.policyList !== 'undefined' && this.props.policy.policySuccess !== prevProps.policy.policySuccess) {
    //        if (this.props.policy.policySuccess == true) {
    //            this.setState({
    //                policydata: this.props.policy.policyList
    //            })
    //        }
    //    }

    //}

    render() {

        let totalItem = 0;
        let userInfo = [];
        if (this.state.policydata !== null) {
            if (typeof this.state.policydata.itemViewListModel !== 'undefined') {
                if (Object.keys(this.state.policydata.itemViewListModel).length > 0) {
                    totalItem = this.state.policydata.totalItems;
                }
            }
        }
        if (this.state.userData) {
            userInfo = this.state.userData;
        }
        return (
            <div className="my-3">
                <div className="container">
                    <Row>
                        <Col xs="12" sm="12" lg="12">
                            <div className="col-sm-12">
                                <h2 className="font-weight-bold text-center"> {userInfo ? "Welcome Back, " + userInfo.lastName : "" }</h2>
                            </div>
                            <br/>
                            <div className="col-sm-12">
                                <h2 className="font-weight-bold text-center" >My Policies</h2>
                            </div>
                            <br/>
                            <div className="col-sm-12 quick-links-cont flex justify-content-center">
                                <div className="col-sm-12 text-center">
                                    <a href="" onClick={e => this.checkStatus(e, 0)} className="col-sm-2 mx-5 items-center flex-wrap flex-no-wrap justify-center noUnder">

                                        <h5 className={this.state.status == 0 ? ' hvr-text custom-active' : 'hvr-text overline-left-underline-right'} style={{ color: this.state.status == 0 ? '#FFDF00' : '' }}>ALL</h5>
                                    </a>
                                    <a href="" onClick={e => this.checkStatus(e, 5, "New")} className="col-sm-2 mx-5 items-center flex-wrap flex-no-wrap justify-center noUnder">

                                        <h5 className={this.state.status == 5 ? ' hvr-text custom-active' : 'hvr-text overline-left-underline-right'} style={{ color: this.state.status == 5 ? '#FFDF00' : '' }}>New</h5>
                                    </a>
                                    <a href="" onClick={e => this.checkStatus(e, 2, "Active")} className="col-sm-2 mx-5 items-center flex-wrap flex-no-wrap justify-center noUnder">

                                        <h5 className={this.state.status == 2 ? ' hvr-text custom-active' : 'hvr-text overline-left-underline-right'} style={{ color: this.state.status == 2 ? '#FFDF00' : '' }}>ACTIVE</h5>
                                    </a>
                                    <a href="" onClick={e => this.checkStatus(e, 3, "Expiring")} className="col-sm-2 mx-5 items-center flex-wrap flex-no-wrap justify-center noUnder">

                                        <h5 className={this.state.status == 3 ? ' hvr-text custom-active' : 'hvr-text overline-left-underline-right'} style={{ color: this.state.status == 3 ? '#FFDF00' : '' }}>EXPIRING</h5>
                                    </a>
                                    <a href="" onClick={e => this.checkStatus(e, 4, "Expired")} className="col-sm-2 mx-5 items-center flex-wrap flex-no-wrap justify-center noUnder">

                                        <h5 className={this.state.status == 4 ? ' hvr-text custom-active' : 'hvr-text overline-left-underline-right'} style={{ color: this.state.status == 4 ? '#FFDF00' : '' }}>EXPIRED</h5>
                                    </a>
                                </div>
                                
                            </div>
                            <br/>
                            <div className="row">
                                {/*{*/}
                                {/*    this.state.status == 0 ?*/}
                                {/*        <div className="col-sm-12 text-center">*/}
                                {/*                <p className="text-danger pl-4 pt-4 pb-4 pr-4" >There is no policy now.</p>*/}
                                {/*            </div> : null*/}
                                {/*}*/}
                                {
                                    this.state.policydata !== null && 
                                        typeof this.state.policydata.itemViewListModel !== 'undefined' &&
                                            Object.keys(this.state.policydata.itemViewListModel).length > 0
                                        ?
                                        this.state.policydata.itemViewListModel.map((data, index)=> {

                                        return (
                                            <div className="col-sm-4" key={index}>
                                                <div className="card hover-card mt-3 mb-3" onClick={()=>this.goPolicyDetails(data.customerPolicyId)}>
                                                    <div className="card-body">
                                                        {/*<div className="h4 card-title">*/}
                                                        {/*    SME BIZ ONE*/}
                                                        {/*</div>*/}
                                                        
                                                        <div className="d-flex">
                                                            <div className="flex-grow-1">
                                                                <div className="h4 card-title">
                                                                    SME BIZ ONE
                                                                </div>
                                                            </div>
                                                            {
                                                                (data.statusId === 3)?
                                                                <div className="mini-stat-icon avatar-sm align-self-center rounded-circle">
                                                                    <img src={ExpiringLogo} height="60" />
                                                                    </div> : (data.statusId === 4) ?
                                                                <div className="mini-stat-icon avatar-sm align-self-center rounded-circle">
                                                                    <img src={ExpiredLogo} height="60" />
                                                                </div> : null
                                                            }
                                                        </div>


                                                        <div className="card-content">
                                                            Business
                                                        </div>
                                                        <hr></hr>
                                                        <div className="card-content text-muted">
                                                            Policy No : <strong className="text-dark">{data.policyNo !== null ? data.policyNo : "Pending"}</strong>
                                                        </div>
                                                        <div className="card-content text-muted">
                                                            Status : <strong className="text-dark">{data.status}</strong>
                                                        </div>
                                                        <div className="card-content text-muted">
                                                            Premium Due Date : <strong className="text-dark">{data.premiumDueDate !== null ? data.premiumDueDate:"Pending"}</strong>
                                                        </div>
                                                        <div className="card-content text-muted">
                                                            Premium Amount : <strong className="text-dark"> {data.totalPremium !== null ? "RM " +numberWithCommas(data.totalPremium.toFixed(2)) :"Pending"}</strong>
                                                        </div>
                                                        <div className="card-content text-muted">
                                                            Coverage Amount : <strong className="text-dark">{data.totalCoverage !== null ? "RM " + numberWithCommas(data.totalCoverage.toFixed(2)) : "Pending"}</strong>
                                                        </div>
                                                        <div className="card-content text-muted">
                                                            Policy Holder : <strong className="text-dark">{data.policyHolderName}</strong>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            )
                                    }) : <div className="col-sm-12 text-center">
                                            <p className="text-danger pl-4 pt-4 pb-4 pr-4" >There is no {this.state.name} policy now.</p>
                                                        </div>
                                }
                                        
                                {/*{
                                {/*    this.state.status == 2? */}
                                {/*        <div className="col-sm-4">*/}
                                {/*            <div className="card hover-card mt-3 mb-3" onClick={this.goPolicyDetails}>*/}
                                {/*                <div className="card-body">*/}
                                {/*                    <div className="h4 card-title">*/}
                                {/*                        SME BIZ ONE*/}
                                {/*                    </div>*/}

                                {/*                    <div className="card-content">*/}
                                {/*                        Business*/}
                                {/*                    </div>*/}
                                {/*                    <hr></hr>*/}
                                {/*                    <div className="card-content text-muted">*/}
                                {/*                        Policy No : <strong className="text-dark">A12345678</strong>*/}
                                {/*                    </div>*/}
                                {/*                    <div className="card-content text-muted">*/}
                                {/*                        Status : <strong className="text-dark">Active</strong>*/}
                                {/*                    </div>*/}
                                {/*                    <div className="card-content text-muted">*/}
                                {/*                        Premium Due Date : <strong className="text-dark">17/11/2021</strong>*/}
                                {/*                    </div>*/}
                                {/*                    <div className="card-content text-muted">*/}
                                {/*                        Premium Amount : <strong className="text-dark">RM 123.00</strong>*/}
                                {/*                    </div>*/}
                                {/*                    <div className="card-content text-muted">*/}
                                {/*                        Coverage Amount : <strong className="text-dark">RM 10,000.00</strong>*/}
                                {/*                    </div>*/}
                                {/*                    <div className="card-content text-muted">*/}
                                {/*                        Policy Holder : <strong className="text-dark">Ali Baba</strong>*/}
                                {/*                    </div>*/}
                                {/*                </div>*/}
                                {/*            </div>*/}
                                {/*        </div>: null*/}
                                {/*}*/}

                                {/*{this.state.status == 4 ?*/}
                                {/*    <div className="col-sm-4">*/}
                                {/*        <div className="card hover-card mt-3 mb-3">*/}
                                {/*            <div className="card-body">*/}
                                {/*                <div className="d-flex">*/}
                                {/*                    <div className="flex-grow-1">*/}
                                {/*                        <div className="h4 card-title">*/}
                                {/*                            SME BIZ ONE*/}
                                {/*                        </div>*/}
                                {/*                    </div>*/}
                                {/*                    <div className="mini-stat-icon avatar-sm align-self-center rounded-circle">*/}
                                {/*                        <img src={ExpiredLogo} height="60" />*/}
                                {/*                    </div>*/}
                                {/*                </div>*/}

                                {/*                <div className="card-content">*/}
                                {/*                    Business*/}
                                {/*                </div>*/}
                                {/*                <hr></hr>*/}
                                {/*                <div className="card-content text-muted">*/}
                                {/*                    Policy No : <strong className="text-dark">A12345678</strong>*/}
                                {/*                </div>*/}
                                {/*                <div className="card-content text-muted">*/}
                                {/*                    Status : <strong className="text-dark">Expired</strong>*/}
                                {/*                </div>*/}
                                {/*                <div className="card-content text-muted">*/}
                                {/*                    Premium Due Date : <strong className="text-dark">17/11/2021</strong>*/}
                                {/*                </div>*/}
                                {/*                <div className="card-content text-muted">*/}
                                {/*                    Premium Amount : <strong className="text-dark">RM 123.00</strong>*/}
                                {/*                </div>*/}
                                {/*                <div className="card-content text-muted">*/}
                                {/*                    Coverage Amount : <strong className="text-dark">RM 10,000.00</strong>*/}
                                {/*                </div>*/}
                                {/*                <div className="card-content text-muted">*/}
                                {/*                    Policy Holder : <strong className="text-dark">Ali Baba</strong>*/}
                                {/*                </div>*/}
                                {/*            </div>*/}
                                {/*        </div>*/}
                                {/*    </div>*/}
                                {/*    : null}*/}

                                {/*{this.state.status == 3 ?*/}
                                {/*    <div className="col-sm-4">*/}
                                {/*        <div className="card hover-card mt-3 mb-3">*/}
                                {/*            <div className="card-body">*/}
                                {/*                <div className="d-flex">*/}
                                {/*                    <div className="flex-grow-1">*/}
                                {/*                        <div className="h4 card-title">*/}
                                {/*                            SME BIZ ONE*/}
                                {/*                        </div>*/}
                                {/*                    </div>*/}
                                {/*                    <div className="mini-stat-icon avatar-sm align-self-center rounded-circle">*/}
                                {/*                        <img src={ExpiringLogo} height="60" />*/}
                                {/*                    </div>*/}
                                {/*                </div>*/}


                                {/*                <div className="card-content">*/}
                                {/*                    Business*/}
                                {/*                </div>*/}
                                {/*                <hr></hr>*/}
                                {/*                <div className="card-content text-muted">*/}
                                {/*                    Policy No : <strong className="text-dark">A12345678</strong>*/}
                                {/*                </div>*/}
                                {/*                <div className="card-content text-muted">*/}
                                {/*                    Status : <strong className="text-dark">Expiring</strong>*/}
                                {/*                </div>*/}
                                {/*                <div className="card-content text-muted">*/}
                                {/*                    Premium Due Date : <strong className="text-dark">17/11/2021</strong>*/}
                                {/*                </div>*/}
                                {/*                <div className="card-content text-muted">*/}
                                {/*                    Premium Amount : <strong className="text-dark">RM 123.00</strong>*/}
                                {/*                </div>*/}
                                {/*                <div className="card-content text-muted">*/}
                                {/*                    Coverage Amount : <strong className="text-dark">RM 10,000.00</strong>*/}
                                {/*                </div>*/}
                                {/*                <div className="card-content text-muted">*/}
                                {/*                    Policy Holder : <strong className="text-dark">Ali Baba</strong>*/}
                                {/*                </div>*/}
                                {/*            </div>*/}
                                {/*        </div>*/}
                                {/*    </div>:null*/}
                                {/*    }*/}
                                
                            </div>
                            {
                                totalItem > 0 ? <div className="text-right">
                                    <PaginationComponent
                                        size="sm"
                                        totalItems={totalItem}
                                        pageSize={this.state.rowPerPage}
                                        onSelect={this.handleSelected}
                                    />
                                </div>:null
                            }
                            
                        </Col>
                    </Row>
                </div>
            </div>
        )
    }
}

const mapDispatchToProps = dispatch => {
    return {
        getProfile: (content) => {
            dispatch(actions.getProfile(content));
        },
        getPolicy: (statusId,page,rowPerPage) => {
            dispatch(actions.getPolicy(statusId, page, rowPerPage));
        }
    }
}

const mapStateToProps = state => {

    const {  policy, router } = state || {};

    return { policy, router };
};


export default connect(
    mapStateToProps,
    mapDispatchToProps
)(PolicyListPresentation)
