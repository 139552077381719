const adminClaimConstants = {

    GET_ADMINCLAIM_REQUEST: 'GET_ADMINCLAIM_REQUEST',
    GET_ADMINCLAIM_SUCCESS: 'GET_ADMINCLAIM_SUCCESS',
    GET_ADMINCLAIM_FAILURE: 'GET_ADMINCLAIM_FAILURE',

    GET_CLAIMDETAILS_REQUEST: 'GET_CLAIMDETAILS_REQUEST',
    GET_CLAIMDETAILS_SUCCESS: 'GET_CLAIMDETAILS_SUCCESS',
    GET_CLAIMDETAILS_FAILURE: 'GET_CLAIMDETAILS_FAILURE',

    UPDATE_ADMINCLAIM_REQUEST: 'UPDATE_ADMINCLAIM_REQUEST',
    UPDATE_ADMINCLAIM_SUCCESS: 'UPDATE_ADMINCLAIM_SUCCESS',
    UPDATE_ADMINCLAIM_FAILURE: 'UPDATE_ADMINCLAIM_FAILURE',

    DOWNLOAD_CLAIMPAYMENTADVICE_REQUEST: 'DOWNLOAD_CLAIMPAYMENTADVICE_REQUEST',
    DOWNLOAD_CLAIMPAYMENTADVICE_SUCCESS: 'DOWNLOAD_CLAIMPAYMENTADVICE_SUCCESS',
    DOWNLOAD_CLAIMPAYMENTADVICE_FAILURE: 'DOWNLOAD_CLAIMPAYMENTADVICE_FAILURE',

}

export default adminClaimConstants;